import {HTMLAttributes} from '../../../../framework/type';
import {h} from 'preact';
import App from '../../app';
import classes from '../../../../framework/classes';

interface Props extends HTMLAttributes {
  horizontal?: boolean;
  forceHorizontal?: boolean;
  endJustified?: boolean;
}

export default function FieldGroup({horizontal, forceHorizontal, endJustified, ...props}: Props) {
  const className = App.useStyle(({theme}) => ({
    ...theme.component.FieldGroup,
    display: 'flex',
    margin: '0.75rem 0',
    flexDirection: forceHorizontal
      ? 'row' as 'row'
      : 'column' as 'column',
    alignItems: 'flex-start',
    justifyContent: endJustified
      ? 'flex-end'
      : 'flex-start',
  }), [horizontal]);

  const mediaClass = App.useStyle(({theme}) => ({
    ...theme.media.mobileOnly({
      $nest: {
        '& > * + *': {
          marginTop: forceHorizontal
            ? void 0 as undefined
            : '0.3125rem !important',
          marginLeft: forceHorizontal
            ? '0.3125rem'
            : void 0 as undefined
        }
      }
    }),
    ...theme.media.tablet({
      flexDirection: forceHorizontal || horizontal
        ? 'row' as 'row'
        : 'column' as 'column',
      justifyContent: endJustified
        ? 'flex-end'
        : 'flex-start',
      $nest: {
        '& > * + *': {
          marginTop: forceHorizontal || horizontal
            ? void 0 as undefined
            : '0.3125rem !important',
          marginLeft: forceHorizontal || horizontal
            ? '0.3125rem !important'
            : void 0 as undefined
        }
      }
    })
  }));

  return (
    <div {...props} className={classes(className, mediaClass, props.className)}/>
  )
}