import {h} from 'preact';
import {ActionRenderer, EditorColumn, EditorPage, SaveHandler} from '../editor/type';
import TableGroup from '../editor/TableGroup';
import JobSection from '../../model/JobSection';
import {Job} from '../../model/Job';
import {CommandContainer} from '../../../../framework/type';
import renderCurrency from '../../helper/renderCurrency';
import renderPercentage from '../../helper/renderPercentage';
import {JobCompanyItem} from '../../model/JobCompanyItem';
import {JobCompany} from '../../model/JobCompany';

interface Props {
  job: Job;
  onSave?: SaveHandler<JobSection>;
  children?: ActionRenderer<JobSection>;
  currentCommand?: CommandContainer<any>;
}

export default function CostingsSectionsTable({currentCommand, job, onSave, children}: Props) {
  const allSections: JobSection[] = job && job.sections
    ? job.sections.all
    : [];

  const renderTotal = (page: number, items: JobSection[], key: keyof JobSection) => {
    if (!job) return '';

    switch (key) {
      case 'cost':
        return renderCurrency(job.cost);
      case 'profit':
        return renderCurrency(job.profit);
      case 'charge':
        return renderCurrency(job.charge);
      case 'markup':
        return renderPercentage(job.markup);

      default:
        return '';
    }
  };

  const columns: Array<EditorColumn<JobSection>> = [
    {
      key: 'order',
      label: '#',
      width: 32,
      numeric: true,
      editable: () => true,
      renderCell: (section) => {
        return section.order;
      }
    },
    {
      key: 'name',
      label: 'Section',
      width: 200,
      editable: (section) => {
        return !section || !section.items.all.some(i => i.locked)
      },
      renderCell: (section) => {
        return section.name;
      }
    },
    {
      key: 'markup',
      label: 'Markup',
      width: 120,
      numeric: true,
      editable: () => false,
      renderTotal,
      renderCell: (section) => {
        return renderPercentage(section.markup);
      }
    },
    {
      key: 'cost',
      label: 'Cost',
      width: 120,
      numeric: true,
      editable: () => false,
      renderTotal,
      renderCell: (section) => {
        return renderCurrency(section.cost);
      }
    },
    {
      key: 'profit',
      label: 'Profit',
      width: 120,
      numeric: true,
      editable: () => false,
      renderTotal,
      renderCell: (section) => {
        return renderCurrency(section.profit);
      }
    },
    {
      key: 'charge',
      label: 'Charge',
      width: 120,
      numeric: true,
      editable: () => false,
      renderTotal,
      renderCell: (section) => {
        return renderCurrency(section.charge);
      }
    }
  ];

  const pages: Array<EditorPage<JobSection>> = [
    {
      name: '',
      items: allSections
    }
  ];

  let filteredColumns = columns;

  if (!job || !job.details.showOrder) {
    filteredColumns = filteredColumns.filter(c => c.key !== 'order')
  }

  return (
    <TableGroup
      id="schedule_sections"
      currentCommand={currentCommand}
      columns={filteredColumns}
      pages={pages}
      singlePage={pages[0]}
      onSave={onSave}
      allowCreate={!!onSave}
      expandToFill={true}
      allowFullScreen={true}
      allowShrinkRows={true}
      children={children}
    />
  )
}