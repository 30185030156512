import {HTMLAttributes} from '../../../../framework/type';
import {h} from 'preact';
import App from '../../app';
import classes from '../../../../framework/classes';
import {em, percent, rem} from 'csx';
import FieldDescription from './FieldDescription';
import Truncated from '../media/Truncated';

interface Props extends HTMLAttributes {
  label?: string;
  description?: string;
  error?: string;
  fill?: boolean;
  required?: boolean;
  truncate?: boolean;
  numeric?: boolean;
  hideExtra?: boolean;
}

export default function Field(props: Props) {
  const {hideExtra = false, required = false, fill = true, numeric = false, truncate = false, label = '', description = '', error = '', children, ...htmlProps} = props;
  const className = App.useStyle(({theme}) => ({
    ...theme.component.Field,
    width: fill
      ? percent(100)
      : 'auto',
    position: 'relative',
    lineHeight: em(2.25),
    fontVariantNumeric: numeric
      ? 'tabular-nums'
      : 'unset',
    margin: '0.25em 0',
    minWidth: rem(8),
    padding: `1.25em 0 ${hideExtra ? '0' : '1.25em'}`,
    textAlign: numeric ? 'right' : 'left',
    $nest: {
      '& > span:first-child': {
        textAlign: numeric ? 'right' : 'left',
        userSelect: 'none',
        fontSize: em(0.875),
        fontStyle: 'italic',
        lineHeight: em(1),
        position: 'absolute',
        top: 0,
        left: numeric ? 'unset' : 0,
        right: numeric ? 0 : 'unset',
      },
      '& > span:first-child::after': {
        display: !required ? 'none' : 'inline-block',
        color: theme.color.red.toString(),
        fontWeight: 900,
        content: '"*"'
      }
    }
  }), [!!error, !!description, required]);

  return (
    <label {...htmlProps} className={classes(className, props.className)}>
      <span>{label}</span>
      {truncate ? <Truncated>{children}</Truncated> : children}
      {!hideExtra && (
        <FieldDescription error={error}>{description}</FieldDescription>
      )}
    </label>
  )
}