import {HTMLAttributes} from '../../../../framework/type';
import App from '../../app';
import {h} from 'preact';
import classes from '../../../../framework/classes';
import {em, percent, rem} from 'csx';
import {default as Icon, IconType} from '../media/Icon';

interface Props extends HTMLAttributes {
  primary?: boolean;
  small?: boolean;
  unstyled?: boolean;
  danger?: boolean;
  onlyIcon?: IconType;
  tooltip?: string;
  tooltipRight?: boolean;
}

export default function Button({primary, danger, small, unstyled, onlyIcon, tooltip, tooltipRight, children, ...props}: Props) {
  const className = App.useStyle(() => ({
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    appearance: 'none',
    padding: 0,
    border: 'none',
    borderRadius: rem(0.125),
    background: 'transparent',
  }));

  const hasIcon = typeof onlyIcon !== 'undefined';

  const styledCassName = App.useStyle(({theme}) => ({
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    appearance: 'none',
    position: 'relative',
    lineHeight: small
      ? rem(1.5)
      : rem(2.25),
    height: hasIcon
      ? small
        ? rem(2)
        : rem(3)
      : 'auto',
    padding: hasIcon
      ? 0
      : `0 ${small ? '0.5rem' : '1.125rem'}`,
    color: danger
      ? theme.color.white.toString()
      : theme.color.black.toString(),
    fontWeight: 900,
    textTransform: 'uppercase',
    fontSize: rem(0.75),
    background: danger
      ? theme.color.red.toString()
      : primary
        ? theme.color.yellow.toString()
        : theme.color.white.toString(),
    borderRadius: rem(0.125),
    border: `0.15rem solid ${theme.color.black.toString()}`,
    userSelect: 'none',
    whiteSpace: 'pre',
    textOverflow: 'ellipsis',
    // overflow: 'hidden',
    ...theme.component.Button,
    $nest: {
      '&[disabled]': {
        color: theme.color.gray.toString(),
        // borderColor: theme.color.gray.toString(),
      },
      '&[readOnly]': {
        color: theme.color.gray.toString(),
        // borderColor: theme.color.gray.toString(),
      },
      '& svg': {
        width: small
          ? rem(1.75)
          : rem(2.25),
        height: small
          ? rem(1.75)
          : rem(2.25)
      },
      '&[disabled] svg g': {
        stroke: theme.color.gray.toString(),
      },
      '&[readOnly] svg g': {
        stroke: theme.color.gray.toString(),
      },
      '&::after': {
        content: `"${tooltip}"`,
        display: 'none',
        position: 'absolute',
        top: percent(100),
        left: tooltipRight
          ? 'unset'
          : rem(-0.15),
        right: tooltipRight
          ? rem(-0.15)
          : 'unset',
        background: theme.color.black.fade(0.9).toString(),
        color: danger
          ? theme.color.red.toString()
          : primary
            ? theme.color.yellow.toString()
            : theme.color.white.toString(),
        fontSize: rem(0.75),
        padding: '0.25em 0.625em',
        zIndex: theme.layer.tooltip,
      },
      '&:hover::after': {
        display: tooltip ? 'block' : 'none'
      },
      '&:focus::after': {
        display: tooltip ? 'block' : 'none'
      }
    }
  }), [primary, small, tooltip]);

  return (
    <button
      {...props}
      className={classes(unstyled ? className : styledCassName, props.className)}
      children={hasIcon ? <Icon type={onlyIcon!}/> : children}
    />
  )
}