import {h} from 'preact';
import App from '../../app';
import {percent, rem} from 'csx';
import TeamUserAvatar from './TeamUserAvatar';
import currentTeam from '../../query/currentTeam';
import Truncated from '../media/Truncated';

interface Props {
  userId: string,
  fill?: boolean;
  noName?: boolean;
}

export default function TeamUserBadge({userId, noName, fill}: Props) {
  const team = currentTeam();
  if (!team) return null;

  const user = App.useQuery(state => state.team.get(team.id)!.users.get(userId));
  if (!user) return null;

  const className = App.useStyle(({theme}) => ({
    display: 'inline-flex',
    lineHeight: rem(1.25),
    flexDirection: 'row',
    userSelect: 'none',
    width: fill
      ? percent(100)
      : 'auto',
    maxWidth: percent(100),
    margin: 0,
    padding: 0,
    $nest: {
      '& > *:first-child': {
        marginRight: rem(0.5),
        flexShrink: 0,
        width: rem(1.25)
      },
    }
  }), [fill]);

  return (
    <div className={className}>
      <TeamUserAvatar user={user}/>
      {!noName && (
        <Truncated>{user.name}</Truncated>
      )}
    </div>
  )
}