import {Value} from './Value';

export default class NumberValue extends Value<number> {
  static WRONG_TYPE = 'Not a number';

  validate() {
    return this.collect([
      this.isNumber()
    ])
  }

  protected isNumber() {
    if (typeof this.value !== 'number' || isNaN(this.value))
      return this.error(NumberValue.WRONG_TYPE)
  }
}