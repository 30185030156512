import {ApplicationStyle, StateContext, StyleHook} from './type';
import {useContext, useMemo} from 'preact/hooks';
import {style} from 'typestyle';

export default function createStyleHook<State>(
  stateContext: StateContext<State>
): StyleHook<State> {
  return (styleFn, ...dependencies) => {
    const state = useContext(stateContext);
    const cached = useMemo<ApplicationStyle>(
      () => typeof styleFn === 'function'
      ? styleFn(state)
      : styleFn,
      [state, ...dependencies]
    );

    return style(cached);
  };
}