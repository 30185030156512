import App from '../app';
import {AnyCommand, AnyEvent, RECEIVE_EVENT_COMMAND, SIGN_OUT_COMMAND, START_SIGN_IN_WITH_EMAIL_COMMAND} from '../type';
import {EventWrapper} from '../../../framework/type';

export default function receiveEvent(payload: EventWrapper<AnyEvent>): AnyCommand {
  return {
    type: RECEIVE_EVENT_COMMAND,
    payload,
  }
}

export const useReceiveEventCommand =
  App.wrapCommand(receiveEvent);