import {
  AnyEvent,
  Collection,
  EventAppService,
  RECEIVE_EVENT_COMMAND,
  ReceiveEventCommand,
  RemoteEventHandler
} from '../../type';
import {AsyncCommandResponse, EventWrapper, WatchFunctionUnsubscribe} from '../../../../framework/type';
import {JobCostingsAppService} from '../../services';
import * as firebase from 'firebase/app';

export default class AppEventService extends JobCostingsAppService implements EventAppService {
  async [RECEIVE_EVENT_COMMAND](_: any, command: ReceiveEventCommand): AsyncCommandResponse {
    this.dispatch(command.payload.event);
    return this.valid;
  }

  protected lastSeenTeamLength = 0;
  protected lastSeenUserLength = 0;

  watchTeamEvents(teamId: string, handler: RemoteEventHandler): WatchFunctionUnsubscribe {
    return this.latestNestedEvents(Collection.TEAMS, teamId)
      .onSnapshot(snapshot => {
        snapshot.docs.slice(this.lastSeenTeamLength).forEach(this.maybeDispatch.bind(this, handler));
        this.lastSeenTeamLength = snapshot.docs.length;
      });
  }

  watchUserEvents(userId: string, handler: RemoteEventHandler): WatchFunctionUnsubscribe {
    return this.latestNestedEvents(Collection.USERS, userId)
      .onSnapshot(snapshot => {
        snapshot.docs.slice(this.lastSeenUserLength).forEach(this.maybeDispatch.bind(this, handler));
        this.lastSeenUserLength = snapshot.docs.length;
      });
  }

  protected dispatchedEvents: AnyEvent[] = [];

  protected maybeDispatch(
    handler: RemoteEventHandler,
    document: firebase.firestore.QueryDocumentSnapshot
  ) {
    const wrapper = document.data() as EventWrapper<AnyEvent>;

    if (wrapper && wrapper.event && wrapper.event.type) {
      handler(wrapper);
    } else {
      console.warn('Received bad event', wrapper);
    }
  }

  protected latestNestedEvents(
    parentCollection: string,
    parentId: string,
  ) {
    return this.app.firestore()
      .collection(parentCollection)
      .doc(parentId)
      .collection(Collection.EVENTS)
      .where('timestamp', '>', new Date())
  }
}
