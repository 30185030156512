import StringValue from '../../../framework/value/StringValue';
import MapValue from '../../../framework/value/MapValue';
import {ItemId} from './App';
import NumberValue from '../../../framework/value/NumberValue';
import {Entity} from '../../../framework/value/Entity';
import BooleanValue from '../../../framework/value/BooleanValue';
import {ValidationError} from '../../../framework/error';
import EnumValue from '../../../framework/value/EnumValue';
import {EditorChoice} from '../component/editor/type';
import { Complete } from './ValuationSectionItem';
import {RoundCharge} from './Job';

export interface JobSectionItemJSON {
  id: string;
  details: JobSectionItemDetailsJSON;
  company: JobSectionItemCompanyJSON;
  status: JobSectionItemStatusJSON,
  order: number;
}

export enum JobSectionItemType {
  COST = 'COST',
  FIXED = 'FIXED',
  INCLUDED = 'INCLUDED',
  EXCLUDED = 'EXCLUDED',
  NOTED = 'NOTED',
}

export class ItemType extends EnumValue(JobSectionItemType) {}

export default class JobSectionItem extends Entity<ItemId, {
  details: Details,
  status: Status;
  order: Order,
  company: JobSectionItemCompany;
}> {
  get type() {
    return this.value.details.value.type.value;
  }

  get company() {
    return this.value.company;
  }

  get order() {
    return this.value.order.value
  }

  get item() {
    return this.value.details.value.item.value;
  }

  get details() {
    return this.value.details;
  }

  get description() {
    return this.value.details.value.description.value;
  }

  get quantity() {
    if (!this.hasCost)
      return 0;

    return this.value.details.value.quantity.value;
  }

  get rate() {
    if (!this.hasCost)
      return 0;

    return this.value.details.value.rate.value;
  }

  get cost() {
    if (!this.hasCost)
      return 0;

    return this.quantity * this.rate;
  }

  get markup() {
    if (!this.hasCost)
      return 0;

    return this.value.details.value.markup.value || 0;
  }

  get actualMarkup() {
    if (!this.hasCost)
      return 0;

    return 100 * (this.charge / this.cost - 1)
  }

  get profit() {
    if (!this.hasCost)
      return 0;

    return this.charge - this.cost;
  }

  get roundCharge() {
    return this.value.details.value.roundCharge.value || 0;
  }

  get charge() {
    if (!this.hasCost)
      return 0;

    const profit = this.cost * (this.markup / 100);
    const charge = this.cost + profit;

    if (!this.roundCharge)
      return charge;

    return Math.ceil(charge / this.roundCharge) * this.roundCharge;
  }

  get imported() {
    return this.value.status.value.imported.value;
  }

  get included() {
    return this.type === JobSectionItemType.INCLUDED;
  }

  get excluded() {
    return this.type === JobSectionItemType.EXCLUDED;
  }

  get noted() {
    return this.type === JobSectionItemType.NOTED;
  }

  get fixedCost() {
    return this.type === JobSectionItemType.FIXED;
  }

  get userCost() {
    return this.type === JobSectionItemType.COST;
  }

  get hasCost() {
    return this.fixedCost || this.userCost;
  }

  get hasAllocation() {
    return !this.excluded && !!this.company.id;
  }

  get trackCompany() {
    return this.markup === (this.company.markup || 0);
  }

  get locked() {
    return this.imported;
  }

  get complete() {
    // return Math.round(Math.random() * 100);
    return this.value.details.value.complete.value;
  }

  get omit() {
    return this.value.details.value.omit.value;
  }

  toJSON(): JobSectionItemJSON {
    return super.toJSON() as JobSectionItemJSON;
  }

  update(details: Partial<JobSectionItemDetailsJSON>): JobSectionItem {
    let response = this;

    if (details.item)
      response = response.setItem(details.item);

    if (details.description)
      response = response.setDescription(details.description);

    if (details.quantity)
      response = response.setQuantity(details.quantity);

    if (details.rate)
      response = response.setRate(details.rate);

    if (details.markup)
      response = response.setMarkup(details.markup);

    return response;
  }

  setItem(item: string) {
    if (this.imported)
      throw new ValidationError('Item', 'This item was fixed at specification');

    return this.extend({
      details: this.details.extend({
        item: new Item(item)
      })
    })
  }

  setCompany(company: Partial<JobSectionItemCompanyJSON>) {
    let response = this;

    // sync the new markup if tracking
    if (this.trackCompany && company.markup !== this.markup) {
      response = response.setMarkup(company.markup || 0)
    }

    return response.extend({
      company: JobSectionItemCompany.fromJSON(company)
    })
  }

  setDescription(description: string) {
    if (this.imported)
      throw new ValidationError('Description', 'This item was fixed at specification');

    return this.extend({
      details: this.details.extend({
        description: new Description(description)
      })
    })
  }

  setOrder(order: number) {
    if (this.imported)
      throw new ValidationError('Order', 'This item was fixed at specification');

    return this.extend({
      order: new Order(order)
    })
  }

  setQuantity(quantity: number) {
    if (this.imported)
      throw new ValidationError('Quantity', 'This item was fixed at specification');

    return this.extend({
      details: this.details.extend({
        quantity: new Quantity(quantity)
      })
    })
  }

  setRate(rate: number) {
    return this.extend({
      details: this.details.extend({
        rate: new Rate(rate)
      })
    })
  }

  setMarkup(markup: number) {
    return this.extend({
      details: this.details.extend({
        markup: new Markup(markup)
      })
    })
  }

  setRoundCharge(roundCharge: number) {
    return this.extend({
      details: this.details.extend({
        roundCharge: new RoundCharge(roundCharge)
      })
    })
  }

  setImported(included: boolean) {
    return this.extend({
      status: this.value.status.extend({
        imported: new BooleanValue(included)
      })
    })
  }

  setType(type: JobSectionItemType) {
    let response = this;

    if (type === JobSectionItemType.EXCLUDED) {
      response = response.setCompany({})
    }

    return response.extend({
      details: this.details.extend({
        type: new ItemType(type)
      })
    })
  }

  setComplete(complete: number) {
    if (complete < 0 || complete > 100)
      throw new ValidationError('Complete', 'This must be a percentage between 0 and 100');

    return this.extend({
      details: this.details.extend({
        complete: new Complete(complete)
      })
    })
  }

  setOmit(omit: boolean) {
    return this.extend({
      details: this.details.extend({
        omit: new BooleanValue(omit)
      })
    })
  }

  static TypeChoices: EditorChoice[] = Object
    .keys(JobSectionItemType)
    .reduce((choices, key) => {
      return [
        ...choices,
        {
          id: key,
          value: key,
        }
      ];
    }, [] as EditorChoice[])

  static fromJSON(json: Partial<JobSectionItemJSON>) {
    return new JobSectionItem({
      id: new ItemId(json.id || ''),
      details: Details.fromJSON(json.details || {}),
      status: Status.fromJSON(json.status || {}),
      order: new Order(json.order || 0),
      company: JobSectionItemCompany.fromJSON(json.company || {})
    })
  }
}

export class Order extends NumberValue {}

export interface JobSectionItemDetailsJSON {
  item: string;
  type: JobSectionItemType;
  description: string;
  quantity: number;
  rate: number;
  markup: number;
  complete: number;
  omit: boolean;
  roundCharge: number;
}

export class Details extends MapValue<{
  item: Item;
  type: ItemType;
  description: Description;
  quantity: Quantity;
  rate: Rate;
  markup: Markup;
  complete: NumberValue;
  omit: BooleanValue;
  roundCharge: NumberValue;
}> {
  toJSON(): JobSectionItemDetailsJSON {
    return super.toJSON() as JobSectionItemDetailsJSON;
  }

  static fromJSON(json: Partial<JobSectionItemDetailsJSON>): Details {
    return new Details({
      item: new Item(json.item || ''),
      type: new ItemType(json.type || JobSectionItemType.COST),
      description: new Description(json.description || ''),
      quantity: new Quantity(json.quantity || 1),
      rate: new Rate(json.rate || 0),
      markup: new Markup(json.markup || 0),
      complete: new NumberValue(json.complete || 0),
      omit: new BooleanValue(typeof json.omit !== 'undefined' ? json.omit : false),
      roundCharge: new NumberValue(json.roundCharge || 0),
    })
  }
}
export interface JobSectionItemCompanyJSON {
  id: string;
  name: string;
  markup: number;
}

export class JobSectionItemCompany extends MapValue<{
  id: StringValue;
  name: StringValue;
  markup: Markup;
}> {
  get id() {
    return this.value.id.value;
  };

  get name() {
    return this.value.name.value;
  }

  get markup() {
    return this.value.markup.value;
  }

  toJSON(): JobSectionItemCompanyJSON {
    return super.toJSON() as JobSectionItemCompanyJSON;
  }

  static fromJSON(json: Partial<JobSectionItemCompanyJSON>): JobSectionItemCompany {
    return new JobSectionItemCompany({
      id: new StringValue(json.id || ''),
      name: new StringValue(json.name || ''),
      markup: new Markup(json.markup || 0),
    })
  }
}

export class Item extends StringValue {}
export class Description extends StringValue {}

export class PositiveValue extends NumberValue {
  static MUST_BE_POSITIVE_NUMBER = 'Must be a number greater than or equal to 0';

  validate() {
    return this.collect([
      ...super.validate(),
      !(this.value >= 0) && this.error(Quantity.MUST_BE_POSITIVE_NUMBER),
    ])
  }
}

export class Quantity extends PositiveValue {}
export class Rate extends NumberValue {}
export class Markup extends PositiveValue {}

export interface JobSectionItemStatusJSON {
  imported: boolean;
}

export class Status extends MapValue<{
  imported: BooleanValue;
}> {
  static fromJSON(json: Partial<JobSectionItemStatusJSON>) {
    return new Status({
      imported: new BooleanValue(!!json.imported),
    })
  }
}
