import {AnyCommand, START_JOB_FILE_UPLOAD_COMMAND, StartJobFileUploadCommand} from '../type';
import App from '../app';

export default function startJobFileUpload(payload: StartJobFileUploadCommand['payload']): AnyCommand {
  return {
    type: START_JOB_FILE_UPLOAD_COMMAND,
    payload,
  }
}

export const useStartJobFileUpload = App.wrapCommand(startJobFileUpload);