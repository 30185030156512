import {ItemId, SectionId} from '../model/App';
import {StateUpdater, useCallback, useState} from 'preact/hooks';
import {Valuation} from '../model/Valuation';
import currentTeam from '../query/currentTeam';
import {SaveHandler} from '../component/editor/type';
import {default as ValuationSectionItem} from '../model/ValuationSectionItem';

export default function saveValuationSectionItemCallback(
  initialValuation: Valuation
): [Valuation, SaveHandler<ValuationSectionItem>, StateUpdater<Valuation>] {
  const [valuation, setLatestValuation] = useState(initialValuation);
  const team = currentTeam();

  const saveItem = useCallback((page: number, target: null | ValuationSectionItem, key: keyof ValuationSectionItem, value: string | null) => {
    if (!valuation || !team) return [];
    const sections = valuation.sections.all;
    const isAdditional = page === sections.length;

    const section = sections[page];
    if (!isAdditional && !section) return [new Error('Cannot find the section to save to')];

    const item = target
      ? isAdditional
        ? valuation.variations.items.get(target.id)!
        : section.items.get(target.id)!
      : ValuationSectionItem.fromJSON({
        id: ItemId.nextId()
      });

    if (!item) return [new Error('Cannot find the item to save to')];

    let nextValue: any;

    function getValue(): ValuationSectionItem {
      switch (key) {
        case 'order':
          nextValue = !value ? 0 : parseFloat(value);
          return item.setOrder(nextValue);

        case 'charge':
          nextValue = !value ? 0 : parseFloat(value);
          return item.setCharge(nextValue);

        case 'complete':
          nextValue = !value ? 0 : parseFloat(value);
          return item.setComplete(nextValue);

        case 'omit':
          nextValue = value === 'true';
          return item.setOmit(nextValue);

        case 'item':
          nextValue = value || '';
          return item.setItem(nextValue);

        case 'po':
          nextValue = value || '';
          return item.setPO(nextValue);

        case 'description':
          nextValue = value || '';
          return item.setDescription(nextValue);

        default:
          return item;
      }
    }

    try {
      const next = getValue();
      const errors = next.validate();

      if (errors.length === 0 && next !== item) {
        if (target) {
          setLatestValuation(
            isAdditional
              ? valuation.updateVariationItem(next)
              : valuation.updateSectionItem(
                new SectionId(section.id),
                next
              )
          );
        } else {
          setLatestValuation(valuation.updateVariationItem(
            next
          ));
        }
      }

      return errors;
    } catch (e) {
      return [e];
    }
  }, [initialValuation, valuation, setLatestValuation]);

  return [
    valuation,
    saveItem,
    setLatestValuation,
  ]
}
