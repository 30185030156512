import { AnyEvent, ARCHIVE_LOADED_EVENT } from '../type';
import { JobJSON } from '../model/Job';

export default function archiveLoaded(teamId: string, jobs: Array<JobJSON>): AnyEvent {
  return {
    type: ARCHIVE_LOADED_EVENT,
    payload: {
      teamId,
      jobs
    },
  }
}