import {
  COMPLETE_JOB_FILE_UPLOAD_COMMAND,
  CompleteJobFileUploadCommand,
  CREATE_JOB_SECTION_COMMAND,
  CREATE_JOB_SECTION_ITEM_COMMAND,
  CREATE_JOB_VARIATION_ITEM_COMMAND,
  CreateJobSectionCommand,
  CreateJobSectionItemCommand,
  CreateJobVariationItemCommand,
  DELETE_JOB_SECTION_ITEMS_COMMAND,
  DELETE_JOB_SECTIONS_COMMAND,
  DELETE_JOB_VARIATION_ITEMS_COMMAND,
  DeleteJobSectionItemsCommand,
  DeleteJobSectionsCommand,
  DeleteJobVariationItemsCommand,
  FAIL_JOB_FILE_UPLOAD_COMMAND,
  FailJobFileUploadCommand,
  FINALISE_COSTINGS,
  FINALISE_SCHEDULE, FINALISE_VALUATION,
  FinaliseCostingsCommand,
  FinaliseScheduleCommand, FinaliseValuationCommand,
  JOB_ASSETS_UPDATED,
  JOB_FILE_UPLOAD_FAILED,
  JOB_FILE_UPLOAD_STARTED,
  JOB_FILE_UPLOAD_UPDATED,
  JOB_FILE_UPLOADED,
  JOB_IMPORT_LOADED,
  JOB_SECTION_CREATED,
  JOB_SECTION_DELETED,
  JOB_SECTION_ITEM_CREATED,
  JOB_SECTION_ITEM_DELETED,
  JOB_SECTION_ITEM_REORDERED,
  JOB_SECTION_ITEM_STATUS_UPDATED,
  JOB_SECTION_ITEM_UPDATED,
  JOB_SECTION_REORDERED,
  JOB_SECTION_UPDATED,
  JOB_VARIATION_ITEM_CREATED,
  JOB_VARIATION_ITEM_DELETED,
  JOB_VARIATION_ITEM_REORDERED,
  JOB_VARIATION_ITEM_UPDATED,
  JobAssetsUpdatedEvent,
  JobFileUploadedEvent,
  JobFileUploadFailedEvent,
  JobFileUploadStartedEvent,
  JobFileUploadUpdatedEvent,
  JobImportLoadedEvent,
  JobSectionCreatedEvent,
  JobSectionDeletedEvent,
  JobSectionItemCreatedEvent,
  JobSectionItemDeletedEvent,
  JobSectionItemReorderedEvent,
  JobSectionItemStatusUpdatedEvent,
  JobSectionItemUpdatedEvent,
  JobSectionReorderedEvent,
  JobSectionUpdatedEvent,
  JobService,
  JobVariationItemCreatedEvent, JobVariationItemDeletedEvent,
  JobVariationItemReorderedEvent,
  JobVariationItemUpdatedEvent,
  LOAD_JOB_IMPORT_COMMAND,
  LoadJobImportCommand,
  REORDER_JOB_SECTION_COMMAND,
  REORDER_JOB_SECTION_ITEM_COMMAND,
  REORDER_JOB_VARIATION_ITEM_COMMAND,
  ReorderJobSectionCommand,
  ReorderJobSectionItemCommand,
  ReorderJobVariationItemCommand,
  START_JOB_FILE_UPLOAD_COMMAND,
  StartJobFileUploadCommand,
  UPDATE_JOB_SECTION_COMMAND,
  UPDATE_JOB_SECTION_ITEM_COMMAND,
  UPDATE_JOB_SECTION_ITEM_STATUS_COMMAND,
  UPDATE_JOB_VARIATION_ITEM_COMMAND,
  UpdateJobSectionCommand,
  UpdateJobSectionItemCommand,
  UpdateJobSectionItemStatusCommand,
  UpdateJobVariationItemCommand
} from '../../type';
import { FileId, ItemId, JobId, SectionId, TeamId, VariationId } from '../../model/App';
import * as firebase from 'firebase/app';
import {State} from '../../model/State';
import {JobCostingsAppService} from '../../services';
import {JobFile, JobFiles, Name, Path, Type} from '../../model/JobFile';
import {AsyncCommandResponse} from '../../../../framework/type';
import jobFileUploadStarted from '../../event/jobFileUploadStarted';
import jobFileUploadFailed from '../../event/jobFileUploadFailed';
import StringValue from '../../../../framework/value/StringValue';
import {Job} from '../../model/Job';
import {TeamJobs} from '../../model/Team';
import NumberValue from '../../../../framework/value/NumberValue';
import BooleanValue from '../../../../framework/value/BooleanValue';
import {default as JobImport, JobImportJSON, JobImports} from '../../model/JobImport';
import jobImportLoaded from '../../event/jobImportLoaded';
import prepareJobPagesImport from '../../command/prepareJobPagesImport';
import JobSectionItem, {Details, Details as JobSectionDetails, Order, Status} from '../../model/JobSectionItem';
import jobSectionItemUpdated from '../../event/jobSectionItemUpdated';
import {Name as SectionName} from '../../model/JobSection';
import jobSectionCreated from '../../event/jobSectionCreated';
import {JobAsset, JobAssetId, JobAssets, JobAssetType} from '../../model/JobAssets';
import jobSectionItemCreated from '../../event/jobSectionItemCreated';
import jobSectionDeleted from '../../event/jobSectionDeleted';
import jobSectionItemDeleted from '../../event/jobSectionItemDeleted';
import jobSectionItemReordered from '../../event/jobSectionItemReordered';
import jobSectionItemStatusUpdated from '../../event/jobSectionItemStatusUpdated';
import jobSectionUpdated from '../../event/jobSectionUpdated';
import jobSectionReordered from '../../event/jobSectionReordered';
import JobVariationItem, { JobVariationItemDetails } from '../../model/JobVariationItem';
import jobVariationItemCreated from '../../event/jobVariationItemCreated';
import jobVariationItemUpdated from '../../event/jobVariationItemUpdated';
import jobVariationItemReordered from '../../event/jobVariationItemReordered';
import jobVariationItemDeleted from '../../event/jobVariationItemDeleted';

export default class AppJobService extends JobCostingsAppService implements JobService {
  async [LOAD_JOB_IMPORT_COMMAND](state: State, command: LoadJobImportCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
      new FileId(command.payload.fileId),
      new StringValue(command.payload.source),
    ], async (teamId: TeamId, jobId: JobId, fileId: FileId, source: StringValue) => {
      if (source.value) {
        const jobImport = await this.downloadJSON<JobImportJSON>(source.value);

        this.dispatch(jobImportLoaded({
          teamId: teamId.id,
          jobId: jobId.id,
          jobImport
        }));

        return this.valid;
      }

      // don't have a schedule, must prepare and import it

      const errors = await this.proxy(prepareJobPagesImport({
        teamId: teamId.id,
        jobId: jobId.id,
        fileId: fileId.id,
      }));

      if (errors.length)
        return errors;

      // try direct access now it's prepared

      const path = this.jobScheduleTransformedStoragePath(teamId.id, jobId.id, fileId.id);
      const jobImport = await this.downloadJSON<JobImportJSON>(path);

      this.dispatch(jobImportLoaded({
        teamId: teamId.id,
        jobId: jobId.id,
        jobImport
      }));

      return this.valid;
    });
  }

  async [START_JOB_FILE_UPLOAD_COMMAND](state: State, command: StartJobFileUploadCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
      new FileId(command.payload.fileId),
      new Name(command.payload.fileName),
      new Type(command.payload.fileType),
      new Path(command.payload.filePath),
    ], async (teamId: TeamId, jobId: JobId, fileId: FileId, fileName: Name, fileType: Type, filePath: Path) => {
      const result = this.proxy(command);

      this.dispatch(jobFileUploadStarted({
        teamId: teamId.id,
        filePath: filePath.value,
        fileType: fileType.value,
        fileName: fileName.value,
        fileId: fileId.id,
        jobId: jobId.id,
      }));

      return result;
    });
  }

  async [FAIL_JOB_FILE_UPLOAD_COMMAND](state: State, command: FailJobFileUploadCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
      new FileId(command.payload.fileId),
      new StringValue(command.payload.error)
    ], async (teamId: TeamId, jobId: JobId, fileId: FileId, error: StringValue) => {
      const result = this.proxy(command);

      this.dispatch(jobFileUploadFailed({
        teamId: teamId.id,
        fileId: fileId.id,
        jobId: jobId.id,
        error: error.value,
      }));

      return result;
    });
  }

  async [COMPLETE_JOB_FILE_UPLOAD_COMMAND](state: State, command: CompleteJobFileUploadCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
      new FileId(command.payload.fileId),
    ], async (teamId: TeamId, jobId: JobId, fileId: FileId) => {
      return this.proxy(command);
    });
  }

  async [CREATE_JOB_SECTION_COMMAND](state: State, command: CreateJobSectionCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
      new SectionId(command.payload.sectionId),
      new SectionName(command.payload.sectionName)
    ], async (teamId: TeamId, jobId: JobId, sectionId: SectionId, name: SectionName) => {
      this.dispatch(jobSectionCreated({
        teamId: teamId.id,
        jobId: jobId.id,
        sectionId: sectionId.id,
        sectionName: name.value,
      }));

      return this.proxy(command);
    });
  }

  async [UPDATE_JOB_SECTION_COMMAND](state: State, command: UpdateJobSectionCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
      new SectionId(command.payload.sectionId),
    ], async (teamId: TeamId, jobId: JobId, sectionId: SectionId) => {
      return this.proxyOptimisticDispatch(command, jobSectionUpdated({
        teamId: teamId.id,
        jobId: jobId.id,
        sectionId: sectionId.id,
        details: command.payload.details
      }));
    });
  }

  async [REORDER_JOB_SECTION_COMMAND](state: State, command: ReorderJobSectionCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
      new SectionId(command.payload.sectionId),
      new Order(command.payload.order),
    ], async (teamId: TeamId, jobId: JobId, sectionId: SectionId, order: Order) => {
      return this.proxyOptimisticDispatch(command, jobSectionReordered({
        teamId: teamId.id,
        jobId: jobId.id,
        sectionId: sectionId.id,
        order: order.value,
      }));
    });
  }

  async [DELETE_JOB_SECTIONS_COMMAND](state: State, command: DeleteJobSectionsCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
    ], async (teamId: TeamId, jobId: JobId) => {
      command.payload.sectionIds.forEach(id => {
        this.dispatch(jobSectionDeleted({
          teamId: teamId.id,
          jobId: jobId.id,
          sectionId: id
        }));
      });

      return this.proxy(command);
    });
  }

  async [CREATE_JOB_SECTION_ITEM_COMMAND](state: State, command: CreateJobSectionItemCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
      new SectionId(command.payload.sectionId),
      new ItemId(command.payload.itemId),
      JobSectionDetails.fromJSON(command.payload.details)
    ], async (teamId: TeamId, jobId: JobId, sectionId: SectionId, itemId: ItemId, details: JobSectionDetails) => {
      const optimisticItem = JobSectionItem
        .fromJSON({id: itemId.id})
        .extend({
          details: Details.fromJSON(command.payload.details),
          order: new Order(999),
        });

      this.dispatch(jobSectionItemCreated({
        teamId: teamId.id,
        jobId: jobId.id,
        sectionId: sectionId.id,
        itemId: itemId.id,
        item: optimisticItem.toJSON()
      }));

      return this.proxy(command);
    });
  }

  async [UPDATE_JOB_SECTION_ITEM_COMMAND](state: State, command: UpdateJobSectionItemCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
      new SectionId(command.payload.sectionId),
      new ItemId(command.payload.itemId),
      JobSectionDetails.fromJSON(command.payload.details)
    ], async (teamId: TeamId, jobId: JobId, sectionId: SectionId, itemId: ItemId, details: JobSectionDetails) => {
      this.dispatch(jobSectionItemUpdated({
        teamId: teamId.id,
        jobId: jobId.id,
        sectionId: sectionId.id,
        itemId: itemId.id,
        details: command.payload.details,
      }));

      return this.proxy(command);
    });
  }

  async [REORDER_JOB_SECTION_ITEM_COMMAND](state: State, command: ReorderJobSectionItemCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
      new SectionId(command.payload.sectionId),
      new ItemId(command.payload.itemId),
      new Order(command.payload.order),
    ], async (teamId: TeamId, jobId: JobId, sectionId: SectionId, itemId: ItemId, order: Order) => {
      return this.proxyOptimisticDispatch(command, jobSectionItemReordered({
        teamId: teamId.id,
        jobId: jobId.id,
        sectionId: sectionId.id,
        itemId: itemId.id,
        order: order.value,
      }));
    });
  }

  async [DELETE_JOB_SECTION_ITEMS_COMMAND](state: State, command: DeleteJobSectionItemsCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
      new SectionId(command.payload.sectionId),
    ], async (teamId: TeamId, jobId: JobId, sectionId: SectionId) => {
      command.payload.itemIds.forEach(itemId => {
        this.dispatch(jobSectionItemDeleted({
          teamId: teamId.id,
          jobId: jobId.id,
          sectionId: sectionId.id,
          itemId
        }));
      });

      return this.proxy(command);
    });
  }

  async [UPDATE_JOB_SECTION_ITEM_STATUS_COMMAND](state: State, command: UpdateJobSectionItemStatusCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
      new SectionId(command.payload.sectionId),
      new ItemId(command.payload.itemId),
      Status.fromJSON(command.payload.status),
    ], async (teamId: TeamId, jobId: JobId, sectionId: SectionId, itemId: ItemId, status: Status) => {
      this.dispatch(jobSectionItemStatusUpdated({
        teamId: teamId.id,
        jobId: jobId.id,
        sectionId: sectionId.id,
        itemId: itemId.id,
        status: command.payload.status,
      }));

      return this.proxy(command);
    });
  }

  async [CREATE_JOB_VARIATION_ITEM_COMMAND](state: State, command: CreateJobVariationItemCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
      new VariationId(command.payload.variationId),
      JobVariationItemDetails.fromJSON(command.payload.details)
    ], async (teamId: TeamId, jobId: JobId, variationId: VariationId, details: JobVariationItemDetails) => {
      const optimisticItem = JobVariationItem
        .fromJSON({id: variationId.id})
        .extend({
          details: details,
          order: new Order(777),
        });

      this.dispatch(jobVariationItemCreated({
        teamId: teamId.id,
        jobId: jobId.id,
        variationId: variationId.id,
        variation: optimisticItem.toJSON()
      }));

      return this.proxy(command);
    });
  }

  async [UPDATE_JOB_VARIATION_ITEM_COMMAND](state: State, command: UpdateJobVariationItemCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
      new VariationId(command.payload.variationId),
      JobVariationItemDetails.fromJSON(command.payload.details)
    ], async (teamId: TeamId, jobId: JobId, variationId: VariationId, details: JobVariationItemDetails) => {
      this.dispatch(jobVariationItemUpdated({
        teamId: teamId.id,
        jobId: jobId.id,
        variationId: variationId.id,
        details: command.payload.details,
      }));

      return this.proxy(command);
    });
  }

  async [REORDER_JOB_VARIATION_ITEM_COMMAND](state: State, command: ReorderJobVariationItemCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
      new VariationId(command.payload.variationId),
      new Order(command.payload.order),
    ], async (teamId: TeamId, jobId: JobId, variationId: VariationId, order: Order) => {
      return this.proxyOptimisticDispatch(command, jobVariationItemReordered({
        teamId: teamId.id,
        jobId: jobId.id,
        variationId: variationId.id,
        order: order.value,
      }));
    });
  }

  async [DELETE_JOB_VARIATION_ITEMS_COMMAND](state: State, command: DeleteJobVariationItemsCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
    ], async (teamId: TeamId, jobId: JobId) => {
      command.payload.variationIds.forEach(variationId => {
        this.dispatch(jobVariationItemDeleted({
          teamId: teamId.id,
          jobId: jobId.id,
          variationId,
        }));
      });

      return this.proxy(command);
    });
  }

  async [FINALISE_COSTINGS](state: State, command: FinaliseCostingsCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
    ], async (teamId: TeamId, jobId: JobId) => {
      return this.proxy(command);
    });
  }

  async [FINALISE_SCHEDULE](state: State, command: FinaliseScheduleCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
    ], async (teamId: TeamId, jobId: JobId) => {
      return this.proxy(command);
    });
  }


  async [FINALISE_VALUATION](state: State, command: FinaliseValuationCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
    ], async (teamId: TeamId, jobId: JobId) => {
      return this.proxy(command);
    });
  }

  [JOB_IMPORT_LOADED](state: State, event: JobImportLoadedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = team.jobs.get(event.payload.jobId);
    if (!job) return state;

    const file = JobImport.fromJSON(event.payload.jobImport);

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(job.extend({
          imports: job.imports.replace<JobImport, JobImports>(file)
        }))
      }))
    });
  }

  [JOB_FILE_UPLOAD_STARTED](state: State, event: JobFileUploadStartedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = team.jobs.get(event.payload.jobId);
    if (!job) return state;

    const file = JobFile.create({
      id: event.payload.fileId,
      fileName: event.payload.fileName,
      fileType: event.payload.fileType,
      filePath: event.payload.filePath,
    });

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(job.extend({
          files: job.files.replace<JobFile, JobFiles>(file)
        }))
      }))
    });
  }

  [JOB_FILE_UPLOAD_UPDATED](state: State, event: JobFileUploadUpdatedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = team.jobs.get(event.payload.jobId);
    if (!job) return state;

    const file = job.files.get(event.payload.fileId);
    if (!file) return state;

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(job.extend({
          files: job.files.replace<JobFile, JobFiles>(file.extend({
            status: file.status.extend({
              progress: new NumberValue(event.payload.progress)
            })
          }))
        }))
      }))
    });
  }

  [JOB_FILE_UPLOAD_FAILED](state: State, event: JobFileUploadFailedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = team.jobs.get(event.payload.jobId);
    if (!job) return state;

    const file = job.files.get(event.payload.fileId);
    if (!file) return state;

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(job.extend({
          files: job.files.replace<JobFile, JobFiles>(file.extend({
            status: file.status.extend({
              failed: new BooleanValue(true)
            })
          }))
        }))
      }))
    });
  }

  [JOB_FILE_UPLOADED](state: State, event: JobFileUploadedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = team.jobs.get(event.payload.jobId);
    if (!job) return state;

    const file = JobFile.create({
      id: event.payload.fileId,
      fileName: event.payload.fileName,
      fileType: event.payload.fileType,
      filePath: event.payload.filePath,
      ready: true,
    });

    let nextState = state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(job.extend({
          files: job.files.replace<JobFile, JobFiles>(file)
        }))
      }))
    });

    if (JobAssetId.KEYS.includes(file.fileName)) {
      // treat as reserved asset, make this nicer...
      const assetFile = file.fileName as JobAssetType;
      const asset = JobAsset.fromJSON(assetFile, {
        id: assetFile,
        path: file.filePath,
        fileId: file.id,
      });

      nextState = nextState.extend({
        team: state.team.replace(team.extend({
          jobs: team.jobs.replace<Job, TeamJobs>(job.extend({
            assets: job.assets.replace<JobAsset, JobAssets>(asset)
          }))
        }))
      });
    }

    return nextState;
  }


  [JOB_SECTION_CREATED](state: State, event: JobSectionCreatedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = team.jobs.get(event.payload.jobId);
    if (!job) return state;

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(
          job.createSection(
            new SectionId(event.payload.sectionId),
            new SectionName(event.payload.sectionName),
          )
        )
      }))
    });
  }

  [JOB_SECTION_UPDATED](state: State, event: JobSectionUpdatedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = team.jobs.get(event.payload.jobId);
    if (!job) return state;

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(
          job.updateSectionDetails(
            new SectionId(event.payload.sectionId),
            event.payload.details,
          )
        )
      }))
    });
  }

  [JOB_SECTION_REORDERED](state: State, event: JobSectionReorderedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = team.jobs.get(event.payload.jobId);
    if (!job) return state;

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(
          job.reorderJobSection(
            new SectionId(event.payload.sectionId),
            event.payload.order
          )
        )
      }))
    })
  }

  [JOB_SECTION_DELETED](state: State, event: JobSectionDeletedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = team.jobs.get(event.payload.jobId);
    if (!job) return state;

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(
          job.deleteJobSection(new SectionId(event.payload.sectionId))
        )
      }))
    });
  }

  [JOB_SECTION_ITEM_CREATED](state: State, event: JobSectionItemCreatedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = team.jobs.get(event.payload.jobId);
    if (!job) return state;

    const nextJob = job.createSectionItem(
      new SectionId(event.payload.sectionId),
      new ItemId(event.payload.itemId),
      event.payload.item.details
    );

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(nextJob)
      }))
    })
  }

  [JOB_SECTION_ITEM_UPDATED](state: State, event: JobSectionItemUpdatedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = team.jobs.get(event.payload.jobId);
    if (!job) return state;
    
    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(
          job.updateSectionItemDetails(
            new SectionId(event.payload.sectionId),
            new ItemId(event.payload.itemId),
            event.payload.details
          )
        )
      }))
    })
  }

  [JOB_SECTION_ITEM_REORDERED](state: State, event: JobSectionItemReorderedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = team.jobs.get(event.payload.jobId);
    if (!job) return state;

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(
          job.reorderJobSectionItem(
            new SectionId(event.payload.sectionId),
            new ItemId(event.payload.itemId),
            event.payload.order
          )
        )
      }))
    })
  }

  [JOB_SECTION_ITEM_STATUS_UPDATED](state: State, event: JobSectionItemStatusUpdatedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = team.jobs.get(event.payload.jobId);
    if (!job) return state;

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(
          job.updateJobSectionItemStatus(
            new SectionId(event.payload.sectionId),
            new ItemId(event.payload.itemId),
            event.payload.status
          )
        )
      }))
    })
  }

  [JOB_SECTION_ITEM_DELETED](state: State, event: JobSectionItemDeletedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = team.jobs.get(event.payload.jobId);
    if (!job) return state;

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(
          job.deleteJobSectionItem(
            new SectionId(event.payload.sectionId),
            new ItemId(event.payload.itemId)
          )
        )
      }))
    })
  }

  [JOB_VARIATION_ITEM_CREATED](state: State, event: JobVariationItemCreatedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = team.jobs.get(event.payload.jobId);
    if (!job) return state;

    const nextJob = job.createVariationItem(
      new VariationId(event.payload.variationId),
      event.payload.variation.details
    );

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(nextJob)
      }))
    })
  }

  [JOB_VARIATION_ITEM_UPDATED](state: State, event: JobVariationItemUpdatedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = team.jobs.get(event.payload.jobId);
    if (!job) return state;

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(
          job.updateVariationItemDetails(
            new VariationId(event.payload.variationId),
            event.payload.details
          )
        )
      }))
    })
  }

  [JOB_VARIATION_ITEM_REORDERED](state: State, event: JobVariationItemReorderedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = team.jobs.get(event.payload.jobId);
    if (!job) return state;

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(
          job.reorderJobVariationItem(
            new VariationId(event.payload.variationId),
            event.payload.order
          )
        )
      }))
    })
  }

  [JOB_VARIATION_ITEM_DELETED](state: State, event: JobVariationItemDeletedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = team.jobs.get(event.payload.jobId);
    if (!job) return state;

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(
          job.deleteJobVariationItem(
            new VariationId(event.payload.variationId),
          )
        )
      }))
    })
  }

  [JOB_ASSETS_UPDATED](state: State, event: JobAssetsUpdatedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = team.jobs.get(event.payload.jobId);
    if (!job) return state;

    const assets = JobAssets.fromJSON({
      ...job.assets.toJSON(),
      ...event.payload.assets,
    });

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(job.extend({
          assets,
        }))
      }))
    })
  }

  uploadJobFile(teamId: string, jobId: string, name: string, upload: File): [JobFile, firebase.storage.UploadTask] {
    const fileId = this.nextId();
    const filePath = this.jobFileStoragePath(teamId, jobId, fileId, name);
    const file = JobFile.create({
      id: fileId,
      filePath,
      fileType: upload.type,
      fileName: name,
    });

    const task = this.app.storage().ref(filePath).put(upload, {
      contentType: upload.type
    });

    return [file, task];
  }

  async getDownloadUrl(filePath: string) {
    return await this.app.storage().ref(filePath).getDownloadURL();
  }
}
