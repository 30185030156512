import currentSession from '../query/currentSession';
import App from '../app';
import {useEffect} from 'preact/hooks';
import {useReceiveEventCommand} from '../command/receiveEvent';

export default function watchCurrentUserEvents() {
  const {userId} = currentSession();
  const {event} = App.useServices();
  const [,receiveEvent] = useReceiveEventCommand();

  useEffect(() => {
    if (!userId.id) return;
    return event.watchUserEvents(userId.id, receiveEvent);
  }, [userId.id])
}