import {AnyCommand, CREATE_TEAM_COMMAND, CreateTeamCommand, LOAD_USER_TEAMS_COMMAND} from '../type';
import App from '../app';

export default function createTeam(payload: CreateTeamCommand['payload']): AnyCommand {
  return {
    type: CREATE_TEAM_COMMAND,
    payload,
  }
}

export const useCreateTeam = App.wrapCommand(createTeam);