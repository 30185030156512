import {useCallback, useEffect, useRef, useState} from 'preact/hooks';
import {h, Fragment} from 'preact';
import TextArea from '../form/TextArea';
import App from '../../app';
import {CellSelection, EditorChoice, EditorColumn, EditorIntention, IntentionHandler, querySelectCell} from './type';
import UserChoices from './UserChoices';

interface Props<Item> {
  onIntention: IntentionHandler;
  item: Item | null;
  column: EditorColumn<Item> | null;
  container: HTMLElement | null;
  selection: CellSelection | null;
  initialValue: string;
  error?: string;
  status?: string;
  changeList?: Array<any>;
}

const defaultPosition = {
  width: 0,
  height: 0,
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  opacity: 0,
};

type Position = typeof defaultPosition;

export default function UserSelection<Item>(props: Props<Item>) {
  const {
    container,
    selection,
    initialValue,
    onIntention,
    item,
    column,
    status,
    error,
    changeList = [],
  } = props;
  const [inputPosition, setInputPosition] = useState<Position>(defaultPosition);
  const [choicePosition, setChoicePosition] = useState<Position>(defaultPosition);
  const [choicesOnRight, setChoicesOnRight] = useState<boolean>(false);
  const [bestMatch, setBestMatch] = useState<string | null>(null);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const isDefaultPosition = inputPosition === defaultPosition;
  const [target, setTarget] = useState<null | HTMLElement>(null);
  const [choices, setChoices] = useState<Array<EditorChoice>>([])
  const [filteredChoices, setFilteredChoices] = useState<Array<EditorChoice>>([])
  const [showingChoices, setShowingChoices] = useState(false);
  const [resetImpulse, toggleResetImpulse] = useState(false);
  const [lastInitialValue, setLastInitialValue] = useState<string | null>(null);
  const [lastInputPosition, setLastInputPosition] = useState<Position | null>(null);
  const ref = useRef<HTMLTextAreaElement>();

  let editable = false;

  if (column) {
    if (column.editable) {
      editable = column.editable(item, column.key)
    }
  }

  const commit = useCallback((event: Event) => {
    if (!selection || !ref || !ref.current) return;
    if (ref.current.value !== initialValue) {
      onIntention(EditorIntention.COMMIT, ref.current.value, event);
    }
    onIntention(EditorIntention.BLUR, ref.current.value, event);
  }, [onIntention, selection, initialValue]);

  useEffect(() => {
    if (!container || !selection) {
      setTarget(null);
      return;
    }

    const targetCell = container.querySelector(querySelectCell(selection)) as HTMLElement;
    setTarget(targetCell);
  }, [container, selection, initialValue]);

  useEffect(() => {
    if (!target || !container || !ref || !ref.current) {
      setInputPosition(defaultPosition);
      return;
    }

    let offsetLeft = target.offsetLeft;
    let offsetTop = target.offsetTop;
    let parent = target.offsetParent as HTMLElement;

    while (parent !== container && parent !== null) {
      offsetLeft += parent.offsetLeft;
      offsetTop += parent.offsetTop;
      parent = parent.offsetParent as HTMLElement;
    }

    const {width, height} = target.getBoundingClientRect();
    const targetStyle = getComputedStyle(target);

    const borderRightWidth = targetStyle.borderRightWidth
      ? parseFloat(targetStyle.borderRightWidth)
      : 0;

    const borderBottomWidth = targetStyle.borderBottomWidth
      ? parseFloat(targetStyle.borderBottomWidth)
      : 0;

    const nextPosition = {
      width: width + borderRightWidth,
      height: height + borderBottomWidth,
      left: offsetLeft,
      top: offsetTop,
      bottom: 0,
      right: 0,
      opacity: 1,
    };

    setInputPosition(nextPosition);

    const choiceWidth = 120;
    const arrowWidth = 10;
    const mountChoicesToRight = nextPosition.width +
      nextPosition.left + choiceWidth + arrowWidth < container.offsetWidth;

    setChoicesOnRight(mountChoicesToRight);
    setChoicePosition({
      ...nextPosition,
      width: choiceWidth,
      left: mountChoicesToRight
        ? nextPosition.left + nextPosition.width + arrowWidth
        : nextPosition.left - choiceWidth - arrowWidth
    });

    const topEdge = offsetTop - container.scrollTop;
    const bottomEdge = (container.scrollTop + container.offsetHeight) - (offsetTop + height);
    const leftEdge = offsetLeft - container.scrollLeft;
    const rightEdge = (container.scrollLeft + container.offsetWidth) - (offsetLeft + width);

    const closestEdgeLength = Math.min(
      topEdge,
      bottomEdge,
      leftEdge,
      rightEdge
    );

    if (closestEdgeLength < 0) {
      setTimeout(() => {
        if (!ref.current) return;
        ref.current.scrollIntoView({
          block: 'center',
          inline: 'center',
        });
      }, 0);
    }
  }, [container, target, setInputPosition, setChoicePosition, setChoicesOnRight, ...changeList]);

  useEffect(() => {
    if (!ref || !ref.current) return;

    if (isDefaultPosition) {
      return ref.current.blur()
    }

    if (inputPosition !== lastInputPosition || initialValue !== lastInitialValue) {
      ref.current.value = initialValue || '';
      ref.current.focus();
      const value = ref.current.value;
      ref.current.setSelectionRange(0, value.length);
      setIsDirty(false);
      setLastInitialValue(initialValue);
      setLastInputPosition(inputPosition);
    }

    const choices = column && column.choices
      ? column.choices(item)
      : [];
    setChoices(choices);

    const bestMatch = choices.length
      ? ref.current.value
      : null;
    setBestMatch(bestMatch)
  }, [resetImpulse, ref, column, initialValue, inputPosition, setLastInputPosition, setIsDirty, setLastInitialValue, isDefaultPosition, setChoices, setBestMatch]);

  useEffect(() => {
    if (isDefaultPosition) return;

    window.addEventListener('click', commit);
    return () => window.removeEventListener('click', commit);
  }, [commit, isDefaultPosition]);

  useEffect(() => {
    const showingChoices = !!selection && isDirty && choices.length > 0;
    setShowingChoices(showingChoices);
    if (showingChoices) {

    }
  }, [setShowingChoices, isDirty, choices, selection, initialValue]);

  const maybeNextChoice = useCallback(() => {
    const current = filteredChoices.findIndex(c => c.id === bestMatch);
    if (current === -1 || current === filteredChoices.length - 1) return null;
    const next = filteredChoices[current + 1];
    if (next) setBestMatch(next.id);

  }, [filteredChoices, bestMatch, setBestMatch]);

  const maybePrevChoice = useCallback(() => {
    const current = filteredChoices.findIndex(c => c.id === bestMatch);
    if (current === -1 || current === 0) return null;
    const prev = filteredChoices[current - 1];
    if (prev) setBestMatch(prev.id);
  }, [filteredChoices, bestMatch, setBestMatch]);

  const keyDown = useCallback((event: KeyboardEvent) => {
    if (!ref || !ref.current) return;

    const textArea = ref.current as HTMLTextAreaElement;

    const {code, shiftKey, metaKey} = event;
    const {length} = textArea.value;
    const end = Math.max(textArea.selectionStart, textArea.selectionEnd);
    const start = Math.min(textArea.selectionStart, textArea.selectionEnd);

    const selectionLength = end - start;
    const allSelected = selectionLength >= length;
    const hasSelection = selectionLength > 0;
    const atStart = !hasSelection && start === 0;
    const atEnd = !hasSelection && start === length;

    const getChoiceIntention = (): EditorIntention | null => {
      switch (true) {
        case (!showingChoices):
          return EditorIntention.NO_OP;

        case (code === 'ArrowUp'):
          maybePrevChoice();
          return null;

        case (code === 'ArrowDown'):
          maybeNextChoice();
          return null;

        default:
          return EditorIntention.NO_OP;
      }
    };

    const getIntention = (): EditorIntention | null => {
      switch (true) {
        case (code === 'NumpadEnter'):
        case (code === 'Enter'):
          return shiftKey
            ? EditorIntention.NO_OP
            : EditorIntention.DOWN;

        case (code === 'ArrowUp'):
          return (atStart || allSelected)
            ? shiftKey
              ? EditorIntention.PREV_SECTION
              : EditorIntention.UP
            : EditorIntention.NO_OP;

        case (code === 'ArrowDown'):
          return (atEnd || allSelected)
            ? shiftKey
              ? EditorIntention.NEXT_SECTION
              : EditorIntention.DOWN
            : EditorIntention.NO_OP;

        case (code === 'ArrowLeft'):
          return (atStart || allSelected)
            ? EditorIntention.LEFT
            : EditorIntention.NO_OP;

        case (code === 'ArrowRight'):
          return (atEnd || allSelected)
            ? EditorIntention.RIGHT
            : EditorIntention.NO_OP;

        case (code === 'Tab' && shiftKey):
          return EditorIntention.LEFT;

        case (code === 'Tab'):
          return EditorIntention.RIGHT;

        case (code === 'Escape'):
          return isDirty
            ? EditorIntention.CANCEL
            : EditorIntention.BLUR;

        default:
          return EditorIntention.NO_OP
      }
    };

    const choiceIntention = getChoiceIntention();
    const intention = getIntention();

    if ((intention !== EditorIntention.NO_OP && !editable) || intention !== EditorIntention.NO_OP || choiceIntention !== EditorIntention.NO_OP) {
      event.preventDefault();
    }

    if (intention === EditorIntention.CANCEL) {
      setLastInitialValue(null);
      return toggleResetImpulse(!resetImpulse);
    }

    const nextValue = showingChoices
      ? bestMatch
      : textArea.value;

    if (choiceIntention !== null && intention !== null && code !== 'ShiftLeft' && code !== 'ShiftRight') {
      onIntention(intention, nextValue, event);
    }
  }, [onIntention, resetImpulse, setLastInitialValue, toggleResetImpulse, showingChoices, ref, editable, choices, maybeNextChoice, maybePrevChoice]);

  const change = useCallback((event: Event) => {
    if (!ref || !ref.current) return;
    const {value} = ref.current;
    setIsDirty(initialValue !== value);
    const lowerValue = value.toLowerCase();
    const filterFirst = (c: EditorChoice) => c.value.toLowerCase().startsWith(lowerValue);
    const filteredChoices = choices.filter(filterFirst);
    setFilteredChoices(filteredChoices);

    const bestMatch = filteredChoices.find(filterFirst);
    if (bestMatch)
      return setBestMatch(bestMatch.id);

    setBestMatch(null);
  }, [ref, setIsDirty, setBestMatch, setFilteredChoices, initialValue, choices]);

  const pulseOutline = App.useKeyframes(({theme}) => ({
    '0%': {
      outlineWidth: theme.color.blue.toString()
    },
    '50%': {
      outlineColor: theme.color.blue.fade(0).toString()
    },
    '100%': {
      outlineColor: theme.color.blue.toString()
    },
  }));

  const pumpOutline = App.useKeyframes(({theme}) => ({
    '0%': {
      outlineColor: theme.color.red.fade(0).toString()
    },
    '10%': {
      outlineColor: theme.color.red.toString()
    },
    '100%': {
      outlineColor: theme.color.red.toString()
    },
  }));

  const className = App.useStyle(({theme}) => ({
    position: 'absolute',
    resize: 'none',
    maxWidth: 'none !important',
    fontVariantNumeric: 'tabular-nums',
    zIndex: 40,
    textAlign: column && column.numeric
      ? 'right'
      : 'left',
    border: `0.0625rem solid ${theme.color.blue.toString()} !important`,
    animation: error
      ? pumpOutline
      : status
        ? pulseOutline
        : 'none',
    animationDuration: '2s',
    animationIterationCount: error
      ? 1
      : 'infinite',
    $nest: {
      '&:focus': {
        outlineOffset: '-0.1875rem !important',
        outlineColor: error
          ? theme.color.red.toString()
          : editable
            ? theme.color.blue.toString()
            : theme.color.gray.toString()
      }
    }
  }), [editable, column, status, choices, error]);

  if (!selection) {
    return null;
  }

  return (
    <Fragment>
      <TextArea
        innerRef={ref}
        className={className}
        style={inputPosition}
        onClick={event => event.stopPropagation()}
        onBlur={commit}
        onKeyDown={keyDown}
        onInput={change}
      />
      {isDirty && (
        <UserChoices
          choices={filteredChoices}
          style={choicePosition}
          current={bestMatch !== null ? bestMatch : void 0}
          arrowOnRight={!choicesOnRight}
          highlightChoice={c => setBestMatch(c.id)}
          selectChoice={c => onIntention(EditorIntention.COMMIT, c.id, null)}
        />
      )}
    </Fragment>
  )
}