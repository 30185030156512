import {AnyCommand, LOAD_USER_TEAMS_COMMAND} from '../type';
import App from '../app';

export default function loadUserTeams(userId: string): AnyCommand {
  return {
    type: LOAD_USER_TEAMS_COMMAND,
    payload: {
      userId,
    }
  }
}

export const useLoadUserTeams = App.wrapCommand(loadUserTeams);