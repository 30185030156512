import { Fragment, h } from 'preact';
import ValuationSection from '../../model/ValuationSection';
import ButtonGroup from '../form/ButtonGroup';
import { Job } from '../../model/Job';
import ValuationTable from './ValuationTable';
import saveValuationCallback from '../../effect/saveValuationCallback';
import FinaliseValuationButton from './FinaliseValuationButton';

interface Props {
  job: Job;
}

export default function ValuationList({job}: Props) {
  if (!job) return null;

  const {valuation} = job;
  const allValuationSections: ValuationSection[] = valuation.allSections;
  const {newPayment} = valuation;

  const [currentItemCommand, saveItem] = saveValuationCallback(job, valuation);

  return (
    <Fragment>
      {allValuationSections.length > 0 && (
        <ValuationTable
          sections={allValuationSections}
          valuation={valuation}
          expandToFill={true}
          onSave={saveItem}
          currentCommand={currentItemCommand}
          children={(items, selection, setSelection, pages) => {
            const additionalPage = pages.length - 1;

            return (
              <ButtonGroup>
                <FinaliseValuationButton job={job}/>
                {/*{!!newPayment && (*/}
                  {/*<Button*/}
                    {/*onClick={onCreateInvoice}*/}
                    {/*primary={!!valuation.billable}*/}
                    {/*small={true}*/}
                    {/*children={`Create invoice for ${renderCurrency(newPayment)}`}*/}
                  {/*/>*/}
                {/*)}*/}
              </ButtonGroup>
            )
          }}
        />
      )}
    </Fragment>
  )
}