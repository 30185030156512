import App from '../app';
import {AnyCommand} from '../type';
import {CommandContainer} from '../../../framework/type';
import {useEffect} from 'preact/hooks';
import currentSession from './currentSession';
import {useLoadJobImportCommand} from '../command/loadJobImport';
import JobImport from '../model/JobImport';

export default function useJobImport(jobId: string, fileId: string): [JobImport | null, CommandContainer<AnyCommand>] {
  const {teamId} = currentSession();
  const [command, loadJobImport] = useLoadJobImportCommand();

  const id = teamId.id;
  const job = App.useQuery(state => {
    const team = state.team.get(id);
    if (!team) return null;

    const job = team.jobs.get(jobId);
    if (!job) return null;

    return job;
  }, [id, jobId, fileId]);

  useEffect(() => {
    if (!id) return;
    loadJobImport({
      teamId: id,
      jobId,
      fileId,
      source: job && job.assets && job.assets.sourceTransformed
        ? job.assets.sourceTransformed
        : ''
    });
  }, [id, jobId]);

  const state = App.useQuery(state => {
    const team = state.team.get(id);
    if (!team) return null;

    const job = team.jobs.get(jobId);
    if (!job) return null;

    return job.imports.get(fileId);
  }, [id, jobId, fileId]);

  return [state, command];
}