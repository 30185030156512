import {CommandContainer, CommandStatus} from '../../../framework/type';
import useCommandErrors from '../../../framework/hook/useCommandErrors';
import {useEffect} from 'preact/hooks';

export default function callOnCommand(
  command: CommandContainer<any> | null | void,
  onStatus: (message: string) => any,
  onError: (error: string) => any,
) {
  useEffect(() => {
    if (!command) return;

    const {system} = useCommandErrors(command);
    switch (command.status) {
      case CommandStatus.INITIAL:
        return onStatus('');

      case CommandStatus.RUNNING:
        return onStatus('Saving...');

      case CommandStatus.SUCCESS:
        onStatus('');
        break;

      case CommandStatus.FAIL:
        onStatus('');
        onError(system || 'Something went wrong');
        break;
    }
  }, [command, onStatus, onError])
}