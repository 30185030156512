import Form from '../form/Form';
import {h} from 'preact';
import FieldGroup from '../form/FieldGroup';
import {useCallback, useEffect} from 'preact/hooks';
import Button from '../form/Button';
import {CommandStatus} from '../../../../framework/type';
import useCommandErrors from '../../../../framework/hook/useCommandErrors';
import commandsAreRunning from '../../../../framework/commandsAreRunning';
import {Job} from '../../model/Job';
import Paragraph from '../media/Paragraph';
import {useDeleteJobsCommand} from '../../command/deleteJobs';
import currentUserTeam from '../../query/currentUserTeam';

interface Props {
  jobs: Job[];
  onCancel(): void;
  onSuccess(): void;
}

export default function DeleteJobsForm({jobs, onCancel, onSuccess}: Props) {
  const team = currentUserTeam();

  const [command, deleteJobs] = useDeleteJobsCommand();
  const {system} = useCommandErrors(command);
  const isLoading = commandsAreRunning(command);

  const submit = useCallback(() => {
    if (jobs.length === 0 || isLoading || !team) return;

    deleteJobs({
      teamId: team.id,
      jobIds: jobs.map(job => job.id)
    })
  }, [jobs, team, isLoading, deleteJobs]);

  useEffect(() => {
    if (!command || command.status !== CommandStatus.SUCCESS) return;
    onSuccess();
  }, [command]);

  return (
    <Form heading="Delete Jobs" onSubmit={submit} error={system}>
      <Paragraph>This will permanently delete the following jobs:</Paragraph>
      <ul>
        {jobs.map(job => (
          <li>{job.name}</li>
        ))}
      </ul>
      <Paragraph>Are you sure?</Paragraph>
      <FieldGroup horizontal={true}>
        <Button danger={true} type="submit" disabled={isLoading} onClick={submit}>Delete</Button>
        <Button type="cancel" onClick={onCancel}>Cancel</Button>
      </FieldGroup>
    </Form>
  )
}