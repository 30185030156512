import {AnyEvent, JOBS_LOADED_EVENT} from '../type';
import {JobJSON} from '../model/Job';

export default function jobsLoaded(teamId: string, jobs: Array<JobJSON>): AnyEvent {
  return {
    type: JOBS_LOADED_EVENT,
    payload: {
      teamId,
      jobs
    },
  }
}