import {ApplicationKeyframe, StateContext, KeyframeHook} from './type';
import {useContext, useMemo} from 'preact/hooks';
import {keyframes, style} from 'typestyle';

export default function createKeyframesHook<State>(
  stateContext: StateContext<State>
): KeyframeHook<State> {
  return (styleFn, ...dependencies) => {
    const state = useContext(stateContext);
    const cached = useMemo<ApplicationKeyframe>(
      () => typeof styleFn === 'function'
      ? styleFn(state)
      : styleFn,
      [state, ...dependencies]
    );

    return keyframes(cached);
  };
}