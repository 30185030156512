import {h, Ref} from 'preact';
import {HTMLAttributes} from '../../../../framework/type';
import App from '../../app';
import {percent, rem} from 'csx';
import classes from '../../../../framework/classes';
import Input from './Input';
import {useState} from 'preact/hooks';

interface Props extends HTMLAttributes {
  innerRef?: Ref<HTMLInputElement>;
}

export default function FileInput({innerRef, onInput, ...props}: Props) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const className = App.useStyle(({theme}) => ({
    position: 'relative',
    background: theme.color.white.toString(),
    padding: '0.5rem 1.5rem 0.5rem 0.5rem',
    fontSize: rem(1),
    lineHeight: rem(2.25),
    height: rem(2.5),
    width: percent(100),
    maxWidth: percent(100),
    ...theme.component.Input,
    $nest: {
      '& > input': {
        color: 'transparent',
        opacity: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        border: 'none',
      },
      '& > input:focus + span': {
        outline: `0.1875rem solid ${theme.color.blue.toString()}`,
        outlineOffset: rem(0.0625)
      },
      '& > span': {
        border: `0.125rem solid ${theme.color.black.toString()}`,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        lineHeight: rem(2.25),
        padding: '0 1.5rem 0 0.5rem',
        whiteSpace: 'pre',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: selectedFile ? 'normal' : 'italic',
        color: selectedFile
          ? theme.color.black.toString()
          : theme.color.gray.toString()
      },
      '&::before, &::after': {
        display: 'block',
        content: '""',
        position: 'absolute',
        right: rem(0.625),
        top: rem(0.9375),
        width: rem(0.125),
        height: rem(0.5),
        background: theme.color.black.toString(),
        transition: `transform ${theme.transition.icon}`
      },
      '&::before': {
        transform: `rotate(${selectedFile ? '45deg' : '0deg'})`
      },
      '&::after': {
        transform: `rotate(${selectedFile ? '135deg' : '90deg'})`
      }
    }
  }), [selectedFile]);

  const captureValue = (event: Event) => {
    const target = event.target as HTMLInputElement;
    const nextValue = target && target.files && target.files[0]
      ? target.files[0]
      : null;

    setSelectedFile(nextValue);

    if (onInput) {
      onInput(event);
    }
  };

  return (
    <div className={className}>
      <Input {...props} onInput={captureValue} innerRef={innerRef} type="file"/>
      <span>{selectedFile ? selectedFile.name : 'Choose a file'}</span>
    </div>
  )
}