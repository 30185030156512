import Form from '../form/Form';
import {h} from 'preact';
import FieldGroup from '../form/FieldGroup';
import Field from '../form/Field';
import {useCallback, useEffect, useRef, useState} from 'preact/hooks';
import Button from '../form/Button';
import autoFocus from '../../effect/autoFocus';
import jobFileStorageUpload from '../../effect/jobFileStorageUpload';
import Select from '../form/Select';
import FileInput from '../form/FileInput';
import {Job} from '../../model/Job';
import Paragraph from '../media/Paragraph';
import {useImportJobCommand} from '../../command/importJob';
import currentTeam from '../../query/currentTeam';
import {CommandStatus} from '../../../../framework/type';
import commandsAreRunning from '../../../../framework/commandsAreRunning';

interface UploadJobFileForm {
  FileName: string;
}

interface Props {
  onCancel(): void;
  onSuccess(jobId: string): void;
}

export default function UploadHistoricalJobForm({onCancel, onSuccess}: Props) {
  const fileData = useRef<HTMLInputElement>();
  const [job, setJob] = useState<null | Job>(null);
  const [error, setError] = useState('');
  const team = currentTeam();

  const [command, importJob] = useImportJobCommand();

  const submit = useCallback(() => {
    if (!job || !team) return;

    importJob({
      job: job.toJSON(),
      teamId: team.id,
    });
  }, [job, team]);

  const onChange = useCallback((event: Event) => {
    if (!fileData.current || !fileData.current.files) return;
    setError('');
    setJob(null);
    const file = fileData.current.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      if (!reader.result)
        return setError(`Couldn't read file ${file.name}`);

      try {
        const rawJobJson = JSON.parse(reader.result.toString());
        const parsedJob = Job.fromJSON(rawJobJson);

        const validationErrors = parsedJob.validate();
        if (validationErrors.length)
          return setError(`Errors validating job:\n${validationErrors.map(error => `${error.message}\n`)}`)

        setJob(parsedJob);
      } catch (error) {
        return setError(`Error parsing file ${file.name}: ${error.message}`);
      }
    }
    reader.readAsText(file, 'utf-8');
  }, [setError, setJob]);

  useEffect(() => {
    if (!job || !command || command.status !== CommandStatus.SUCCESS) return;
    onSuccess(job.id);
  }, [command, job]);

  const isLoading = commandsAreRunning(command);

  return (
    <Form heading="Upload Historical Job"  onSubmit={submit} error={error}>
      <Paragraph>
        Select a historical .json file to import it.
      </Paragraph>
      <FieldGroup>
        <Field label="Historical File">
          <FileInput
            innerRef={fileData}
            onChange={onChange}
          />
        </Field>
      </FieldGroup>
      <FieldGroup horizontal={true}>
        <Button disabled={!job || isLoading} primary={true} type="submit">{isLoading ? 'Uploading...' : 'Upload Job'}</Button>
        <Button type="cancel" onClick={onCancel}>Cancel</Button>
      </FieldGroup>
    </Form>
  )
}