import {Entity} from '../../../framework/value/Entity';
import {CompanyId, SectionId} from './App';
import StringValue from '../../../framework/value/StringValue';
import {Name} from './JobSection';
import MapValue from '../../../framework/value/MapValue';
import {JobSectionItemCompany, Markup} from './JobSectionItem';
import {Email} from '../../../framework/value/Email';
import {JobCompanyItem} from './JobCompanyItem';

export interface JobCompanyJSON {
  id: string;
  details: Partial<JobCompanyDetailsJSON>;
}

export class JobCompany extends Entity<CompanyId, {
  details: JobCompanyDetails
}> {
  get details() {
    return this.value.details;
  }

  get name() {
    return this.value.details.value.name.value;
  }

  get email() {
    return this.value.details.value.email.value;
  }

  get markup() {
    return this.value.details.value.markup.value;
  }

  toItemCompany(): JobSectionItemCompany {
    return JobSectionItemCompany.fromJSON({
      id: this.id,
      name: this.name,
      markup: this.markup
    })
  }

  setName(name: string) {
    return this.extend({
      details: this.details.extend({
        name: new Name(name)
      })
    })
  }

  setEmail(email: string) {
    return this.extend({
      details: this.details.extend({
        email: new Email(email)
      })
    })
  }

  setMarkup(markup: number) {
    return this.extend({
      details: this.details.extend({
        markup: new Markup(markup)
      })
    })
  }

  fixedCost(items: JobCompanyItem[]) {
    return items.filter(i => i.fixedCost).reduce((cost, item) => {
      return cost + item.cost;
    }, 0);
  }

  cost(items: JobCompanyItem[]) {
    return items.reduce((cost, item) => {
      return cost + item.cost;
    }, 0);
  }

  profit(items: JobCompanyItem[]) {
    return items.reduce((profit, item) => {
      return profit + item.profit;
    }, 0);
  }

  charge(items: JobCompanyItem[]) {
    return items.reduce((charge, item) => {
      return charge + item.charge;
    }, 0);
  }

  actualMarkup(items: JobCompanyItem[]) {
    const charge = this.charge(items);
    const cost = this.cost(items);

    return cost === 0
      ? 0
      : Math.round((charge / cost - 1) * 10000) / 100;
  }

  static fromJSON(json: Partial<JobCompanyJSON> = {}) {
    return new JobCompany({
      id: new SectionId(json.id || ''),
      details: JobCompanyDetails.fromJSON(json.details || {}),
    })
  }
}

export interface JobCompanyDetailsJSON {
  name: string;
  email: string;
  markup: number;
}

export class JobCompanyDetails extends MapValue<{
  name: CompanyName;
  email: Email;
  markup: Markup;
}, JobCompanyDetailsJSON> {
  toJSON(): JobCompanyDetailsJSON {
    return super.toJSON() as JobCompanyDetailsJSON;
  }

  static fromJSON(json: Partial<JobCompanyDetailsJSON> = {}): JobCompanyDetails {
    return new JobCompanyDetails({
      name: new Name(json.name || ''),
      email: new Email(json.email || ''),
      markup: new Markup(json.markup || 0),
    })
  }
}

export class CompanyName extends StringValue {
  validate() {
    return this.collect([
      ...super.validate(),
      !this.value && this.error('Must not be empty'),
    ])
  }
}