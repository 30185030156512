import {h} from 'preact';
import {EditorColumn} from './type';
import {px, rem} from 'csx';
import App from '../../app';
import {useRef, useState} from 'preact/hooks';
import classes from '../../../../framework/classes';

interface Props {
  onResize(key: string, width: number): void;
  columns: Array<EditorColumn<any>>;
}

export default function TableResizer({columns, onResize}: Props) {
  const className = App.useStyle(({theme}) => ({
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    zIndex: 13,
    top: 0,
    bottom: 0,
    left: rem(0.0625),
    $nest: {
      '& > div': {
        position: 'relative',
      },
    }
  }));

  const hoverClass = App.useStyle(({theme}) => ({
    $nest: {
      '&:hover > div': {
        flexShrink: 0,
        borderRight: `0.0625rem solid ${theme.color.blue.toString()}`,
      },
      '& > div:hover': {
        borderRightWidth: rem(0.25),
      },
      '& > div::after': {
        content: '""',
        cursor: 'col-resize',
        display: 'block',
        position: 'absolute',
        top: 0,
        right: rem(-0.25),
        bottom: 0,
        width: rem(0.25),
      }
    }
  }));

  const ref = useRef<HTMLDivElement>();
  const cursorRef = useRef<HTMLDivElement>();
  const [isTracking, setTracking] = useState(false);

  const startTracking = (event: MouseEvent) => {
    const target = event.target as HTMLDivElement;
    if (!target) return;
    event.preventDefault();
    let columnIndex = 0;
    let currentTarget: Node | Element | null = target;
    while ((currentTarget = currentTarget.previousSibling) !== null) {
      columnIndex++
    }

    let initialX = event.clientX;

    const stopTracking = (event: MouseEvent) => {
      const column = columns[columnIndex];
      const delta = event.clientX - initialX;

      window.removeEventListener('mousemove', moveTracking);
      window.removeEventListener('mouseup', stopTracking);
      onResize(column.key.toString(), column.width + delta);
      setTracking(false);
    };

    const moveTracking = (event: MouseEvent) => {
      const delta = event.clientX - initialX;
    };

    window.addEventListener('mousemove', moveTracking);
    window.addEventListener('mouseup', stopTracking);

    setTracking(true);
  };

  return (
    <div className={classes(className, !isTracking && hoverClass)} onMouseDown={startTracking} ref={ref}>
      {columns.map((column) => (
        <div style={{width: px(column.width)}} />
      ))}
    </div>
  )
}