import {h} from 'preact';
import {HTMLAttributes} from '../../../../framework/type';
import App from '../../app';
import classes from '../../../../framework/classes';
import {percent} from 'csx';

export default function ButtonGroup(props: HTMLAttributes) {
  const className = App.useStyle(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    maxHeight: percent(100),
    $nest: {
      '& > button': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      '& > button:last-child': {
        borderTopRightRadius: theme.component.Button.borderRadius,
        borderBottomRightRadius: theme.component.Button.borderRadius,
      },
      '& > * + button': {
        marginLeft: `-${theme.component.Button.borderWidth}`,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
      '& > button:focus': {
        zIndex: 1,
      }
    }
  }));

  return (
    <div {...props} className={classes(className, props.className)}/>
  )
}