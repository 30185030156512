import {Value} from './Value';

export default class StringValue extends Value<string> {
  static WRONG_TYPE = 'Not a string';

  constructor(
    public value: string
  ) {super(value)}

  validate() {
    return this.collect([
      this.isString()
    ])
  }

  protected isString() {
    if (typeof this.value !== 'string')
      return this.error(StringValue.WRONG_TYPE)
  }
}