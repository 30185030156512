import {HTMLAttributes} from '../../../../framework/type';
import App from '../../app';
import {em, rem} from 'csx';
import {h} from 'preact';
import classes from '../../../../framework/classes';

export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

interface Props extends HTMLAttributes {
  level: HeadingLevel;
  noBottomMargin?: boolean;
}
export default function Heading({level, noBottomMargin, ...props}: Props) {
  const className = classes(App.useStyle(({theme}) => ({
    fontFamily: theme.font.heading,
    fontWeight: 900,
    lineHeight: em(1.05),
    letterSpacing: em(-0.05),
    margin: `1.875em 0 ${noBottomMargin ? '0' : '1.125em'}`,
    $nest: {
      'h1&': {
        fontSize: rem(2.5)
      },
      'h2&': {
        fontSize: rem(2)
      },
      'h3&': {
        fontSize: rem(1.75)
      },
      'h4&': {
        fontSize: rem(1.5)
      },
      'h5&': {
        fontSize: rem(1.25)
      },
      'h6&': {
        fontSize: rem(1)
      },
    }
  })), props.className);

  switch (level) {
    case 1: return (<h1 {...props} className={className} />);
    case 2: return (<h2 {...props} className={className} />);
    case 3: return (<h3 {...props} className={className} />);
    case 4: return (<h4 {...props} className={className} />);
    case 5: return (<h5 {...props} className={className} />);
    case 6: return (<h6 {...props} className={className} />);
  }
}