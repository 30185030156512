import {Fragment, h} from 'preact';
import {Job} from '../../model/Job';
import {Link} from 'preact-router';
import {ActionRenderer, EditorColumn, EditorPage} from '../editor/type';
import TableGroup from '../editor/TableGroup';
import Paragraph from '../media/Paragraph';
import App from '../../app';
import TeamUserBadge from './TeamUserBadge';
import PassiveIndicator from '../media/PassiveIndicator';
import renderCurrency from '../../helper/renderCurrency';
import renderPercentage from '../../helper/renderPercentage';
import DateRelative from '../media/DateRelative';

interface Props {
  jobs: Job[]
  children?: ActionRenderer<Job>;
}

export default function JobList({jobs, children}: Props) {
  const className = App.useStyle(({theme}) => ({
    $nest: {}
  }));

  const cellYesClass = App.useStyle(({theme}) => ({
    color: theme.color.green.toString(),
    fontWeight: 900,
  }));

  const cellNoClass = App.useStyle(({theme}) => ({
    color: theme.color.red.toString(),
    fontWeight: 900,
  }));

  const yesNoClasses = (job: Job | null, key: keyof Job) => {
    return !!job && !!job[key]
      ? cellYesClass
      : cellNoClass
  };

  const columns: Array<EditorColumn<Job>> = [
    {
      key: 'ref',
      label: 'Job ref',
      width: 80,
      renderCell: (item) => {
        return <Link href={`/job/${item.id}`}>{item.ref}</Link>;
      }
    },
    {
      key: 'receivedAt',
      label: 'Date received',
      width: 100,
      renderCell: (item) => {
        return item.receivedAt.isValid
          ? <DateRelative date={item.receivedAt.date}/>
          : <PassiveIndicator>N/A</PassiveIndicator>;
      }
    },
    {
      key: 'client',
      label: 'Client',
      width: 140,
      renderCell: (item) => {
        return item.details.client;
      }
    },
    {
      key: 'contact',
      label: 'Individual',
      width: 100,
      renderCell: (item) => {
        return item.details.contactName;
      }
    },
    {
      key: 'name',
      label: 'Job',
      width: 100,
      renderCell: (item) => {
        return (
          <Link href={`/job/${item.id}`}>{item.name || <i>Unnamed</i>}</Link>
        )
      }
    },
    {
      key: 'internal',
      label: 'Int/Ext',
      width: 100,
      renderCell: (item) => {
        return [
          item.internal && 'INT',
          item.external && 'EXT',
        ].filter(Boolean).join('/') || <PassiveIndicator>N/A</PassiveIndicator>
      }
    },
    {
      key: 'returnBy',
      label: 'Return by',
      width: 100,
      renderCell: (item) => {
        return item.returnBy.isValid
          ? <DateRelative date={item.returnBy.date}/>
          : <PassiveIndicator>N/A</PassiveIndicator>;
      }
    },
    {
      key: 'enquiriesSent',
      label: 'Enquiries sent',
      width: 100,
      classes: yesNoClasses,
      renderCell: (item) => {
        return item.enquiriesSent
          ? 'YES'
          : 'NO';
      }
    },
    {
      key: 'siteVisitArranged',
      label: 'Site visit arranged',
      width: 140,
      classes: yesNoClasses,
      renderCell: (item) => {
        return item.siteVisitArranged
          ? 'YES'
          : 'NO';
      }
    },
    {
      key: 'costing',
      label: 'Costing',
      width: 80,
      classes: yesNoClasses,
      renderCell: (item) => {
        return item.costing
          ? 'YES'
          : 'NO';
      }
    },
    {
      key: 'assignee',
      label: 'Surveyor',
      width: 100,
      renderCell: (item) => {
        return (
          <TeamUserBadge userId={item.details.assignee}/>
        )
      }
    },
    {
      key: 'charge',
      label: 'Tender sum',
      width: 100,
      numeric: true,
      renderCell: (item) => {
        return item.charge
          ? renderCurrency(item.charge)
          : '';
      }
    },
    {
      key: 'markup',
      label: 'Margin',
      width: 100,
      numeric: true,
      renderCell: (item) => {
        return item.markup
          ? renderPercentage(item.markup)
          : '';
      }
    },
  ];

  const pages: Array<EditorPage<Job>> = [
    {
      name: '',
      items: jobs
    }
  ];

  return (
    <Fragment>
      <TableGroup
        className={className}
        id="jobs"
        columns={columns}
        pages={pages}
        singlePage={pages[0]}
        preventSelect={true}
        allowShrinkRows={true}
        expandToFill={true}
        children={children}
      />
      {!jobs.length && (
        <Paragraph>There are no active jobs.</Paragraph>
      )}
    </Fragment>
  );
}