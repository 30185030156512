import {Application, EventFunction, IdentifiedCommandFunction, ServiceMap, SystemState} from './type';
import {createContext, h} from 'preact';
import createServiceHook from './createServiceHook';
import createStyleHook from './createStyleHook';
import createCommandHook from './createCommandHook';
import createQueryHook from './createQueryHook';
import createContainer from './createContainer';
import createGlobalStyleHook from './createGlobalStyleHook';
import createStyleSheetHook from './createStyleSheetHook';
import createIdGenerator from './createIdGenerator';
import createWrapCommand from './createWrapCommand';
import createKeyframesHook from './createKeyframesHook';

export default function createApplication<State, CommandTypes, EventTypes, Services extends ServiceMap<State, CommandTypes, EventTypes>>(
  defaultServices: Services,
  defaultState: State,
): Application<State, CommandTypes, EventTypes, Services> {
  const ServicesContext = createContext<Services>(defaultServices);
  const StateContext = createContext<State>(defaultState);
  const SystemStateContext = createContext<SystemState<CommandTypes>>({
    command: {}
  });
  const DispatchFunction = createContext<IdentifiedCommandFunction<CommandTypes>>(() => '');

  const useServices = createServiceHook<State, CommandTypes, EventTypes, Services>(ServicesContext);
  const useStyle = createStyleHook(StateContext);
  const useKeyframes = createKeyframesHook(StateContext);
  const useGlobalStyle = createGlobalStyleHook(StateContext);
  const useStyleSheet = createStyleSheetHook(StateContext);
  const useCommand = createCommandHook(DispatchFunction, SystemStateContext);
  const useQuery = createQueryHook(StateContext);
  const nextCommandId = createIdGenerator();
  const wrapCommand = createWrapCommand(nextCommandId, useCommand);

  const Container = createContainer<State, CommandTypes, EventTypes, Services>({
    dispatch: DispatchFunction,
    state: StateContext,
    services: ServicesContext,
    systemState: SystemStateContext,
  }, {
    services: defaultServices,
    state: defaultState,
  });

  return {
    useServices,
    useStyle,
    useKeyframes,
    useGlobalStyle,
    useStyleSheet,
    useCommand,
    nextCommandId,
    wrapCommand,
    useQuery,
    Container,
  }
}