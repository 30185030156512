import {h} from 'preact';
import {Link} from 'preact-router/match';
import App from '../../app';
import {percent, rem} from 'csx';

export interface TabItem {
  label: string;
  href: string;
}

interface Props {
  left?: TabItem[];
  right?: TabItem[];
}

export default function Tabs(props: Props) {
  const {left = [], right = []} = props;

  const className = App.useStyle(({theme}) => ({
    width: percent(100),
    margin: `1.25rem 0`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    borderBottom: `0.0625rem solid ${theme.color.gray.toString()}`,
    justifyContent: 'space-between',
    $nest: {
      '& > div': {
        marginBottom: rem(-0.0625),
        flexDirection: 'row',
        alignItems: 'flex-start',
      },
      '& > div:first-child': {
        justifyContent: 'flex-end',

      },
      '& > div:last-child': {
        justifyContent: 'flex-start',
      },
    }
  }), []);

  const tabClass = App.useStyle(({theme}) => ({
    display: 'inline-flex',
    textDecoration: 'none',
    color: theme.color.black.toString(),
    border: `0.0625rem solid transparent`,
    fontSize: rem(0.875),
    borderBottomColor: theme.color.gray.toString(),
    height: rem(2.25),
    lineHeight: rem(2.25),
    padding: '0 1.125rem',
  }), []);

  const activeClass = App.useStyle(({theme}) => ({
    border: `0.0625rem solid ${theme.color.gray.toString()}`,
    borderBottomColor: theme.color.background.toString(),
  }), []);

  const renderTab = (item: TabItem) => (
    <Link
      className={tabClass}
      activeClassName={activeClass}
      href={item.href}
      children={item.label}
    />
  );

  return (
    <div className={className}>
      <div>{left.map(renderTab)}</div>
      <div>{right.map(renderTab)}</div>
    </div>
  )
}