import { h } from 'preact';
import TableGroup from '../editor/TableGroup';
import { ActionRenderer, EditorColumn, EditorColumnType, EditorPage, SaveHandler } from '../editor/type';
import { Job } from '../../model/Job';
import renderCurrency from '../../helper/renderCurrency';
import renderPercentage from '../../helper/renderPercentage';
import { CommandContainer } from '../../../../framework/type';
import PassiveIndicator from '../media/PassiveIndicator';
import JobVariationItem from '../../model/JobVariationItem';
import coerceDate from '../../helper/coerceDate';
import DateRelative from '../media/DateRelative';

interface Props {
  job?: Job;
  items: JobVariationItem[];
  onSave?: SaveHandler<JobVariationItem>;
  currentCommand?: CommandContainer<any>;
  children?: ActionRenderer<JobVariationItem>;
  forceQuantity?: boolean;
  expandToFill?: boolean;
}

export default function VariationsTable({children, expandToFill, forceQuantity, items, job, onSave, currentCommand}: Props) {
  const editable = (item: JobVariationItem, key: keyof JobVariationItem) => {
    if (!job) return false;

    switch (key) {
      case 'ci':
      case 'po':
      case 'description':
      case 'company':
      case 'rate':
      case 'markup':
      case 'instructedAt':
      case 'sentAt':
      case 'proceedAt':
      case 'commencedAt':
      case 'completedAt':
        return true;

      default:
        return false;
    }
  };

  const columns: Array<EditorColumn<JobVariationItem>> = [
    {
      key: 'instructedAt',
      label: 'Instructed At',
      width: 40,
      type: EditorColumnType.DATE,
      editable,
      renderCell: (item) => {
        return item && item.instructedAt.isValid
          ? <DateRelative date={item.instructedAt.date} />
          : '';
      },
      stringValue: (item) => {
        return item && item.instructedAt.toString();
      },
    },
    {
      key: 'order',
      label: 'Var no',
      width: 32,
      editable,
      renderCell: (item) => {
        return item && item.order;
      }
    },
    {
      key: 'ci',
      label: 'CI no',
      width: 32,
      editable,
      renderCell: (item) => {
        return item && item.ci;
      }
    },
    {
      key: 'description',
      label: 'Description',
      width: 320,
      renderCell: (item) => {
        return item && item.description;
      },
      editable,
    },
    {
      key: 'company',
      label: 'Allocation',
      width: 120,
      renderCell: (item) => {
        return item && item.company.name;
      },
      stringValue: (item) => {
        return item && item.company.name;
      },
      editable,
      choices: () => job
        ? job.companies.choices
        : []
    },
    {
      key: 'rate',
      label: 'Cost',
      width: 80,
      numeric: true,
      type: EditorColumnType.CURRENCY,
      renderCell: (item) => {
        return item && item.rate ? renderCurrency(item.rate) : '';
      },
      editable,
    },
    {
      key: 'markup',
      label: 'Profit',
      width: 80,
      numeric: true,
      type: EditorColumnType.PERCENTAGE,
      renderCell: (item) => {
        if (item.trackCompany) return (
          <PassiveIndicator>
            {renderPercentage(item.markup)}
          </PassiveIndicator>
        );

        return item && item.markup ? renderPercentage(item.markup) : '';
      },
      editable,
    },
    {
      key: 'charge',
      label: 'Charge',
      width: 80,
      numeric: true,
      type: EditorColumnType.CURRENCY,
      renderCell: (item) => {
        return item && item.charge ? renderCurrency(item.charge) : '';
      },
      editable,
    },
    {
      key: 'po',
      label: 'PO no',
      width: 32,
      editable,
      renderCell: (item) => {
        return item && item.po;
      }
    },
    {
      key: 'sentAt',
      label: 'Sent to CA',
      width: 40,
      type: EditorColumnType.DATE,
      editable,
      renderCell: (item) => {
        return item && item.sentAt.isValid
          ? <DateRelative date={item.sentAt.date} />
          : '';
      },
      stringValue: (item) => {
        return item && item.sentAt.toString()
      }
    },
    {
      key: 'proceedAt',
      label: 'Proceed received',
      width: 40,
      type: EditorColumnType.DATE,
      editable,
      renderCell: (item) => {
        return item && item.proceedAt.isValid
          ? <DateRelative date={item.proceedAt.date} />
          : '';
      },
      stringValue: (item) => {
        return item && item.proceedAt.toString()
      }
    },
    {
      key: 'commencedAt',
      label: 'Commenced at',
      width: 40,
      type: EditorColumnType.DATE,
      editable,
      renderCell: (item) => {
        return item && item.commencedAt.isValid
          ? <DateRelative date={item.commencedAt.date} />
          : '';
      },
      stringValue: (item) => {
        return item && item.commencedAt.toString()
      }
    },
    {
      key: 'completedAt',
      label: 'Completed at',
      width: 40,
      type: EditorColumnType.DATE,
      editable,
      renderCell: (item) => {
        return item && item.completedAt.isValid
          ? <DateRelative date={item.completedAt.date} />
          : '';
      },
      stringValue: (item) => {
        return item && item.completedAt.toString()
      }
    },
    {
      key: 'totalDays',
      label: 'Total duration',
      width: 40,
      editable,
      renderCell: (item) => {
        return item && item.totalDays;
      },
    },
  ];

  const pages: Array<EditorPage<JobVariationItem>> = [
    {
      items,
      name: '',
    }
  ];

  return (
    <TableGroup
      id="variations_table"
      columns={columns}
      pages={pages}
      singlePage={pages[0]}
      onSave={onSave}
      allowCreate={true}
      children={children}
      currentCommand={currentCommand}
      allowFullScreen={true}
      allowShrinkRows={true}
      expandToFill={expandToFill}
      stickOnScroll={true}
    />
  )
}