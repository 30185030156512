import MapValue from '../../../framework/value/MapValue';
import StoragePath from './StoragePath';
import {Entity} from '../../../framework/value/Entity';
import EntityMapValue from '../../../framework/value/EntityMapValue';
import Identity from '../../../framework/value/Identity';
import StringValue from '../../../framework/value/StringValue';

export enum JobAssetType {
  UNKNOWN = 'UNKNOWN',
  DETAILS = 'DETAILS',
  SCHEDULE = 'SCHEDULE',
  SOURCE_SCHEDULE = 'SOURCE_SCHEDULE',
  SOURCE_TRANSFORMED = 'SOURCE_TRANSFORMED',
  SOURCE_MAPPING = 'SOURCE_MAPPING', 
  SOURCE_QUOTE = 'SOURCE_QUOTE',
  COMPANIES = 'COMPANIES',
  COSTINGS = 'COSTINGS', 
  QUOTE = 'QUOTE', 
  BREAKDOWN = 'BREAKDOWN', 
  PROGRESS = 'PROGRESS', 
  INVOICES = 'INVOICES', 
  PAYMENTS = 'PAYMENTS',
  VALUATION = 'VALUATION',
}

export class JobAssetId extends Identity<JobAssetType> {
  static KEYS = Object.keys(JobAssetType);
  static INVALID_ASSET_TYPE = 'Invalid JobAssetType';

  validate() {
    return this.collect([
      ...super.validate(),
      !(JobAssetId.KEYS.includes(this.value)) && this.error(JobAssetId.INVALID_ASSET_TYPE),
    ])
  }

  static UNKNOWN = new JobAssetId(JobAssetType.UNKNOWN)
}

type JobAssetsValue = Record<JobAssetType, string>;

export type JobAssetsJSON = {[key in keyof JobAssetsValue]: JobAssetJSON}

export interface JobAssetJSON {
  id: string;
  path: string;
  fileId: string;
}

export class JobAsset extends Entity<JobAssetId, {
  path: StoragePath
  fileId: StringValue
}> {
  get filePath(): string {
    return this.value.path.value;
  }

  get exists(): boolean {
    return !!this.filePath;
  }

  get label(): string {
    return JobAsset.LABEL[this.id as JobAssetType]
  }

  get fileId(): string {
    return this.value.fileId.value;
  }

  static fromJSON(id: JobAssetType, json: Partial<JobAssetJSON>): JobAsset {
    return new JobAsset({
      id: new JobAssetId(id),
      path: new StoragePath(json.path || ''),
      fileId: new StringValue(json.fileId || ''),
    })
  }
  
  static LABEL: {[key in keyof JobAssetsValue]: string} = {
    UNKNOWN: 'Unknown',
    DETAILS: 'Job Details',
    SCHEDULE: 'Schedule of Works',
    SOURCE_SCHEDULE: 'Original Client Schedule',
    SOURCE_QUOTE: 'Completed Client Schedule',
    SOURCE_TRANSFORMED: 'Intermediate Client Schedule',
    SOURCE_MAPPING: 'Client Schedule Mapping',
    COMPANIES: 'Companies',
    COSTINGS: 'Costings',
    QUOTE: 'Quote',
    BREAKDOWN: 'Company Breakdown',
    PROGRESS: 'PROGRESS',
    INVOICES: 'INVOICES',
    PAYMENTS: 'PAYMENTS',
    VALUATION: 'Latest Interim Valuation'
  }
}

export class JobAssets extends EntityMapValue<JobAssetId, JobAsset, JobAssetsJSON> {
  get details(): string {
    return this.value[JobAssetType.DETAILS].value.path.value;
  }

  get schedule(): string {
    return this.value[JobAssetType.SCHEDULE].value.path.value;
  }

  get sourceSchedule(): string {
    return this.value[JobAssetType.SOURCE_SCHEDULE].value.path.value;
  }

  get sourceTransformed(): string {
    return this.value[JobAssetType.SOURCE_TRANSFORMED].value.path.value;
  }

  get sourceMapping(): string {
    return this.value[JobAssetType.SOURCE_MAPPING].value.path.value;
  }

  get companies(): string {
    return this.value[JobAssetType.COMPANIES].value.path.value;
  }

  get quote(): string {
    return this.value[JobAssetType.QUOTE].value.path.value;
  }

  get costings(): string {
    return this.value[JobAssetType.COSTINGS].value.path.value;
  }

  get breakdown(): string {
    return this.value[JobAssetType.BREAKDOWN].value.path.value;
  }

  get progress(): string {
    return this.value[JobAssetType.PROGRESS].value.path.value;
  }

  get invoices(): string {
    return this.value[JobAssetType.INVOICES].value.path.value;
  }

  get payments(): string {
    return this.value[JobAssetType.PAYMENTS].value.path.value;
  }

  get valuation(): string {
    return this.value[JobAssetType.VALUATION].value.path.value;
  }

  get all(): JobAsset[] {
    return Object.keys(this.value).reduce((assets, key) => {
      const asset = this.value[key];
      if (!asset || !asset.exists) return assets;

      return [...assets, this.value[key]];
    }, [] as JobAsset[]);
  }

  get userFacingTypes(): JobAssetType[] {
    return [
      // JobAssetType.SCHEDULE,
      JobAssetType.SOURCE_SCHEDULE,
      // JobAssetType.SOURCE_QUOTE,
      JobAssetType.QUOTE,
      // JobAssetType.COSTINGS,
      JobAssetType.COMPANIES,
      JobAssetType.VALUATION,
    ]
  }

  get userFacing(): JobAsset[] {
    return this.all.filter(({id}) => this.userFacingTypes.includes(id))
  }

  static fromJSON(json: Partial<JobAssetsJSON> = {}): JobAssets {
    return new JobAssets({
      [JobAssetType.DETAILS]: JobAsset.fromJSON(JobAssetType.DETAILS, json.DETAILS || {}),
      [JobAssetType.SCHEDULE]: JobAsset.fromJSON(JobAssetType.SCHEDULE, json.SCHEDULE || {}),
      [JobAssetType.SOURCE_SCHEDULE]: JobAsset.fromJSON(JobAssetType.SOURCE_SCHEDULE, json.SOURCE_SCHEDULE || {}),
      [JobAssetType.SOURCE_TRANSFORMED]: JobAsset.fromJSON(JobAssetType.SOURCE_TRANSFORMED, json.SOURCE_TRANSFORMED || {}),
      [JobAssetType.SOURCE_MAPPING]: JobAsset.fromJSON(JobAssetType.SOURCE_MAPPING, json.SOURCE_MAPPING || {}),
      [JobAssetType.SOURCE_QUOTE]: JobAsset.fromJSON(JobAssetType.SOURCE_QUOTE, json.SOURCE_MAPPING || {}),
      [JobAssetType.COMPANIES]: JobAsset.fromJSON(JobAssetType.COMPANIES, json.COMPANIES || {}),
      [JobAssetType.COSTINGS]: JobAsset.fromJSON(JobAssetType.COSTINGS, json.COSTINGS || {}),
      [JobAssetType.QUOTE]: JobAsset.fromJSON(JobAssetType.QUOTE, json.QUOTE || {}),
      [JobAssetType.BREAKDOWN]: JobAsset.fromJSON(JobAssetType.BREAKDOWN, json.BREAKDOWN || {}),
      [JobAssetType.PROGRESS]: JobAsset.fromJSON(JobAssetType.PROGRESS, json.PROGRESS || {}),
      [JobAssetType.INVOICES]: JobAsset.fromJSON(JobAssetType.INVOICES, json.INVOICES || {}),
      [JobAssetType.PAYMENTS]: JobAsset.fromJSON(JobAssetType.PAYMENTS, json.PAYMENTS || {}),
      [JobAssetType.VALUATION]: JobAsset.fromJSON(JobAssetType.VALUATION, json.VALUATION || {}),
    })
  }

  static create(): JobAssets {
    return JobAssets.fromJSON()
  }
}

interface DetailsJSON {

}

export class Details extends MapValue<{}, DetailsJSON> {
}