import createApplication from '../../framework/createApplication';
import {AnyCommand, AnyEvent, AppServices, JobCostingsApplication} from './type';
import MemoryAuthService from './service/memory/AuthService';
import createMemoryStorage from '../../framework/createMemoryStorage';
import MemoryJobService from './service/memory/JobService';
import {State} from './model/State';
import MemoryUserService from './service/memory/UserService';
import MemoryEventService from './service/memory/EventService';
import MemoryTeamService from './service/memory/TeamService';
import MemoryEditorService from './service/memory/EditorService';
import MemoryJobCompanyService from './service/memory/JobCompanyService';

const storage = createMemoryStorage();

const services: AppServices = {
  auth: new MemoryAuthService(storage),
  team: new MemoryTeamService(storage),
  editor: new MemoryEditorService(storage),
  event: new MemoryEventService(storage),
  job: new MemoryJobService(storage),
  jobCompany: new MemoryJobCompanyService(storage),
  user: new MemoryUserService(storage),
};

const state: State = State.DEFAULT;

const App: JobCostingsApplication = createApplication<State, AnyCommand, AnyEvent, AppServices>(services, state);

export default App;