import MapValue from './MapValue';
import {Value} from './Value';
import BooleanValue from './BooleanValue';

export default class EntityStatus<T extends Record<string, Value<any>> = {}> extends MapValue<T & {
  loading: BooleanValue;
  isNew: BooleanValue;
}> {
  get loading() {
    return this.value.loading.value;
  }

  get isNew() {
    return this.value.isNew.value;
  }
}