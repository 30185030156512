import StringValue from '../../../framework/value/StringValue';
import MapValue from '../../../framework/value/MapValue';
import { ItemId } from './App';
import NumberValue from '../../../framework/value/NumberValue';
import { Entity } from '../../../framework/value/Entity';
import BooleanValue from '../../../framework/value/BooleanValue';
import { ValidationError } from '../../../framework/error';
import EnumValue from '../../../framework/value/EnumValue';

export interface ValuationSectionItemJSON {
  id: string;
  details: ValuationSectionItemDetailsJSON;
  company: ValuationSectionItemCompanyJSON;
  order: number;
}

export enum ValuationSectionItemType {
  IMPORTED = 'IMPORTED',
  VARIATION = 'VARIATION',
}

export class ItemType extends EnumValue(ValuationSectionItemType) {}

export default class ValuationSectionItem extends Entity<ItemId, {
  details: Details,
  order: Order,
  company: ValuationSectionItemCompany;
}> {
  get type() {
    return this.value.details.value.type.value;
  }

  get company() {
    return this.value.company;
  }

  get po() {
    return this.details.value.po.value;
  }

  get ci() {
    return this.details.value.ci.value;
  }

  get order() {
    return this.value.order.value
  }

  get item() {
    return this.value.details.value.item.value;
  }

  get details() {
    return this.value.details;
  }

  get description() {
    return this.value.details.value.description.value;
  }

  get charge() {
    return this.value.details.value.charge.value;
  }

  get invoiced() {
    return this.value.details.value.invoiced.value;
  }

  get complete() {
    // return Math.round(Math.random() * 100);
    return this.value.details.value.complete.value;
  }

  get unstarted() {
    return this.complete === 0;
  }

  get completed() {
    return this.complete === 100;
  }

  get newPayment() {
    return this.billable - this.invoiced;
  }

  get omit() {
    return this.details.value.omit.value;
  }

  get omitted() {
    return this.omit
      ? this.charge
      : 0;
  }

  get imported() {
    return this.type === ValuationSectionItemType.IMPORTED;
  }

  get variation() {
    return this.type === ValuationSectionItemType.VARIATION;
  }

  get billable() {
    if (this.omit || this.complete <= 0) return 0;

    return Math.max(0, this.charge * (this.complete / 100));
  }

  toJSON(): ValuationSectionItemJSON {
    return super.toJSON() as ValuationSectionItemJSON;
  }

  update(details: Partial<ValuationSectionItemDetailsJSON>): ValuationSectionItem {
    let response = this;

    if (details.item)
      response = response.setItem(details.item);

    if (details.description)
      response = response.setDescription(details.description);

    if (details.charge)
      response = response.setCharge(details.charge);

    return response;
  }

  setItem(item: string) {
    if (this.imported)
      throw new ValidationError('Item', 'This item was fixed at specification');

    return this.extend({
      details: this.details.extend({
        item: new Item(item)
      })
    })
  }

  setPO(po: string) {
    return this.extend({
      details: this.details.extend({
        po: new StringValue(po)
      })
    })
  }

  setDescription(description: string) {
    if (this.imported)
      throw new ValidationError('Description', 'This item was fixed at specification');

    return this.extend({
      details: this.details.extend({
        description: new Description(description)
      })
    })
  }

  setOrder(order: number) {
    if (this.imported)
      throw new ValidationError('Order', 'This item was fixed at specification');

    return this.extend({
      order: new Order(order)
    })
  }

  setCharge(charge: number) {
    if (this.imported)
      throw new ValidationError('Charge', 'This item was fixed at specification');

    return this.extend({
      details: this.details.extend({
        charge: new Charge(charge)
      })
    })
  }

  setComplete(complete: number) {
    if (complete < 0 || complete > 100)
      throw new ValidationError('Complete', 'This must be a percentage between 0 and 100')

    return this.extend({
      details: this.details.extend({
        complete: new Complete(complete)
      })
    })
  }

  setOmit(omit: boolean) {
    return this.extend({
      details: this.details.extend({
        omit: new BooleanValue(omit)
      })
    })
  }

  setInvoiced(invoiced: number) {
    if (invoiced < this.invoiced)
      throw new ValidationError('Invoiced', 'Cannot set to a lower amount than already invoiced')

    if (invoiced > this.charge)
      throw new ValidationError('Invoiced', 'Cannot invoice more than the charge');

    return this.extend({
      details: this.value.details.extend({
        invoiced: new Invoiced(invoiced)
      })
    })
  }

  static fromJSON(json: Partial<ValuationSectionItemJSON>) {
    return new ValuationSectionItem({
      id: new ItemId(json.id || ''),
      details: Details.fromJSON(json.details || {}),
      order: new Order(json.order || 0),
      company: ValuationSectionItemCompany.fromJSON(json.company || {})
    })
  }
}

export class Order extends NumberValue {}

export interface ValuationSectionItemDetailsJSON {
  item: string;
  type: ValuationSectionItemType;
  description: string;
  charge: number;
  complete: number;
  invoiced: number;
  po: string;
  ci: string;
  omit: boolean;
}

export class Details extends MapValue<{
  item: Item;
  type: ItemType;
  description: Description;
  charge: Charge;
  invoiced: Invoiced;
  complete: Complete;
  po: StringValue;
  ci: StringValue;
  omit: BooleanValue;
}> {
  toJSON(): ValuationSectionItemDetailsJSON {
    return super.toJSON() as ValuationSectionItemDetailsJSON;
  }

  static fromJSON(json: Partial<ValuationSectionItemDetailsJSON>): Details {
    return new Details({
      item: new Item(json.item || ''),
      type: new ItemType(json.type || ValuationSectionItemType.VARIATION),
      description: new Description(json.description || ''),
      charge: new Charge(json.charge || 0),
      invoiced: new Invoiced(json.invoiced || 0),
      complete: new Complete(json.complete || 0),
      po: new StringValue(json.po || ''),
      ci: new StringValue(json.ci || ''),
      omit: new BooleanValue(typeof json.omit !== 'undefined' ? json.omit : false),
    });
  }
}
export interface ValuationSectionItemCompanyJSON {
  id: string;
  name: string;
}

export class ValuationSectionItemCompany extends MapValue<{
  id: StringValue;
  name: StringValue;
}> {
  get id() {
    return this.value.id.value;
  };

  get name() {
    return this.value.name.value;
  }

  toJSON(): ValuationSectionItemCompanyJSON {
    return super.toJSON() as ValuationSectionItemCompanyJSON;
  }

  static fromJSON(json: Partial<ValuationSectionItemCompanyJSON>): ValuationSectionItemCompany {
    return new ValuationSectionItemCompany({
      id: new StringValue(json.id || ''),
      name: new StringValue(json.name || ''),
    })
  }
}

export class Item extends StringValue {}
export class Description extends StringValue {}

export class PositiveValue extends NumberValue {
  static MUST_BE_POSITIVE_NUMBER = 'Must be a number greater than or equal to 0';

  validate() {
    return this.collect([
      ...super.validate(),
      !(this.value >= 0) && this.error(Charge.MUST_BE_POSITIVE_NUMBER),
    ])
  }
}

export class Charge extends PositiveValue {}
export class Invoiced extends PositiveValue {}
export class Complete extends NumberValue {}

export interface ValuationSectionItemStatusJSON {
  omit: boolean;
}
