import {AnyCommand, UPDATE_JOB_SECTION_ITEM_COMMAND, UpdateJobSectionItemCommand} from '../type';
import App from '../app';

export default function updateJobSectionItem(payload: UpdateJobSectionItemCommand['payload']): AnyCommand {
  return {
    type: UPDATE_JOB_SECTION_ITEM_COMMAND,
    payload,
  }
}

export const useUpdateJobSectionItemCommand = App.wrapCommand(updateJobSectionItem);