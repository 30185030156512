import { Fragment, h } from 'preact';
import Button from '../form/Button';
import { useCallback } from 'preact/hooks';
import { Job } from '../../model/Job';
import JobVariationItem from '../../model/JobVariationItem';
import { IconType } from '../media/Icon';
import currentTeam from '../../query/currentTeam';
import callOnCommandStatus from '../../effect/callOnCommandStatus';
import { CommandStatus } from '../../../../framework/type';
import { useReorderJobVariationItemCommand } from '../../command/reorderJobVariationItem';

interface Props {
  job: Job;
  variation: JobVariationItem;
  onRunning?(): any;
}

export default function MoveVariationItemDownButton({job, variation, onRunning}: Props) {
  const [command, reorderJobVariationItem] = useReorderJobVariationItemCommand();
  const nextItem = job.nextVariationItem(variation.id);
  const team = currentTeam();

  const click = useCallback((event: MouseEvent) => {
    event.stopPropagation();

    if (!!team && !!nextItem) {
      reorderJobVariationItem({
        teamId: team.id,
        jobId: job.id,
        variationId: variation.id,
        order: nextItem.order + 0.5,
      })
    }
  }, [reorderJobVariationItem, job, team, nextItem]);

  if (onRunning) {
    callOnCommandStatus(CommandStatus.RUNNING, command, onRunning);
  }

  return (
    <Fragment>
      <Button
        small={true}
        onClick={click}
        onlyIcon={IconType.MOVE_DOWN}
        readOnly={!nextItem}
        tooltip={`Move Variation Down`}
      />
    </Fragment>
  )
}