import {Fragment, h} from 'preact';
import {
  ActionRenderer,
  CellSelection,
  CellSelector,
  EditorColumn,
  EditorIntention,
  EditorPage,
  extractSettings,
  IntentionHandler,
  RowSelection,
  SaveHandler
} from './type';
import {CommandContainer, HTMLAttributes} from '../../../../framework/type';
import Button from '../form/Button';
import {useCallback, useEffect, useRef, useState} from 'preact/hooks';
import EditorTable from './Table';
import Heading from '../media/Heading';
import App from '../../app';
import {em, important, percent, px, rem} from 'csx';
import ButtonGroup from '../form/ButtonGroup';
import TableResizer from './TableResizer';
import UserSelection from './UserSelection';
import StatusBar from '../form/StatusBar';
import classes from '../../../../framework/classes';
import {IconType} from '../media/Icon';
import callOnCommand from '../../effect/callOnCommand';
import extendAtIndex from '../../effect/expandAtIndex';
import synchroniseScrolling from '../../effect/synchroniseScrolling';
import Select from '../form/Select';

export interface Props<T> extends HTMLAttributes {
  id: string;
  columns: Array<EditorColumn<T>>;
  pages?: Array<EditorPage<T>>;
  singlePage?: EditorPage<T>;
  highlights?: Array<RowSelection>;
  onClickPageCell?: CellSelector;
  onSave?: SaveHandler<T>;
  currentCommand?: CommandContainer<any> | null;
  preventSelect?: boolean;
  allowCreate?: boolean;
  onlyCreateLast?: boolean;
  allowFullscreen?: boolean;
  allowShrinkRows?: boolean;
  expandToFill?: boolean;
  children?: ActionRenderer<T>;
  stickyHeader?: any;
  stickOnScroll?: boolean;
  maxHeight?: any;
}

const HEADER_HEIGHT = rem(1.625);
const ACTIONS_HEIGHT = rem(2.5);
const TABLE_SPACING = rem(2.5);
const EMPTY_ROWS: any[] = [];

export default function TableGroup<T>(props: Props<T>) {
  const {
    id,
    columns,
    singlePage,
    pages,
    onSave,
    highlights = [],
    currentCommand,
    preventSelect,
    allowCreate,
    onlyCreateLast,
    allowFullScreen,
    allowShrinkRows,
    expandToFill,
    children,
    stickyHeader,
    stickOnScroll,
    maxHeight,
    ...htmlProps
  } = props;
  const font = App.useQuery(state => state.theme.font.text);
  const {editor} = App.useServices();
  const initialSettings = extractSettings(columns);
  const [settings, setSettings] = useState(editor.setInitialSettings(id, initialSettings));
  const [error, setError] = useState('');
  const [status, setStatus] = useState('');
  const [selectedCell, setSelectedCell] = useState<null | CellSelection>(null);
  const [selectedRows, setSelectedRows] = useState<CellSelection[]>([]);
  const [selectedItem, setSelectedItem] = useState<null | T>(null);
  const [selectedItems, setSelectedItems] = useState<T[]>([]);
  const [isExpanded, toggleExpanded] = useState(false);
  const [isShrinkRows, toggleShrinkRows] = useState(false);

  useEffect(() => {
    document.body.style.overflow = isExpanded
      ? 'hidden'
      : 'initial'
  }, [isExpanded]);

  callOnCommand(currentCommand, setStatus, setError);

  const [combinedColumns, setCombinedColumns] = useState<Array<EditorColumn<T>>>([]);
  extendAtIndex(columns, settings.columns, setCombinedColumns);

  const setColumnWidth = useCallback((key: string, width: number) => {
    editor.setColumnWidth(id, key, width);
    const nextSettings = editor.getColumnSettings(id);
    if (nextSettings) setSettings(nextSettings);
  }, [id, editor, setSettings]);

  const fitColumns = useCallback(() => {
    const minWidth = 16;
    const maxWidth = 320;
    const offset = 32;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (context)
      context.font = `12px ${font}`;

    const getLength = (text: string) => !context
      ? text.length * 9.5
      : context.measureText(text).width;

    combinedColumns.forEach((column: EditorColumn<T>, index: number) => {
      const matchPages = singlePage ? [singlePage] : pages ? pages : [];
      let current = Math.max(getLength(column.label), 0);

      matchPages.forEach(page => {
        page.items.forEach(item => {
          if (current === maxWidth) return;
          const cell = column.renderCell
            ? column.renderCell(item, index)
            : null;

          const value = cell === null || typeof cell !== 'string'
            ? '' + item[column.key]
            : cell.toString();

          current = Math.min(maxWidth, Math.max(getLength(value), current));
        });
      });

      setColumnWidth(column.key as string, Math.max(Math.ceil(current), minWidth) + offset);
    })
  }, [singlePage, pages, combinedColumns, setColumnWidth]);

  const stickyRef = useRef<HTMLDivElement>();
  const actionsRef = useRef<HTMLDivElement>();
  const headerRef = useRef<HTMLDivElement>();
  const contentRef = useRef<HTMLDivElement>();

  const [stickyOffset, setStickyOffset] = useState(0);

  useEffect(() => {
    if (!stickyRef || !stickyRef.current) return;
    const {height} = getComputedStyle(stickyRef.current);
    const nextOffset = (height === null ? 0 : parseFloat(height));
    setStickyOffset(nextOffset);
    stickyRef.current.style.marginBottom = `-${nextOffset}px`;
  }, [isExpanded, setStickyOffset, stickyRef]);

  const deselect = useCallback(() => {
    setSelectedCell(null);
    setSelectedRows([]);
    setSelectedItem(null);
    setSelectedItems([]);
  }, [setSelectedCell, setSelectedItem, setSelectedItems]);

  const outerClass = App.useStyle(({theme}) => {
    return {
      width: percent(100),
      maxWidth: percent(100),
      display: 'flex',
      flexDirection: 'column',
      $nest: {
        '& > *': {
          width: percent(100),
        },
        '& > *:last-child': {
          flex: 1,
        }
      }
    }
  }, [isExpanded]);

  const containerClass = App.useStyle(({theme}) => {
    const position = isExpanded ? 'fixed' : 'relative';
    const width = isExpanded ? `calc(100% - ${rem(2)})` : percent(100);
    const offset = isExpanded ? rem(1) : 'unset';

    return {
      position,
      width,
      top: offset,
      left: offset,
      right: offset,
      bottom: offset,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      maxHeight: percent(100),
      background: theme.color.background.toString(),
      zIndex: isExpanded ? 998 : 'auto',
      '&::after': {
        position: 'absolute',
        display: 'block',
        content: '""',
        left: 0,
        bottom: 0,
        right: 0,
        height: rem(1.25),
        background: `linear-gradient(to bottom, transparent 0%, ${theme.color.background.toString()} 100%)`
      },
    }
  }, [isExpanded]);

  const actionsClass = App.useStyle(({theme}) => {
    const position = isExpanded ? 'fixed' : 'relative';
    const offset = isExpanded ? rem(1) : 'unset';
    const top = isExpanded
      ? px(stickyOffset)
      : 'unset';

    return {
      position,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'betweenJustified',
      zIndex: isExpanded ? 999 : 'auto',
      top,
      left: offset,
      right: offset,
      height: ACTIONS_HEIGHT,
      background: theme.color.background.toString(),
      padding: `0.25rem 0`,
      fontSize: rem(0.875),
      $nest: {
        '&> div:nth-child(1)': {
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start'
        },
        '&> div:nth-child(1) > *:last-child': {
          flex: 1,
        }
      }
    }
  }, [isExpanded]);

  const headerClass = App.useStyle(({theme}) => ({
    zIndex: 40,
    overflow: 'hidden',
    position: 'absolute',
    height: HEADER_HEIGHT,
    top: isExpanded
      ? `calc(${ACTIONS_HEIGHT} + ${px(stickyOffset)} - 1rem)`
      : 0,
    left: 0,
    right: 0,
    background: theme.color.background.toString(),
    $nest: {
      '& > table': {
        pointerEvents: 'none',
        borderTopColor: important(theme.color.background.toString()),
      },
      '& th': {
        background: theme.color.background.toString(),
        borderRight: important('none'),
      },
    }
  }), [isExpanded]);

  const contentClass = App.useStyle(({theme}) => ({
    position: 'relative',
    padding: `${singlePage ? (isExpanded ? `calc(${ACTIONS_HEIGHT} + ${stickyOffset}px)` : 0) : `calc(${TABLE_SPACING} + ${stickyOffset}px)`} 0 1.25rem`,
    margin: `calc(${HEADER_HEIGHT}) 0 0`,
    maxHeight: maxHeight
      ? maxHeight
      : isExpanded || expandToFill
        ? 'unset'
        : percent(100),
    overflow: 'auto',
    flex: 1,
    width: percent(100),
    $nest: {
      '& td': {
        whiteSpace: isShrinkRows
          ? important('pre')
          : 'initial',
      },
      '& table': {
        borderTopColor: important(theme.color.background.toString()),
      },
      '& th': {
        background: theme.color.background.toString(),
        borderRight: important('none'),
        paddingLeft: important('0.3125rem'),
      },
      '& th:first-child': {
        borderLeft: `0.0625rem solid ${theme.color.background.toString()}`,
      }
    }
  }), [isExpanded, expandToFill, singlePage]);

  const pageHeadingClass = App.useStyle(({theme}) => ({
    position: 'relative',
    $nest: {
      '& + &': {
        fontWeight: 400,
        pointerEvents: 'none',
        color: theme.color.black.toString(),
        margin: `${em(-1.0625)} 0 0`,
      }
    }
  }), [isExpanded, expandToFill, singlePage]);

  const stickyClass = App.useStyle(({theme}) => ({
    position: 'fixed',
    zIndex: 1001,
    bottom: percent(100),
    left: 0,
    right: 0,
    width: percent(100),
    background: theme.color.background.toString(),
    transition: `margin ${theme.transition.navBar}`,
    padding: '0 1rem',
    ...theme.media.tablet({
      padding: '0 2rem'
    })
  }), [isExpanded, expandToFill, singlePage]);

  const pageSelectorClass = App.useStyle(({theme}) => ({
    maxWidth: rem(10),
    $nest: {
      '& > select': {
        fontSize: important(rem(0.75)),
        fontWeight: important(900),
        textTransform: 'uppercase',
      }
    }
  }), [isExpanded, expandToFill, singlePage]);

  const getItemFromSelection = useCallback((selection: CellSelection): T | null => {
    const warn = (...messages: any[]) => {
      console.warn(...messages);
      return null;
    };

    const page = singlePage || (pages ? pages[selection.page || 0] : null);
    if (!page) return warn('Bad page ' + selection.page, selection);

    const row = page.items[selection.row] as T;
    if (!row && !allowCreate) return warn('Bad row ' + selection.row, selection);

    return row;
  }, [singlePage, pages]);

  const getValueFromSelection = useCallback((selection: CellSelection): string => {
    const warn = (...messages: any[]) => {
      console.warn(...messages);
      return '';
    };

    const row = getItemFromSelection(selection);
    if (!row && !allowCreate) return warn('Bad row ' + selection.row, selection);


    const column = columns[selection.column];
    if (!column) return warn('Bad column ' + selection.column, selection);

    if (row && column.stringValue) {
      return column.stringValue(row);
    }

    try {
      if (!row || typeof row[column.key] === 'undefined' || row[column.key] === null) {
        return '';
      }

      return row[column.key] + '';
    } catch (e) {
      return warn(e.message, selection);
    }
  }, [getItemFromSelection]);

  const clickCell: CellSelector = useCallback((page, column, row, virtual, event?: MouseEvent) => {
    if (!contentRef || !contentRef.current) return;

    const nextSelection = {page, column, row};
    const nextItem = getItemFromSelection(nextSelection);
    const isSelectedCell = !!nextItem && JSON.stringify(nextItem) === JSON.stringify(selectedItem);
    const selectedIndex = !!nextItem
      ? selectedItems.indexOf(nextItem)
      : -1;

    const alreadySelected = selectedIndex !== -1;

    if (nextSelection && nextItem && event && event.shiftKey && !isSelectedCell) {
      if (alreadySelected) {
        setSelectedItems(selectedItems.filter(i => i !== nextItem));
        setSelectedRows(selectedRows.filter(r => r.row !== nextSelection.row));
      } else {
        setSelectedItems([...selectedItems, nextItem]);
        setSelectedRows([...selectedRows, nextSelection]);
      }
    } else {
      setSelectedCell(nextSelection);
      setSelectedRows(nextSelection ? [nextSelection] : []);
      setSelectedItem(nextItem);
      setSelectedItems(nextItem ? [nextItem] : []);
    }
  }, [selectedItems, getItemFromSelection, setSelectedItem, setSelectedItems, setSelectedRows, setSelectedCell, selectedCell, selectedItem, selectedItems, selectedRows, contentRef]);

  useEffect(() => {
    if (!selectedCell) return deselect();
    const nextItem = getItemFromSelection(selectedCell);

    setSelectedItem(nextItem);
    setSelectedItems(nextItem ? [nextItem] : []);
  }, [pages, selectedCell, setSelectedItems, setSelectedItem, deselect]);


  const initialValue = selectedCell
    ? getValueFromSelection(selectedCell)
    : '';

  const handleIntention: IntentionHandler = useCallback((intention, value, event: Event | null) => {
    if (selectedCell === null) return;
    const initialValue = selectedCell
      ? getValueFromSelection(selectedCell)
      : '';

    const {key, editable} = columns[selectedCell.column];
    const isDirty = value !== initialValue;

    const isEditable = editable && (selectedItem || allowCreate)
      ? editable(selectedItem, key)
      : false;

    const {row, column} = selectedCell;
    const page = selectedCell.page || 0;
    const currentPage = singlePage || pages![page];

    const nextPage = singlePage ? null : pages![page + 1] || null;
    const prevPage = singlePage ? null : pages![page - 1] || null;
    const nextColumn = columns[column + 1] || null;
    const prevColumn = columns[column - 1] || null;
    const nextRow = currentPage.items[row + 1] || null;
    const prevRow = currentPage.items[row - 1] || null;
    const isLastRow = row === currentPage.items.length - 1;
    const isCreateRow = row === currentPage.items.length;
    const isLastPage = !!singlePage || page === pages!.length - 1;
    const canCreate = allowCreate
      ? onlyCreateLast
        ? isLastPage
        : true
      : false;

    function nextSelection(): CellSelection {
      const currentCell = selectedCell!;

      const select = (part: Partial<CellSelection>) => {
        const next = {...currentCell, ...part};
        return getItemFromSelection(next) !== null
          ? next
          : currentCell;
      };

      switch (intention) {
        case EditorIntention.UP: {
          switch (true) {
            case (prevRow !== null):
              return select({row: row - 1});
            case (prevPage !== null):
              return select({
                page: page - 1,
                row: !onlyCreateLast && canCreate
                  ? Math.max(prevPage!.items.length, 0)
                  : Math.max(prevPage!.items.length - 1, 0)
              });
            default: return currentCell;
          }
        }

        case EditorIntention.DOWN: {
          switch (true) {
            case (nextRow !== null):
            case ((isDirty && isCreateRow) || (isLastRow && canCreate)):
              return select({row: row + 1});
            case (nextPage !== null):
              return select({page: page + 1, row: 0});
            default: return currentCell;
          }
        }

        case EditorIntention.LEFT: {
          switch (true) {
            case (prevColumn !== null):
              return select({column: column - 1});
            case (prevRow !== null):
              return select({column: columns.length - 1, row: row - 1});
            case (prevPage !== null):
              return select({page:
                page - 1,
                column: columns.length - 1,
                row: !onlyCreateLast && canCreate
                  ? Math.max(prevPage!.items.length, 0)
                  : Math.max(prevPage!.items.length - 1, 0)
              });
            default: return currentCell;
          }
        }

        case EditorIntention.RIGHT: {
          switch (true) {
            case (nextColumn !== null):
              return select({column: column + 1});
            case (nextRow !== null):
            case (isLastRow && allowCreate):
              return select({column: 0, row: row + 1});
            case (nextPage !== null):
              return select({page: page + 1, column: 0, row: 0});
            default: return currentCell;
          }
        }

        case EditorIntention.PREV_SECTION: {
          switch (true) {
            case (prevPage !== null):
              return select({page: page - 1, row: 0});
            default: return currentCell;
          }
        }

        case EditorIntention.NEXT_SECTION: {
          switch (true) {
            case (nextPage !== null):
              return select({page: page + 1, row: 0});
            default: return currentCell;
          }
        }

        case EditorIntention.COMMIT:
        case EditorIntention.NO_OP:
        default:
          return currentCell;
      }
    }

    const next = nextSelection();

    if (intention === EditorIntention.COMMIT || next !== selectedCell) {
      if (isEditable && isDirty && onSave) {
        const errors = onSave(page, selectedItem, key, value);

        if (errors.length) {
          return setError(errors[0].message);
        }
      }

      clickCell(next.page || 0, next.column, next.row, !selectedItem);
    }

    if (!isEditable && intention === EditorIntention.NO_OP) {
      setError('This cell is locked');
    } else {
      setError('');
    }

    const isInsideContent = event && event.target && contentRef && contentRef.current
      ? contentRef.current.contains(event.target as Node)
      : false;

    if ((intention === EditorIntention.BLUR && !isInsideContent) || intention === EditorIntention.CANCEL) {
      deselect()
    }
  }, [onSave, allowCreate, onlyCreateLast, deselect, getItemFromSelection, getValueFromSelection, clickCell, selectedCell, selectedItem, columns, pages]);

  const currentColumn = selectedCell
    ? columns[selectedCell.column]
    : null;

  const [filteredPage, setFilteredPage] = useState<null | number>(null);
  const hasPages = !singlePage && pages && pages.length > 0;
  const selectFilteredPage = useCallback((event: Event) => {
    if (!hasPages) return;
    const target = event.target as HTMLSelectElement;
    const showAll = target.value === '';
    if (showAll)
      return setFilteredPage(null);

    setFilteredPage(parseInt(target.value));
  }, [hasPages, setFilteredPage]);

  const filteredPages = !singlePage && pages
    ? filteredPage === null
      ? pages
      : [pages[filteredPage]]
    : [];

  return (
    <div className={outerClass}>
      {isExpanded && stickyHeader && (
        <div ref={stickyRef} className={stickyClass}>
          {stickyHeader}
        </div>
      )}
      <div className={actionsClass} ref={actionsRef}>
        <div>
          {children && children(selectedItems, selectedCell, setSelectedCell, pages || [])}
          <StatusBar
            error={error}
            status={status}
          />
        </div>
        <ButtonGroup>
          {hasPages && (
            <Select onChange={selectFilteredPage} small={true} className={pageSelectorClass}>
              <option value="">Show Everything</option>
              <optgroup label="Show only:">
                {pages!.map((page, index) => (
                  <option value={index}>{page.name}</option>
                ))}
              </optgroup>
            </Select>
          )}
          <Button
            small={true}
            onClick={fitColumns}
            onlyIcon={IconType.FIT_COLUMNS}
            tooltip={'Fit columns'}
            tooltipRight={true}
          />
          {allowShrinkRows && (
            <Button
              small={true}
              onClick={() => toggleShrinkRows(!isShrinkRows)}
              onlyIcon={isShrinkRows ? IconType.EXPAND_ROWS : IconType.SHRINK_ROWS}
              tooltip={isShrinkRows ? 'Expand rows' : 'Shrink rows'}
              tooltipRight={true}
            />
          )}
          {false && allowFullScreen && (
            <Button
              small={true}
              onClick={() => toggleExpanded(!isExpanded)}
              onlyIcon={isExpanded ? IconType.WINDOW_SCREEN : IconType.FULL_SCREEN}
            />
          )}
        </ButtonGroup>
      </div>
      <div {...htmlProps} className={classes(containerClass, htmlProps.className)} id={id}>
        <div
          className={headerClass}
          onScroll={synchroniseScrolling(contentRef)}
          ref={headerRef}>
          <TableResizer
            columns={combinedColumns}
            onResize={setColumnWidth}
          />
          <EditorTable
            columns={combinedColumns}
            rowData={EMPTY_ROWS}
            forceLabelHeader={true}
            preventSelect={true}
          />
        </div>
        <div
          className={contentClass}
          onScroll={synchroniseScrolling(headerRef)}
          ref={contentRef}
        >
          {!preventSelect && (
            <UserSelection
              item={selectedItem}
              column={currentColumn}
              initialValue={initialValue}
              selection={selectedCell}
              onIntention={handleIntention}
              container={contentRef.current || null}
              error={error}
              status={status}
              changeList={[isShrinkRows, isExpanded]}
            />
          )}
          {filteredPages.map((page, index, {length}) => {
            const isLast = index === length - 1;
            const virtualRows = allowCreate
              ? onlyCreateLast
                ? (isLast ? 1 : 0)
                : 1
              : 0;

            return (
              <Fragment>
                <Heading className={pageHeadingClass} level={5}>{page.name}</Heading>
                {page.subtitle && (
                  <Heading className={pageHeadingClass} level={6}>{page.subtitle}</Heading>
                )}
                <EditorTable
                  page={index}
                  columns={combinedColumns}
                  rowData={page.items}
                  onClickCell={clickCell.bind(null, index)}
                  highlights={selectedRows.filter(h => h.page === index)}
                  preventSelect={preventSelect}
                  virtualRows={virtualRows}
                />
              </Fragment>
            )
          })}
          {singlePage && (
            <EditorTable
              page={0}
              columns={combinedColumns}
              rowData={singlePage.items}
              hideHeader={true}
              onClickCell={clickCell.bind(null, 0)}
              highlights={selectedRows.filter(h => h.page === 0)}
              preventSelect={preventSelect}
              virtualRows={allowCreate ? 1 : 0}
            />
          )}
        </div>
      </div>
    </div>
  )
}