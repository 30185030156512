import {CompanyId} from '../model/App';
import {useCallback} from 'preact/hooks';
import {Job} from '../model/Job';
import latestCommand from '../../../framework/latestCommand';
import {SaveHandler} from '../component/editor/type';
import {CommandContainer} from '../../../framework/type';
import {JobCompany} from '../model/JobCompany';
import {useCreateJobCompanyCommand} from '../command/createJobCompany';
import currentSession from '../query/currentSession';
import {useUpdateJobCompanyCommand} from '../command/updateJobCompany';

export default function saveJobCompanyCallback(
  job: Job
): [CommandContainer<any>, SaveHandler<JobCompany>] {
  const {teamId} = currentSession();
  const [createCompanyCommand, createJobCompany] = useCreateJobCompanyCommand();
  const [updateJobCompanyCommand, updateJobCompany] = useUpdateJobCompanyCommand();
  const commandContainer = latestCommand([createCompanyCommand, updateJobCompanyCommand]);

  const saveCompany = useCallback((page: number, target: null | JobCompany, key: keyof JobCompany, value: string | null) => {
    if (!job) return [];

    const item = target
      ? job.companies.get(target.id)!
      : JobCompany.fromJSON({
        id: CompanyId.nextId()
      });

    if (!item) return [new Error('Cannot find the item to save to')];

    let nextValue: any;

    function getValue(): JobCompany {
      switch (key) {
        case 'name':
          nextValue = value || '';
          return item.setName(nextValue);

        case 'email':
          nextValue = value || '';
          return item.setEmail(nextValue);

        case 'markup':
          nextValue = !value ? 0 : parseFloat(value);
          return item.setMarkup(nextValue);

        default:
          return item;
      }
    }

    try {
      const next = getValue();
      const errors = next.validate();

      if (errors.length === 0) {
        if (target) {
          updateJobCompany({
            teamId: teamId.id,
            jobId: job.id,
            companyId: next.id,
            details: {
              [key]: nextValue
            },
          });
        } else {
          createJobCompany({
            teamId: teamId.id,
            jobId: job.id,
            companyId: next.id,
            details: {
              [key]: nextValue
            },
          });
        }
      }

      return errors;
    } catch (e) {
      return [e];
    }
  }, [job, updateJobCompany, createCompanyCommand]);

  return [
    commandContainer,
    saveCompany,
  ]
}
