import {
  COMPLETE_JOB_FILE_UPLOAD_COMMAND,
  CompleteJobFileUploadCommand,
  CREATE_JOB_SECTION_COMMAND,
  CREATE_JOB_SECTION_ITEM_COMMAND,
  CreateJobSectionCommand,
  CreateJobSectionItemCommand,
  DELETE_JOB_SECTIONS_COMMAND,
  DeleteJobSectionsCommand,
  FAIL_JOB_FILE_UPLOAD_COMMAND,
  FailJobFileUploadCommand,
  FINALISE_COSTINGS, FINALISE_SCHEDULE,
  FinaliseCostingsCommand, FinaliseScheduleCommand,
  JOB_ASSETS_UPDATED,
  JOB_FILE_UPLOAD_FAILED,
  JOB_FILE_UPLOAD_STARTED,
  JOB_FILE_UPLOAD_UPDATED,
  JOB_FILE_UPLOADED,
  JOB_IMPORT_LOADED,
  JOB_SECTION_CREATED,
  JOB_SECTION_ITEM_CREATED,
  JOB_SECTION_ITEM_DELETED,
  JOB_SECTION_ITEM_REORDERED,
  JOB_SECTION_ITEM_STATUS_UPDATED,
  JOB_SECTION_ITEM_UPDATED, JOB_SECTION_REORDERED, JOB_SECTION_UPDATED,
  JobAssetsUpdatedEvent,
  JobFileUploadedEvent,
  JobFileUploadFailedEvent,
  JobFileUploadStartedEvent,
  JobFileUploadUpdatedEvent,
  JobImportLoadedEvent,
  JobSectionCreatedEvent,
  JobSectionItemCreatedEvent,
  JobSectionItemDeletedEvent,
  JobSectionItemReorderedEvent, JobSectionItemStatusUpdatedEvent,
  JobSectionItemUpdatedEvent, JobSectionReorderedEvent, JobSectionUpdatedEvent,
  JobService,
  LOAD_JOB_IMPORT_COMMAND,
  LoadJobImportCommand, REORDER_JOB_SECTION_COMMAND,
  REORDER_JOB_SECTION_ITEM_COMMAND, ReorderJobSectionCommand,
  ReorderJobSectionItemCommand,
  START_JOB_FILE_UPLOAD_COMMAND,
  StartJobFileUploadCommand, UPDATE_JOB_SECTION_COMMAND,
  UPDATE_JOB_SECTION_ITEM_COMMAND, UpdateJobSectionCommand,
  UpdateJobSectionItemCommand
} from '../../type';
import {AsyncCommandResponse} from '../../../../framework/type';
import {TodoError} from '../../../../framework/error';
import {JobCostingsBaseService} from '../../services';
import {JobFile} from '../../model/JobFile';
import {State} from '../../model/State';

export default class MemoryJobService extends JobCostingsBaseService implements JobService {
  constructor(
    protected readonly storage: Storage,
  ) {super()}

  async [LOAD_JOB_IMPORT_COMMAND](state: State, command: LoadJobImportCommand): AsyncCommandResponse {
    return this.todo();
  }

  async [START_JOB_FILE_UPLOAD_COMMAND](state: State, command: StartJobFileUploadCommand): AsyncCommandResponse {
    return this.todo();
  }

  async [FAIL_JOB_FILE_UPLOAD_COMMAND](state: State, command: FailJobFileUploadCommand): AsyncCommandResponse {
    return this.todo();
  }

  async [COMPLETE_JOB_FILE_UPLOAD_COMMAND](state: State, command: CompleteJobFileUploadCommand): AsyncCommandResponse {
    return this.todo();
  }

  async [CREATE_JOB_SECTION_ITEM_COMMAND](state: State, command: CreateJobSectionItemCommand): AsyncCommandResponse {
    return this.todo();
  }

  async [UPDATE_JOB_SECTION_ITEM_COMMAND](state: State, command: UpdateJobSectionItemCommand): AsyncCommandResponse {
    return this.todo();
  }

  async [REORDER_JOB_SECTION_ITEM_COMMAND](state: State, command: ReorderJobSectionItemCommand): AsyncCommandResponse {
    return this.todo();
  }

  async [REORDER_JOB_SECTION_COMMAND](state: State, command: ReorderJobSectionCommand): AsyncCommandResponse {
    return this.todo();
  }

  async [CREATE_JOB_SECTION_COMMAND](state: State, command: CreateJobSectionCommand): AsyncCommandResponse {
    return this.todo();
  }

  async [DELETE_JOB_SECTIONS_COMMAND](state: State, command: DeleteJobSectionsCommand): AsyncCommandResponse {
    return this.todo();
  }

  async [FINALISE_SCHEDULE](state: State, command: FinaliseScheduleCommand): AsyncCommandResponse {
    return this.todo();
  }

  async [FINALISE_COSTINGS](state: State, command: FinaliseCostingsCommand): AsyncCommandResponse {
    return this.todo();
  }

  async [UPDATE_JOB_SECTION_COMMAND](state: State, command: UpdateJobSectionCommand): AsyncCommandResponse {
    return this.todo();
  }

  [JOB_SECTION_UPDATED](state: State, event: JobSectionUpdatedEvent) {
    return state;
  }

  [JOB_SECTION_CREATED](state: State, event: JobSectionCreatedEvent) {
    return state;
  }

  [JOB_SECTION_REORDERED](state: State, event: JobSectionReorderedEvent) {
    return state;
  }

  [JOB_IMPORT_LOADED](state: State, event: JobImportLoadedEvent) {
    return state;
  }

  [JOB_FILE_UPLOAD_STARTED](state: State, event: JobFileUploadStartedEvent) {
    return state;
  }

  [JOB_FILE_UPLOAD_UPDATED](state: State, event: JobFileUploadUpdatedEvent) {
    return state;
  }

  [JOB_FILE_UPLOAD_FAILED](state: State, event: JobFileUploadFailedEvent) {
    return state;
  }

  [JOB_FILE_UPLOADED](state: State, event: JobFileUploadedEvent) {
    return state;
  }

  [JOB_SECTION_ITEM_CREATED](state: State, event: JobSectionItemCreatedEvent) {
    return state;
  }

  [JOB_SECTION_ITEM_UPDATED](state: State, event: JobSectionItemUpdatedEvent) {
    return state;
  }

  [JOB_SECTION_ITEM_REORDERED](state: State, event: JobSectionItemReorderedEvent) {
    return state;
  }

  [JOB_SECTION_ITEM_STATUS_UPDATED](state: State, event: JobSectionItemStatusUpdatedEvent) {
    return state;
  }

  [JOB_SECTION_ITEM_DELETED](state: State, event: JobSectionItemDeletedEvent) {
    return state;
  }

  [JOB_ASSETS_UPDATED](state: State, event: JobAssetsUpdatedEvent) {
    return state;
  }

  uploadJobFile(teamId: string, jobId: string, name: string, upload: File): [JobFile, firebase.storage.UploadTask] {
    throw new TodoError();
  }
}