import {h} from 'preact';
import {Job} from '../../model/Job';
import ButtonGroup from '../form/ButtonGroup';
import Form from '../form/Form';
import SaveAsTemplateButton from '../team/SaveAsTemplateButton';
import CostingsSectionsTable from './CostingsSectionsTable';
import saveJobSectionCallback from '../../effect/saveJobSectionCallback';
import CompanySummaryTable from './CompanySummaryTable';
import DeleteCompaniesButton from './DeleteCompaniesButton';
import saveJobCompanyCallback from '../../effect/saveJobCompanyCallback';
import Heading from '../media/Heading';
import Button from '../form/Button';
import {IconType} from '../media/Icon';
import FinaliseCostingsButton from './FinaliseCostingsButton';
import MoveSectionUpButton from './MoveSectionUpButton';
import MoveSectionDownButton from './MoveSectionDownButton';
import DeleteSectionsButton from './DeleteSectionsButton';

interface Props {
  job: Job;
}

export const SummarySectionId = {
  DETAILS: 'details',
  SECTIONS: 'sections',
  COMPANIES: 'companies',
};

export default function SummaryList({job}: Props) {
  if (!job) return null;

  const [currentSectionCommand, saveSection] = saveJobSectionCallback(job);
  const [currentCompanyCommand, saveCompany] = saveJobCompanyCallback(job);

  return (
    <Form fill={true}>
      <ButtonGroup>
        <FinaliseCostingsButton job={job}/>
        <SaveAsTemplateButton job={job}/>
      </ButtonGroup>
      <Heading level={5} id={SummarySectionId.SECTIONS}>Sections</Heading>
      <CostingsSectionsTable
        job={job}
        onSave={saveSection}
        currentCommand={currentSectionCommand}
        children={(items, selection, setSelection, pages) => {
          const item = !!selection && !!items.length && items[0];

          return (
            <ButtonGroup>
              <Button
                small={true}
                tooltip="Create Section"
                primary={!job.hasSections}
                onlyIcon={IconType.CREATE_ITEM}
                onClick={() => setSelection({page: 0, column: 0, row: pages.length ? pages[0].items.length : 0})}
              />
              {!!item && (
                <MoveSectionUpButton
                  job={job}
                  section={item}
                  onRunning={() => setSelection({...selection!, row: selection!.row - 1})}
                />
              )}
              {!!item && (
                <MoveSectionDownButton
                  job={job}
                  section={item}
                  onRunning={() => setSelection({...selection!, row: selection!.row + 1})}
                />
              )}
              <DeleteSectionsButton
                job={job}
                sections={items}
                onSuccess={() => setSelection(null)}
              />
            </ButtonGroup>
          )
        }}
      />
      <Heading level={5} id={SummarySectionId.COMPANIES}>Companies</Heading>
      <CompanySummaryTable
        job={job}
        onSave={saveCompany}
        currentCommand={currentCompanyCommand}
        children={(items, selection, setSelection, pages) => {
          return (
            <ButtonGroup>
              <Button
                small={true}
                tooltip="Create Company"
                onlyIcon={IconType.CREATE_ITEM}
                onClick={() => setSelection({page: 0, column: 0, row: pages.length ? pages[0].items.length : 1})}
              />
              {!!selection && (
                <DeleteCompaniesButton
                  job={job}
                  companies={items}
                  onSuccess={() => setSelection(null)}
                />
              )}
            </ButtonGroup>
          )
        }}
      />
    </Form>
  )
}