import {AnyCommand, DELETE_JOB_SECTION_ITEMS_COMMAND, DeleteJobSectionItemsCommand} from '../type';
import App from '../app';

export default function deleteJobSectionItems(payload: DeleteJobSectionItemsCommand['payload']): AnyCommand {
  return {
    type: DELETE_JOB_SECTION_ITEMS_COMMAND,
    payload,
  }
}

export const useDeleteJobSectionItemsCommand = App.wrapCommand(deleteJobSectionItems);