import {AnyCommand, COMPLETE_JOB_FILE_UPLOAD_COMMAND, CompleteJobFileUploadCommand} from '../type';
import App from '../app';

export default function completeJobFileUpload(payload: CompleteJobFileUploadCommand['payload']): AnyCommand {
  return {
    type: COMPLETE_JOB_FILE_UPLOAD_COMMAND,
    payload,
  }
}

export const useCompleteJobFileUpload = App.wrapCommand(completeJobFileUpload);