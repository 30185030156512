import {CommandContainer, CommandStatus} from '../../../framework/type';
import {useEffect} from 'preact/hooks';

export default function callOnCommandStatus(
  status: CommandStatus,
  command: CommandContainer<any>,
  callback: () => any,
) {
  useEffect(() => {
    if (!command || !status || !callback) return;
    if (command.status === status) callback();
  }, [status, command]);
}