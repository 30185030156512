import {Fragment, h} from 'preact';
import {EditorColumn, EditorPage} from '../editor/type';
import TableGroup from '../editor/TableGroup';
import ButtonGroup from '../form/ButtonGroup';
import {Team, TeamUser} from '../../model/Team';
import InviteUserButton from './InviteUserButton';
import getInviteCode from '../../helper/getInviteCode';

interface Props {
  team: Team;
}

export default function MemberList({team}: Props) {
  if (!team) return null;
  const teamUsers: TeamUser[] = team.users ? team.users.all : [];

  const columns: Array<EditorColumn<TeamUser>> = [
    {
      key: 'name',
      label: 'Name',
      width: 120,
      renderCell: (item) => {
        return item.name
      }
    },
    {
      key: 'email',
      label: 'Email',
      width: 240,
      renderCell: (item) => {
        return item.email;
      }
    },
    {
      key: 'id',
      label: 'Invite link',
      width: 240,
      numeric: true,
      renderCell: (item) => {
        const link = getInviteCode(team, item);
        return (
          <a href={link} target="_blank">{link}</a>
        );
      },
      stringValue: (item) => {
        return getInviteCode(team, item);
      }
    },
    {
      key: 'currentStatus',
      label: 'Status',
      width: 120,
      numeric: true,
      renderCell: (item) => {
        return [
          team.isOwner(item.id) && 'Owner',
          item.currentStatus,
        ].filter(Boolean).join(', ');
      }
    },
  ];

  const pages: Array<EditorPage<TeamUser>> = [
    {
      name: '',
      items: teamUsers
    }
  ];

  return (
    <Fragment>
      <TableGroup
        id="team_users"
        columns={columns}
        pages={pages}
        preventSelect={true}
        singlePage={pages[0]}
        expandToFill={true}
        children={(items) => {
          return (
            <ButtonGroup>
              <InviteUserButton />
            </ButtonGroup>
          )
        }}
      />
    </Fragment>
  )
}