import {h} from 'preact';
import TableGroup from '../editor/TableGroup';
import {ActionRenderer, EditorColumn, EditorColumnType, EditorPage} from '../editor/type';
import {Valuation} from '../../model/Valuation';
import renderCurrency from '../../helper/renderCurrency';
import ActiveIndicator from '../media/ActiveIndicator';
import binaryChoice from '../../helper/binaryChoice';
import PassiveIndicator from '../media/PassiveIndicator';
import PercentageIndicator from '../media/PercentageIndicator';
import {ValuationInvoice} from '../../model/ValuationInvoice';
import ValuationInvoiceItem from '../../model/ValuationInvoiceItem';
import leftPad from '../../helper/leftPad';

interface Props {
  valuation: Valuation;
  invoices: ValuationInvoice[];
  children?: ActionRenderer<ValuationInvoiceItem>;
  expandToFill?: boolean;
}

export default function ValuationInvoicesTable({children, valuation, invoices, expandToFill}: Props) {
  const renderHeader = (page: number, _: ValuationInvoiceItem[], key: keyof ValuationInvoiceItem) => {
    if (!valuation) return '';
    const invoice = invoices[page];
    if (!invoice) return '';

    switch (key) {
      case 'charge':
        return renderCurrency(invoice.charge);
      case 'invoiced':
        return renderCurrency(invoice.invoiced);
      case 'billable':
        return renderCurrency(invoice.billable);

      default:
        return '';
    }
  };

  const editable = () => false;

  const columns: Array<EditorColumn<ValuationInvoiceItem>> = [
    {
      key: 'order',
      label: '#',
      width: 32,
      numeric: true,
      editable: () => false,
      renderCell: (item) => {
        return item && item.order;
      }
    },
    {
      key: 'sectionName',
      label: 'Section',
      width: 48,
      editable,
      renderHeader,
    },
    {
      key: 'item',
      label: 'Item',
      width: 48,
      editable,
      renderHeader,
    },
    {
      key: 'description',
      label: 'Description',
      width: 320,
      editable,
      renderHeader,
    },
    {
      key: 'charge',
      label: 'Charge',
      width: 80,
      numeric: true,
      type: EditorColumnType.CURRENCY,
      renderCell: (item) => {
        return renderCurrency(item.charge);
      },
      editable,
      renderHeader,
    },

    {
      key: 'omitted',
      label: 'Omitted',
      width: 80,
      numeric: true,
      type: EditorColumnType.CURRENCY,
      renderCell: (item) => {
        if (!item.omitted)
          return '';

        return renderCurrency(item.charge);
      },
      editable,
      renderHeader,
      choices: binaryChoice
    },
    {
      key: 'complete',
      label: 'Complete',
      width: 80,
      numeric: true,
      type: EditorColumnType.PERCENTAGE,
      renderCell: (item) => {
        if (item.omitted)
          return <PassiveIndicator>N/A</PassiveIndicator>;

        return (
          <PercentageIndicator percent={item.complete} difference={item.completeDifference}/>
        );
      },
      editable,
      renderHeader,
    },
    {
      key: 'newPayment',
      label: 'Paid',
      width: 80,
      numeric: true,
      type: EditorColumnType.CURRENCY,
      renderCell: (item) => {
        if (item.omitted)
          return <ActiveIndicator>OMIT</ActiveIndicator>;

        if (!item.newPayment)
          return '';

        return renderCurrency(item.newPayment);
      },
      editable,
      renderHeader,
    },
  ];

  let filteredColumns = columns;

  if (!valuation || !valuation.details.showOrder) {
    filteredColumns = filteredColumns.filter(c => c.key !== 'order')
  }

  const pages: Array<EditorPage<ValuationInvoiceItem>> = invoices.map((invoice, index) => {
    const isPreview = !invoice.ref;

    return {
      name: isPreview ? 'Next invoice' : invoice.ref,
      subtitle: isPreview ? '' : `#${leftPad((index + 1).toString(), 4)}`,
      items: invoice.items.all
    }
  });

  return (
    <TableGroup
      id="valuation_invoices_table"
      columns={filteredColumns}
      pages={pages}
      allowCreate={false}
      children={children}
      allowFullScreen={true}
      allowShrinkRows={true}
      expandToFill={expandToFill}
      stickOnScroll={true}
    />
  )
}