import {AnyCommand, FAIL_JOB_FILE_UPLOAD_COMMAND, FailJobFileUploadCommand} from '../type';
import App from '../app';

export default function failJobFileUpload(payload: FailJobFileUploadCommand['payload']): AnyCommand {
  return {
    type: FAIL_JOB_FILE_UPLOAD_COMMAND,
    payload,
  }
}

export const useFailJobFileUpload = App.wrapCommand(failJobFileUpload);