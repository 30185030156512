import {Fragment, h} from 'preact';
import Button from '../form/Button';
import Dialog from '../media/Dialog';
import {useCallback, useEffect, useState} from 'preact/hooks';
import {Job} from '../../model/Job';
import JobSection from '../../model/JobSection';
import JobSectionItem from '../../model/JobSectionItem';
import DeleteSectionItemsForm from './DeleteSectionItemsForm';
import {IconType} from '../media/Icon';
import {useUpdateJobSectionCommand} from '../../command/updateJobSection';
import {useReorderJobSectionCommand} from '../../command/reorderJobSection';
import currentSession from '../../query/currentSession';
import currentTeam from '../../query/currentTeam';
import callOnCommandStatus from '../../effect/callOnCommandStatus';
import {CommandStatus} from '../../../../framework/type';
import {useReorderJobSectionItemCommand} from '../../command/reorderJobSectionItem';
import AllocateItemsForm from './AllocateItemsForm';

interface Props {
  job: Job;
  section: JobSection;
  items: JobSectionItem[];
  onSuccess?(): any;
}

export default function AllocateItemsButton({job, section, items, onSuccess}: Props) {
  const {length} = items;
  if (length === 0)
    return null;

  const [showDialog, toggleDialog] = useState(false);

  const dialog = {
    show: toggleDialog.bind(null, true),
    hide: toggleDialog.bind(null, false)
  };

  const click = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    if (!showDialog) toggleDialog(true);
  }, [toggleDialog, showDialog, job, section, items]);


  return (
    <Fragment>
      <Button
        small={true}
        onClick={click}
        onlyIcon={IconType.ALLOCATE}
        tooltip={`Allocate${length > 1 ? ` ${length}`:''} Item${length === 1 ? '':'s'}`}
      />
      <Dialog show={showDialog} onRequestClose={dialog.hide}>
        <AllocateItemsForm
          job={job}
          section={section}
          items={items}
          onCancel={dialog.hide}
          onSuccess={() => {
            if (onSuccess) onSuccess();
            dialog.hide();
          }}
        />
      </Dialog>
    </Fragment>
  )
}