import {AnyCommand, UPDATE_JOB_COMPANY_COMMAND, UpdateJobCompanyCommand} from '../type';
import App from '../app';

export default function updateJobCompany(payload: UpdateJobCompanyCommand['payload']): AnyCommand {
  return {
    type: UPDATE_JOB_COMPANY_COMMAND,
    payload,
  }
}

export const useUpdateJobCompanyCommand = App.wrapCommand(updateJobCompany);