import {AnyCommand, LOAD_JOBS_COMMAND, LoadJobsCommand} from '../type';
import App from '../app';

export default function loadJobs(payload: LoadJobsCommand['payload']): AnyCommand {
  return {
    type: LOAD_JOBS_COMMAND,
    payload,
  }
}

export const useLoadJobsCommand = App.wrapCommand(loadJobs);