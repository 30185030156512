import {AnyCommand, CREATE_JOB_COMMAND, CreateJobCommand} from '../type';
import App from '../app';

export default function createJob(payload: CreateJobCommand['payload']): AnyCommand {
  return {
    type: CREATE_JOB_COMMAND,
    payload,
  }
}

export const useCreateJobCommand = App.wrapCommand(createJob);