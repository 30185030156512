import {AnyCommand, JOIN_TEAM_COMMAND, JoinTeamCommand} from '../type';
import App from '../app';

export default function joinTeam(payload: JoinTeamCommand['payload']): AnyCommand {
  return {
    type: JOIN_TEAM_COMMAND,
    payload,
  }
}

export const useJoinTeam = App.wrapCommand(joinTeam);