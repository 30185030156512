import {h} from 'preact';
import {HTMLAttributes} from '../../../../framework/type';
import App from '../../app';
import {percent, rem} from 'csx';

interface Props extends HTMLAttributes {
  error?: string
}

export default function FieldDescription({error, children}: Props) {
  const className = App.useStyle(({theme}) => ({
    userSelect: 'none',
    maxWidth: percent(100),
    fontSize: rem(0.875),
    lineHeight: rem(1),
    padding: rem(0.125),
    margin: `${rem(0.25)} 0`,
    color: error
      ? theme.color.red.toString()
      : theme.color.gray.toString(),
    $nest: {
      'input:focus ~ &': {
        color: error
          ? theme.color.red.toString()
          : theme.color.black.toString(),
      }
    }
  }), [error]);

  return (
    <div className={className}>
      {error || children}
    </div>
  )
}