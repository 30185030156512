import {h} from 'preact';
import App from '../../app';
import classes from '../../../../framework/classes';
import {rem} from 'csx';

export enum IconType {
  EXPAND_ROWS,
  FIT_COLUMNS,
  FULL_SCREEN,
  SHRINK_ROWS,
  WINDOW_SCREEN,

  CREATE_ITEM,
  DELETE_ITEM,
  UPDATE_ITEM,
  
  MOVE_DOWN,
  MOVE_UP,

  ALLOCATE,
}

interface Props {
  className?: string;
  type: IconType
}

export default function Icon({type, ...props}: Props) {
  const className = App.useStyle(({theme}) => ({
    width: rem(2),
    height: rem(2),
    $nest: {
      '& g': {
        fill: 'none',
        stroke: theme.color.black.toString(),
        strokeWidth: 16,
      },
      '& path': {
        transition: `d ${theme.transition.icon}`
      }
    }
  }));

  return (
    <svg className={classes(className, props.className)} viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
      {renderIcon(type)}
    </svg>
  )
}

function renderIcon(type: IconType) {
  switch (type) {
    case IconType.EXPAND_ROWS:
      return (
        <g>
          <path d="M128 120V72"/>
          <path d="M128 184V136"/>
          <path d="M56 208H200"/>
          <path d="M56 48H200"/>
          <path d="M88 104L128 72L168 104"/>
          <path d="M168 152L128 184L88 152"/>
        </g>
      );
      
    case IconType.FIT_COLUMNS:
      return (
        <g>
          <path d="M80 128L128 128"/>
          <path d="M128 128L176 128"/>
          <path d="M192 200V56"/>
          <path d="M64 200L64 56"/>
          <path d="M112 88L80.0005 128L112 168"/>
          <path d="M144 168L176 128L144 88"/>
        </g>
      );

    case IconType.FULL_SCREEN:
      return (
        <g>
          <path d="M104 88L64 48"/>
          <path d="M64 104V48H120"/>
          <path d="M152 168L192 208"/>
          <path d="M192 152L192 208L136 208"/>
          <path d="M88 112H160V136"/>
          <path d="M168 144L96 144L96 120"/>
        </g>
      );

    case IconType.SHRINK_ROWS:
      return (
        <g>
          <path d="M128 88V40"/>
          <path d="M128 216V168"/>
          <path d="M56 144H200"/>
          <path d="M56 112H200"/>
          <path d="M88 56L128 88L168 56"/>
          <path d="M168 200L128 168L88 200"/>
        </g>
      );

    case IconType.WINDOW_SCREEN:
      return (
        <g>
          <path d="M104 88L64 48"/>
          <path d="M112 40V96H56"/>
          <path d="M152 168L192 208"/>
          <path d="M144 216L144 160L200 160"/>
          <path d="M128 48H192V144"/>
          <path d="M128 208L64 208L64 112"/>
        </g>
      )

    case IconType.CREATE_ITEM:
      return (
        <g>
          <path d="M120 72L56 72"/>
          <path d="M88 40V72V104"/>
          <path d="M192 144L192 216"/>
          <path d="M184.5 208L160 208L128 208"/>
          <path d="M128 48H192V144"/>
          <path d="M128 208L64 208L64 112"/>
        </g>
      )

    case IconType.DELETE_ITEM:
      return (
        <g>
          <path d="M110.627 49.3726L65.3726 94.6274"/>
          <path d="M65.3726 49.3726L88 72L110.627 94.6274"/>
          <path d="M192 144L192 216"/>
          <path d="M184.5 208L160 208L128 208"/>
          <path d="M128 48H192V144"/>
          <path d="M128 208L64 208L64 112"/>
        </g>
      )

    case IconType.UPDATE_ITEM:
      return (
        <g>
          <path d="M98 60L104 48"/>
          <path d="M120 96H80L96 64"/>
          <path d="M192 144L192 216"/>
          <path d="M184.5 208L160 208L128 208"/>
          <path d="M128 48H192V144"/>
          <path d="M128 208L64 208L64 112"/>
        </g>
      )

    case IconType.MOVE_DOWN:
      return (
        <g>
          <path d="M184 48L64 48L64 88"/>
          <path d="M88 120L128 152L168 120"/>
          <path d="M128 96L128 152"/>
          <path d="M72 176L192 176L192 216"/>
          <path d="M192 40V80H72"/>
          <path d="M184 208L64 208L64 168"/>
        </g>
      )

    case IconType.MOVE_UP:
      return (
        <g>
          <path d="M184 48L64 48L64 88"/>
          <path d="M168 136L128 104L88 136"/>
          <path d="M128 104L128 160"/>
          <path d="M72 176L192 176L192 216"/>
          <path d="M192 40V80H72"/>
          <path d="M184 208L64 208L64 168"/>
        </g>
      )

    case IconType.ALLOCATE:
      return (
        <g>
          <path d="M120 72L56 72"/>
          <path d="M88 40V72V104"/>
          <path d="M168 160L133.31 160L88 160"/>
          <path d="M192 213L128 88L64 213"/>
        </g>
      )
  } 
}