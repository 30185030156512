import {h} from 'preact';
import {UserTeam} from '../../model/User';
import TeamUserBadge from './UserTeamBadge';
import FieldGroup from '../form/FieldGroup';
import Button from '../form/Button';
import App from '../../app';
import {percent, rem} from 'csx';
import {useSelectTeamCommand} from '../../command/selectTeam';

interface Props {
  teams: UserTeam[]
}

export default function TeamList(props: Props) {
  const [,selectTeam] = useSelectTeamCommand();

  const buttonClassName = App.useStyle(() => ({
    width: percent(100),
    maxWidth: rem(20),
  }));

  return (
    <FieldGroup>
      {props.teams.map((team: UserTeam) => (
        <Button unstyled={true} className={buttonClassName} onClick={() => selectTeam(team.id)}>
          <TeamUserBadge team={team} fill={true}/>
        </Button>
      ))}
    </FieldGroup>
  )
}