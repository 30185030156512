import {h, Ref} from 'preact';
import Select from './Select';
import currentTeamUsers from '../../query/currentTeamUsers';
import {HTMLAttributes} from '../../../../framework/type';

interface Props extends HTMLAttributes {
  allowEmpty?: boolean;
  unstyled?: boolean;
  innerRef?: Ref<HTMLSelectElement>;
}

export default function TeamUserSelect({children, allowEmpty, ...props}: Props) {
  const teamUsers = currentTeamUsers();

  return (
    <Select {...props}>
      {allowEmpty && (
        <option value="">UNASSIGNED</option>
      )}
      {teamUsers.map(user => (
        <option value={user.id}>{user.name}</option>
      ))}
    </Select>
  )
}