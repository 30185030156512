import {h} from 'preact';
import {UserTeam} from '../../model/User';
import App from '../../app';
import {percent, rem} from 'csx';
import UserTeamAvatar from './UserTeamAvatar';
import UserTeamName from './UserTeamName';

interface Props {
  team: UserTeam,
  fill?: boolean;
}

export default function UserTeamBadge({team, fill}: Props) {
  const className = App.useStyle(({theme}) => ({
    display: 'inline-flex',
    flexDirection: 'row',
    userSelect: 'none',
    width: fill
      ? percent(100)
      : 'auto',
    maxWidth: rem(20),
    borderRadius: rem(0.125),
    border: `0.125rem solid ${theme.color.black.toString()}`,
    margin: 0,
    padding: `0.5rem`,
    $nest: {
      '& > *:first-child': {
        marginRight: rem(0.5)
      }
    }
  }), [fill]);

  return (
    <div className={className}>
      <UserTeamAvatar team={team}/>
      <UserTeamName team={team}/>
    </div>
  )
}