import {Entity} from '../../../framework/value/Entity';
import { CompanyId, ItemId, JobId, PageId, SectionId, UserId, VariationId } from './App';
import StringValue from '../../../framework/value/StringValue';
import EntityMapValue from '../../../framework/value/EntityMapValue';
import MapValue from '../../../framework/value/MapValue';
import {JobFile, JobFiles, JobFilesJSON} from './JobFile';
import {JobImports, JobImportsJSON} from './JobImport';
import JobSection, {
  Items,
  JobSectionDetails,
  JobSectionDetailsJSON,
  JobSectionJSON,
  Name as SectionName
} from './JobSection';
import {JobAssets, JobAssetsJSON} from './JobAssets';
import BooleanValue from '../../../framework/value/BooleanValue';
import NumberValue from '../../../framework/value/NumberValue';
import UTCDateValue from '../../../framework/value/UTCDateValue';
import JobSectionItem, {
  Details as JobSectionItemDetails,
  JobSectionItemCompanyJSON,
  JobSectionItemDetailsJSON,
  JobSectionItemStatusJSON,
  JobSectionItemType,
  Order
} from './JobSectionItem';
import {ValidationError} from '../../../framework/error';
import {EditorChoice} from '../component/editor/type';
import {JobCompany, JobCompanyDetails, JobCompanyDetailsJSON, JobCompanyJSON} from './JobCompany';
import {JobCompanyItem} from './JobCompanyItem';
import {Email} from '../../../framework/value/Email';
import ArrayValue from '../../../framework/value/ArrayValue';
import JobVariations, { JobVariationsJSON } from './JobVariations';
import JobVariationItem, {
  JobVariationItemCompanyJSON,
  JobVariationItemDetails,
  JobVariationItemDetailsJSON
} from './JobVariationItem';
import { Valuation } from './Valuation';

export enum JobStatus {
  SPECIFY = 'specify',
  COSTING = 'costing',
  QUOTED = 'quoted',
  PLANNED = 'planned',
  RUNNING = 'running',
  COMPLETE = 'complete',
}

export enum JobFlagTypes {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
  ENQUIRIES_SENT = 'enquiries_sent',
  SITE_VISIT_ARRANGED = 'site_visit_arranged',
  COSTING = 'costing',
}

export enum JobTab {
  SUMMARY = 'summary',
  DETAILS = 'details',
  SCHEDULE = 'schedule',
  COMPANIES = 'companies',
  COSTINGS = 'costings',
  VALUATION = 'valuation',
  VARIATIONS = 'variations',
  IMPORTS = 'imports',
  ACTIVITY = 'activity'
}

export const jobPath = (id: string, part: string = '') =>
  `/job/${id}${part === JobTab.SUMMARY ? '' : `/${part}`}`;

export interface JobJSON {
  id: string;
  ownerId: string;
  assets: Partial<JobAssetsJSON>;
  details: Partial<JobDetailsJSON>;
  files: Partial<JobFilesJSON>;
  sections: Partial<JobSectionsJSON>;
  imports: Partial<JobImportsJSON>;
  companies: Partial<JobCompaniesJSON>;
  variations: Partial<JobVariationsJSON>;
}

export class Job extends Entity<JobId, {
  ownerId: Owner;
  details: JobDetails;
  files: JobFiles;
  sections: JobSections;
  imports: JobImports;
  assets: JobAssets;
  companies: JobCompanies;
  variations: JobVariations;
}> {
  get ref() {
    return this.details.value.ref.value;
  }

  get flags() {
    return this.details.value.flags;
  }

  get receivedAt() {
    return this.details.value.receivedAt;
  }

  get returnBy() {
    return this.details.value.returnBy;
  }

  get returnedAt() {
    return this.details.value.returnedAt;
  }

  get startDate() {
    return this.details.value.startDate;
  }

  get duration() {
    return this.details.value.duration.value
  }

  get internal() {
    return this.flags.has(JobFlagTypes.INTERNAL);
  }

  get external() {
    return this.flags.has(JobFlagTypes.EXTERNAL);
  }

  get enquiriesSent() {
    return this.flags.has(JobFlagTypes.ENQUIRIES_SENT);
  }

  get siteVisitArranged() {
    return this.flags.has(JobFlagTypes.SITE_VISIT_ARRANGED);
  }

  get costing() {
    return this.flags.has(JobFlagTypes.COSTING);
  }

  get details() {
    return this.value.details;
  }

  get assignee() {
    return this.details.assignee;
  }

  get cost() {
    return this.sections.all.reduce((cost, item) => {
      return cost + item.cost;
    }, 0);
  }

  get profit() {
    return this.sections.all.reduce((profit, item) => {
      return profit + item.profit;
    }, 0);
  }

  get charge() {
    return this.sections.all.reduce((charge, item) => {
      return charge + item.charge;
    }, 0);
  }

  get markup() {
    if (this.cost === 0) return 0;

    return Math.round((this.charge / this.cost - 1) * 10000) / 100;
  }

  get valuation(): Valuation {
    return Valuation.fromJob(this);
  }

  get status(): JobStatus {
    switch (true) {
      case (this.lost || this.completed):
        return JobStatus.COMPLETE;

      case (!this.specified):
        return JobStatus.SPECIFY;

      case (!this.costed):
        return JobStatus.COSTING;

      case (!this.won):
        return JobStatus.QUOTED;

      case (!this.ended):
        return JobStatus.RUNNING;

      default:
        return JobStatus.COMPLETE;
    }
  }

  get name() {
    return this.value.details.value.name.value || this.id;
  }

  get client() {
    return this.value.details.value.client.value;
  }

  get contact() {
    return this.value.details.value.contactName.value;
  }

  get files() {
    return this.value.files;
  }

  get sections() {
    return this.value.sections;
  }

  get variations() {
    return this.value.variations;
  }

  get imports() {
    return this.value.imports;
  }

  get assets() {
    return this.value.assets;
  }

  get companies() {
    return this.value.companies;
  }

  get specified() {
    return true;
  }

  get costed() {
    // return !!this.assets.costings && !!this.assets.quote;
    return false;
  }

  get won() {
    return this.value.details.value.won.value;
  }

  get lost() {
    return this.value.details.value.lost.value;
  }

  get completed() {
    return this.value.details.value.completed.value;
  }

  get pastStartDate() {
    return this.value.details.value.startedAt.isOnOrAfter(UTCDateValue.create())
  }

  get pastEndDate() {
    return this.value.details.value.endedAt.isOnOrAfter(UTCDateValue.create())
  }

  get ended() {
    return (this.lost || this.completed) && this.pastEndDate;
  }

  get maximumSectionOrder() {
    return this.sections.all.reduce((max, section) => {
      return Math.max(max, section.order);
    }, 0)
  }

  get hasItems() {
    return !!this.sections.all.find(s => s.items.all.length)
  }

  get hasSections() {
    return !!this.sections.all.length;
  }

  get retention() {
    return this.details.value.retention;
  }

  get invoiced() {
    return this.details.value.invoiced;
  }

  get roundCharge() {
    return this.details.roundCharge;
  }

  get availableItemTypes(): EditorChoice[] {
    return this.specified
      ? JobSectionItem.TypeChoices.filter(k => k.id !== JobSectionItemType.FIXED)
      : JobSectionItem.TypeChoices;
  }

  static create(props: {jobId: string, ownerId: string, details: Partial<JobDetailsJSON>}) {
    return new Job({
      id: new JobId(props.jobId),
      ownerId: new Owner(props.ownerId),
      details: JobDetails.withDefaults(props.details),
      sections: new JobSections({}),
      files: new JobFiles({}),
      imports: new JobImports({}),
      assets: JobAssets.create(),
      companies: JobCompanies.fromJSON({}),
      variations: JobVariations.fromJSON({}),
    })
  }

  addFile(file: JobFile) {
    return this.extend({
      files: this.files.extend({
        [file.id]: file
      })
    })
  }

  createSection(sectionId: SectionId, sectionName: SectionName): Job {
    if (this.sections.get(sectionId.id)) {
      throw new Error('Section already exists');
    }

    const section = JobSection.fromJSON({
      id: sectionId.id,
      order: this.maximumSectionOrder + 1,
      details: {
        name: sectionName.value,
      }
    });

    return this.extend({
      sections: this.sections.extend({
        [section.id]: section
      })
    });
  };

  createSectionItem(sectionId: SectionId, itemId: ItemId, details: Partial<JobSectionItemDetailsJSON>) {
    const section = this.sections.get(sectionId.id);
    if (!section) return this;

    if (section.items.get(itemId.id)) {
      throw new Error('Item already exists');
    }

    const item = JobSectionItem
      .fromJSON({id: itemId.id})
      .extend({
        order: new Order(section.maximumItemOrder + 1),
        details: JobSectionItemDetails.fromJSON({
          ...details,
          roundCharge: this.roundCharge
        })
      });

    return this.extend({
      sections: this.sections.extend({
        [section.id]: section.extend({
          items: section.items.extend({
            [item.id]: item
          })
        })
      })
    });
  }

  deleteJobSection(sectionId: SectionId) {
    const section = this.sections.get(sectionId.id);
    if (!section) return this;

    const importedItems = section.items.all
      .filter(item => item.imported);

    if (importedItems.length > 0) {
      throw new ValidationError('JobSection', 'Cannot delete imported section');
    }

    return this.extend({
      sections: this.sections.remove(new PageId(sectionId.id))
    })
  }

  deleteJobSectionItem(sectionId: SectionId, itemId: ItemId) {
    const section = this.sections.get(sectionId.id);
    if (!section) return this;

    const item = section.items.get(itemId.id);
    if (!item) return this;

    if (item.imported) {
      throw new ValidationError('JobSectionItem', 'Cannot delete imported item');
    }

    const nextItems = section.items.remove(itemId);

    return this.extend({
      sections: this.sections.extend({
        [section.id]: section.extend({
          items: nextItems
        })
      })
    })
  }

  reorderJobSection(sectionId: SectionId, order: number) {
    const section = this.sections.get(sectionId.id);
    if (!section) return this;

    const reorderedSections = this.sections.all.reduce((nextSections, section) => {
      const isTarget = section.id === sectionId.id;

      if (!isTarget && section.order < order)
        return nextSections;

      return nextSections.extend({
        [section.id]: section.extend({
          order: new Order(isTarget ? order : section.order + 1)
        })
      })
    }, this.sections);

    const nextSections = reorderedSections.all.reduce((nextSections, section, index) => {
      return nextSections.extend({
        [section.id]: section.extend({order: new Order(index + 1)})
      })
    }, this.sections);

    return this.extend({
      sections: nextSections
    })
  }

  reorderJobSectionItem(sectionId: SectionId, itemId: ItemId, order: number) {
    const section = this.sections.get(sectionId.id);
    if (!section) return this;

    const item = section.items.get(itemId.id);
    if (!item) return this;

    const reorderedItems = section.items.all.reduce((nextItems, item) => {
      const isTarget = item.id === itemId.id;

      if (!isTarget && item.order < order)
        return nextItems;

      return nextItems.extend({
        [item.id]: item.extend({
          order: new Order(isTarget ? order : item.order + 1)
        })
      })
    }, section.items);

    const nextItems = reorderedItems.all.reduce((nextItems, item, index) => {
      return nextItems.extend({
        [item.id]: item.extend({order: new Order(index + 1)})
      })
    }, section.items);

    return this.extend({
      sections: this.sections.extend({
        [section.id]: section.extend({
          items: nextItems
        })
      })
    })
  }

  updateSectionItemDetails(sectionId: SectionId, itemId: ItemId, details: Partial<JobSectionItemDetailsJSON>): Job {
    let response = this;

    const section = this.sections.get(sectionId.id);
    if (!section) return response;

    const item = section.items.get(itemId.id);
    if (!item) return response;

    let nextItem = item;

    if (typeof details.type !== 'undefined') {
      if (this.specified && details.type === JobSectionItemType.FIXED) {
        throw new Error('Can only set fixed cost at specification');
      }

      nextItem = nextItem.setType(details.type);
    }

    if (typeof details.item !== 'undefined') {
      if (this.specified && nextItem.fixedCost) {
        throw new ValidationError('Item', 'This value was fixed at specification');
      }

      nextItem = nextItem.setItem(details.item)
    }

    if (typeof details.description !== 'undefined') {
      if (this.specified && nextItem.fixedCost) {
        throw new ValidationError('Description', 'This value was fixed at specification');
      }

      nextItem = nextItem.setDescription(details.description)
    }

    if (typeof details.quantity !== 'undefined') {
      if (this.specified && nextItem.fixedCost) {
        throw new ValidationError('Quantity', 'This value was fixed at specification');
      }

      nextItem = nextItem.setQuantity(details.quantity);
    }

    if (typeof details.rate !== 'undefined') {
      if (this.specified && nextItem.fixedCost) {
        throw new ValidationError('Rate', 'This value was fixed at specification');
      }

      nextItem = nextItem.setRate(details.rate);
    }

    if (typeof details.markup !== 'undefined') {
      if (this.specified && nextItem.fixedCost) {
        throw new ValidationError('Markup', 'This value was fixed at specification');
      }

      nextItem = nextItem.setMarkup(details.markup);
    }

    if (typeof details.complete !== 'undefined') {
      nextItem = nextItem.setComplete(details.complete);
    }

    if (typeof details.omit !== 'undefined') {
      nextItem = nextItem.setOmit(details.omit);
    }

    return response.extend({
      sections: this.sections.extend({
        [section.id]: section.extend({
          items: section.items.extend({
            [item.id]: nextItem
          })
        })
      })
    });
  }

  updateJobSectionItemStatus(sectionId: SectionId, itemId: ItemId, status: Partial<JobSectionItemStatusJSON>) {
    const section = this.sections.get(sectionId.id);
    if (!section) return this;

    const item = section.items.get(itemId.id);
    if (!item) return this;

    let response = this;

    if (typeof status.imported !== 'undefined') {
      if (this.specified) {
        throw new Error('Cannot unset imported status');
      }

      response = response.extend({
        sections: this.sections.extend({
          [section.id]: section.extend({
            items: section.items.extend({
              [item.id]: item.setImported(status.imported)
            })
          })
        })
      });
    }

    return response;
  }

  createVariationItem(itemId: ItemId, details: Partial<JobVariationItemDetailsJSON>) {
    if (this.variations.get(itemId.id)) {
      throw new Error('Item already exists');
    }

    const variation = JobVariationItem
      .fromJSON({id: itemId.id})
      .extend({
        order: new Order(this.variations.maximumVariationOrder + 1),
        details: JobVariationItemDetails.fromJSON(details)
      });

    return this.extend({
      variations: this.variations.extend({
        [variation.id]: variation,
      })
    });
  }

  updateVariationItemDetails(variationId: VariationId, details: Partial<JobVariationItemDetailsJSON>): Job {
    let response = this;

    const variation = this.variations.get(variationId.id);
    if (!variation) return response;

    let nextVariation = variation;

    if (typeof details.instructedAt !== 'undefined') {
      nextVariation = nextVariation.setInstructedAt(details.instructedAt);
    }

    if (typeof details.ci !== 'undefined') {
      nextVariation = nextVariation.setCI(details.ci);
    }

    if (typeof details.description !== 'undefined') {
      nextVariation = nextVariation.setDescription(details.description)
    }

    if (typeof details.rate !== 'undefined') {
      nextVariation = nextVariation.setRate(details.rate);
    }

    if (typeof details.markup !== 'undefined') {
      nextVariation = nextVariation.setMarkup(details.markup);
    }

    if (typeof details.po !== 'undefined') {
      nextVariation = nextVariation.setPO(details.po);
    }

    if (typeof details.sentAt !== 'undefined') {
      nextVariation = nextVariation.setSentAt(details.sentAt);
    }

    if (typeof details.proceedAt !== 'undefined') {
      nextVariation = nextVariation.setProceedAt(details.proceedAt);
    }

    if (typeof details.commencedAt !== 'undefined') {
      nextVariation = nextVariation.setCommencedAt(details.commencedAt);
    }

    if (typeof details.completedAt !== 'undefined') {
      nextVariation = nextVariation.setCompletedAt(details.completedAt);
    }

    if (typeof details.complete !== 'undefined') {
      nextVariation = nextVariation.setComplete(details.complete);
    }

    if (typeof details.omit !== 'undefined') {
      nextVariation = nextVariation.setOmit(details.omit);
    }

    console.log(nextVariation.toJSON());

    return response.extend({
      variations: this.variations.extend({
        [variationId.id]: nextVariation
      })
    });
  }

  reorderJobVariationItem(variationId: VariationId, order: number) {
    const variation = this.variations.get(variationId.id);
    if (!variation) return this;

    const reorderedItems = this.variations.all.reduce((nextItems, item) => {
      const isTarget = item.id === variationId.id;

      if (!isTarget && item.order < order)
        return nextItems;

      return nextItems.extend({
        [item.id]: item.extend({
          order: new Order(isTarget ? order : item.order + 1)
        })
      })
    }, this.variations);

    const nextItems = reorderedItems.all.reduce((nextItems, item, index) => {
      return nextItems.extend({
        [item.id]: item.extend({order: new Order(index + 1)})
      })
    }, this.variations);

    return this.extend({
      variations: nextItems
    })
  }

  deleteJobVariationItem(variationId: VariationId) {
    const item = this.variations.get(variationId.id);
    if (!item) return this;

    const nextItems = this.variations.remove(variationId);

    return this.extend({
      variations: nextItems
    })
  }

  unpricedItems(): JobCompanyItem[] {
    return this.sections.all.reduce((items, section) => {
      return [
        ...items,
        ...section.items.uncosted.reduce((items, item) => {
          return [
            ...items,
            new JobCompanyItem({
              section,
              item,
            })
          ]
        }, [] as JobCompanyItem[])
      ]
    }, [] as JobCompanyItem[])
  }

  companyItems(companyId?: CompanyId): JobCompanyItem[] {
    const isAll = !companyId;
    const isNull = companyId && companyId.id === '';
    const company = companyId ? this.companies.get(companyId.id) : null;

    if (!isAll && !isNull && !company) return [];

    return this.sections.all.reduce((items, section) => {
      const sectionItems = isAll && !companyId
        ? section.items.all
        : section.items.all.filter(item => item.company.id === companyId!.id);

      return [
        ...items,
        ...sectionItems.reduce((items, item) => {
          return [
            ...items,
            new JobCompanyItem({
              section,
              item,
            })
          ]
        }, [] as JobCompanyItem[])
      ]
    }, [] as JobCompanyItem[])
  }

  createCompany(companyId: CompanyId, details: Partial<JobCompanyDetailsJSON>) {
    return this.extend({
      companies: this.companies.extend({
        [companyId.id]: JobCompany.fromJSON({
          id: companyId.id,
          details,
        })
      })
    })
  }

  updateSectionDetails(sectionId: SectionId, details: Partial<JobSectionDetailsJSON>) {
    const section = this.sections.get(sectionId.id);
    if (!section) return this;

    return this.extend({
      sections: this.sections.extend({
        [section.id]: section.extend({
          details: JobSectionDetails.fromJSON({
            ...section.value.details.toJSON(),
            ...details,
          })
        })
      })
    })
  }

  updateDetails(details: Partial<JobDetailsJSON>) {
    const {roundCharge} = details;

    const sections = typeof roundCharge === 'undefined' ? this.sections : JobSections.fromJSON(this.sections.all.reduce((sections, section) => {
      return {
        ...sections,
        [section.id]: {
          ...section.toJSON(),
          items: Items.fromJSON(section.items.all.reduce((items, item) => {
            return {
              ...items,
              [item.id]: item.setRoundCharge(roundCharge).toJSON()
            }
          }, {})).toJSON()
        }
      }
    }, {}));

    return this.extend({
      details: JobDetails.fromJSON({
        ...this.details.toJSON(),
        ...details
      }),
      sections
    })
  }

  updateCompany(companyId: CompanyId, details: Partial<JobCompanyDetailsJSON>) {
    const company = this.companies.get(companyId.id);
    if (!company) return this;

    const matchingItemIds = this.companyItems(companyId).map(i => i.itemId);
    const nextCompany = company.extend({
      details: JobCompanyDetails.fromJSON({
        ...company.details.toJSON(),
        ...details,
      })
    });

    return this.extend({
      companies: this.companies.extend({
        [companyId.id]: nextCompany
      })
    }).allocateCompanyItems(companyId, matchingItemIds, {
      id: companyId.id,
      markup: nextCompany.markup,
      name: nextCompany.name,
    })
  }

  deleteCompanies(companyIds: string[]) {
    return companyIds.reduce((job, id) => {
      return job.deleteCompany(new CompanyId(id))
    }, this);
  }

  deleteCompany(companyId: CompanyId) {
    const company = this.companies.get(companyId.id);
    if (!company) return this;

    const nextCompanies = this.companies.remove(companyId);

    const matchingItemIds = this.companyItems(companyId).map(i => i.itemId);

    return this
      .allocateCompanyItems(companyId, matchingItemIds, null)
      .extend({companies: nextCompanies})
  }

  allocateCompanyItems(companyId: CompanyId, itemIds: string[], company: JobSectionItemCompanyJSON | null = null) {
    const isRemove = companyId.id === '';
    const exists = this.companies.get(companyId.id);

    if (!isRemove && !exists) throw new Error('Missing company to allocate');
    if (!isRemove && company && companyId.id !== company.id) throw new Error('Mismatched company id');

    const targetItems = this.companyItems().filter((item) => itemIds.includes(item.itemId));

    return targetItems.reduce((job, item) => {
      return job.allocateCompanyItem(companyId, new SectionId(item.sectionId), new ItemId(item.itemId), isRemove ? null : company)
    }, this)
  }

  allocateCompanyItem(companyId: CompanyId, sectionId: SectionId, itemId: ItemId, company: JobSectionItemCompanyJSON | null) {
    const section = this.sections.get(sectionId.id);
    if (!section) return this;

    const item = section.items.get(itemId.id);
    if (!item) return this;

    return this.extend({
      sections: this.sections.extend({
        [section.id]: section.extend({
          items: section.items.extend({
            [item.id]: item.setCompany(company || {})
          })
        })
      })
    });
  }

  allocateVariationItems(companyId: CompanyId, itemIds: string[], company: JobVariationItemCompanyJSON | null = null) {
    const isRemove = companyId.id === '';
    const exists = this.companies.get(companyId.id);

    if (!isRemove && !exists) throw new Error('Missing company to allocate');
    if (!isRemove && company && companyId.id !== company.id) throw new Error('Mismatched company id');

    const targetItems = this.variations.all.filter((item) => itemIds.includes(item.id));

    return targetItems.reduce((job, item) => {
      return job.allocateVariationItem(companyId, new VariationId(item.id), isRemove ? null : company)
    }, this)
  }

  allocateVariationItem(companyId: CompanyId, variationId: VariationId, company: JobVariationItemCompanyJSON | null) {
    const item = this.variations.get(variationId.id);
    if (!item) return this;

    return this.extend({
      variations: this.variations.extend({
        [variationId.id]: item.setCompany(company || {})
      })
    });
  }

  prevSection(sectionId: string): JobSection | null {
    const sections = this.sections.all;
    const index = sections.findIndex(s => s.id === sectionId);
    return index <= 0
      ? null
      : sections[index - 1];
  }

  nextSection(sectionId: string): JobSection | null {
    const sections = this.sections.all;
    const {length} = sections;
    const index = sections.findIndex(s => s.id === sectionId);
    return index >= length - 1 || index == -1
      ? null
      : sections[index + 1];
  }

  prevSectionItem(sectionId: string, itemId: string): JobSectionItem | null {
    const section = this.sections.get(sectionId);
    if (!section) return null;
    const items = section.items.all;

    const index = items.findIndex(s => s.id === itemId);
    return index <= 0
      ? null
      : items[index - 1];
  }

  nextSectionItem(sectionId: string, itemId: string): JobSectionItem | null {
    const section = this.sections.get(sectionId);
    if (!section) return null;
    const items = section.items.all;
    const {length} = items;
    const index = items.findIndex(s => s.id === itemId);
    return index >= length - 1 || index == -1
      ? null
      : items[index + 1];
  }

  prevVariationItem(variationId: string): JobVariationItem | null {
    const items = this.variations.all;

    const index = items.findIndex(s => s.id === variationId);
    return index <= 0
      ? null
      : items[index - 1];
  }

  nextVariationItem(variationId: string): JobVariationItem | null {
    const items = this.variations.all;
    const {length} = items;
    const index = items.findIndex(s => s.id === variationId);
    return index >= length - 1 || index == -1
      ? null
      : items[index + 1];
  }

  isFirstSection(sectionId: string) {
    const sections = this.sections.all;
    const {length} = sections;
    return !!length && this.sections.all[0].id === sectionId;
  }

  isLastSection(sectionId: string) {
    const sections = this.sections.all;
    const {length} = sections;
    return !!length && sections[length - 1].id === sectionId;
  }

  toJSON(): JobJSON {
    return super.toJSON() as JobJSON;
  }

  static fromJSON(data: Partial<JobJSON>) {
    return new Job({
      id: new JobId(data.id || ''),
      ownerId: new Owner(data.ownerId || ''),
      details: JobDetails.fromJSON(data.details || {}),
      sections: JobSections.fromJSON(data.sections || {}),
      files: JobFiles.fromJSON(data.files || {}),
      imports: JobImports.fromJSON(data.imports || {}),
      assets: JobAssets.fromJSON(data.assets || {}),
      companies: JobCompanies.fromJSON(data.companies || {}),
      variations: JobVariations.fromJSON(data.variations || {}),
    });
  }
}

export interface JobDetailsJSON {
  name: string;
  ref: string;
  flags: string[];
  assignee: string;
  client: string;
  contactName: string;
  contactEmail: string;
  contactTel: string;
  showQuantity: boolean;
  showOrder: boolean;
  won: boolean;
  lost: boolean;
  completed: boolean;
  startDate: string;
  duration: number;
  startedAt: string;
  receivedAt: string;
  returnBy: string;
  returnedAt: string;
  endedAt: string;
  deleted: boolean;
  archived: boolean;
  notes: string;
  retention: number;
  invoiced: number;
  roundCharge: number;
}

export class JobDetails extends MapValue<{
  name: JobName;
  ref: JobRef;
  flags: JobFlags;
  assignee: StringValue;
  client: ClientName;
  contactName: ContactName;
  contactEmail: ContactEmail;
  contactTel: ContactTel;
  showQuantity: ShowQuantity;
  showOrder: ShowOrder;
  won: BooleanValue;
  lost: BooleanValue;
  completed: BooleanValue;
  startDate: UTCDateValue;
  duration: Duration;
  receivedAt: ReceivedAt;
  returnBy: ReturnBy;
  returnedAt: UTCDateValue;
  startedAt: UTCDateValue;
  endedAt: UTCDateValue;
  deleted: BooleanValue;
  archived: BooleanValue;
  notes: StringValue;
  retention: Retention;
  invoiced: Invoiced;
  roundCharge: RoundCharge;
}, JobDetailsJSON> {
  get name() {
    return this.value.name.value;
  }
  get flags() {
    return this.value.flags.value;
  }
  get client() {
    return this.value.client.value;
  }
  get contactName() {
    return this.value.contactName.value;
  }
  get contactEmail() {
    return this.value.contactEmail.value;
  }
  get contactTel() {
    return this.value.contactTel.value;
  }
  get assignee() {
    return this.value.assignee.value;
  }
  get showQuantity() {
    return this.value.showQuantity.value;
  }
  get showOrder() {
    return this.value.showOrder.value;
  }
  get roundCharge() {
    return this.value.roundCharge.value;
  }
  get deleted() {
    return this.value.deleted.value;
  }
  
  static fromJSON(json: Partial<JobDetailsJSON>): JobDetails {
    return new JobDetails({
      name: new JobName(json.name || ''),
      ref: new JobRef(json.ref || ''),
      flags: JobFlags.fromJSON(json.flags || []),
      assignee: new StringValue(json.assignee || ''),
      client: new ClientName(json.client || ''),
      contactName: new ContactName(json.contactName || ''),
      contactEmail: new ContactEmail(json.contactEmail || ''),
      contactTel: new ContactTel(json.contactTel || ''),
      showQuantity: new ShowQuantity(typeof json.showQuantity !== 'undefined' ? json.showQuantity : false),
      showOrder: new ShowOrder(typeof json.showOrder !== 'undefined' ? json.showOrder : false),
      won: new BooleanValue(typeof json.won !== 'undefined' ? json.won : false),
      lost: new BooleanValue(typeof json.lost !== 'undefined' ? json.lost : false),
      completed: new BooleanValue(typeof json.completed !== 'undefined' ? json.completed : false),
      startDate: new UTCDateValue(json.startDate || ''),
      duration: new Duration(json.duration || 0),
      startedAt: new UTCDateValue(json.startedAt || ''),
      receivedAt: new ReceivedAt(json.receivedAt || ''),
      returnBy: new ReturnBy(json.returnBy || ''),
      returnedAt: new UTCDateValue(json.returnedAt || ''),
      endedAt: new UTCDateValue(json.endedAt || ''),
      deleted: new BooleanValue(typeof json.deleted !== 'undefined' ? json.deleted : false),
      archived: new BooleanValue(typeof json.archived !== 'undefined' ? json.archived : false),
      notes: new StringValue(json.notes || ''),
      retention: new Retention(json.retention || 0),
      invoiced: new Invoiced(json.invoiced || 0),
      roundCharge: new RoundCharge(json.roundCharge || 0),
    })
  }

  static withDefaults(json: Partial<JobDetailsJSON>): JobDetails {
    return JobDetails.fromJSON(json).extend({
      receivedAt: UTCDateValue.create(),
      startDate: UTCDateValue.create(),
      startedAt: UTCDateValue.create(),
      endedAt: UTCDateValue.create(),
      duration: new Duration(1),
    })
  }
}

export class Jobs extends EntityMapValue<JobId, Job> {}

export class Owner extends UserId {}
export class JobName extends StringValue {
  validate() {
    return this.collect([
      ...super.validate(),
      !this.value && this.error('Must not be empty'),
    ])
  }
}
export class JobRef extends StringValue {

}

export class ReceivedAt extends UTCDateValue {}

export class ReturnBy extends UTCDateValue {}

export class ClientName extends StringValue {
}

export class ContactName extends StringValue {
}

export class ContactEmail extends Email {
}

export class ContactTel extends StringValue {
}

export class Duration extends NumberValue {}

export class ShowQuantity extends BooleanValue {}
export class ShowOrder extends BooleanValue {}
export class Retention extends NumberValue {}
export class Invoiced extends NumberValue {}
export class RoundCharge extends NumberValue {}

export interface JobSectionsJSON {
  [id: string]: JobSectionJSON;
}

export class JobFlags extends ArrayValue<JobFlag> {
  static fromJSON(json: string[]) {
    return new JobFlags(json.map(item => new JobFlag(item)))
  }

  has(value: string) {
    return this.value.find(v => v.value === value);
  }
}

export class JobFlag extends StringValue {

}

export interface JobCompaniesJSON {
  [id: string]: JobCompanyJSON;
}

interface ScheduleJSON {
  source: string;
  data: string;
  fileId: string;
  mapping: string;
}

export class JobSections extends EntityMapValue<PageId, JobSection> {
  get all(): JobSection[] {
    return Object.keys(this.value).reduce((sections, key) => {
      return [...sections, this.value[key]];
    }, [] as JobSection[]).sort((a, b) => a.order < b.order ? -1 : a.order > b.order ? 1 : 0);
  }

  toJSON(): JobSectionsJSON {
    return super.toJSON() as JobSectionsJSON;
  }

  static fromJSON(json: Partial<JobSectionsJSON>) {
    return new JobSections(Object.keys(json).reduce((items, id) => {
      return {
        ...items,
        [id]: JobSection.fromJSON(json[id])
      };
    }, {} as Record<string, JobSection>))
  }
}

export class JobCompanies extends EntityMapValue<CompanyId, JobCompany> {
  get all(): JobCompany[] {
    return Object.keys(this.value).reduce((sections, key) => {
      return [...sections, this.value[key]];
    }, [] as JobCompany[]);

    // return [
    //   JobCompany.fromJSON({id: '1', details: {name: 'Foo', email: 'foo@boo.com', markup: 20}}),
    //   JobCompany.fromJSON({id: '2', details: {name: 'Bar', email: 'bar@bar.biz', markup: 10}}),
    //   JobCompany.fromJSON({id: '3', details: {name: 'Baz', email: 'baz@moon.zo', markup: 5}}),
    // ]
  }

  get choices(): EditorChoice[] {
    return [
      {
        id: '',
        value: 'Unallocated'
      },
      ...this.all.map(c => ({
        id: c.id,
        value: c.name,
      }))
    ]
  }

  toJSON(): JobCompaniesJSON {
    return super.toJSON() as JobCompaniesJSON;
  }

  static fromJSON(json: Partial<JobCompaniesJSON>) {
    return new JobCompanies(Object.keys(json).reduce((items, id) => {
      return {
        ...items,
        [id]: JobCompany.fromJSON(json[id])
      };
    }, {} as Record<string, JobCompany>))
  }
}