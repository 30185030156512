import {h} from 'preact';
import {Link} from 'preact-router';
import {Job, JobTab} from '../../model/Job';
import FieldGroup from '../form/FieldGroup';
import Field from '../form/Field';
import ButtonGroup from '../form/ButtonGroup';
import TeamUserBadge from '../team/TeamUserBadge';
import UpdateJobButton from '../team/UpdateJobButton';
import Form from '../form/Form';
import Heading from '../media/Heading';
import Paragraph from '../media/Paragraph';
import DateRelative from '../media/DateRelative';
import {valuationPath, ValuationTab} from '../../model/Valuation';
import renderPercentage from '../../helper/renderPercentage';
import renderCurrency from '../../helper/renderCurrency';

interface Props {
  job: Job;
}

export const SummarySectionId = {
  DETAILS: 'details',
  SECTIONS: 'sections',
  COMPANIES: 'companies',
};

export default function DetailList({job}: Props) {
  if (!job) return null;

  return (
    <Form fill={true}>
      <Paragraph>
        Add <a href={`#${SummarySectionId.DETAILS}`}>details</a>, <a href={`#${SummarySectionId.SECTIONS}`}>sections</a> and <a href={`#${SummarySectionId.COMPANIES}`}>companies</a> to the job below.
      </Paragraph>
      <Paragraph>
        Optionally <Link href={`/job/${job.id}/${JobTab.IMPORTS}`}>import</Link> a job from a spreadsheet, and allocate and price on the <Link href={`/job/${job.id}/${JobTab.COSTINGS}`}>costings</Link> tab.
      </Paragraph>
      <Paragraph>
        Preview <Link href={valuationPath(job.id, ValuationTab.PROGRESS)}>interim valuations</Link>.
      </Paragraph>
      <Heading level={5} id={SummarySectionId.DETAILS}>Details</Heading>
      <ButtonGroup>
        <UpdateJobButton job={job}/>
      </ButtonGroup>
      <FieldGroup horizontal={true}>
        <Field label="Job name">
          {job.name}
        </Field>
        <Field label="Job ref" fill={false}>
          {job.ref}
        </Field>
        <Field label="Surveyor">
          <TeamUserBadge userId={job.details.assignee}/>
        </Field>
      </FieldGroup>
      <FieldGroup horizontal={true}>
        <Field label="Client">
          {job.client}
        </Field>
        <Field label="Contact name">
          {job.details.contactName}
        </Field>
        <Field label="Contact email">
          {job.details.contactEmail}
        </Field>
        <Field label="Contact Tel">
          {job.details.contactTel}
        </Field>
      </FieldGroup>
      <Heading level={5}>Timeline</Heading>
      <FieldGroup horizontal={true}>
        <Field label="Date received" fill={false}>
          <DateRelative date={job.receivedAt.date}/>
        </Field>
        <Field label="Return by" fill={false}>
          <DateRelative date={job.returnBy.date}/>
        </Field>
        <Field label="Start date" fill={false}>
          <DateRelative date={job.startDate.date}/>
        </Field>
        <Field label="Duration" fill={false}>
          {!!job.duration && `${job.duration} week${job.duration === 1?'':'s'}`}
        </Field>
      </FieldGroup>
      <FieldGroup horizontal={true}>
        <Field label="Enquiries sent" fill={false}>
          {job.enquiriesSent ? 'Yes' : 'No'}
        </Field>
        <Field label="Site visit arranged" fill={false}>
          {job.siteVisitArranged ? 'Yes' : 'No'}
        </Field>
        <Field label="Costing" fill={false}>
          {job.costing ? 'Yes' : 'No'}
        </Field>
      </FieldGroup>
      <Heading level={5}>Billing</Heading>
      <FieldGroup horizontal={true}>
        <Field label="Retention %" fill={false}>
          {renderPercentage(job.retention)}
        </Field>
        <Field label="Invoiced to Date" fill={false}>
          {renderCurrency(job.invoiced)}
        </Field>
      </FieldGroup>
      <Heading level={5}>Settings</Heading>
      <FieldGroup horizontal={true}>
        <Field label="Internal" fill={false}>
          {job.internal ? 'Yes' : 'No'}
        </Field>
        <Field label="External" fill={false}>
          {job.external ? 'Yes' : 'No'}
        </Field>
        <Field label="Show quantity" fill={false}>
          {job.details.showQuantity ? 'Yes' : 'No'}
        </Field>
        <Field label="Show order" fill={false}>
          {job.details.showOrder ? 'Yes' : 'No'}
        </Field>
        <Field label="Round charge" fill={false}>
          {job.details.roundCharge ? `£${renderCurrency(job.details.roundCharge)}` : 'Off'}
        </Field>
      </FieldGroup>
    </Form>
  )
}