import { Entity } from '../../../framework/value/Entity';
import { ItemId, VariationId } from './App';
import MapValue from '../../../framework/value/MapValue';
import NumberValue from '../../../framework/value/NumberValue';
import StringValue from '../../../framework/value/StringValue';
import { Description, JobSectionItemCompany, PositiveValue } from './JobSectionItem';
import UTCDateValue from '../../../framework/value/UTCDateValue';
import BooleanValue from '../../../framework/value/BooleanValue';
import { ValidationError } from '../../../framework/error';
import { Complete } from './ValuationSectionItem';

export interface JobVariationItemJSON {
  id: string;
  details: JobVariationItemDetailsJSON;
  company: JobVariationItemCompanyJSON;
  order: number;
}

export default class JobVariationItem extends Entity<VariationId, {
  details: JobVariationItemDetails;
  company: JobVariationItemCompany;
  order: Order;
}> {
  get details() {
    return this.value.details;
  }

  get order() {
    return this.value.order.value;
  }

  get company() {
    return this.value.company;
  }

  get ci() {
    return this.value.details.value.ci.value;
  }

  get po() {
    return this.value.details.value.po.value;
  }

  get description() {
    return this.value.details.value.description.value;
  }

  get rate() {
    return this.value.details.value.rate.value;
  }

  get cost() {
    return this.rate;
  }

  get markup() {
    return this.value.details.value.markup.value || 0;
  }

  get profit() {
    return this.cost * (this.markup / 100);
  }

  get charge() {
    return this.cost + this.profit;
  }

  get instructedAt() {
    return this.value.details.value.instructedAt;
  }

  get sentAt() {
    return this.value.details.value.sentAt;
  }

  get proceedAt() {
    return this.value.details.value.proceedAt;
  }

  get commencedAt() {
    return this.value.details.value.commencedAt;
  }

  get completedAt() {
    return this.value.details.value.completedAt;
  }

  get totalDays() {
    if (!this.instructedAt.isValid || !this.completedAt.isValid)
      return '';

    return this.completedAt.dayDifference(this.instructedAt);
  }

  get trackCompany() {
    return this.markup === (this.company.markup || 0);
  }


  get complete() {
    // return Math.round(Math.random() * 100);
    return this.value.details.value.complete.value;
  }

  get omit() {
    return this.value.details.value.omit.value;
  }

  get omitted() {
    return this.omit
      ? this.charge
      : 0;
  }

  get billable() {
    if (this.omit || this.complete <= 0) return 0;

    return Math.max(0, this.charge * (this.complete / 100));
  }

  setOrder(order: number) {
    return this.extend({
      order: new Order(order)
    })
  }

  setCompany(company: Partial<JobVariationItemCompanyJSON>) {
    let response = this;

    // sync the new markup if tracking
    if (this.trackCompany && company.markup !== this.markup) {
      response = response.setMarkup(company.markup || 0)
    }

    return response.extend({
      company: JobSectionItemCompany.fromJSON(company)
    })
  }

  setCI(ci: string) {
    return this.extend({
      details: this.details.extend({
        ci: new StringValue(ci)
      })
    })
  }

  setPO(po: string) {
    return this.extend({
      details: this.details.extend({
        po: new StringValue(po)
      })
    })
  }

  setDescription(description: string) {
    return this.extend({
      details: this.details.extend({
        description: new Description(description)
      })
    })
  }

  setRate(rate: number) {
    return this.extend({
      details: this.details.extend({
        rate: new Rate(rate)
      })
    })
  }

  setMarkup(markup: number) {
    return this.extend({
      details: this.details.extend({
        markup: new Markup(markup)
      })
    })
  }

  setInstructedAt(instructedAt: string) {
    return this.extend({
      details: this.details.extend({
        instructedAt: new UTCDateValue(instructedAt)
      })
    })
  }

  setSentAt(sentAt: string) {
    return this.extend({
      details: this.details.extend({
        sentAt: new UTCDateValue(sentAt)
      })
    })
  }

  setProceedAt(proceedAt: string) {
    return this.extend({
      details: this.details.extend({
        proceedAt: new UTCDateValue(proceedAt)
      })
    })
  }

  setCommencedAt(commencedAt: string) {
    return this.extend({
      details: this.details.extend({
        commencedAt: new UTCDateValue(commencedAt)
      })
    })
  }

  setCompletedAt(completedAt: string) {
    return this.extend({
      details: this.details.extend({
        completedAt: new UTCDateValue(completedAt)
      })
    })
  }

  setComplete(complete: number) {
    if (complete < 0 || complete > 100)
      throw new ValidationError('Complete', 'This must be a percentage between 0 and 100');

    return this.extend({
      details: this.details.extend({
        complete: new Complete(complete)
      })
    })
  }

  setOmit(omit: boolean) {
    return this.extend({
      details: this.details.extend({
        omit: new BooleanValue(omit)
      })
    })
  }


  toJSON(): JobVariationItemJSON {
    return super.toJSON() as JobVariationItemJSON;
  }

  static fromJSON(json: Partial<JobVariationItemJSON>) {
    return new JobVariationItem({
      id: new ItemId(json.id || ''),
      details: JobVariationItemDetails.fromJSON(json.details || {}),
      company: JobVariationItemCompany.fromJSON(json.company || {}),
      order: new Order(json.order || 0),
    })
  }
}

export interface JobVariationItemDetailsJSON {
  ci: string;
  po: string;
  description: string;
  rate: number;
  markup: number;
  instructedAt: string;
  sentAt: string;
  proceedAt: string;
  commencedAt: string;
  completedAt: string;
  complete: number;
  omit: boolean;
}

export class JobVariationItemDetails extends MapValue<{
  ci: StringValue;
  po: StringValue;
  description: StringValue;
  rate: Rate;
  markup: Markup;
  instructedAt: UTCDateValue;
  sentAt: UTCDateValue;
  proceedAt: UTCDateValue;
  commencedAt: UTCDateValue;
  completedAt: UTCDateValue;
  complete: NumberValue;
  omit: BooleanValue;
}> {
  toJSON(): JobVariationItemDetailsJSON {
    return super.toJSON() as JobVariationItemDetailsJSON;
  }

  static fromJSON(json: Partial<JobVariationItemDetailsJSON>): JobVariationItemDetails {
    return new JobVariationItemDetails({
      ci: new StringValue(json.ci || ''),
      po: new StringValue(json.po || ''),
      description: new StringValue(json.description || ''),
      rate: new Rate(json.rate || 0),
      markup: new Markup(json.markup || 0),
      instructedAt: new UTCDateValue(json.instructedAt || ''),
      sentAt: new UTCDateValue(json.sentAt || ''),
      proceedAt: new UTCDateValue(json.proceedAt || ''),
      commencedAt: new UTCDateValue(json.commencedAt || ''),
      completedAt: new UTCDateValue(json.completedAt || ''),
      complete: new NumberValue(json.complete || 0),
      omit: new BooleanValue(typeof json.omit !== 'undefined' ? json.omit : false),
    })
  }
}

export class Order extends NumberValue {}
export class Rate extends NumberValue {}
export class Markup extends PositiveValue {}

export interface JobVariationItemCompanyJSON {
  id: string;
  name: string;
  markup: number;
}

export class JobVariationItemCompany extends MapValue<{
  id: StringValue;
  name: StringValue;
  markup: Markup;
}> {
  get id() {
    return this.value.id.value;
  };

  get name() {
    return this.value.name.value;
  }

  get markup() {
    return this.value.markup.value;
  }

  toJSON(): JobVariationItemCompanyJSON {
    return super.toJSON() as JobVariationItemCompanyJSON;
  }

  static fromJSON(json: Partial<JobVariationItemCompanyJSON>): JobVariationItemCompany {
    return new JobVariationItemCompany({
      id: new StringValue(json.id || ''),
      name: new StringValue(json.name || ''),
      markup: new Markup(json.markup || 0),
    })
  }
}