import {
  ARCHIVE_JOBS_COMMAND,
  ARCHIVE_LOADED_EVENT, ArchiveJobsCommand, ArchiveLoadedEvent,
  Collection,
  CREATE_JOB_COMMAND,
  CreateJobCommand,
  DELETE_JOBS_COMMAND,
  DeleteJobsCommand, IMPORT_JOB_COMMAND, ImportJobCommand,
  JOB_CREATED_EVENT,
  JOB_LOADED_EVENT,
  JOB_TEMPLATE_SAVED_EVENT,
  JOB_UPDATED_EVENT,
  JobCreatedEvent,
  JobLoadedEvent, JOBS_ARCHIVED_EVENT,
  JOBS_DELETED_EVENT,
  JOBS_LOADED_EVENT, JOBS_UNARCHIVED_EVENT, JobsArchivedEvent,
  JobsDeletedEvent,
  JobsLoadedEvent, JobsUnarchivedEvent,
  JobTemplateSavedEvent,
  JobUpdatedEvent, LOAD_ARCHIVE_COMMAND,
  LOAD_JOB_COMMAND,
  LOAD_JOBS_COMMAND,
  LOAD_TEMPLATES_COMMAND, LoadArchiveCommand,
  LoadJobCommand,
  LoadJobsCommand,
  LoadTemplatesCommand,
  SAVE_JOB_TEMPLATE_COMMAND,
  SaveJobTemplateCommand,
  TeamService,
  TEMPLATES_LOADED_EVENT,
  TemplatesLoadedEvent, UNARCHIVE_JOBS_COMMAND, UnarchiveJobsCommand,
  UPDATE_JOB_COMMAND,
  UpdateJobCommand
} from '../../type';
import {JobCostingsAppService} from '../../services';
import {State} from '../../model/State';
import {JobId, TeamId} from '../../model/App';
import {Job, JobDetails, JobJSON, JobName} from '../../model/Job';
import jobsLoaded from '../../event/jobsLoaded';
import {TeamJobs} from '../../model/Team';
import jobLoaded from '../../event/jobLoaded';
import jobsDeleted from '../../event/jobsDeleted';
import templatesLoaded from '../../event/templatesLoaded';
import archiveLoaded from '../../event/archiveLoaded';
import jobsArchived from '../../event/jobsArchived';

export default class AppTeamService extends JobCostingsAppService implements TeamService {
  async [IMPORT_JOB_COMMAND](_: any, command: ImportJobCommand) {
    return this.execute([
      new TeamId(command.payload.teamId),
      Job.fromJSON(command.payload.job),
    ],async (id: TeamId, job: Job) => {
      return this.proxy(command);
    });
  }

  async [CREATE_JOB_COMMAND](_: any, command: CreateJobCommand) {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
      JobDetails.fromJSON(command.payload.details),
    ],async (id: TeamId, jobId: JobId, details: JobDetails) => {
      return this.proxy(command);
    });
  }

  async [UPDATE_JOB_COMMAND](_: any, command: UpdateJobCommand) {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
      JobDetails.fromJSON(command.payload.details),
    ],async (id: TeamId, jobId: JobId, details: JobDetails) => {
      return this.proxy(command);
    });
  }

  async [LOAD_JOBS_COMMAND](state: State, command: LoadJobsCommand) {
    return this.execute([
      new TeamId(command.payload.teamId)
    ],async (teamId) => {
      const id = teamId.id;
      const data = await this.getTeamJobsRef(id)
        .orderBy('details.startDate', 'desc')
        .get();
      const jobs = data.docs.map(doc => {
        // TODO validate here that its correct
        return doc.data() || null;
      }).filter(Boolean) as JobJSON[];

      this.dispatch(jobsLoaded(id, jobs));
    });
  }

  async [LOAD_ARCHIVE_COMMAND](state: State, command: LoadArchiveCommand) {
    return this.execute([
      new TeamId(command.payload.teamId)
    ],async (teamId) => {
      const id = teamId.id;
      const data = await this.getTeamArchiveRef(id)
        .orderBy('details.startDate', 'desc')
        .get();
      const jobs = data.docs.map(doc => {
        // TODO validate here that its correct
        return doc.data() || null;
      }).filter(Boolean) as JobJSON[];

      this.dispatch(archiveLoaded(id, jobs));
    });
  }

  async [LOAD_TEMPLATES_COMMAND](state: State, command: LoadTemplatesCommand) {
    return this.execute([
      new TeamId(command.payload.teamId)
    ],async (teamId) => {
      const id = teamId.id;
      const data = await this.getTeamTemplatesRef(id)
        .orderBy('details.name', 'asc')
        .get();
      const templates = data.docs.map(doc => {
        // TODO validate here that its correct
        return doc.data() || null;
      }).filter(Boolean) as JobJSON[];

      this.dispatch(templatesLoaded(id, templates));
    });
  }

  async [LOAD_JOB_COMMAND](state: State, command: LoadJobCommand) {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId)
    ],async (teamId, jobId) => {
      const id = teamId.id;
      const doc = await this.getTeamJobRef(id, jobId.id).get();
      const job = doc.data() as JobJSON;

      if (job) {
        this.dispatch(jobLoaded({
          teamId: id,
          job,
        }))
      }
    });
  }

  async [SAVE_JOB_TEMPLATE_COMMAND](state: State, command: SaveJobTemplateCommand) {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
      new JobName(command.payload.templateName),
    ],async (id: TeamId, jobId: JobId, templateName: JobName) => {
      return this.proxy(command);
    });
  }

  async [ARCHIVE_JOBS_COMMAND](_: any, command: ArchiveJobsCommand) {
    return this.execute([
      new TeamId(command.payload.teamId),
      ...command.payload.jobIds.map(id => new JobId(id)),
    ], async (teamId: TeamId, ...jobIds: JobId[]) => {
      const proxy = await this.proxy(command);

      this.dispatch(jobsArchived({
        teamId: teamId.id,
        jobIds: jobIds.map(id => id.id)
      }));

      return proxy;
    })
  }

  async [UNARCHIVE_JOBS_COMMAND](_: any, command: UnarchiveJobsCommand) {
    return this.execute([
      new TeamId(command.payload.teamId),
      ...command.payload.jobIds.map(id => new JobId(id)),
    ], async (teamId: TeamId, ...jobIds: JobId[]) => {
      const proxy = await this.proxy(command);

      this.dispatch(jobsArchived({
        teamId: teamId.id,
        jobIds: jobIds.map(id => id.id)
      }));

      return proxy;
    })
  }

  async [DELETE_JOBS_COMMAND](_: any, command: DeleteJobsCommand) {
    return this.execute([
      new TeamId(command.payload.teamId),
      ...command.payload.jobIds.map(id => new JobId(id)),
    ], async (teamId: TeamId, ...jobIds: JobId[]) => {
      const proxy = await this.proxy(command);

      this.dispatch(jobsDeleted({
        teamId: teamId.id,
        jobIds: jobIds.map(id => id.id)
      }));

      return proxy;
    })
  }

  [JOB_CREATED_EVENT](state: State, event: JobCreatedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = Job.fromJSON(event.payload.job);

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(job)
      }))
    });
  }

  [JOB_UPDATED_EVENT](state: State, event: JobUpdatedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = team.jobs.get(event.payload.jobId);
    if (!job) return state;

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(job.updateDetails(event.payload.details))
      }))
    })
  }

  [JOBS_LOADED_EVENT](state: State, event: JobsLoadedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: TeamJobs.fromJSON(event.payload.jobs)
      }))
    });
  }

  [ARCHIVE_LOADED_EVENT](state: State, event: ArchiveLoadedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    return state.extend({
      team: state.team.replace(team.extend({
        archive: TeamJobs.fromJSON(event.payload.jobs)
      }))
    });
  }

  [TEMPLATES_LOADED_EVENT](state: State, event: TemplatesLoadedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    return state.extend({
      team: state.team.replace(team.extend({
        templates: TeamJobs.fromJSON(event.payload.templates)
      }))
    });
  }

  [JOB_LOADED_EVENT](state: State, event: JobLoadedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const existingJob = team.jobs.get(event.payload.job.id);
    const job = Job.fromJSON(event.payload.job);

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(existingJob
          ? existingJob.merge(job)
          : job)
      }))
    });
  }

  [JOB_TEMPLATE_SAVED_EVENT](state: State, event: JobTemplateSavedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const template = Job.fromJSON(event.payload.template);

    return state.extend({
      team: state.team.replace(team.extend({
        templates: team.templates.replace<Job, TeamJobs>(
          template
        )
      }))
    });
  }

  [JOBS_DELETED_EVENT](state: State, event: JobsDeletedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    return state.extend({
      team: state.team.replace(
        team.removeJobs(event.payload.jobIds)
      )
    })
  }

  [JOBS_ARCHIVED_EVENT](state: State, event: JobsArchivedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    // todo add archived jobs to local state
    return state.extend({
      team: state.team.replace(
        team.removeJobs(event.payload.jobIds)
      )
    })
  }

  [JOBS_UNARCHIVED_EVENT](state: State, event: JobsUnarchivedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    // todo add archived jobs to local state
    return state.extend({
      team: state.team.replace(
        team.removeArchive(event.payload.jobIds)
      )
    })
  }

  protected getTeamJobsRef(teamId: string) {
    return this.app.firestore()
      .collection(Collection.TEAMS)
      .doc(teamId)
      .collection(Collection.JOBS)
  }

  protected getTeamArchiveRef(teamId: string) {
    return this.app.firestore()
      .collection(Collection.TEAMS)
      .doc(teamId)
      .collection(Collection.ARCHIVE)
  }

  protected getTeamTemplatesRef(teamId: string) {
    return this.app.firestore()
      .collection(Collection.TEAMS)
      .doc(teamId)
      .collection(Collection.TEMPLATES)
  }

  protected getTeamJobRef(teamId: string, jobId: string) {
    return this.getTeamJobsRef(teamId)
      .doc(jobId);
  }

}