import {h} from 'preact';
import {Link} from 'preact-router';
import {Valuation, valuationPath, ValuationTab} from '../../model/Valuation';
import FieldGroup from '../form/FieldGroup';
import Field from '../form/Field';
import ButtonGroup from '../form/ButtonGroup';
import TeamUserBadge from '../team/TeamUserBadge';
import Form from '../form/Form';
import Heading from '../media/Heading';
import Paragraph from '../media/Paragraph';
import Button from '../form/Button';
import renderCurrency from '../../helper/renderCurrency';
import renderPercentage from '../../helper/renderPercentage';

interface Props {
  valuation: Valuation;
}

export const SummarySectionId = {
  DETAILS: 'details',
  SECTIONS: 'sections',
  COMPANIES: 'companies',
};

export default function ValuationSummaryList({valuation}: Props) {
  if (!valuation) return null;

  const path = valuationPath.bind(null, valuation.id);

  return (
    <Form fill={true}>
      <Paragraph>
        Record <Link href={path(ValuationTab.PROGRESS)}>progress</Link> of items in the job, omitting and adding items as needed.
      </Paragraph>
      {/*<Paragraph>*/}
        {/*Create and link <Link href={path(ValuationTab.PURCHASE_ORDERS)}>Purchase Orders</Link> to items in the job.*/}
      {/*</Paragraph>*/}
      <Paragraph>
        Once work has progressed, create an invoice and view previous <Link href={path(ValuationTab.INVOICES)}>invoices</Link>.
      </Paragraph>
      <Heading level={5}>Job Progress</Heading>
      <FieldGroup horizontal={true}>
        <Field label="Contract price" fill={false}>
          {renderCurrency(valuation.charge)}
        </Field>
        <Field label="Omitted" fill={false}>
          {renderCurrency(valuation.omitted)}
        </Field>
        <Field label="Additionals" fill={false}>
          {renderCurrency(valuation.additional)}
        </Field>
        <Field label="New price" fill={false}>
          {renderCurrency(valuation.newCharge)}
        </Field>
      </FieldGroup>
      <FieldGroup horizontal={true}>
        <Field label="Works completed" fill={false}>
          {renderCurrency(valuation.billable)}
        </Field>
        <Field label="Retention" fill={false}>
          {renderPercentage(0)}
        </Field>
        <Field label="Previously invoiced" fill={false}>
          {renderCurrency(valuation.invoiced)}
        </Field>
        <Field label="New valuation total" fill={false}>
          {renderCurrency(valuation.newPayment)}
        </Field>
      </FieldGroup>
      <Heading level={5} id={SummarySectionId.DETAILS}>Details</Heading>
      <FieldGroup horizontal={true}>
        <Field label="Valuation Name">
          {valuation.name}
        </Field>
        <Field label="Valuation Ref" fill={false}>
          {valuation.ref}
        </Field>
        <Field label="Surveyor">
          <TeamUserBadge userId={valuation.details.assignee}/>
        </Field>
      </FieldGroup>
      <FieldGroup horizontal={true}>
        <Field label="Client">
          {valuation.client}
        </Field>
        <Field label="Contact Name">
          {valuation.details.contactName}
        </Field>
        <Field label="Contact Email">
          {valuation.details.contactEmail}
        </Field>
        <Field label="Contact Tel">
          {valuation.details.contactTel}
        </Field>
      </FieldGroup>
    </Form>
  )
}