import Form from '../form/Form';
import {h} from 'preact';
import FieldGroup from '../form/FieldGroup';
import {useEffect, useState} from 'preact/hooks';
import Button from '../form/Button';
import {CommandStatus} from '../../../../framework/type';
import useCommandErrors from '../../../../framework/hook/useCommandErrors';
import commandsAreRunning from '../../../../framework/commandsAreRunning';
import Paragraph from '../media/Paragraph';
import {useFinaliseCostings} from '../../command/finaliseCostings';
import currentUserTeam from '../../query/currentUserTeam';
import {Job} from '../../model/Job';
import {JobCompanyItem} from '../../model/JobCompanyItem';

interface Props {
  job: Job;
  onCancel(): void;
  onSuccess(): void;
}

export default function FinaliseCostingsForm({job, onCancel, onSuccess}: Props) {
  const team = currentUserTeam();
  const [command, finaliseCostings] = useFinaliseCostings();
  const [unpricedItems, setUnpricedItems] = useState<Array<JobCompanyItem>>([])

  const {system} = useCommandErrors(command);

  const submit = () => {
    if (!team) return null;

    finaliseCostings({
      teamId: team.id,
      jobId: job.id,
    });
  };

  useEffect(() => {
    setUnpricedItems(job.unpricedItems());
  }, [job, setUnpricedItems]);

  useEffect(() => {
    if (!command || command.status !== CommandStatus.SUCCESS) return;
    onSuccess();
  }, [command]);


  const isLoading = commandsAreRunning(command);

  return (
    <Form heading="Generate Quote & Reports" onSubmit={submit} error={system}>
      <Paragraph>This will generate a quote to return to the client and a breakdown by company</Paragraph>
      {unpricedItems.length > 0 && (
        <Paragraph small={true}>
          Note: There {unpricedItems.length === 1 ? 'is' :  'are'} {unpricedItems.length} item{unpricedItems.length === 1 ?'':'s'} without a COST
        </Paragraph>
      )}
      <FieldGroup horizontal={true}>
        <Button primary={true} type="submit" disabled={isLoading}>{isLoading ? 'Finalising...' : 'Finalise'}</Button>
        <Button type="cancel" onClick={onCancel}>Cancel</Button>
      </FieldGroup>
    </Form>
  )
}