import {PropRef, useEffect} from 'preact/hooks';

export default function setDateValueOnMount(ref: PropRef<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, value: Date) {
  useEffect(() => {
    if (ref && ref.current) {
      ref.current.value = isNaN(value.getTime())
        ? ''
        : value.toISOString().split('T')[0];
    }
  }, [value])
}