import {HTMLAttributes} from '../../../../framework/type';
import App from '../../app';
import {h} from 'preact';
import classes from '../../../../framework/classes';

interface Props extends HTMLAttributes {
  children: any;
  strong?: boolean;
}

export default function FinancialIndicator({strong, ...props}: Props) {
  const value = parseFloat(props.children);

  const className = App.useStyle(({theme}) => ({
    color: isNaN(value) || value >= 0
      ? theme.color.black.toString()
      : theme.color.red.toString(),
  }), []);

  const finalProps = {
    ...props,
    className: classes(className, props.className)
  };

  if (strong)
    return <strong {...finalProps} />;

  return <span {...finalProps}/>;
}