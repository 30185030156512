import {
  AuthAppService,
  CLEAR_TEAM_COMMAND,
  ClearTeamCommand,
  Collection,
  COMPLETE_SIGN_IN_WITH_EMAIL_COMMAND,
  CompleteSignInWithEmailCommand,
  CREATE_TEAM_COMMAND,
  CreateTeamCommand,
  CURRENT_USER_SIGNED_OUT_EVENT,
  CurrentUserSignedOutEvent, INVITE_TEAM_USER_COMMAND, InviteTeamUserCommand, JOIN_TEAM_COMMAND, JoinTeamCommand,
  SELECT_TEAM_COMMAND,
  SelectTeamCommand,
  SIGN_OUT_COMMAND,
  START_SIGN_IN_WITH_EMAIL_COMMAND,
  START_SIGN_IN_WITH_GOOGLE_COMMAND,
  StartSignInWithEmailCommand,
  TEAM_CLEARED_EVENT, TEAM_JOINED_EVENT,
  TEAM_SELECTED_EVENT, TEAM_USER_INVITED_EVENT,
  TeamClearedEvent, TeamJoinedEvent,
  TeamSelectedEvent, TeamUserInvitedEvent,
  USER_SIGNED_IN_EVENT,
  USER_STATUS_UPDATED_EVENT,
  UserSignedInEvent,
  UserStatusUpdatedEvent
} from '../../type';
import {User, UserEmail, UserName, UserStatus} from '../../model/User';
import {AppSession, AppUrl, TeamId, UserId} from '../../model/App';
import {Team, TeamJSON, TeamName, TeamUser, TeamUserColor, TeamUserName} from '../../model/Team';
import {AsyncCommandResponse} from '../../../../framework/type';
import * as firebase from 'firebase/app';
import signInEmailSent from '../../event/signInEmailSent';
import userSignedIn from '../../event/userSignedIn';
import currentUserSignedOut from '../../event/userSignedOut';
import {State} from '../../model/State';
import {JobCostingsAppService} from '../../services';
import teamSelected from '../../event/teamSelected';
import teamCleared from '../../event/teamCleared';
import config from '../../config';
import {Email} from '../../../../framework/value/Email';
import StringValue from '../../../../framework/value/StringValue';

export default class AppAuthService extends JobCostingsAppService implements AuthAppService {
  constructor(
    protected readonly storage: Storage,
    protected readonly app: firebase.app.App,
    private readonly onAuthStateChanged: () => void,
  ) {
    super(storage, app);
    app.auth().onAuthStateChanged(this.handleAuthStateChange.bind(this, onAuthStateChanged));
  }

  private handleAuthStateChange(
    onAuthStateChanged: () => void,
    user: firebase.User | null
  ) {
    if (!user) {
      this.dispatch(currentUserSignedOut())
    } else {
      this.dispatch(userSignedIn({
        userId: user.uid,
        userEmail: user.email || '',
        userName: user.displayName || UserName.ANONYMOUS.toString(),
        isNewUser: false,
      }));
    }

    return onAuthStateChanged();
  }

  async [SIGN_OUT_COMMAND](): AsyncCommandResponse {
    return this.execute([], async () => {
      await this.app.auth().signOut();
    })
  }
  async [START_SIGN_IN_WITH_GOOGLE_COMMAND](): AsyncCommandResponse {
    return this.execute([], async () => {
      const provider = new firebase.auth.GoogleAuthProvider();
      await this.app.auth().signInWithPopup(provider);
    });
  }

  async [START_SIGN_IN_WITH_EMAIL_COMMAND](_: any, command: StartSignInWithEmailCommand): AsyncCommandResponse {
    return this.execute([
      new UserEmail(command.payload.email),
      new AppUrl(command.payload.redirectUrl),
    ],async (email, redirectUrl) => {
      await this.app.auth().sendSignInLinkToEmail(email.toString(), {
        url: redirectUrl.toString(),
        handleCodeInApp: true,
      });

      this.storage.setItem(config.localStorage.authEmail, email.toString());
      this.dispatch(signInEmailSent(email.toString()));
    });
  }

  async [COMPLETE_SIGN_IN_WITH_EMAIL_COMMAND](_: any, command: CompleteSignInWithEmailCommand): AsyncCommandResponse {
    return this.execute([
      new UserEmail(command.payload.email),
      new AppUrl(command.payload.linkUrl),
    ], async (email, linkUrl) => {
      await this.app.auth().signInWithEmailLink(email.toString(), linkUrl.toString());
      this.storage.removeItem(config.localStorage.authEmail);
    });
  }

  async [CREATE_TEAM_COMMAND](_: any, command: CreateTeamCommand): AsyncCommandResponse {
    return this.execute([
      new UserId(command.payload.userId),
      new TeamName(command.payload.teamName),
      new TeamUserName(command.payload.userName),
      new TeamUserColor(command.payload.userColor)
    ],async (id: UserId, name: TeamName, userName: TeamUserName, userColor: TeamUserColor) => {
      return this.proxy(command);
    });
  }

  async [SELECT_TEAM_COMMAND](_: any, command: SelectTeamCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId)
    ], async (id: TeamId) => {
      this.storage.setItem(config.localStorage.authTeam, id.id);
      const doc = await this.app.firestore()
        .collection(Collection.TEAMS)
        .doc(id.id)
        .get();

      const team = doc.data() as TeamJSON;

      if (team) {
        this.dispatch(teamSelected(id.id, team));
      }
    })
  }

  async [CLEAR_TEAM_COMMAND](_: any, command: ClearTeamCommand): AsyncCommandResponse {
    return this.execute([], async () => {
      this.storage.removeItem(config.localStorage.authTeam);
      this.dispatch(teamCleared())
    });
  }

  async [INVITE_TEAM_USER_COMMAND](_: any, command: InviteTeamUserCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new TeamUserName(command.payload.userName),
      new Email(command.payload.userEmail),
    ],async (id: TeamId, userName: TeamUserName, email: Email) => {
      return this.proxy(command);
    });
  }

  async [JOIN_TEAM_COMMAND](_: any, command: JoinTeamCommand): AsyncCommandResponse {
    return this.execute([
      new StringValue(command.payload.inviteCode),
      new TeamUserName(command.payload.userName)
    ],async (inviteCode: StringValue, userName: TeamUserName) => {
      return this.proxy(command);
    });
  }

  [USER_SIGNED_IN_EVENT](state: State, event: UserSignedInEvent): State {
    return state.extend({
      session: new AppSession({
        userId: new UserId(event.payload.userId),
        teamId: state.session.teamId,
      }),
      user: state.user.replace(User.create({
        id: event.payload.userId,
        name: event.payload.userName,
        email: event.payload.userEmail,
        isNew: event.payload.isNewUser,
      }))
    });
  }

  [CURRENT_USER_SIGNED_OUT_EVENT](state: State, event: CurrentUserSignedOutEvent): State {
    return state.extend({
      session: AppSession.EMPTY
    });
  }

  [USER_STATUS_UPDATED_EVENT](state: State, event: UserStatusUpdatedEvent): State {
    const user = state.user.get(event.payload.userId);
    if (!user) return state;

    return state.extend({
      user: state.user.replace(user.extend({
        status: UserStatus.create(event.payload.loading)
      }))
    })
  }

  [TEAM_SELECTED_EVENT](state: State, event: TeamSelectedEvent): State {
    return state.extend({
      session: new AppSession({
        userId: state.session.userId,
        teamId: new TeamId(event.payload.teamId),
      }),
      team: state.team.replace(Team.fromJSON(event.payload.team))
    })
  }

  [TEAM_CLEARED_EVENT](state: State, event: TeamClearedEvent): State {
    return state.extend({
      session: new AppSession({
        userId: state.session.userId,
        teamId: TeamId.NULL,
      }),
    })
  }

  [TEAM_JOINED_EVENT](state: State, event: TeamJoinedEvent): State {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const invitedUser = TeamUser.fromJSON(event.payload.user);

    return state.extend({
      team: state.team.extend({
        [team.id]: team.acceptInvite(
          new UserId(event.payload.originalUserId),
          new TeamUserName(invitedUser.name),
          invitedUser.email,
        )
      })
    });
  }

  [TEAM_USER_INVITED_EVENT](state: State, event: TeamUserInvitedEvent): State {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const invitedUser = TeamUser.fromJSON(event.payload.user);

    return state.extend({
      team: state.team.extend({
        [team.id]: team.inviteUser(invitedUser)
      })
    });
  }

  isSignInWithEmailLink(url: string) {
    return this.app.auth().isSignInWithEmailLink(url);
  }

  getStoredEmail() {
    return this.storage.getItem(config.localStorage.authEmail);
  }

  getStoredTeam() {
    return this.storage.getItem(config.localStorage.authTeam);
  }
}
