import MapValue from '../../../framework/value/MapValue';
import {AppSession, TeamId, UserId} from './App';
import {Users} from './User';
import {Jobs} from './Job';
import Theme, {DefaultTheme} from './Theme';
import {Teams} from './Team';

export class State extends MapValue<{
  session: AppSession;
  user: Users;
  team: Teams;
  theme: Theme
}> {
  get session() {
    return this.value.session;
  }

  get theme() {
    return this.value.theme;
  }

  get user() {
    return this.value.user;
  }

  get team() {
    return this.value.team;
  }

  static DEFAULT = new State({
    theme: DefaultTheme,
    session: new AppSession({
      userId: UserId.NULL,
      teamId: TeamId.NULL,
    }),
    user: new Users({}),
    team: new Teams({}),
  })
}