import { Fragment, h } from 'preact';
import Button from '../form/Button';
import Dialog from '../media/Dialog';
import { useState } from 'preact/hooks';
import { Job } from '../../model/Job';
import FinaliseValuationForm from './FinaliseValuationForm';
import { route } from 'preact-router';
import renderCurrency from '../../helper/renderCurrency';

interface Props {
  job: Job;
}

export default function FinaliseValuationButton({job}: Props) {
  const [showDialog, toggleDialog] = useState(false);
  const {valuation} = job;

  if (!valuation.canInvoice) return null;

  const dialog = {
    show: toggleDialog.bind(null, true),
    hide: toggleDialog.bind(null, false)
  };

  return (
    <Fragment>
      <Button small={true} disabled={!valuation.canInvoice} primary={valuation.canInvoice} onClick={dialog.show}>
        Generate Valuation for {renderCurrency(valuation.newPayment)}
      </Button>
      <Dialog show={showDialog} onRequestClose={dialog.hide}>
        <FinaliseValuationForm
          job={job}
          onCancel={dialog.hide}
          onSuccess={() => {
            dialog.hide();
            route(`/job/${job.id}/imports`);
          }}
        />
      </Dialog>
    </Fragment>
  )
}