import MapValue from './MapValue';
import {Entity} from './Entity';
import Identity from './Identity';

export default class EntityMapValue<ID extends Identity<any>, E extends Entity<ID, any>, JSON = any> extends MapValue<{
  [id: string]: E
}, JSON> {
  get(id: string): E | null {
    return this.value[id] || null;
  }

  replace<V extends Entity<ID, any>, T extends EntityMapValue<ID, V>>(entity: V) {
    return this.constructor({
      ...this.value,
      [entity.id]: entity,
    }) as T;
  }

  remove<T extends EntityMapValue<ID, E>>(id: ID) {
    return new (this.constructor as any)({
      ...Object.keys(this.value).reduce((next, key) => {
        if (key === id.id)
          return next;

        return {
          ...next,
          [key]: this.value[key],
        }
      }, {} as E)
    }) as this;
  }
}