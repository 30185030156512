import { h } from 'preact';
import Heading from '../media/Heading';
import useJob from '../../query/useJob';
import SectionList from '../job/SectionList';
import AssetList from '../job/AssetList';
import Tabs, { TabItem } from '../media/Tabs';
import SummaryList from '../job/SummaryList';
import { jobPath, JobTab } from '../../model/Job';
import CompanyList from '../job/CompanyList';
import JobStatusLine from '../job/JobStatusLine';
import { percent } from 'csx';
import App from '../../app';
import { useCallback } from 'preact/hooks';
import DetailList from '../job/DetailList';
import VariationList from '../valuation/VariationList';
import ValuationList from '../valuation/ValuationList';
import ValuationStatusLine from '../valuation/ValuationStatusLine';

interface Props {
  jobId: string
  tab: string;
}

export default function JobRoute({jobId, tab = JobTab.SUMMARY}: Props) {
  const [job] = useJob(jobId);

  if (!job) return null;
  const path = jobPath.bind(null, jobId);

  const renderTab = useCallback(() => {
    switch (tab) {
      case JobTab.COMPANIES:
        return (
          <CompanyList jobId={jobId}/>
        );

      case JobTab.COSTINGS:
        return (
          <SectionList job={job}/>
        );

      case JobTab.IMPORTS:
        return (
          <AssetList job={job}/>
        );

      case JobTab.SUMMARY:
        return (
          <SummaryList job={job}/>
        );

      case JobTab.DETAILS:
        return (
          <DetailList job={job}/>
        );

      case JobTab.VALUATION:
        return (
          <ValuationList job={job}/>
        );

      case JobTab.VARIATIONS:
        return (
          <VariationList job={job}/>
        );

      default:
        return null;
    }
  }, [tab, job]);

  const leftTabs: TabItem[] = [
    {
      label: 'Summary',
      href: path(JobTab.SUMMARY)
    },
    {
      label: 'Costings',
      href: path(JobTab.COSTINGS)
    },
    {
      label: 'Companies',
      href: path(JobTab.COMPANIES)
    },
    {
      label: 'Valuation',
      href: path(JobTab.VALUATION)
    },
    {
      label: 'Variations',
      href: path(JobTab.VARIATIONS)
    }
  ];

  const rightTabs: TabItem[] = [
    {
      label: 'Files',
      href: path(JobTab.IMPORTS)
    },
    {
      label: 'Settings',
      href: path(JobTab.DETAILS)
    },
    // {
    //   label: 'Activity',
    //   href: path(JobTab.ACTIVITY)
    // }
  ];

  const containerClass = App.useStyle(({theme}) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    height: percent(100),
    maxHeight: percent(100),
    $nest: {
      '& > *:last-child': {
        flex: 1,
        flexDirection: 'row',
        width: percent(100),
        maxWidth: percent(100),
        display: 'flex',
        position: 'relative',
        overflow: 'auto'
      }
    }
  }), []);

  const headingClass = App.useStyle(({theme}) => ({
    width: percent(100),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    $nest: {
      '& > *': {
        margin: '0 !important'
      }
    }
  }), []);

  const contentClass = App.useStyle(({theme}) => ({
    width: percent(100),
    flex: 1,
  }), []);

  const currentTab = renderTab();
  const isValuations = tab === JobTab.VALUATION || tab === JobTab.VARIATIONS;

  return (
    <div className={containerClass}>
      <div>
        <div className={headingClass}>
          <Heading level={4}>{job.name}</Heading>
          {!isValuations && (
            <JobStatusLine job={job} small={true} rightAlign={true}/>
          )}
          {isValuations && (
            <ValuationStatusLine job={job} small={true} rightAlign={true}/>
          )}
        </div>
        <Tabs
          left={leftTabs}
          right={rightTabs}
        />
      </div>
      <div>{currentTab}</div>
    </div>
  )
}