import {AnyRecord, CommandContainer, CommandErrors} from '../type';
import {ValidationError} from '../error';

export default function useCommandErrors<T extends AnyRecord>(
  container?: CommandContainer<any>,
): CommandErrors<T> {
  const errors = container ? container.errors : [];
  const systemError = errors
    .filter(error => false === error instanceof ValidationError)
    .reduce((message, error) => `${message}\n${error.message ? error.message : error}`, '')
    .slice(1);

  const validationErrors = errors
    .filter(error => true === error instanceof ValidationError) as ValidationError[]

  const field = validationErrors
    .reduce((fields, error: ValidationError) => {
      const {field, reason} = error;

      const response = fields[field]
        ? `${fields[field]}\n${reason}`
        : reason;

      return {
        ...fields,
        [field]: response
      }
    }, {} as T);

  const system = systemError || validationErrors.length
    ? `Failed: ${systemError || (validationErrors.length ? 'The form is invalid' : '')}`
    : '';

  return {
    system,
    field
  }
}