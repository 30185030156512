import {Fragment, h} from 'preact';
import Button from '../form/Button';
import Dialog from '../media/Dialog';
import {useState} from 'preact/hooks';
import {Job} from '../../model/Job';
import FinaliseCostingsForm from './FinaliseCostingsForm';
import {route} from 'preact-router';

interface Props {
  job: Job;
}

export default function FinaliseCostingsButton({job}: Props) {
  const [showDialog, toggleDialog] = useState(false);

  const dialog = {
    show: toggleDialog.bind(null, true),
    hide: toggleDialog.bind(null, false)
  };

  return (
    <Fragment>
      <Button small={true} disabled={!job.specified} primary={job.hasItems} onClick={dialog.show}>
        Generate Quote & Reports
      </Button>
      <Dialog show={showDialog} onRequestClose={dialog.hide}>
        <FinaliseCostingsForm
          job={job}
          onCancel={dialog.hide}
          onSuccess={() => {
            dialog.hide();
            route(`/job/${job.id}/imports`);
          }}
        />
      </Dialog>
    </Fragment>
  )
}