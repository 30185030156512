import {AnyCommand, DELETE_JOB_COMPANIES_COMMAND, DeleteJobCompaniesCommand} from '../type';
import App from '../app';

export default function deleteJobCompanies(payload: DeleteJobCompaniesCommand['payload']): AnyCommand {
  return {
    type: DELETE_JOB_COMPANIES_COMMAND,
    payload,
  }
}

export const useDeleteJobCompaniesCommand = App.wrapCommand(deleteJobCompanies);