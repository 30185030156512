import { Fragment, h } from 'preact';
import Button from '../form/Button';
import Dialog from '../media/Dialog';
import { useCallback, useState } from 'preact/hooks';
import { Job } from '../../model/Job';
import { IconType } from '../media/Icon';
import DeleteVariationItemsForm from './DeleteVariationItemsForm';
import JobVariationItem from '../../model/JobVariationItem';

interface Props {
  job: Job;
  variations: JobVariationItem[];
  onSuccess?(): any;
}

export default function DeleteVariationItemsButton({job, variations, onSuccess}: Props) {
  const {length} = variations;
  if (length === 0)
    return null;

  const [showDialog, toggleDialog] = useState(false);

  const dialog = {
    show: toggleDialog.bind(null, true),
    hide: toggleDialog.bind(null, false)
  };

  const click = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    if (!showDialog) toggleDialog(true);
  }, [toggleDialog, showDialog, job, variations]);

  return (
    <Fragment>
      <Button
        small={true}
        danger={true}
        onClick={click}
        onlyIcon={IconType.DELETE_ITEM}
        tooltip={`Delete${length > 1 ? ` ${length}`:''} Variation${length === 1 ? '':'s'}`}
      />
      <Dialog show={showDialog} onRequestClose={dialog.hide}>
        <DeleteVariationItemsForm
          job={job}
          variations={variations}
          onCancel={dialog.hide}
          onSuccess={() => {
            if (onSuccess) onSuccess();
            dialog.hide();
          }}
        />
      </Dialog>
    </Fragment>
  )
}