import MapValue from '../../../framework/value/MapValue';
import {ItemId, SectionId} from './App';
import NumberValue from '../../../framework/value/NumberValue';
import {Entity} from '../../../framework/value/Entity';
import StringValue from '../../../framework/value/StringValue';
import EntityMapValue from '../../../framework/value/EntityMapValue';
import JobSectionItem, {JobSectionItemJSON} from './JobSectionItem';
import BooleanValue from '../../../framework/value/BooleanValue';

export interface JobSectionJSON {
  id: string;
  details: JobSectionDetailsJSON;
  order: number;
  status: StatusJSON
  items: JobSectionItemsJSON;
}

export default class JobSection extends Entity<SectionId, {
  details: JobSectionDetails,
  order: Order,
  status: Status,
  items: Items,
}> {
  get id() {
    return this.value.id.id;
  }

  get items() {
    return this.value.items;
  }

  get name() {
    return this.value.details.value.name.value;
  }

  get order() {
    return this.value.order.value;
  }

  get fixedCost() {
    return this.items.all.filter(i => i.fixedCost).reduce((cost, item) => {
      return cost + item.cost;
    }, 0);
  }

  get cost() {
    return this.items.all.reduce((cost, item) => {
      return cost + item.cost;
    }, 0);
  }

  get profit() {
    return this.items.all.reduce((profit, item) => {
      return profit + item.profit;
    }, 0);
  }

  get charge() {
    return this.items.all.reduce((charge, item) => {
      return charge + item.charge;
    }, 0);
  }

  get markup() {
    if (this.cost === 0) return 0;

    return Math.round((this.charge / this.cost - 1) * 10000) / 100;
  }

  get maximumItemOrder() {
    return this.items.all.reduce((max, item) => {
      return Math.max(max, item.order);
    }, 0)
  }

  setName(name: string) {
    return this.extend({
      details: this.value.details.extend({
        name: new Name(name)
      })
    })
  }

  setOrder(order: number) {
    return this.extend({
      order: new Order(order)
    })
  }

  toJSON(): JobSectionJSON {
    return super.toJSON() as JobSectionJSON;
  }

  static fromJSON(json: Partial<JobSectionJSON> = {}) {
    return new JobSection({
      id: new SectionId(json.id || ''),
      details: JobSectionDetails.fromJSON(json.details),
      order: new Order(json.order || 0),
      status: Status.fromJSON(json.status),
      items: Items.fromJSON(json.items || {}),
    })
  }
}

export interface JobSectionDetailsJSON {
  name: string;
}

export class JobSectionDetails extends MapValue<{
  name: Name
}, JobSectionDetailsJSON> {
  static fromJSON(json: Partial<JobSectionDetailsJSON> = {}) {
    return new JobSectionDetails({
      name: new Name(json.name || '')
    })
  }
}

export class Name extends StringValue {}
export class Order extends NumberValue {}

export interface JobSectionItemsJSON {
  [id: string]: JobSectionItemJSON
}

export class Items extends EntityMapValue<ItemId, JobSectionItem> {
  get all(): JobSectionItem[] {
    return Object.keys(this.value).reduce((items, key) => {
      return [...items, this.value[key]];
    }, [] as JobSectionItem[]).sort((a, b) => a.order < b.order ? -1 : a.order > b.order ? 1 : 0);
  }

  get uncosted(): JobSectionItem[] {
    return this.all.filter(item => item.hasCost && item.charge === 0);
  }

  static fromJSON(json: JobSectionItemsJSON) {
    return new Items(Object.keys(json).reduce((items, id) => {
      return {
        ...items,
        [id]: JobSectionItem.fromJSON(json[id])
      };
    }, {} as Record<string, JobSectionItem>))
  }
}

interface StatusJSON {
  imported: boolean;
}

export class Status extends MapValue<{
  imported: BooleanValue;
}> {
  static fromJSON(json: Partial<StatusJSON> = {}) {
    return new Status({
      imported: new BooleanValue(!!json.imported)
    })
  }
}


