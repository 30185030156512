import StringValue from '../../../framework/value/StringValue';
import {ItemId, SectionId} from './App';
import {Entity} from '../../../framework/value/Entity';
import EnumValue from '../../../framework/value/EnumValue';
import ValuationSectionItem, {ValuationSectionItemJSON} from './ValuationSectionItem';
import NumberValue from '../../../framework/value/NumberValue';

export interface ValuationInvoiceItemJSON {
  id: string;
  item: ValuationSectionItemJSON;
  sectionId: string;
  sectionName: string;
  order: number;
}

export enum ValuationInvoiceItemType {
  IMPORTED = 'IMPORTED',
  VARIATION = 'VARIATION',
}

export class ItemType extends EnumValue(ValuationInvoiceItemType) {}

export default class ValuationInvoiceItem extends Entity<ItemId, {
  item: ValuationSectionItem;
  order: Order;
  sectionId: SectionId;
  sectionName: StringValue;
}> {
  get sectionName() {
    return this.value.sectionName.value
  }

  get sectionId() {
    return this.value.sectionId.value;
  }

  get order() {
    return this.value.order.value;
  }

  get billable() {
    return this.value.item.billable;
  }

  get item() {
    return this.value.item.item;
  }

  get description() {
    return this.value.item.description;
  }

  get charge() {
    return this.value.item.charge;
  }

  get newPayment() {
    return this.billable - this.invoiced;
  }

  get invoiced() {
    return this.value.item.invoiced;
  }

  get complete() {
    // return Math.round(Math.random() * 100);
    return this.value.item.complete;
  }

  get completeDifference() {
    return 100 * this.billable / this.charge;
  }

  get omitted() {
    return this.value.item.omitted;
  }

  setItem(item: ValuationSectionItem) {
    return this.extend({
      item
    })
  }

  toJSON(): ValuationInvoiceItemJSON {
    return super.toJSON() as ValuationInvoiceItemJSON;
  }

  static fromJSON(json: Partial<ValuationInvoiceItemJSON> = {}) {
    return new ValuationInvoiceItem({
      id: new ItemId(json.id || ''),
      item: ValuationSectionItem.fromJSON(json.item || {}),
      sectionId: json.sectionId
        ? new SectionId(json.sectionId)
        : SectionId.NULL,
      sectionName: new StringValue(json.sectionName || ''),
      order: new Order(json.order || 0)
    })
  }
}

export class Order extends NumberValue {}

