import {Fragment, h} from 'preact';
import Button from '../form/Button';
import Dialog from '../media/Dialog';
import {useCallback, useState} from 'preact/hooks';
import DeleteJobsForm from './DeleteJobsForm';
import {Job} from '../../model/Job';
import {IconType} from '../media/Icon';

interface Props {
  jobs: Job[];
  onSuccess?(): any;
}

export default function DeleteJobsButton({jobs, onSuccess}: Props) {
  const {length} = jobs
  if (length === 0)
    return null;

  const [showDialog, toggleDialog] = useState(false);

  const dialog = {
    show: toggleDialog.bind(null, true),
    hide: toggleDialog.bind(null, false)
  };

  const click = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    dialog.show();
  }, [dialog]);

  return (
    <Fragment>
      <Button
        small={true}
        onClick={click}
        onlyIcon={IconType.DELETE_ITEM}
        tooltip={`Delete${length > 1 ? ` ${length}`:''} Job${length === 1 ? '':'s'}`}
      />
      <Dialog show={showDialog} onRequestClose={dialog.hide}>
        {showDialog && (
          <DeleteJobsForm
            jobs={jobs}
            onCancel={dialog.hide}
            onSuccess={() => {
              dialog.hide();
              if (onSuccess) onSuccess();
            }}
          />
        )}
      </Dialog>
    </Fragment>
  )
}