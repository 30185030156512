import {default as JobSection, JobSectionJSON} from './JobSection';
import MapValue from '../../../framework/value/MapValue';
import {default as JobSectionItem, JobSectionItemJSON} from './JobSectionItem';

export interface JobCompanyItemJSON {
  item: JobSectionItemJSON;
  section: JobSectionJSON;
}

export class JobCompanyItem extends MapValue<{
  item: JobSectionItem
  section: JobSection
}> {
  get sectionId() {
    return this.value.section.id;
  }

  get section() {
    return this.value.section.name;
  }

  get itemId() {
    return this.value.item.id;
  }

  get item() {
    return this.value.item.item;
  }

  get description() {
    return this.value.item.description;
  }

  get quantity() {
    return this.value.item.quantity;
  }

  get rate() {
    return this.value.item.rate;
  }

  get cost() {
    return this.value.item.cost;
  }

  get markup() {
    return this.value.item.markup;
  }

  get actualMarkup() {
    return this.value.item.actualMarkup;
  }

  get profit() {
    return this.value.item.profit;
  }

  get charge() {
    return this.value.item.charge;
  }

  get type() {
    return this.value.item.type;
  }

  get hasCost() {
    return this.value.item.hasCost;
  }

  get trackCompany() {
    return this.value.item.trackCompany;
  }

  get fixedCost() {
    return this.value.item.fixedCost;
  }

  static fromJSON(json: Partial<JobCompanyItemJSON> = {}) {
    return new JobCompanyItem({
      item: JobSectionItem.fromJSON(json.item || {}),
      section: JobSection.fromJSON(json.section || {}),
    })
  }
}