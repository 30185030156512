import {Fragment, h} from 'preact';
import Button from '../form/Button';
import Dialog from '../media/Dialog';
import {useCallback, useState} from 'preact/hooks';
import {Job} from '../../model/Job';
import JobSection from '../../model/JobSection';
import DeleteSectionsForm from './DeleteSectionsForm';
import {IconType} from '../media/Icon';

interface Props {
  job: Job;
  sections: JobSection[];
  onSuccess?(): any;
}

export default function DeleteSectionsButton({job, sections, onSuccess}: Props) {
  const {length} = sections;
  if (length === 0)
    return null;

  const [showDialog, toggleDialog] = useState(false);

  const dialog = {
    show: toggleDialog.bind(null, true),
    hide: toggleDialog.bind(null, false)
  };

  const click = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    dialog.show();
  }, [dialog, job, sections]);

  return (
    <Fragment>
      <Button
        small={true}
        danger={true}
        onClick={click}
        onlyIcon={IconType.DELETE_ITEM}
        tooltip={`Delete${length > 1 ? ` ${length}`:''} Section${length === 1 ? '':'s'}`}
      />
      <Dialog show={showDialog} onRequestClose={dialog.hide}>
        <DeleteSectionsForm
          job={job}
          sections={sections}
          onCancel={dialog.hide}
          onSuccess={() => {
            toggleDialog(false);
            if (onSuccess) onSuccess();
          }}
        />
      </Dialog>
    </Fragment>
  )
}