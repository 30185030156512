import {
  AuthAppService,
  CLEAR_TEAM_COMMAND,
  ClearTeamCommand,
  COMPLETE_SIGN_IN_WITH_EMAIL_COMMAND,
  CompleteSignInWithEmailCommand,
  CREATE_TEAM_COMMAND,
  CreateTeamCommand,
  CURRENT_USER_SIGNED_OUT_EVENT,
  CurrentUserSignedOutEvent, INVITE_TEAM_USER_COMMAND, InviteTeamUserCommand, JOIN_TEAM_COMMAND, JoinTeamCommand,
  SELECT_TEAM_COMMAND,
  SelectTeamCommand,
  SIGN_OUT_COMMAND,
  START_SIGN_IN_WITH_EMAIL_COMMAND,
  START_SIGN_IN_WITH_GOOGLE_COMMAND,
  StartSignInWithEmailCommand,
  TEAM_CLEARED_EVENT, TEAM_JOINED_EVENT,
  TEAM_SELECTED_EVENT, TEAM_USER_INVITED_EVENT,
  TeamClearedEvent, TeamJoinedEvent,
  TeamSelectedEvent, TeamUserInvitedEvent,
  USER_SIGNED_IN_EVENT,
  USER_STATUS_UPDATED_EVENT,
  UserSignedInEvent,
  UserStatusUpdatedEvent
} from '../../type';
import {AsyncCommandResponse} from '../../../../framework/type';
import {State} from '../../model/State';
import {JobCostingsBaseService} from '../../services';
import config from '../../config';

export default class MemoryAuthService extends JobCostingsBaseService implements AuthAppService {
  constructor(
    protected readonly storage: Storage,
  ) {super()}

  async [SIGN_OUT_COMMAND](): AsyncCommandResponse {
    return this.todo()
  }

  async [START_SIGN_IN_WITH_GOOGLE_COMMAND](): AsyncCommandResponse {
    return this.todo()
  }

  async [START_SIGN_IN_WITH_EMAIL_COMMAND](_: any, command: StartSignInWithEmailCommand): AsyncCommandResponse {
    return this.todo()
  }

  async [COMPLETE_SIGN_IN_WITH_EMAIL_COMMAND](_: any, command: CompleteSignInWithEmailCommand): AsyncCommandResponse {
    return this.todo()
  }

  async [CREATE_TEAM_COMMAND](_: any, command: CreateTeamCommand): AsyncCommandResponse {
    return this.todo()
  }

  async [SELECT_TEAM_COMMAND](_: any, command: SelectTeamCommand): AsyncCommandResponse {
    return this.todo()
  }

  async [CLEAR_TEAM_COMMAND](_: any, command: ClearTeamCommand): AsyncCommandResponse {
    return this.todo()
  }

  async [INVITE_TEAM_USER_COMMAND](_: any, command: InviteTeamUserCommand): AsyncCommandResponse {
    return this.todo()
  }

  async [JOIN_TEAM_COMMAND](_: any, command: JoinTeamCommand): AsyncCommandResponse {
    return this.todo()
  }

  [USER_SIGNED_IN_EVENT](state: State, event: UserSignedInEvent): State {
    return state;
  }

  [CURRENT_USER_SIGNED_OUT_EVENT](state: State, event: CurrentUserSignedOutEvent): State {
    return state;
  }

  [USER_STATUS_UPDATED_EVENT](state: State, event: UserStatusUpdatedEvent): State {
    return state;
  }

  [TEAM_SELECTED_EVENT](state: State, event: TeamSelectedEvent): State {
    return state;
  }

  [TEAM_CLEARED_EVENT](state: State, event: TeamClearedEvent): State {
    return state;
  }

  [TEAM_JOINED_EVENT](state: State, event: TeamJoinedEvent): State {
    return state;
  }

  [TEAM_USER_INVITED_EVENT](state: State, event: TeamUserInvitedEvent): State {
    return state;
  }

  isSignInWithEmailLink(url: string) {
    return false;
  }

  getStoredEmail() {
    return this.storage.getItem(config.localStorage.authEmail);
  }

  getStoredTeam() {
    return this.storage.getItem(config.localStorage.authTeam);
  }
}
