import {EditorService} from '../../type';
import {JobCostingsAppService} from '../../services';
import {EditorSettings} from '../../component/editor/type';
import config from '../../config';

const EMPTY_SETTINGS: EditorSettings<any> = {columns: []};

export default class AppEditorService extends JobCostingsAppService implements EditorService {
  setInitialSettings(id: string, initialSettings: EditorSettings<any> = EMPTY_SETTINGS) {
    const key = this.editorKey(id);
    const existing = this.storage.getItem(key);
    let settings = initialSettings;

    if (existing) {
      settings = JSON.parse(existing) as EditorSettings<any>;
      const {columns} = settings;

      settings.columns = initialSettings.columns.map(column => {
        return columns.find(c => c.key === column.key) || column
      });
    }

    this.storage.setItem(key, JSON.stringify(settings));

    return settings;
  }

  getColumnSettings(id: string) {
    const key = this.editorKey(id);
    const existing = this.storage.getItem(key);

    if (!existing) {
      return null;
    }

    return JSON.parse(existing) as EditorSettings<any>;
  }

  setColumnWidth(id: string, key: string, rawWidth: number): void {
    const storageKey = this.editorKey(id);
    const settings = this.getColumnSettings(id);
    if (!settings) return;

    const width = Math.max(20, rawWidth);
    const columnIndex = settings.columns.findIndex(c => c.key === key);
    if (columnIndex == -1) {
      settings.columns.push({
        key,
        width
      });
    } else {
      settings.columns[columnIndex] = {
        ...settings.columns[columnIndex],
        width
      }
    }

    this.storage.setItem(storageKey, JSON.stringify(settings));
  }

  protected editorKey = (id: string) => {
    return `${config.localStorage.editorSettings}${id}`;
  }
}