import {
  CREATE_JOB_COMMAND,
  CreateJobCommand,
  DELETE_JOBS_COMMAND,
  DeleteJobsCommand,
  JOB_CREATED_EVENT,
  JOB_LOADED_EVENT,
  JOB_UPDATED_EVENT,
  JobCreatedEvent,
  JobLoadedEvent,
  JOBS_DELETED_EVENT,
  JOBS_LOADED_EVENT,
  JobsDeletedEvent,
  JobsLoadedEvent,
  JobUpdatedEvent,
  LOAD_JOB_COMMAND,
  LOAD_JOBS_COMMAND,
  LoadJobCommand,
  LoadJobsCommand,
  TeamService,
  UPDATE_JOB_COMMAND,
  UpdateJobCommand
} from '../../type';
import {JobCostingsBaseService} from '../../services';
import {State} from '../../model/State';
import {TodoError} from '../../../../framework/error';

export default class MemoryTeamService extends JobCostingsBaseService implements TeamService {
  constructor(
    protected readonly storage: Storage,
  ) {super()}

  async [CREATE_JOB_COMMAND](_: any, command: CreateJobCommand) {
    return this.todo();
  }

  async [LOAD_JOBS_COMMAND](state: State, command: LoadJobsCommand) {
    return this.todo();
  }

  async [LOAD_JOB_COMMAND](state: State, command: LoadJobCommand) {
    return this.todo();
  }

  async [UPDATE_JOB_COMMAND](_: any, command: UpdateJobCommand) {
    return this.todo();
  }

  async [DELETE_JOBS_COMMAND](_: any, command: DeleteJobsCommand) {
    return this.todo();
  }

  [JOB_UPDATED_EVENT](state: State, event: JobUpdatedEvent) {
    return state;
  }

  [JOB_CREATED_EVENT](state: State, event: JobCreatedEvent) {
    return state;
  }

  [JOBS_LOADED_EVENT](state: State, event: JobsLoadedEvent) {
    return state;
  }

  [JOB_LOADED_EVENT](state: State, event: JobLoadedEvent) {
    return state;
  }

  [JOBS_DELETED_EVENT](state: State, event: JobsDeletedEvent) {
    return state;
  }

  protected getTeamJobsRef(teamId: string) {
    throw new TodoError();
  }

  protected getTeamJobRef(teamId: string, jobId: string) {
    throw new TodoError();
  }

}