import {AnyCommand, CREATE_JOB_VARIATION_ITEM_COMMAND, CreateJobVariationItemCommand} from '../type';
import App from '../app';

export default function createJobVariationItem(payload: CreateJobVariationItemCommand['payload']): AnyCommand {
  return {
    type: CREATE_JOB_VARIATION_ITEM_COMMAND,
    payload,
  }
}

export const useCreateJobVariationItemCommand = App.wrapCommand(createJobVariationItem);