import App from '../app';
import {AnyCommand, START_SIGN_IN_WITH_GOOGLE_COMMAND} from '../type';

export default function startSignInWithGoogle(): AnyCommand {
  return {
    type: START_SIGN_IN_WITH_GOOGLE_COMMAND
  }
}

export const useStartSignInWithGoogleCommand =
  App.wrapCommand(startSignInWithGoogle);