import {COMMAND_UPDATED, CommandContainer, CommandNotifier, CommandStatus, SystemDispatch} from './type';

export default function createCommandNotifier<T>(
  container: CommandContainer<T>,
  dispatch: SystemDispatch
): CommandNotifier {
  const dispatchUpdate = (command: CommandContainer<T>) => {
    dispatch({
      type: COMMAND_UPDATED,
      payload: command
    })
  };

  return {
    initial: () => dispatchUpdate({
      ...container,
      timestamp: Date.now(),
      status: CommandStatus.INITIAL
    }),
    running: () => dispatchUpdate({
      ...container,
      timestamp: Date.now(),
      status: CommandStatus.RUNNING
    }),
    success: () => dispatchUpdate({
      ...container,
      timestamp: Date.now(),
      status: CommandStatus.SUCCESS
    }),
    fail: (errors: Error[] = []) => dispatchUpdate({
      ...container,
      timestamp: Date.now(),
      status: CommandStatus.FAIL,
      errors: [...errors, ...container.errors]
    })
  }
}