import {Fragment, h} from 'preact';
import Button from '../form/Button';
import Dialog from '../media/Dialog';
import {useState} from 'preact/hooks';
import UploadScheduleForm from './UploadScheduleForm';
import {Job} from '../../model/Job';

interface Props {
  job: Job;
}

export default function UploadScheduleButton({job}: Props) {
  const [showUploadFile, toggleUploadFile] = useState(false);
  const uploadFile = {
    show: toggleUploadFile.bind(null, true),
    hide: toggleUploadFile.bind(null, false)
  };

  const hasSchedule = !!job.assets.sourceSchedule;

  return (
    <Fragment>
      <Button primary={!job.hasItems} small={true} onClick={hasSchedule ? void 0 : uploadFile.show} disabled={hasSchedule}>
        Upload Schedule
      </Button>
      <Dialog show={showUploadFile} onRequestClose={uploadFile.hide}>
        <UploadScheduleForm
          jobId={job.id}
          onCancel={uploadFile.hide}
          onSuccess={() => {
            uploadFile.hide();
          }}
        />
      </Dialog>
    </Fragment>
  )
}