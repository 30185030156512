import {Entity} from '../../../framework/value/Entity';
import {InvoiceId, ItemId} from './App';
import EntityMapValue from '../../../framework/value/EntityMapValue';
import MapValue from '../../../framework/value/MapValue';
import StringValue from '../../../framework/value/StringValue';
import {default as ValuationInvoiceItem, ValuationInvoiceItemJSON} from './ValuationInvoiceItem';

export interface ValuationInvoiceJSON {
  id: string;
  details: Partial<ValuationInvoiceDetailsJSON>;
  items: Partial<ValuationInvoiceItemsJSON>;
}

export class ValuationInvoice extends Entity<InvoiceId, {
  details: ValuationInvoiceDetails;
  items: ValuationInvoiceItems;
}> {
  get ref() {
    return this.details.value.ref.value;
  }

  get details() {
    return this.value.details;
  }

  get charge() {
    return this.items.all.reduce((total, item) => {
      return total + item.charge;
    }, 0);
  }

  get invoiced() {
    return this.items.all.reduce((total, item) => {
      return total + item.invoiced;
    }, 0);
  }

  get billable() {
    return this.items.all.reduce((total, item) => {
      return total + item.billable;
    }, 0);
  }

  get items() {
    return this.value.items;
  }

  get maximumSectionOrder() {
    return this.items.all.reduce((max, section) => {
      return Math.max(max, section.order);
    }, 0)
  }

  toJSON(): ValuationInvoiceJSON {
    return super.toJSON() as ValuationInvoiceJSON;
  }

  fromInvoicableItems() {

  }

  static fromJSON(data: Partial<ValuationInvoiceJSON>) {
    return new ValuationInvoice({
      id: new InvoiceId(data.id || ''),
      details: ValuationInvoiceDetails.fromJSON(data.details || {}),
      items: ValuationInvoiceItems.fromJSON(data.items || {}),
    });
  }
}

export class Variations extends EntityMapValue<InvoiceId, ValuationInvoice> {}

export interface ValuationInvoiceItemsJSON extends Record<string, ValuationInvoiceItemJSON> {};

export class ValuationInvoiceItems extends EntityMapValue<ItemId, ValuationInvoiceItem> {
  get all(): ValuationInvoiceItem[] {
    return Object.keys(this.value).reduce((sections, key) => {
      return [...sections, this.value[key]];
    }, [] as ValuationInvoiceItem[]).sort((a, b) => a.order < b.order ? -1 : a.order > b.order ? 1 : 0);
  }

  toJSON(): ValuationInvoiceItemsJSON {
    return super.toJSON() as ValuationInvoiceItemsJSON;
  }

  static fromJSON(json: Partial<ValuationInvoiceItemsJSON>) {
    return new ValuationInvoiceItems(Object.keys(json).reduce((items, id) => {
      return {
        ...items,
        [id]: ValuationInvoiceItem.fromJSON(json[id])
      };
    }, {} as Record<string, ValuationInvoiceItem>))
  }
}

export interface ValuationInvoiceDetailsJSON {
  ref: string;
}

export class ValuationInvoiceDetails extends MapValue<{
  ref: StringValue;
}, ValuationInvoiceDetailsJSON> {
  toJSON(): ValuationInvoiceDetailsJSON {
    return super.toJSON() as ValuationInvoiceDetailsJSON;
  }

  static fromJSON(json: Partial<ValuationInvoiceDetailsJSON> = {}): ValuationInvoiceDetails {
    return new ValuationInvoiceDetails({
      ref: new StringValue(json.ref || ''),
    })
  }
}