import {AnyCommand, DELETE_JOB_SECTIONS_COMMAND, DeleteJobSectionsCommand} from '../type';
import App from '../app';

export default function deleteJobSections(payload: DeleteJobSectionsCommand['payload']): AnyCommand {
  return {
    type: DELETE_JOB_SECTIONS_COMMAND,
    payload,
  }
}

export const useDeleteJobSectionsCommand = App.wrapCommand(deleteJobSections);