import {
  Collection,
  LOAD_USER_TEAMS_COMMAND,
  LoadUserTeamsCommand,
  USER_STATUS_UPDATED_EVENT,
  USER_TEAM_JOINED_EVENT,
  USER_TEAMS_LOADED_EVENT,
  UserService,
  UserStatusUpdatedEvent,
  UserTeamJoinedEvent,
  UserTeamsLoadedEvent
} from '../../type';
import {UserStatus, UserTeam, UserTeamJSON, UserTeams} from '../../model/User';
import {UserId} from '../../model/App';
import {State} from '../../model/State';
import userTeamsLoaded from '../../event/userTeamsLoaded';
import {JobCostingsAppService} from '../../services';

export default class AppUserService extends JobCostingsAppService implements UserService {
  async [LOAD_USER_TEAMS_COMMAND](state: State, command: LoadUserTeamsCommand) {
    return this.execute([
      new UserId(command.payload.userId)
    ],async (userId) => {
      const id = userId.id;
      const data = await this.getUserTeamsRef(id).get();
      const teams = data.docs.map(doc => {
        // TODO validate here that its correct
        return doc.data() || null;
      }).filter(Boolean) as UserTeamJSON[];

      this.dispatch(userTeamsLoaded(id, teams));
    });
  }

  [USER_STATUS_UPDATED_EVENT](state: State, event: UserStatusUpdatedEvent): State {
    const user = state.user.get(event.payload.userId);
    if (!user) return state;

    return state.extend({
      user: state.user.replace(user.extend({
        status: UserStatus.create(event.payload.loading)
      }))
    })
  }

  [USER_TEAMS_LOADED_EVENT](state: State, event: UserTeamsLoadedEvent): State {
    const user = state.user.get(event.payload.userId);
    if (!user) return state;

    return state.extend({
      user: state.user.replace(user.extend({
        teams: UserTeams.fromJSON(event.payload.teams)
      }))
    })
  }

  [USER_TEAM_JOINED_EVENT](state: State, event: UserTeamJoinedEvent): State {
    const user = state.user.get(event.payload.userId);
    if (!user) return state;

    return state.extend({
      user: state.user.replace(user.extend({
        teams: user.teams.replace<UserTeam, UserTeams>(UserTeam.fromJSON(event.payload.team))
      }))
    })
  }

  protected getUserDocRef(userId: string) {
    return this.app.firestore().collection(Collection.USERS).doc(userId);
  }

  protected getUserTeamsRef(userId: string) {
    return this.getUserDocRef(userId).collection(Collection.TEAMS);
  }

  protected getUserEventsRef(userId: string) {
    return this.getUserDocRef(userId).collection(Collection.EVENTS);
  }
}
