import {h, Ref} from 'preact';
import {HTMLAttributes} from '../../../../framework/type';
import App from '../../app';
import {percent, rem} from 'csx';
import classes from '../../../../framework/classes';

interface Props extends HTMLAttributes {
  unstyled?: boolean;
  small?: boolean;
  innerRef?: Ref<HTMLSelectElement>;
}

export default function Select({innerRef, small, unstyled, className, ...props}: Props) {
  const componentClass = App.useStyle(({theme}) => ({
    position: 'relative',
    $nest: {
      '&::after': {
        pointerEvents: 'none',
        display: 'block',
        content: '""',
        width: 0,
        height: 0,
        border: `0.25rem solid transparent`,
        borderTopColor: theme.color.black.toString(),
        fontSize: rem(0.75),
        lineHeight: rem(1.5),
        position: 'absolute',
        top: rem(0.5),
        right: rem(0.5)
      },
      '& > select': {
        '-webkit-appearance': 'none',
        '-moz-appearance': 'none',
        appearance: 'none',
        fontFamily: theme.font.text,
      }
    }
  }));

  const styledClass = App.useStyle(({theme}) => ({
    $nest: {
      '&::after': {
        pointerEvents: 'none',
        display: 'block',
        content: '""',
        width: 0,
        height: 0,
        border: `0.25rem solid transparent`,
        borderTopColor: theme.color.black.toString(),
        fontSize: rem(0.75),
        lineHeight: small
          ? rem(1.5)
          : rem(2.25),
        position: 'absolute',
        top: small
          ? rem(0.825)
          : rem(0.5),
        right: rem(0.5)
      },
      '& > select': {
        background: theme.color.white.toString(),
        border: `0.125rem solid ${theme.color.black.toString()}`,
        fontSize: rem(1),
        lineHeight: small
          ? rem(1.5)
          : rem(2.25),
        padding: '0 1.5rem 0 0.5rem',
        width: percent(100),
        minWidth: rem(5),
        maxWidth: percent(100),
        height: small
          ? rem(2)
          : rem(2.5),
        fontFamily: theme.font.text,
        ...theme.component.Input,
      }
    }
  }));

  return (
    <span className={classes(componentClass, !unstyled && styledClass, className)}>
      <select {...props} ref={innerRef}/>
    </span>
  )
}