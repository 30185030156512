import {h, Ref} from 'preact';
import {HTMLAttributes} from '../../../../framework/type';
import App from '../../app';
import {percent, rem} from 'csx';
import classes from '../../../../framework/classes';
import Input from './Input';

interface Props extends HTMLAttributes {
  innerRef?: Ref<HTMLInputElement>;
}

export default function DateInput({innerRef, ...props}: Props) {
  return (
    <Input {...props} innerRef={innerRef} type="date" placeholder="yyyy-mm-dd"/>
  )
}