export class TodoError extends Error {
  constructor(public readonly details?: string) {
    super('ToDo' + (details ? ': ' + details : ''));
  }

  toString() {
    return super.message;
  }
}

export class SystemError extends Error {
  constructor(
    public readonly message: string,
    public readonly details?: any,
  ) {
    super(message);
  }
}

export class ValidationError extends Error {
  constructor(
    public readonly field: string,
    public readonly reason: string,
  ) {
    super(ValidationError.message(field, reason));
  }

  static extend = (field: string, error: ValidationError) => {
    return new ValidationError(field + '.' + error.field, error.reason);
  };

  static message = (field: string, reason: string) =>
    `[${field}] ${reason}`
}