import { h } from 'preact';
import renderCurrency from '../../helper/renderCurrency';
import renderPercentage from '../../helper/renderPercentage';
import FieldGroup from '../form/FieldGroup';
import Field from '../form/Field';
import { em, percent, rem } from 'csx';
import App from '../../app';
import ActiveIndicator from '../media/ActiveIndicator';
import { Job } from '../../model/Job';
import FinancialIndicator from '../media/FinancialIndicator';
import useTimedToggle from '../../effect/useTimedToggle';

interface Props {
  job: Job;
  small?: boolean;
  rightAlign?: boolean;
}

export default function ValuationStatusLine({job, small, rightAlign = false}: Props) {
  const {valuation} = job;

  const className = App.useStyle(({theme}) => ({
    flex: 1,
    position: 'relative',
    fontSize: small ? em(0.75) : em(1),
    $nest: {
      '& > *': {
        lineHeight: small ? em(1.25) : rem(1.5),
        minWidth: small ? rem(5) : rem(8),
        width: percent(25)
      },
      '& label': {
        minWidth: '0 !important'
      }
    }
  }), []);

  const variationLabel = renderCurrency(valuation.billable);
  const retentionLabel = `Retention ${renderPercentage(valuation.retention)}`;

  const toggle = useTimedToggle();

  if (!toggle) return (
    <FieldGroup forceHorizontal={true} className={className}>
      <Field
        label="Original"
        title={renderCurrency(valuation.charge)}
        numeric={rightAlign}
        hideExtra={true}
        truncate={true}
        children={renderCurrency(valuation.charge)}
      />
      <Field
        label="Revised"
        title={renderCurrency(valuation.newCharge)}
        numeric={rightAlign}
        hideExtra={true}
        truncate={true}
        children={renderCurrency(valuation.newCharge)}
      />
      <Field
        label="Omit"
        title={renderCurrency(valuation.omitted)}
        numeric={rightAlign}
        hideExtra={true}
        truncate={true}
        children={renderCurrency(valuation.omitted)}
      />
      <Field
        label="Additional"
        title={renderCurrency(valuation.additional)}
        numeric={rightAlign}
        hideExtra={true}
        truncate={true}
        children={renderCurrency(valuation.additional)}
      />
      <Field
        label="Under Sum"
        title={renderCurrency(valuation.underSum)}
        numeric={rightAlign}
        hideExtra={true}
        truncate={true}
        children={(
          <FinancialIndicator>
            {renderCurrency(valuation.underSum)}
          </FinancialIndicator>
        )}
      />
      <Field
        label="Interim Payment"
        title={renderCurrency(valuation.newPayment)}
        numeric={rightAlign}
        hideExtra={true}
        truncate={true}
        children={(
          <FinancialIndicator strong={true}>
            {renderCurrency(valuation.newPayment)}
          </FinancialIndicator>
        )}
      />
    </FieldGroup>
  )

  return (
    <FieldGroup forceHorizontal={true} className={className}>
      <Field
        label="Original"
        title={renderCurrency(valuation.charge)}
        numeric={rightAlign}
        hideExtra={true}
        truncate={true}
        children={renderCurrency(valuation.charge)}
      />
      <Field
        label="Revised"
        title={renderCurrency(valuation.newCharge)}
        numeric={rightAlign}
        hideExtra={true}
        truncate={true}
        children={renderCurrency(valuation.newCharge)}
      />
      <Field
        label="Completed"
        title={variationLabel}
        numeric={rightAlign}
        hideExtra={true}
        truncate={true}
        children={variationLabel}
      />
      <Field
        label={retentionLabel}
        title={renderCurrency(valuation.retained)}
        numeric={rightAlign}
        hideExtra={true}
        truncate={true}
        children={renderCurrency(valuation.retained)}
      />
      <Field
        label="Invoiced"
        title={renderCurrency(valuation.invoiced)}
        numeric={rightAlign}
        hideExtra={true}
        truncate={true}
        children={renderCurrency(valuation.invoiced)}
      />
      <Field
        label="Interim Payment"
        title={renderCurrency(valuation.newPayment)}
        numeric={rightAlign}
        hideExtra={true}
        truncate={true}
        children={(
          <FinancialIndicator strong={true}>
            {renderCurrency(valuation.newPayment)}
          </FinancialIndicator>
        )}
      />
    </FieldGroup>
  )
}