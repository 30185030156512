import {AnyCommand, REORDER_JOB_VARIATION_ITEM_COMMAND, ReorderJobVariationItemCommand} from '../type';
import App from '../app';

export default function reorderJobVariationItem(payload: ReorderJobVariationItemCommand['payload']): AnyCommand {
  return {
    type: REORDER_JOB_VARIATION_ITEM_COMMAND,
    payload,
  }
}

export const useReorderJobVariationItemCommand = App.wrapCommand(reorderJobVariationItem);