const region = 'europe-west2' as 'europe-west2';

const config = {
  firebase: {
    region,
    emulator: {
      firestore: {
        host: 'localhost:8080',
        ssl: false,
      },
      functions: 'http://localhost:5001',
    },
    config: {
      apiKey: 'AIzaSyDGzd2vhRAaRU5K3i8f4X_EIrXD_v7lcbc',
      authDomain: 'auth.jobcostings.app',
      databaseURL: 'https://jobcostings-app.firebaseio.com',
      projectId: 'jobcostings-app',
      storageBucket: 'gs://jobcostings-app.appspot.com',
      messagingSenderId: '765852250122',
      appId: '1:765852250122:web:532edb6af27ebc874e2ff3'
    }
  },
  localStorage: {
    authEmail: 'auth_email',
    authTeam: 'auth_team',
    editorSettings: 'editor_',
  }
};

export type Config = typeof config;

if (typeof window === 'undefined' || !window.location.href.startsWith('https://jobcostings.app')) {
  config.firebase.config = {
    apiKey: 'AIzaSyC8vr2FWWFB926IlCB8PrA_IFSFqW0lHY0',
    authDomain: 'jobcostings-qa.firebaseapp.com',
    databaseURL: 'https://jobcostings-qa.firebaseio.com',
    projectId: 'jobcostings-qa',
    storageBucket: 'jobcostings-qa.appspot.com',
    messagingSenderId: '1076330004908',
    appId: '1:1076330004908:web:1dfe47711c2ec3b3443a09'
  };
}

export default config;