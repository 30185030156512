import {color, percent, rem} from 'csx';
import createMediaQuery from '../../../framework/createMediaQuery';
import {Value} from '../../../framework/value/Value';

const theme = {
  color: {
    white: color('#fff'),
    background: color('#fbfbfb'),
    gray: color('#bfbfbf'),
    black: color('#0f0f0f'),
    red: color('#ff6666'),
    yellow: color('#ffe766'),
    blue: color('#66b3ff'),
    green: color('#82F013'),
  },
  font: {
    text: '"Inter", "Helvetica Neue", arial, sans-serif',
    heading: '"Inter", "Helvetica Neue", arial, sans-serif',
  },
  media: {
    mobileOnly: createMediaQuery({
      maxWidth: '639px'
    }),
    tablet: createMediaQuery({
      minWidth: '640px'
    })
  },
  transition: {
    navBar: `250ms cubic-bezier(0.23, 1, 0.32, 1)`,
    dialog: `117ms cubic-bezier(0.23, 1, 0.32, 1)`,
    icon: `350ms cubic-bezier(0.23, 1, 0.32, 1)`,
  },
  component: {
    Button: {
      borderWidth: rem(0.125),
      borderRadius: rem(0.125),
    },
    EditorTable: {

    },
    Field: {

    },
    FieldGroup: {
      minWidth: rem(12),
      maxWidth: rem(48)
    },
    Input: {
      borderRadius: 0,
      maxWidth: rem(20),
    },
    TextArea: {
      borderRadius: 0,
      maxWidth: rem(20),
    },
    Paragraph: {
      margin: `${rem(1.25)} 0`
    }
  },
  layer: {
    tooltip: 99,
  }
};

export default class Theme extends Value<typeof theme> {
  get color() {
    return this.value.color;
  }
  get font() {
    return this.value.font;
  }
  get media() {
    return this.value.media;
  }
  get component() {
    return this.value.component;
  }
  get transition() {
    return this.value.transition;
  }
  get layer() {
    return this.value.layer;
  }
}

export const DefaultTheme = new Theme(theme);