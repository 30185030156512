import {Value} from './Value';
import {ValidationError} from '../error';

export default class MapValue<T extends Record<string, Value<any>> = Record<string, Value<any>>, JSON extends Record<string, any> = {}> extends Value<T> {

  static MISSING_FIELD = 'This field is missing';

  validate() {
    const namespace = ValidationError.extend
      .bind(null, this.constructor.name);

    return Object.keys(this.value).reduce((validations, key) => {
      const property = this.value[key];

      if (!property) return [
        ...validations,
        new ValidationError(key, MapValue.MISSING_FIELD)
      ];

      return [
        ...validations,
        ...property.validate(),
      ]
    }, [] as ValidationError[]).map(namespace)
  }

  extend(props: Partial<T>) {
    return new (this.constructor as any)({
      ...this.value,
      ...props,
    }) as this;
  }

  merge<T extends MapValue<any>>(value: T) {
    return new (this.constructor as any)({
      ...value.value,
      ...this.value,
    }) as this;
  }

  toJSON(): JSON {
    return Object.keys(this.value).reduce((out, key) => ({
      ...out,
      [key]: this.value[key].toJSON()
    }), {}) as JSON
  }
}