import {AnyCommand, REORDER_JOB_SECTION_COMMAND, ReorderJobSectionCommand} from '../type';
import App from '../app';

export default function reorderJobSection(payload: ReorderJobSectionCommand['payload']): AnyCommand {
  return {
    type: REORDER_JOB_SECTION_COMMAND,
    payload,
  }
}

export const useReorderJobSectionCommand = App.wrapCommand(reorderJobSection);