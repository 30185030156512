import {AnyCommand, UPDATE_JOB_COMMAND, UpdateJobCommand} from '../type';
import App from '../app';

export default function updateJob(payload: UpdateJobCommand['payload']): AnyCommand {
  return {
    type: UPDATE_JOB_COMMAND,
    payload,
  }
}

export const useUpdateJobCommand = App.wrapCommand(updateJob);