import {AnyCommand, CREATE_JOB_COMPANY_COMMAND, CreateJobCompanyCommand} from '../type';
import App from '../app';

export default function createJobCompany(payload: CreateJobCompanyCommand['payload']): AnyCommand {
  return {
    type: CREATE_JOB_COMPANY_COMMAND,
    payload,
  }
}

export const useCreateJobCompanyCommand = App.wrapCommand(createJobCompany);