import {Fragment, h} from 'preact';
import Button from '../form/Button';
import Dialog from '../media/Dialog';
import {useCallback, useState} from 'preact/hooks';
import CreateTeamForm from './CreateTeamForm';

interface Props {
  onSuccess?(): any;
}

export default function CreateTeamButton({onSuccess}: Props) {
  const [showDialog, toggleDialog] = useState(false);

  const dialog = {
    show: toggleDialog.bind(null, true),
    hide: toggleDialog.bind(null, false)
  };

  const click = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    dialog.show();
  }, [dialog]);

  return (
    <Fragment>
      <Button primary={true} onClick={click}>
        Create Team
      </Button>
      <Dialog show={showDialog} onRequestClose={dialog.hide}>
        <CreateTeamForm
          onCancel={dialog.hide}
          onSuccess={() => {
            dialog.hide();
            if (onSuccess) onSuccess();
          }}
        />
      </Dialog>
    </Fragment>
  )
}