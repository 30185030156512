import {AnyCommand, ALLOCATE_JOB_COMPANY_COMMAND, AllocateJobCompanyCommand} from '../type';
import App from '../app';

export default function allocateJobCompany(payload: AllocateJobCompanyCommand['payload']): AnyCommand {
  return {
    type: ALLOCATE_JOB_COMPANY_COMMAND,
    payload,
  }
}

export const useAllocateJobCompanyCommand = App.wrapCommand(allocateJobCompany);