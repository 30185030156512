import {Fragment, h} from 'preact';
import Button from '../form/Button';
import Dialog from '../media/Dialog';
import {useCallback, useState} from 'preact/hooks';
import UpdateJobForm from './UpdateJobForm';
import {Job} from '../../model/Job';
import {IconType} from '../media/Icon';

interface Props {
  job: Job;
  onSuccess?(): any;
}

export default function UpdateJobButton({job, onSuccess}: Props) {
  const [showDialog, toggleDialog] = useState(false);

  const dialog = {
    show: toggleDialog.bind(null, true),
    hide: toggleDialog.bind(null, false)
  };

  const click = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    dialog.show();
  }, [dialog]);

  return (
    <Fragment>
      <Button
        small={true}
        onClick={click}
        tooltip="Edit Job"
        onlyIcon={IconType.UPDATE_ITEM}
      />
      <Dialog show={showDialog} onRequestClose={dialog.hide}>
        <UpdateJobForm
          job={job}
          onCancel={dialog.hide}
          onSuccess={() => {
            dialog.hide();
            if (onSuccess) onSuccess();
          }}
        />
      </Dialog>
    </Fragment>
  )
}