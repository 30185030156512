import {h} from 'preact';
import {HTMLAttributes} from '../../../../framework/type';
import App from '../../app';
import {em, rem} from 'csx';
import classes from '../../../../framework/classes';

interface Props extends HTMLAttributes {
  small?: boolean;
  large?: boolean;
}

export default function Paragraph({small, large, ...props}: Props) {
  const className = App.useStyle(({theme}) => ({
    ...theme.component.Paragraph,
    fontSize: small
      ? rem(0.75)
      : large
        ? rem(1.125)
        : rem(1),
    lineHeight: em(1.25)
  }));

  return (
    <p {...props} className={classes(className, props.className)} />
  )
}