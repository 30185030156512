import { ItemId, SectionId } from '../model/App';
import { useCallback } from 'preact/hooks';
import { Valuation } from '../model/Valuation';
import currentTeam from '../query/currentTeam';
import { SaveHandler } from '../component/editor/type';
import { default as ValuationSectionItem } from '../model/ValuationSectionItem';
import { CommandContainer } from '../../../framework/type';
import { useUpdateJobSectionItemCommand } from '../command/updateJobSectionItem';
import { useUpdateJobVariationItemCommand } from '../command/updateJobVariationItem';
import latestCommand from '../../../framework/latestCommand';
import { Job } from '../model/Job';
import { JobSectionItemDetailsJSON } from '../model/JobSectionItem';
import { JobVariationItemDetailsJSON } from '../model/JobVariationItem';

export default function saveValuationCallback(
  job: Job,
  valuation: Valuation
): [CommandContainer<any>, SaveHandler<ValuationSectionItem>] {
  const team = currentTeam();

  const [updateItemCommand, updateJobSectionItem] = useUpdateJobSectionItemCommand();
  const [updateVariationCommand, updateJobVariationItem] = useUpdateJobVariationItemCommand();

  const commandContainer = latestCommand([updateItemCommand, updateVariationCommand]);

  const saveItem = useCallback((page: number, target: null | ValuationSectionItem, key: keyof ValuationSectionItem, value: string | null) => {
    if (!valuation || !team || !target) return [];
    const sections = valuation.sections.all;
    const isAdditional = page === sections.length;

    const section = sections[page];
    if (!isAdditional && !section) return [new Error('Cannot find the section to save to')];

    const item = isAdditional
      ? valuation.variations.items.get(target.id)!
      : section.items.get(target.id)!;

    if (!item) return [new Error(`Cannot find the ${isAdditional ? 'variation' : 'item'} to save to`)];

    let nextValue: any;

    function getValue(): ValuationSectionItem {
      switch (key) {
        case 'complete':
          nextValue = !value ? 0 : parseFloat(value);
          return item.setComplete(nextValue);

        case 'omit':
          nextValue = value === 'true';
          return item.setOmit(nextValue);

        default:
          return item;
      }
    }

    try {
      const next = getValue();
      const errors = next.validate();

      if (errors.length === 0 && next !== item) {
        const allDetails = next.details.toJSON();

        let details: Partial<JobSectionItemDetailsJSON & JobVariationItemDetailsJSON> = {};

        if (key === 'complete') {
          details['complete'] = allDetails.complete;
        }

        if (key === 'omit') {
          details['omit'] = allDetails.omit;
        }

        if (isAdditional) {
          updateJobVariationItem({
            variationId: item.id,
            details,
            jobId: job.id,
            teamId: team.id,
          })
        } else {
          updateJobSectionItem({
            jobId: job.id,
            teamId: team.id,
            sectionId: section.id,
            itemId: item.id,
            details,
          })
        }
      }

      return errors;
    } catch (e) {
      return [e];
    }
  }, [valuation, updateItemCommand, updateVariationCommand]);

  return [
    commandContainer,
    saveItem,
  ]
}
