import {Fragment, h} from 'preact';
import {Valuation} from '../../model/Valuation';
import ValuationInvoicesTable from './InvoicesTable';
import {ValuationInvoice} from '../../model/ValuationInvoice';

interface Props {
  valuation: Valuation;
}

export default function ValuationInvoicesList({valuation}: Props) {
  if (!valuation) return null;

  const allValuationInvoices: ValuationInvoice[] = valuation.invoices.all;

  return (
    <Fragment>
      {allValuationInvoices.length > 0 && (
        <ValuationInvoicesTable
          valuation={valuation}
          expandToFill={true}
          invoices={allValuationInvoices}
        />
      )}
    </Fragment>
  )
}