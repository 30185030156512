import {AnyCommand, REORDER_JOB_SECTION_ITEM_COMMAND, ReorderJobSectionItemCommand} from '../type';
import App from '../app';

export default function reorderJobSectionItem(payload: ReorderJobSectionItemCommand['payload']): AnyCommand {
  return {
    type: REORDER_JOB_SECTION_ITEM_COMMAND,
    payload,
  }
}

export const useReorderJobSectionItemCommand = App.wrapCommand(reorderJobSectionItem);