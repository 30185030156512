import {AnyCommand, IMPORT_JOB_COMMAND, ImportJobCommand} from '../type';
import App from '../app';

export default function importJob(payload: ImportJobCommand['payload']): AnyCommand {
  return {
    type: IMPORT_JOB_COMMAND,
    payload,
  }
}

export const useImportJobCommand = App.wrapCommand(importJob);