import {StateContext, StyleSheetHook} from './type';
import {useEffect} from 'preact/hooks';

export default function createStyleSheetHook<State>(
  stateContext: StateContext<State>
): StyleSheetHook<State> {
  return function(href, ...dependencies)  {
    useEffect(() => {
      const getElement = () =>
        document.querySelector(`link[href="${href}"]`);

      if (!getElement()) {
        const element = document.createElement('link') as HTMLLinkElement;
        element.rel = 'stylesheet';
        element.href = href;
        document.head.appendChild(element);
      }

      return () => {
        const element = getElement();
        if (element && element.parentElement) {
          element.parentElement.removeChild(element);
        }
      }
    }, dependencies);
  };
}