import {AnyCommand, UPDATE_JOB_SECTION_COMMAND, UpdateJobSectionCommand} from '../type';
import App from '../app';

export default function updateJobSection(payload: UpdateJobSectionCommand['payload']): AnyCommand {
  return {
    type: UPDATE_JOB_SECTION_COMMAND,
    payload,
  }
}

export const useUpdateJobSectionCommand = App.wrapCommand(updateJobSection);