import {Value} from './Value';
import {JobAssetId, JobAssetType} from '../../app/JobCostings/model/JobAssets';

export default function EnumValue<T>(impl: T): new (props: string) => Value<string> {
  const KEYS = Object.keys(impl);
  const INVALID_TYPE = 'Invalid type: ';

  return class extends Value<string> {
    static KEYS = KEYS;
    static INVALID_TYPE = INVALID_TYPE;

    validate() {
      return this.collect([
        ...super.validate(),
        !(KEYS.includes(this.value)) && this.error(INVALID_TYPE + this.value),
      ])
    }

    static UNKNOWN = new JobAssetId(JobAssetType.UNKNOWN)
  }
}