import {Fragment, h} from 'preact';
import JobImport, {
  JobImportDetection,
  JobImportFormat,
  JobImportItemType,
  JobImportItemTypeChoices,
  JobImportItemTypeLabel,
  JobImportMappingJSON
} from '../../model/JobImport';
import {useCallback, useEffect, useState} from 'preact/hooks';
import {EditorColumn, EditorPage} from '../editor/type';
import getLetterFromIndex from '../../helper/getLetterFromIndex';
import TableGroup from '../editor/TableGroup';
import FieldGroup from '../form/FieldGroup';
import Field from '../form/Field';
import Select from '../form/Select';
import ButtonGroup from '../form/ButtonGroup';
import Button from '../form/Button';
import Paragraph from '../media/Paragraph';
import {rem} from 'csx';

interface Props {
  data: JobImport | null
  existingMapping: JobImportMappingJSON | null;
  onMapping(mapping: JobImportMappingJSON): void;
}

type CellRecord = Record<string, string>
type ImportTableColumn = EditorColumn<CellRecord>;
type ImportTablePage = EditorPage<CellRecord>;

const MAGIC_ACTION = '_';
const MAGIC_SOURCE = '_source';

export default function ImportTable({data, existingMapping, onMapping}: Props) {
  const [loaded, setLoaded] = useState(false);
  const [format, setFormat] = useState<string>(JobImportFormat.ONE_TAB);
  const [hasQuantity, setHasQuantity] = useState<boolean>(false);
  const [willReindex, setWillReindex] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [itemColumn, setItemColumn] = useState(0);
  const [nameColumn, setNameColumn] = useState(1);
  const [costColumn, setCostColumn] = useState(hasQuantity ? 3 : 2);
  const [quantityColumn, setQuantityColumn] = useState(2);
  const [columns, setColumns] = useState<ImportTableColumn[]>([]);
  const [pages, setPages] = useState<ImportTablePage[]>([]);
  const [allPages, setAllPages] = useState<ImportTablePage[]>([]);
  const [detections, setDetections] = useState<JobImportDetection[]>([])

  const proxySetDetections = useCallback((rawDetections: JobImportDetection[]) => {
    const detections = format === JobImportFormat.ONE_TAB
      ? rawDetections.filter(d => d.page === page)
      : rawDetections;

    setDetections(detections);
    onMapping({
      detections,
      columns: {
        cost: costColumn,
        name: nameColumn,
        item: itemColumn,
        quantity: quantityColumn,
      },
      format: format as JobImportFormat,
      hasQuantity,
      willReindex,
    });
  }, [setDetections, page, onMapping, format, costColumn, nameColumn, itemColumn, hasQuantity, willReindex]);

  const selectAction = useCallback((page: number, item: any, key: any, value: string | null) => {
    if (key !== MAGIC_ACTION) return [];

    const sourceRow = parseInt(item[MAGIC_SOURCE]);
    const type = value as JobImportItemType;

    proxySetDetections(detections.map(detection => {
      if (detection.sourceRow !== sourceRow)
        return detection;

      return {
        ...detection,
        type
      };
    }));

    return [];
  }, [detections, proxySetDetections]);

  useEffect(() => {
    if (existingMapping) {
      setFormat(existingMapping.format);
      setItemColumn(existingMapping.columns.item);
      setCostColumn(existingMapping.columns.cost);
      setNameColumn(existingMapping.columns.name);
      setDetections(existingMapping.detections);
    }
  }, [existingMapping]);

  const id = `import_table_${data?data.id:''}`;

  useEffect(() => {
    if (!data) {
      setColumns([]);
      setPages([]);
      setAllPages([]);
      setDetections([]);
      setLoaded(false);
      return;
    }

    const allPages = data.pages.all.map(page => {
      return {
        name: page.name,
        items: page.items.all.reduce((items, item) => {
          return [
            ...items,
            {
              ...item.columns.collapse(),
              [MAGIC_ACTION]: JobImportItemType.NOTED,
              [MAGIC_SOURCE]: item.value.sourceRow.value.toString()
            },
          ]
        }, [] as CellRecord[])
      } as ImportTablePage
    });

    detections.forEach(detection => {
      allPages[detection.page].items[detection.row][MAGIC_ACTION] = detection.type
    });

    const columnArray = new Array(data.maxColumns).fill(1);

    const getMaxTextLength = (column: number) => {
      return data.pages.all.reduce((max, page) => {
        return Math.max(max, page.items.all.reduce((max, item) => {
          const value = item.columns.collapse()[column];
          return value && value.length
            ? Math.max(max, value.length)
            : max;
        }, 0));
      }, 0);
    };

    const statusSelector = (item: any) => {
      return (JobImportItemTypeLabel as any)[item[MAGIC_ACTION]]
    };

    const nextColumns: ImportTableColumn[] = [
      {
        key: MAGIC_ACTION,
        label: 'Import',
        width: 80,
        editable: () => true,
        renderCell: statusSelector,
        choices: () => JobImportItemTypeChoices
      },
      ...columnArray.map((_, index) => {
        return {
          key: index.toString(),
          label: getLetterFromIndex(index),
          width: Math.max(Math.min(getMaxTextLength(index) * 10, 540), 40),
        } as ImportTableColumn
      })
    ];

    const nextPages = format === JobImportFormat.ONE_TAB
      ? [allPages[page]]
      : allPages;

    setColumns(nextColumns);
    setAllPages(allPages);
    setPages(nextPages);
    setLoaded(true);
  }, [data, page, format, detections]);

  if (!loaded) return null;

  const detectItems = useCallback((event?: MouseEvent) => {
    if (event) {
      event.preventDefault();
    }

    if (!data) return;

    const detections = allPages.reduce((detected, page, pageIndex) => {
      const items = page.items.map((columns, rowIndex) => {
        const detect = (type: JobImportItemType) => ({
          page: pageIndex,
          row: rowIndex,
          sourceRow: parseInt(columns[MAGIC_SOURCE]),
          column: itemColumn + 1,
          type,
        } as JobImportDetection);

        const item = columns[itemColumn];
        const name = columns[nameColumn];
        const cost = columns[costColumn];

        const isItem = /^\d+(\.\d+)+\.?/.test(item);
        const isHeadingItem = /^(\d+|\d+\.|\d+\.0+)$/.test(item);
        const hasName = !!name;
        const hasCost = !!cost && !isNaN(parseFloat(cost));

        switch (true) {
          case (isHeadingItem && hasName):
            return detect(JobImportItemType.SECTION_HEADING);

          case (isItem && hasName && hasCost):
            return detect(JobImportItemType.ITEM_FIXED);

          case (isItem && hasName):
            return detect(JobImportItemType.ITEM_QUOTE);

          default:
            return detect(JobImportItemType.NOTED);
        }
      });

      return [
        ...detected,
        ...items as JobImportDetection[]
      ]
    }, [] as JobImportDetection[]);

    proxySetDetections(detections);
  }, [proxySetDetections, itemColumn, nameColumn, costColumn]);

  const clickCell = (page: number, column: number, row: number) => {
    const match = detections.findIndex(d => d.page === page && d.row === row);
  };

  const selector = (value: number, onInput: (event: Event) => any) => (
    <Select value={value.toString()} onInput={onInput}>
      <option value="-1">Select...</option>
      {columns.slice(1).map(column => (
        <option value={column.key}>{column.label}</option>
      ))}
    </Select>
  );

  return (
    <Fragment>
      <Paragraph>
        Please select the format for the import below, and the columns that correspond to the item, description and cost.
      </Paragraph>
      <FieldGroup horizontal={true}>
        <Field label="Import format" fill={false}>
          <Select value={format} onInput={e => setFormat((e.target as HTMLSelectElement).value)}>
            <option value={JobImportFormat.ONE_TAB}>One Tab (Section-per-Page)</option>
            <option value={JobImportFormat.MULTIPLE_TABS}>Multiple Tabs (Section-per-Tab)</option>
          </Select>
        </Field>
        <Field label="Import quantity?" fill={false}>
          <Select value={hasQuantity.toString()} onInput={e => {
            const nextValue = (e.target as HTMLSelectElement).value === 'true';
            setHasQuantity(nextValue);
            setQuantityColumn(nameColumn + 1);
            setCostColumn(nextValue ? costColumn + 1 : costColumn - 1);
          }}>
            <option value={false.toString()}>No</option>
            <option value={true.toString()}>Yes</option>
          </Select>
        </Field>
        {format === JobImportFormat.ONE_TAB && (
          <Field label="Import tab" fill={false}>
            <Select value={page.toString()} onInput={e => setPage(parseInt((e.target as HTMLSelectElement).value))}>
              {allPages.map((page, index) => (
                <option value={index}>{page.name}</option>
              ))}
            </Select>
          </Field>
        )}
        {format === JobImportFormat.MULTIPLE_TABS && (
          <Field label="Reindex sections?" fill={false}>
            <Select value={willReindex.toString()} onInput={e => {
              const nextValue = (e.target as HTMLSelectElement).value === 'true';
              setWillReindex(nextValue);
            }}>
              <option value={false.toString()}>No</option>
              <option value={true.toString()}>Yes</option>
            </Select>
          </Field>
        )}
      </FieldGroup>
      <FieldGroup horizontal={true}>
        <Field label="Item" fill={false}>
          {selector(itemColumn, event => setItemColumn(parseInt((event.target as HTMLSelectElement).value)))}
        </Field>
        <Field label="Description" fill={false}>
          {selector(nameColumn, event => setNameColumn(parseInt((event.target as HTMLSelectElement).value)))}
        </Field>
        {hasQuantity && (
          <Field label="Quantity" fill={false}>
            {selector(quantityColumn, event => setQuantityColumn(parseInt((event.target as HTMLSelectElement).value)))}
          </Field>
        )}
        <Field label="Cost" fill={false}>
          {selector(costColumn, event => setCostColumn(parseInt((event.target as HTMLSelectElement).value)))}
        </Field>
        <Field>
          <ButtonGroup>
            <Button primary={!detections.length} onClick={detectItems}>
              {!detections.length ? 'Detect Items' : 'Re-detect'}
            </Button>
          </ButtonGroup>
        </Field>
      </FieldGroup>
      {!!detections.length && (
        <TableGroup
          id={id}
          columns={columns}
          pages={pages}
          onSave={selectAction}
          onClickPageCell={clickCell}
          allowFullScreen={true}
          allowShrinkRows={true}
          maxHeight={rem(26.25)}
          // highlights={detections}
        />
      )}
    </Fragment>
  )
}