import * as firebase from 'firebase/app';
import createBrowserEntrypoint from '../../framework/createBrowserEntrypoint';
import {h} from 'preact';
import JobCostingsApp from './component/JobCostingsApp';
import {AnyCommand, AppServices} from './type';
import AppAuthService from './service/app/AuthService';
import App from './app';
import AppUserService from './service/app/UserService';
import bootstrapServices from '../../framework/bootstrapServices';
import {SystemError} from '../../framework/error';
import {ProxyCommandContainer, ProxyCommandHandler} from '../../framework/type';
import config from './config';
import AppEventService from './service/app/EventService';
import AppTeamService from './service/app/TeamService';
import AppJobService from './service/app/JobService';
import AppEditorService from './service/app/EditorService';
import AppJobSectionImportService from './service/app/JobSectionImportService';
import AppJobCompanyService from './service/app/JobCompanyService';

export default createBrowserEntrypoint(async () => {
  await import('./dependencies');

  const isDev = process.env.NODE_ENV === 'development';
  const app = firebase.initializeApp(config.firebase.config);
  const storage = window.localStorage;

  if (isDev) {
    app.functions().useFunctionsEmulator(config.firebase.emulator.functions);
  }

  const currentPromises: Array<Promise<any>> = [];

  const dispatch: ProxyCommandHandler<AnyCommand> = async (command: AnyCommand) => {
    const base = isDev
      ? app.functions()
      : app.functions(config.firebase.region);

    const dispatch = base.httpsCallable('dispatch');
    const container: ProxyCommandContainer<AnyCommand> = {
      // TODO teamId inject from store
      userId: '',
      teamId: '',
      command,
    };

    await Promise.all(currentPromises);
    const nextPromise = dispatch(container);
    currentPromises.push(nextPromise);

    const {data} = await nextPromise;
    return data;
  };

  const onLoad = () => {
    completing();
    setTimeout(complete, 500)
  };

  const services: AppServices = bootstrapServices<AnyCommand, AppServices>({
    auth: new AppAuthService(storage, app, onLoad),
    event: new AppEventService(storage, app),
    editor: new AppEditorService(storage, app),
    job: new AppJobService(storage, app),
    jobCompany: new AppJobCompanyService(storage, app),
    jobSectionImport: new AppJobSectionImportService(storage, app),
    user: new AppUserService(storage, app),
    team: new AppTeamService(storage, app),
  }, [dispatch]);

  function completing() {
    const load = document.getElementById('load');
    if (load) {
      load.classList.add('completing');
    }
  }

  function complete() {
    const load = document.getElementById('load');
    if (load) {
      load.classList.add('complete');
      load.classList.remove('completing');
    }
  }

  return (
    <App.Container services={services}>
      <JobCostingsApp />
    </App.Container>
  )
});