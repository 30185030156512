import {AnyCommand, LOAD_JOB_IMPORT_COMMAND, LoadJobImportCommand} from '../type';
import App from '../app';

export default function loadJobImport(payload: LoadJobImportCommand['payload']): AnyCommand {
  return {
    type: LOAD_JOB_IMPORT_COMMAND,
    payload,
  }
}

export const useLoadJobImportCommand = App.wrapCommand(loadJobImport);