import App from '../app';
import { AnyCommand } from '../type';
import { CommandContainer } from '../../../framework/type';
import { useEffect } from 'preact/hooks';
import currentSession from './currentSession';
import { TeamJobs } from '../model/Team';
import { useLoadArchiveCommand } from '../command/loadArchive';

export default function currentTeamArchive(): [TeamJobs, CommandContainer<AnyCommand>] {
  const {teamId} = currentSession();
  const [command, getArchive] = useLoadArchiveCommand();

  const id = teamId.id;

  useEffect(() => {
    if (!id) return;
    getArchive({teamId: id});
  }, [id]);

  const state = App.useQuery(state => {
    const team = state.team.get(id);

    if (!team) return TeamJobs.EMPTY;

    return team.archive;
  }, [id]);

  return [state, command];
}