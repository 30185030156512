export default function coerceDate(raw: string) {
  if (raw.length === 0)
    return raw;

  const parts = raw.split(/[:/-]/g);
  const year = getYear(parts[2]);
  const month = getMonth(parts[1]);
  const date = getDate(parts[0]);

  console.log(`${year}-${month}-${date}`);

  return `${year}-${month}-${date}`;
}

function getYear(raw: string | undefined) {
  const currentYear = new Date().getUTCFullYear();

  if (typeof raw === 'undefined' || (raw.length !== 4 && raw.length !== 2))
    return currentYear;

  if (raw.length < 4)
    return currentYear.toString().slice(0, 2) + raw;

  return raw;
}

function getMonth(raw: string | undefined) {
  const currentMonth = new Date().getUTCMonth() + 1;

  if (typeof raw === 'undefined' || raw.length > 2)
    return currentMonth;

  if (raw.length < 2)
    return 0 + raw;

  return raw;
}

function getDate(raw: string | undefined) {
  const currentDate = new Date().getUTCDate();

  if (typeof raw === 'undefined' || raw.length > 2)
    return currentDate;

  if (raw.length < 2)
    return 0 + raw;

  return raw;
}