import ApplicationService from '../../framework/ApplicationService';
import {State} from './model/State';
import {AsyncCommandResponse} from '../../framework/type';
import {AnyCommand, AnyEvent, AppServiceCommandHandler, AppServiceEventHandler, Collection} from './type';
import * as firebase from 'firebase/app';
import pushId from '../../framework/vendor/pushId';

export class JobCostingsBaseService extends ApplicationService<State, AnyCommand, AnyEvent> {
  async onCommand(state: State, command: AnyCommand): AsyncCommandResponse {
    const method = (this as any)[command.type] as AppServiceCommandHandler;
    if (method) {
      console.log(`execute ${this.constructor.name}:${command.type}`);
    }

    return method
      ? method.call(this, state, command)
      : this.valid;
  }

  onEvent(state: State, event: AnyEvent): State {
    const method = (this as any)[event.type] as AppServiceEventHandler;
    return method
      ? method.call(this, state, event)
      : state;
  }

  protected jobFileStoragePath(teamId: string, jobId: string, fileId: string, name: string) {
    return `${Collection.TEAMS}/${teamId}/${Collection.JOBS}/${jobId}/files/${fileId}-${name}`
  }

  protected jobScheduleTransformedStoragePath(teamId: string, jobId: string, fileId: string) {
    return `${Collection.TEAMS}/${teamId}/${Collection.JOBS}/${jobId}/schedule/transformed/${fileId}`
  }

  protected jobScheduleMappingStoragePath(teamId: string, jobId: string, fileId: string) {
    return `${Collection.TEAMS}/${teamId}/${Collection.JOBS}/${jobId}/schedule/mapping/${fileId}`
  }

  protected jobScheduleOutputStoragePath(teamId: string, jobId: string, fileId: string) {
    return `${Collection.TEAMS}/${teamId}/${Collection.JOBS}/${jobId}/schedule/output/${fileId}`
  }

  protected jobQuoteStoragePath(teamId: string, jobId: string, fileId: string) {
    return `${Collection.TEAMS}/${teamId}/${Collection.JOBS}/${jobId}/schedule/quote/${fileId}`
  }
  protected jobSourceQuoteStoragePath(teamId: string, jobId: string, fileId: string) {
    return `${Collection.TEAMS}/${teamId}/${Collection.JOBS}/${jobId}/schedule/source_quote/${fileId}`
  }

  protected jobCostingsStoragePath(teamId: string, jobId: string, fileId: string) {
    return `${Collection.TEAMS}/${teamId}/${Collection.JOBS}/${jobId}/schedule/costings/${fileId}`
  }

  protected jobCompaniesStoragePath(teamId: string, jobId: string, fileId: string) {
    return `${Collection.TEAMS}/${teamId}/${Collection.JOBS}/${jobId}/schedule/company/${fileId}`
  }

  protected jobsValuationStoragePath(teamId: string, jobId: string, fileId: string) {
    return `${Collection.TEAMS}/${teamId}/${Collection.JOBS}/${jobId}/valuation/${fileId}`
  }

  protected nextId() {
    return pushId();
  }
}

export class JobCostingsAppService extends JobCostingsBaseService {
  constructor(
    protected readonly storage: Storage,
    protected readonly app: firebase.app.App
  ) {
    super();
  }

  public async downloadJSON<T>(path: string): Promise<T> {
    const url = await await this.app.storage().ref(path).getDownloadURL();
    const resp = await fetch(url);
    const json = await resp.json();

    return json as T;
  }

  // TODO extract these into interface
  protected get users() {
    return this.app.firestore().collection(Collection.USERS);
  }

  protected userEvents(userId: string) {
    return this.users.doc(userId).collection(Collection.EVENTS)
  }

  protected userTeams(userId: string) {
    return this.users.doc(userId).collection(Collection.TEAMS)
  }

  protected get teams() {
    return this.app.firestore().collection(Collection.TEAMS);
  }

  protected teamEvents(teamId: string) {
    return this.teams.doc(teamId).collection(Collection.EVENTS)
  }

  protected teamJobs(teamId: string) {
    return this.teams.doc(teamId).collection(Collection.JOBS)
  }

  protected teamJob(teamId: string, jobId: string) {
    return this.teamJobs(teamId).doc(jobId);
  }

  protected teamJobFiles(teamId: string, jobId: string) {
    return this.teamJob(teamId, jobId).collection(Collection.FILES);
  }

  protected teamJobFile(teamId: string, jobId: string, fileId: string) {
    return this.teamJobFiles(teamId, jobId).doc(fileId);
  }

  protected teamJobImports(teamId: string, jobId: string) {
    return this.teamJob(teamId, jobId).collection(Collection.IMPORTS);
  }

  protected teamJobImport(teamId: string, jobId: string, fileId: string) {
    return this.teamJobImports(teamId, jobId).doc(fileId);
  }

  protected teamJobSections(teamId: string, jobId: string) {
    return this.teamJob(teamId, jobId).collection(Collection.SECTIONS)
  }

  protected teamJobSection(teamId: string, jobId: string, sectionId: string) {
    return this.teamJobSections(teamId, jobId).doc(sectionId);
  }

  protected teamJobSectionItems(teamId: string, jobId: string, sectionId: string) {
    return this.teamJobSection(teamId, jobId, sectionId).collection(Collection.ITEMS);
  }
}