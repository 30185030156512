import {AnyCommand, UPDATE_JOB_VARIATION_ITEM_COMMAND, UpdateJobVariationItemCommand} from '../type';
import App from '../app';

export default function updateJobVariationItem(payload: UpdateJobVariationItemCommand['payload']): AnyCommand {
  return {
    type: UPDATE_JOB_VARIATION_ITEM_COMMAND,
    payload,
  }
}

export const useUpdateJobVariationItemCommand = App.wrapCommand(updateJobVariationItem);