import { AnyCommand, LOAD_ARCHIVE_COMMAND, LoadArchiveCommand } from '../type';
import App from '../app';

export default function loadArchive(payload: LoadArchiveCommand['payload']): AnyCommand {
  return {
    type: LOAD_ARCHIVE_COMMAND,
    payload,
  }
}

export const useLoadArchiveCommand = App.wrapCommand(loadArchive);