import { AnyCommand, ARCHIVE_JOBS_COMMAND, ArchiveJobsCommand } from '../type';
import App from '../app';

export default function archiveJobs(payload: ArchiveJobsCommand['payload']): AnyCommand {
  return {
    type: ARCHIVE_JOBS_COMMAND,
    payload,
  }
}

export const useArchiveJobsCommand = App.wrapCommand(archiveJobs);