import {h} from 'preact';
import TableGroup from '../editor/TableGroup';
import {ActionRenderer, EditorColumn, EditorColumnType, EditorPage, SaveHandler} from '../editor/type';
import {Job} from '../../model/Job';
import renderCurrency from '../../helper/renderCurrency';
import renderPercentage from '../../helper/renderPercentage';
import {CommandContainer} from '../../../../framework/type';
import {useEffect, useState} from 'preact/hooks';
import {CompanyId} from '../../model/App';
import {JobCompanyItem} from '../../model/JobCompanyItem';
import {JobSectionItemType} from '../../model/JobSectionItem';
import {JobCompany} from '../../model/JobCompany';
import PassiveIndicator from '../media/PassiveIndicator';
import ActiveIndicator from '../media/ActiveIndicator';

interface Props {
  job: Job;
  onSave?: SaveHandler<JobCompanyItem>;
  currentCommand?: CommandContainer<any>;
  children?: ActionRenderer<JobCompanyItem>;
}

export default function CompanySectionsTable({children, job, onSave, currentCommand}: Props) {
  const [companies, setCompanies] = useState<Array<JobCompany>>([]);
  const [pages, setPages] = useState<Array<EditorPage<JobCompanyItem>>>([])

  useEffect(() => {
    const companies = [
      ...job.companies.all,
      JobCompany.fromJSON({}),
    ];

    const companyItems = companies.map(c => job.companyItems(new CompanyId(c.id)));
    const pages = companies.map((company, index) => {
      const items = companyItems[index];

      if (company.id === '') return {
        name: 'Unallocated',
        items,
      };

      return {
        name: company.name,
        subtitle: `Default ${renderPercentage(company.markup)} markup`,
        items
      }
    }).filter(p => p.items.length > 0);

    setPages(pages);
    setCompanies(companies);
  }, [setPages, setCompanies, job]);


  const renderHeader = (page: number, items: JobCompanyItem[], key: keyof JobCompanyItem) => {
    if (!job) return '';
    const company: JobCompany = companies[page];
    if (!company) return '';

    switch (key) {
      case 'cost':
        return renderCurrency(company.cost(items));
      case 'profit':
        return renderCurrency(company.profit(items));
      case 'charge':
        return renderCurrency(company.charge(items));
      case 'markup':
        return renderPercentage(company.actualMarkup(items));

      default:
        return '';
    }
  };

  const columns: Array<EditorColumn<JobCompanyItem>> = [
    {
      key: 'section',
      label: 'Section',
      width: 80,
      renderHeader,
      renderCell: (item) => {
        return item.section;
      }
    },
    {
      key: 'item',
      label: 'Item',
      width: 48,
      renderHeader,
      renderCell: (item) => {
        return item.item;
      }
    },
    {
      key: 'description',
      label: 'Description',
      width: 320,
      renderCell: (item) => {
        return item.description;
      },
      renderHeader,
    },
    {
      key: 'type',
      label: 'Type',
      width: 80,
      renderCell: (item) => {
        return item.type
      },
      stringValue: (item) => {
        return item.type
      },
      renderHeader,
    },
    {
      key: 'quantity',
      label: 'Qty',
      width: 48,
      numeric: true,
      type: EditorColumnType.CURRENCY,
      renderCell: (item) => {
        return item.quantity || '';
      },
      renderHeader,
    },
    {
      key: 'rate',
      label: 'Unit Rate',
      width: 80,
      numeric: true,
      type: EditorColumnType.CURRENCY,
      renderCell: (item) => {
        return item.rate ? renderCurrency(item.rate) : '';
      },
      renderHeader,
    },
    {
      key: 'markup',
      label: 'Markup',
      width: 80,
      numeric: true,
      type: EditorColumnType.PERCENTAGE,
      renderCell: (item) => {
        if (item.trackCompany) return (
          <PassiveIndicator>
            {renderPercentage(item.actualMarkup)}
          </PassiveIndicator>
        );

        return item.actualMarkup ? renderPercentage(item.actualMarkup) : '';
      },
      stringValue: (item: JobCompanyItem) => {
        return item.trackCompany ? '' : item.markup.toString();
      },
      renderHeader,
    },
    {
      key: 'cost',
      label: 'Cost',
      width: 80,
      numeric: true,
      type: EditorColumnType.CURRENCY,
      renderCell: (item) => {
        return item.cost ? renderCurrency(item.cost) : '';
      },
      renderHeader,
    },
    {
      key: 'profit',
      label: 'Profit',
      width: 80,
      numeric: true,
      type: EditorColumnType.CURRENCY,
      renderCell: (item) => {
        return item.profit ? renderCurrency(item.profit) : '';
      },
      renderHeader,
    },
    {
      key: 'charge',
      label: 'Charge',
      width: 80,
      numeric: true,
      type: EditorColumnType.CURRENCY,
      renderCell: (item) => {
        return item.type === JobSectionItemType.COST || item.fixedCost
          ? item.charge
            ? renderCurrency(item.charge)
            : ''
          : (<ActiveIndicator>{item.type}</ActiveIndicator>);
      },
      renderHeader,
    }
  ];

  let filteredColumns = columns;

  if (!job || !job.details.showQuantity) {
    filteredColumns = filteredColumns.filter(c => c.key !== 'quantity')
  }

  return (
    <TableGroup
      id="company_sections_table"
      columns={filteredColumns}
      pages={pages}
      onSave={onSave}
      children={children}
      currentCommand={currentCommand}
      allowFullScreen={true}
      allowShrinkRows={true}
    />
  )
}