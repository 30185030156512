import {ValidationResult} from '../type';
import {ValidationError} from '../error';

export class Value<T> {
  public getValidators(): Array<(this: Value<T>) => ValidationResult> {
    return [];
  };

  public constructor(
    public readonly value: T
  ) {
  }

  isEqual(other: Value<T>) {
    return this.toJSON() === other.toJSON();
  }

  toString(): string {
    return String(this.value);
  }

  toJSON(): any {
    return this.value;
  }

  validate(): ValidationResult {
    if (!this.getValidators().length)
      return Value.VALID;

    return this.getValidators().reduce((result, validator) => ([
      ...result,
      ...validator.apply(this),
    ]), [] as ValidationResult);
  };

  static readonly VALID = [];

  protected collect(errors: Array<ValidationError | false | null | undefined>) {
    const filtered = errors.filter(Boolean) as ValidationError[];
    return filtered.length ? filtered : Value.VALID;
  }

  protected error(message: string) {
    return new ValidationError(this.constructor.name, message);
  }
}