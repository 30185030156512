import {h} from 'preact';
import App from '../../app';
import {useRef} from 'preact/hooks';
import classes from '../../../../framework/classes';

interface Props {
  show: boolean;
  onRequestClose(): void;
  children: any;
}

export default function Dialog({show, children, onRequestClose}: Props) {
  const className = App.useStyle(({theme}) => ({
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 42,
    padding: `1.25rem 1rem 1rem`,
    overflow: 'auto',
    userSelect: show
      ? 'initial'
      : 'none',
    pointerEvents: show
      ? 'initial'
      : 'none',
    background: show
      ? theme.color.black.fade(0.9).toString()
      : theme.color.black.fade(0).toString(),
    transition: `all ${theme.transition.dialog}`,
    $nest: {
      '& > div': {
        padding: `1.25rem 1rem`,
        display: 'inline-block',
        position: 'relative',
        maxWidth: `calc(100% - 2rem)`,
        maxHeight: `calc(100% - 2rem)`,
        overflow: 'auto',
        background: theme.color.background.toString(),
      }
    },
  }), [show]);

  const tabletClassName = App.useStyle(({theme}) => ({
    ...theme.media.tablet({
      padding: '2.5rem 2rem 2rem !important',
      $nest: {
        '& > div': {
          padding: '2.5rem 2rem 2rem !important'
        }
      }
    })
  }), [show]);

  const blanketRef = useRef<HTMLDivElement>();
  const closeIfBlanket = (event: MouseEvent) => {
    if (blanketRef.current === event.target) {
      event.stopPropagation();
      onRequestClose();
    }
  };

  return (
    <div className={classes(className, tabletClassName)} onClick={closeIfBlanket} ref={blanketRef}>
      {show && (
        <div onClick={event => event.stopPropagation()}>
          {children}
        </div>
      )}
    </div>
  )
}