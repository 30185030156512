export default function binaryChoice() {
  return [
    {
      id: 'false',
      value: 'No',
    },
    {
      id: 'true',
      value: 'Yes'
    }
  ]
}