import {h} from 'preact';
import {
  ApplicationComponent,
  DispatchContext,
  ServiceMap,
  ServicesContext,
  StateContext,
  SystemStateContext
} from './type';
import useEventServiceReducer from './hook/useEventServiceReducer';
import useCommandServiceReducer from './hook/useCommandServiceReducer';

export default function createContainer<State, CommandTypes, EventTypes, Services extends ServiceMap<State, CommandTypes, EventTypes>>(
  contexts: {
    services: ServicesContext<State, CommandTypes, EventTypes, Services>,
    dispatch: DispatchContext<CommandTypes>,
    state: StateContext<State>,
    systemState: SystemStateContext<CommandTypes>,
  },
  defaults: {
    services: Services,
    state: State,
  }
): ApplicationComponent<State, CommandTypes, EventTypes, Services> {
  const {
    services: ServicesContext,
    dispatch: DispatchContext,
    state: StateContext,
    systemState: SystemStateContext,
  } = contexts;

  return function (props) {
    const services = props.services || defaults.services;
    const initialState = props.initialState || defaults.state;
    const [appState] = useEventServiceReducer(services, initialState);
    const [commandState, commandDispatch] = useCommandServiceReducer<CommandTypes>(services);

    return (
      <SystemStateContext.Provider value={commandState}>
        <StateContext.Provider value={appState}>
          <ServicesContext.Provider value={services}>
            <DispatchContext.Provider value={commandDispatch}>
                {props.children}
            </DispatchContext.Provider>
          </ServicesContext.Provider>
        </StateContext.Provider>
      </SystemStateContext.Provider>
    )
  }
}