import {CommandContainer, CommandHook, IdGenerator} from './type';

export default function createWrapCommand<CommandTypes>(
  nextId: IdGenerator,
  useCommand: CommandHook<CommandTypes>
) {
  return <Args extends Array<any>>(command: (...args: Args) => CommandTypes) => {
    return (): [CommandContainer<CommandTypes>, (...args: Args) => void] => {
      const [container, dispatch] = useCommand();

      const wrappedDispatch = (...args: Args) => {
        dispatch(command.apply(null, args))
      };

      return [container, wrappedDispatch];
    }
  }
}