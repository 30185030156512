import {useCallback, useEffect, useState} from 'preact/hooks';

export default function() {
  const [offline, setOffline] = useState(!navigator.onLine);

  useEffect(() => {
    const toggleOffline = setOffline.bind(null, true);
    const toggleOnline = setOffline.bind(null, false);

    window.addEventListener('online', toggleOnline);
    window.addEventListener('offline', toggleOffline);

    return () => {
      window.removeEventListener('online', toggleOnline);
      window.removeEventListener('offline', toggleOffline);
    }
  }, [setOffline]);

  const fetchIp = useCallback(async () => {
    try {
      await fetch('https://ifconfig.co/json', {
        mode: 'no-cors'
      });
      setOffline(false);
    } catch (e) {
      setOffline(true);
    }
  }, [setOffline]);

  useEffect(() => {
    const interval = setInterval(fetchIp, 10000);
    return () => clearInterval(interval);
  }, [])

  return offline;
}