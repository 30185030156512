import {Fragment, h} from 'preact';
import {useClearTeamCommand} from '../../command/clearTeam';
import Button from '../form/Button';
import ButtonGroup from '../form/ButtonGroup';
import MemberList from '../team/MemberList';
import currentTeam from '../../query/currentTeam';

export default function TeamRoute() {
  const team = currentTeam();
  const [,clearTeam] = useClearTeamCommand();

  if (!team) return null;

  return (
    <Fragment>
      <MemberList team={team} />
      <ButtonGroup>
        <Button small={true} onClick={clearTeam}>Sign out from Team</Button>
      </ButtonGroup>
    </Fragment>
  )
}