import { h } from 'preact';
import ButtonGroup from '../form/ButtonGroup';
import { Job } from '../../model/Job';
import JobVariationItem from '../../model/JobVariationItem';
import VariationsTable from './VariationsTable';
import saveJobVariationItemCallback from '../../effect/saveJobVariationItemCallback';
import DeleteVariationItemsButton from './DeleteVariationItemsButton';
import MoveVariationItemUpButton from './MoveVariationItemUpButton';
import MoveVariationItemDownButton from './MoveVariationItemDownButton';
import AllocateVariationsButton from './AllocateVariationsButton';

interface Props {
  job: Job;
}

export default function VariationList({job}: Props) {
  if (!job) return null;
  const variations: JobVariationItem[] = job.variations.all;
  const [currentItemCommand, saveItem] = saveJobVariationItemCallback(job);

  return (
    <VariationsTable
      items={variations}
      job={job}
      onSave={saveItem}
      expandToFill={true}
      currentCommand={currentItemCommand}
      children={(items, selection, setSelection) => {
        const item = items[0];

        return (
          <ButtonGroup>
            {!!item && (
              <MoveVariationItemUpButton
                job={job}
                variation={item}
                onRunning={() => setSelection({...selection!, row: selection!.row - 1})}
              />
            )}
            {!!item && (
              <MoveVariationItemDownButton
                job={job}
                variation={item}
                onRunning={() => setSelection({...selection!, row: selection!.row + 1})}
              />
            )}
            {!!item && (
              <AllocateVariationsButton
                job={job}
                variations={variations}
              />
            )}
            <DeleteVariationItemsButton
              job={job}
              variations={items}
              onSuccess={() => setSelection(null)}
            />
          </ButtonGroup>
        )
      }}
    />
  )
}