import {
  AnyCommand,
  CREATE_TEAM_COMMAND,
  CreateTeamCommand,
  LOAD_USER_TEAMS_COMMAND,
  SELECT_TEAM_COMMAND
} from '../type';
import App from '../app';

export default function selectTeam(teamId: string): AnyCommand {
  return {
    type: SELECT_TEAM_COMMAND,
    payload: {
      teamId,
    },
  }
}

export const useSelectTeamCommand = App.wrapCommand(selectTeam);