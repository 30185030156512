import {Fragment, h} from 'preact';
import ValuationSectionsTable from './SectionsTable';
import ValuationSection from '../../model/ValuationSection';
import {Valuation} from '../../model/Valuation';
import {SaveHandler} from '../editor/type';
import ValuationSectionItem from '../../model/ValuationSectionItem';
import {IconType} from '../media/Icon';
import ButtonGroup from '../form/ButtonGroup';
import Button from '../form/Button';
import renderCurrency from '../../helper/renderCurrency';

interface Props {
  valuation: Valuation;
  onSave: SaveHandler<ValuationSectionItem>;
  onCreateInvoice(): any;
}

export default function ValuationSectionList({valuation, onSave, onCreateInvoice}: Props) {
  if (!valuation) return null;

  const allValuationSections: ValuationSection[] = valuation.allSections;
  const {newPayment} = valuation;

  return (
    <Fragment>
      {allValuationSections.length > 0 && (
        <ValuationSectionsTable
          sections={allValuationSections}
          valuation={valuation}
          expandToFill={true}
          onSave={onSave}
          children={(items, selection, setSelection, pages) => {
            const additionalPage = pages.length - 1;

            return (
              <ButtonGroup>
                <Button
                  small={true}
                  tooltip="Create Additional"
                  onlyIcon={IconType.CREATE_ITEM}
                  onClick={() => setSelection({page: additionalPage, column: 1, row: pages.length ? pages[additionalPage].items.length : 0})}
                />
                {!!newPayment && (
                  <Button
                    onClick={onCreateInvoice}
                    primary={!!valuation.billable}
                    small={true}
                    children={`Create invoice for ${renderCurrency(newPayment)}`}
                  />
                )}
              </ButtonGroup>
            )
          }}
        />
      )}
    </Fragment>
  )
}