import App from '../app';
import {UserTeams} from '../model/User';
import currentSession from './currentSession';
import {useEffect} from 'preact/hooks';
import {CommandContainer} from '../../../framework/type';
import {AnyCommand} from '../type';
import {useLoadUserTeams} from '../command/loadUserTeams';

export default function currentUserTeams(): [UserTeams, CommandContainer<AnyCommand>] {
  const {userId} = currentSession();
  const [command, getTeams] = useLoadUserTeams();

  const id = userId.id;

  useEffect(() => {
    if (!id) return;
    getTeams(id);
  }, [id]);

  const state = App.useQuery(state => {
    const user = state.user.get(userId.id);

    if (!user) return UserTeams.EMPTY;

    return user.teams;
  }, [id]);

  return [state, command];
}