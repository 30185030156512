import Form from '../form/Form';
import { h } from 'preact';
import FieldGroup from '../form/FieldGroup';
import { useEffect, useState } from 'preact/hooks';
import Button from '../form/Button';
import { CommandStatus } from '../../../../framework/type';
import useCommandErrors from '../../../../framework/hook/useCommandErrors';
import commandsAreRunning from '../../../../framework/commandsAreRunning';
import Paragraph from '../media/Paragraph';
import { useFinaliseCostings } from '../../command/finaliseCostings';
import currentUserTeam from '../../query/currentUserTeam';
import { Job } from '../../model/Job';
import { JobCompanyItem } from '../../model/JobCompanyItem';
import renderCurrency from '../../helper/renderCurrency';
import ValuationSummaryTable from './ValuationSummaryTable';
import { useFinaliseValuation } from '../../command/finaliseValuation';

interface Props {
  job: Job;
  onCancel(): void;
  onSuccess(): void;
}

export default function FinaliseValuationForm({job, onCancel, onSuccess}: Props) {
  const team = currentUserTeam();
  const {valuation} = job;
  const [command, finaliseValuation] = useFinaliseValuation();
  const [unpricedItems, setUnpricedItems] = useState<Array<JobCompanyItem>>([])

  const {system} = useCommandErrors(command);
  const isLoading = commandsAreRunning(command);

  const submit = () => {
    if (!team || isLoading) return null;

    finaliseValuation({
      teamId: team.id,
      jobId: job.id,
    });
  };

  useEffect(() => {
    setUnpricedItems(job.unpricedItems());
  }, [job, setUnpricedItems]);

  useEffect(() => {
    if (!command || command.status !== CommandStatus.SUCCESS) return;
    onSuccess();
  }, [command]);

  return (
    <Form heading={`Generate Valuation for ${renderCurrency(valuation.newPayment)}`} onSubmit={e => e.preventDefault()} error={system}>
      <Paragraph>This will generate an interim valuation to send to accounting</Paragraph>
      {unpricedItems.length > 0 && (
        <Paragraph small={true}>
          Note: There {unpricedItems.length === 1 ? 'is' :  'are'} {unpricedItems.length} item{unpricedItems.length === 1 ?'':'s'} without a COST
        </Paragraph>
      )}
      <ValuationSummaryTable valuation={valuation}/>
      <FieldGroup horizontal={true}>
        <Button onClick={submit} primary={true} type="submit" disabled={isLoading}>{isLoading ? 'Generating...' : 'Generate'}</Button>
        <Button type="cancel" onClick={onCancel}>Cancel</Button>
      </FieldGroup>
    </Form>
  )
}