import {SectionId} from '../model/App';
import JobSection from '../model/JobSection';
import {useCallback} from 'preact/hooks';
import {Job} from '../model/Job';
import {useUpdateJobSectionCommand} from '../command/updateJobSection';
import {useCreateJobSectionCommand} from '../command/createJobSection';
import currentTeam from '../query/currentTeam';
import latestCommand from '../../../framework/latestCommand';
import {SaveHandler} from '../component/editor/type';
import {CommandContainer} from '../../../framework/type';
import {useReorderJobSectionCommand} from '../command/reorderJobSection';

export default function saveJobSectionCallback(
  job: Job
): [CommandContainer<any>, SaveHandler<JobSection>] {
  const team = currentTeam();
  const [createSectionCommand, createSection] = useCreateJobSectionCommand();
  const [reorderSectionCommand, reorderSection] = useReorderJobSectionCommand();
  const [updateSectionCommand, updateSection] = useUpdateJobSectionCommand();
  const commandContainer = latestCommand([createSectionCommand, updateSectionCommand, reorderSectionCommand]);


  const saveSection = useCallback((page: number, target: null | JobSection, key: keyof JobSection, value: string | null) => {
    if (!job || !team) return [];

    const item = target
      ? job.sections.get(target.id)!
      : JobSection.fromJSON({
        id: SectionId.nextId()
      });

    if (!item) return [new Error('Cannot find the item to save to')];

    let nextValue: any;

    function getValue(): JobSection {
      switch (key) {
        case 'name':
          nextValue = value || '';
          return item.setName(nextValue);

        case 'order':
          nextValue = !value ? 0 : parseInt(value);
          return item.setOrder(nextValue);

        default:
          return item;
      }
    }

    const next = getValue();
    const errors = next.validate();

    if (errors.length === 0) {
      if (key === 'order') {
        reorderSection({
          teamId: team.id,
          jobId: job.id,
          sectionId: next.id,
          order: nextValue,
        })
      } else if (target) {
        updateSection({
          teamId: team.id,
          jobId: job.id,
          sectionId: next.id,
          details: {[key]: value}
        })
      } else {
        createSection({
          teamId: team.id,
          jobId: job.id,
          sectionId: next.id,
          sectionName: next.name,
        });
      }
    }

    return errors;
  }, [job, team, createSection, updateSection]);

  return [
    commandContainer,
    saveSection,
  ]
}
