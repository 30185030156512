import Form from '../form/Form';
import { h } from 'preact';
import FieldGroup from '../form/FieldGroup';
import { useCallback, useEffect, useState } from 'preact/hooks';
import Button from '../form/Button';
import { CommandStatus } from '../../../../framework/type';
import useCommandErrors from '../../../../framework/hook/useCommandErrors';
import commandsAreRunning from '../../../../framework/commandsAreRunning';
import { Job } from '../../model/Job';
import Paragraph from '../media/Paragraph';
import currentUserTeam from '../../query/currentUserTeam';
import { useDeleteJobVariationItemsCommand } from '../../command/deleteJobVariationItems';
import JobVariationItem from '../../model/JobVariationItem';

interface Props {
  job: Job;
  variations: JobVariationItem[];
  onCancel(): void;
  onSuccess(): void;
}

export default function DeleteVariationItemsForm({job, variations, onCancel, onSuccess}: Props) {
  const team = currentUserTeam();

  const [command, deleteVariationItems] = useDeleteJobVariationItemsCommand();
  const [deletingItems, setDeletingItems] = useState(variations);
  const {system} = useCommandErrors(command);
  const isLoading = commandsAreRunning(command);

  const submit = useCallback(() => {
    if (variations.length === 0 || isLoading || !team) return;
    setDeletingItems(variations);
    deleteVariationItems({
      teamId: team.id,
      jobId: job.id,
      variationIds: variations.map(item => item.id)
    })
  }, [job, team, variations, isLoading, deleteVariationItems]);

  useEffect(() => {
    if (!command || command.status !== CommandStatus.SUCCESS) return;
    onSuccess();
  }, [command]);


  return (
    <Form heading="Delete Variations" onSubmit={submit} error={system}>
      <Paragraph>This will permanently delete the following variation{variations.length === 1 ? '' : 's'}:</Paragraph>
      <ul>
        {deletingItems.map(item => (
          <li>[{item.order}] {item.description}</li>
        ))}
      </ul>
      <Paragraph>Are you sure?</Paragraph>
      <FieldGroup horizontal={true}>
        <Button danger={true} type="submit" disabled={isLoading} onClick={submit}>Delete</Button>
        <Button type="cancel" onClick={onCancel}>Cancel</Button>
      </FieldGroup>
    </Form>
  )
}