import {Relation} from '../../../framework/value/Relation';
import {JobId, TeamId, UserId} from './App';
import {Entity} from '../../../framework/value/Entity';
import MapValue from '../../../framework/value/MapValue';
import BooleanValue from '../../../framework/value/BooleanValue';
import EntityMapValue from '../../../framework/value/EntityMapValue';
import NumberValue from '../../../framework/value/NumberValue';
import StringValue from '../../../framework/value/StringValue';
import {Job, JobJSON} from './Job';
import {Email} from '../../../framework/value/Email';

export class TeamOwner extends UserId {}
export class TeamUserId extends UserId {}
export class TeamJobId extends JobId {}

export class Team extends Entity<TeamId, {
  owner: TeamOwner
  profile: TeamProfile;
  users: TeamUsers;
  jobs: TeamJobs;
  archive: TeamJobs;
  templates: TeamJobs;
}> {

  get jobs() {
    return this.value.jobs;
  }

  get archive() {
    return this.value.archive;
  }

  get templates() {
    return this.value.templates;
  }

  get name() {
    return this.value.profile.value.name.value;
  }

  get users() {
    return this.value.users;
  }

  isOwner(userId: string) {
    return !!userId && this.value.owner.value === userId;
  }

  inviteUser(invitedUser: TeamUser) {
    const match = this.users.findByEmail(invitedUser.email);

    if (match) {
      throw new Error('Teammate has already been invited with that email');
    }

    return this.extend({
      users: this.users.extend({
        [invitedUser.id]: invitedUser
      })
    })
  }

  acceptInvite(userId: UserId, name: TeamUserName, email: string) {
    const match = this.users.findByEmail(email);

    if (!match) {
      throw new Error('Invite does not exist');
    }

    const matchJson = match.toJSON();
    const replacement = TeamUser.fromJSON({
      ...matchJson,
      id: userId.id,
      status: {
        ...matchJson.status,
        pending: false,
      },
      profile: {
        ...matchJson.profile,
        name: name.value
      }
    });

    return this.extend({
      users: this.users.remove(new TeamUserId(match.id)).extend({
        [replacement.id]: replacement
      })
    })
  }

  removeJobs(jobIds: string[]): Team {
    const jobs = jobIds.reduce((job, id) => {
      return job.remove(new JobId(id))
    }, this.jobs);

    return this.extend({jobs});
  }

  removeArchive(jobIds: string[]): Team {
    const archive = jobIds.reduce((archive, id) => {
      return archive.remove(new JobId(id))
    }, this.archive);

    return this.extend({archive});
  }

  static create = (props: {
    teamId: string,
    userId: string,
    teamName: string,
    userName: string,
    userColor: number,
    userEmail: string,
  }) => new Team({
    id: new TeamId(props.teamId),
    owner: new TeamOwner(props.userId),
    profile: new TeamProfile({
      name: new TeamName(props.teamName)
    }),
    users: new TeamUsers({
      [props.userId]: new TeamUser({
        id: new TeamUserId(props.userId),
        status: new TeamUserStatus({
          newUser: new BooleanValue(false),
          pending: new BooleanValue(false),
        }),
        profile: new TeamUserProfile({
          name: new TeamUserName(props.userName),
          email: new Email(props.userEmail),
          color: new TeamUserColor(props.userColor)
        })
      })
    }),
    jobs: new TeamJobs({}),
    archive: new TeamJobs({}),
    templates: new TeamJobs({}),
  });

  static fromJSON(data: Partial<TeamJSON>) {
    return new Team({
      id: new TeamId(data.id || ''),
      owner: new TeamOwner(data.owner || ''),
      profile: TeamProfile.fromJSON(data.profile || {}),
      users: TeamUsers.fromJSON(data && data.users || {}),
      jobs: new TeamJobs({}),
      archive: new TeamJobs({}),
      templates: new TeamJobs({}),
    })
  }
}

export interface TeamJSON {
  id: string;
  owner: string;
  profile: TeamProfileJSON;
  users: TeamUsersJSON;
  jobs: TeamJobsJSON;
}

export class Teams extends EntityMapValue<TeamId, Team> {}

export class TeamName extends StringValue {
  static MUST_NOT_BE_EMPTY = 'Must not be empty';

  validate() {
    return this.collect([
      !this.value && this.error(TeamName.MUST_NOT_BE_EMPTY)
    ])
  }
}

interface TeamProfileJSON {
  name: string;
}

export class TeamProfile extends MapValue<{
  name: TeamName
}, TeamProfileJSON> {
  static fromJSON(data: Partial<TeamProfileJSON>) {
    return new TeamProfile({
      name: new TeamName(data.name || '')
    })
  }
}

export interface TeamUsersJSON {
  [userId: string]: TeamUserJSON
}

export interface TeamUserJSON {
  id: string,
  status: TeamUserStatusJSON,
  profile: TeamUserProfileJSON,
}

export class TeamUser extends Relation<TeamUserId, {
  profile: TeamUserProfile
  status: TeamUserStatus
}> {
  get name() {
    return this.value.profile.value.name.value;
  }

  get color() {
    return this.value.profile.value.color.value;
  }

  get userColor() {
    return `hsl(${Math.abs(this.color % 360)}, 100%, 70%)`
  }

  get email() {
    return this.value.profile.value.email.value;
  }

  get pending() {
    return this.value.status.value.pending.value;
  }

  get newUser() {
    return this.value.status.value.newUser.value;
  }

  get userLetter() {
    return (this.name[0] || '-').toUpperCase()
  }

  get currentStatus() {
    return [
      (!this.pending && !this.newUser) && 'Active',
      this.pending && 'Pending',
      this.newUser && 'New User',
    ].filter(Boolean).join(', ');
  }

  static fromJSON(json: Partial<TeamUserJSON>): TeamUser {
    return new TeamUser({
      id: new UserId(json.id || ''),
      status: TeamUserStatus.fromJSON(json.status || {}),
      profile: TeamUserProfile.fromJSON(json.profile || {}),
    })
  }

  toJSON(): TeamUserJSON {
    return super.toJSON() as TeamUserJSON;
  }
}

export class TeamUsers extends EntityMapValue<TeamUserId, TeamUser> {
  static fromJSON(json: TeamUsersJSON) {
    return new TeamUsers(Object.keys(json).reduce((users, id) => {
      return {
        ...users,
        [id]: TeamUser.fromJSON(json[id])
      };
    }, {} as Record<string, TeamUser>))
  }

  get all(): TeamUser[] {
    return Object.keys(this.value).reduce((users, key) => {
      return [...users, this.value[key]];
    }, [] as TeamUser[]);
  }

  findByEmail(email: string) {
    return this.all.find(user => user.email === email);
  }
}

interface TeamJobsJSON {
  [jobId: string]: Job
}

export class TeamJobs extends EntityMapValue<TeamJobId, Job> {
  static EMPTY = new TeamJobs({});

  static fromJSON(json: JobJSON[]) {
    const record = json.map(Job.fromJSON).reduce((record, job) => ({
      ...record,
      [job.id]: job
    }), {} as Record<string, Job>);

    return new TeamJobs(record);
  }

  get all(): Job[] {
    return Object.keys(this.value).reduce((jobs, key) => {
      return [...jobs, this.value[key]];
    }, [] as Job[]);
  }

  get cost() {
    return this.all.reduce((cost, item) => {
      return cost + item.cost;
    }, 0);
  }

  get profit() {
    return this.all.reduce((profit, item) => {
      return profit + item.profit;
    }, 0);
  }

  get charge() {
    return this.all.reduce((charge, item) => {
      return charge + item.charge;
    }, 0);
  }

  get markup() {
    if (this.cost === 0) return 0;

    return Math.round((this.charge / this.cost - 1) * 10000) / 100;
  }
}

export interface TeamUserStatusJSON {
  newUser: boolean;
  pending: boolean;
}

export class TeamUserStatus extends MapValue<{
  newUser: BooleanValue;
  pending: BooleanValue;
}, TeamUserStatusJSON> {
  static fromJSON(json: Partial<TeamUserStatusJSON>): TeamUserStatus {
    return new TeamUserStatus({
      newUser: new BooleanValue(typeof json.newUser === 'undefined' ? false : json.newUser),
      pending: new BooleanValue(typeof json.pending === 'undefined' ? false : json.pending),
    })
  }
}

export class TeamUserName extends StringValue {
  validate() {
    return this.collect([
      !this.value && this.error(TeamName.MUST_NOT_BE_EMPTY)
    ])
  }
}

export interface TeamUserProfileJSON {
  name: string;
  color: number;
  email: string;
}

export class TeamUserColor extends NumberValue {}
export class TeamUserProfile extends MapValue<{
  name: TeamUserName,
  color: TeamUserColor,
  email: Email,
}> {
  static fromJSON(json: Partial<TeamUserProfileJSON>): TeamUserProfile {
    return new TeamUserProfile({
      name: new TeamUserName(json.name || ''),
      color: new TeamUserColor(json.color || 42),
      email: new Email(json.email || ''),
    })
  }
}