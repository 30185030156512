import {Fragment, h} from 'preact';
import Button from '../form/Button';
import Dialog from '../media/Dialog';
import {useCallback, useEffect, useState} from 'preact/hooks';
import JoinTeamForm from './JoinTeamForm';

interface Props {
  initialCode: string;
  onSuccess?(): any;
  onCancel?(): any;
}


export default function JoinTeamButton({initialCode, onSuccess, onCancel}: Props) {
  const [showDialog, toggleDialog] = useState(false);

  const dialog = {
    show: toggleDialog.bind(null, true),
    hide: toggleDialog.bind(null, false)
  };

  const click = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    dialog.show();
  }, [dialog]);


  useEffect(() => {
    if (!!initialCode && !showDialog) toggleDialog(true);
  }, [initialCode, showDialog, toggleDialog]);

  return (
    <Fragment>
      <Button onClick={click}>
        Join Team
      </Button>
      <Dialog show={showDialog} onRequestClose={dialog.hide}>
        <JoinTeamForm
          initialCode={initialCode}
          onCancel={() => {
            dialog.hide();
            if (onCancel) onCancel();
          }}
          onSuccess={() => {
            dialog.hide();
            if (onSuccess) onSuccess();
          }}
        />
      </Dialog>
    </Fragment>
  )
}