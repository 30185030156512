import {CommandHook, DispatchContext, SystemStateContext} from './type';
import {useCallback, useContext, useState} from 'preact/hooks';

export default function createCommandHook<CommandTypes>(
  dispatchContext: DispatchContext<CommandTypes>,
  systemStateContext: SystemStateContext<CommandTypes>
): CommandHook<CommandTypes> {
  return () => {
    const state = useContext(systemStateContext);
    const dispatch = useContext(dispatchContext);
    const [id, setId] = useState<string>('');
    const container = state.command[id]

    const wrappedDispatch = useCallback((command: CommandTypes) => {
      const id = dispatch(command);
      setId(id);
    }, []);

    return [container, wrappedDispatch];
  }
};