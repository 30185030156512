import {
  IMPORT_JOB_SECTIONS_COMMAND,
  ImportJobSectionsCommand,
  JOB_SECTION_IMPORTED,
  JobSectionImportedEvent,
  JobSectionImportService
} from '../../type';
import {FileId, JobId, TeamId} from '../../model/App';
import {State} from '../../model/State';
import {JobCostingsAppService} from '../../services';
import {AsyncCommandResponse} from '../../../../framework/type';
import {Job, JobSections} from '../../model/Job';
import {TeamJobs} from '../../model/Team';
import JobSection from '../../model/JobSection';

export default class AppJobSectionImportService extends JobCostingsAppService implements JobSectionImportService {
  async [IMPORT_JOB_SECTIONS_COMMAND](state: State, command: ImportJobSectionsCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
      new FileId(command.payload.fileId),
    ], async (teamId: TeamId, jobId: JobId, fileId: FileId) => {
      return this.proxy(command);
    });
  }

  [JOB_SECTION_IMPORTED](state: State, event: JobSectionImportedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = team.jobs.get(event.payload.jobId);
    if (!job) return state;

    const section = JobSection.fromJSON(event.payload.section);

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(job.extend({
          sections: job.sections.extend({
            [section.id]: section
          })
        }))
      }))
    });
  }
}
