import {AnyCommand, IMPORT_JOB_SECTIONS_COMMAND, ImportJobSectionsCommand} from '../type';
import App from '../app';

export default function importJobPages(payload: ImportJobSectionsCommand['payload']): AnyCommand {
  return {
    type: IMPORT_JOB_SECTIONS_COMMAND,
    payload,
  }
}

export const useImportJobPagesCommand = App.wrapCommand(importJobPages);