import {EventAppService, RECEIVE_EVENT_COMMAND, RemoteEventHandler} from '../../type';
import {AsyncCommandResponse, WatchFunctionUnsubscribe} from '../../../../framework/type';
import {TodoError} from '../../../../framework/error';
import {JobCostingsBaseService} from '../../services';

export default class MemoryEventService extends JobCostingsBaseService implements EventAppService {
  constructor(
    protected readonly storage: Storage,
  ) {super()}

  async [RECEIVE_EVENT_COMMAND](): AsyncCommandResponse {
    return this.todo()
  }

  watchTeamEvents(teamId: string, handler: RemoteEventHandler): WatchFunctionUnsubscribe {
    throw new TodoError()
  }

  watchUserEvents(userId: string, handler: RemoteEventHandler): WatchFunctionUnsubscribe {
    throw new TodoError()
  }
}
