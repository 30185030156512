import {AnyCommand, ALLOCATE_VARIATION_COMPANY_COMMAND, AllocateVariationCompanyCommand} from '../type';
import App from '../app';

export default function allocateVariationCompany(payload: AllocateVariationCompanyCommand['payload']): AnyCommand {
  return {
    type: ALLOCATE_VARIATION_COMPANY_COMMAND,
    payload,
  }
}

export const useAllocateVariationCompanyCommand = App.wrapCommand(allocateVariationCompany);