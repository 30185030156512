import {UserTeam} from '../model/User';
import currentSession from './currentSession';
import currentUser from './currentUser';

export default function currentUserTeam(): UserTeam | null {
  const {teamSelected, teamId} = currentSession();
  const user = currentUser();

  return user && teamSelected && teamId.id
    ? user.teams.get(teamId.id)
    : null;
}