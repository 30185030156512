import App from '../app';
import {AnyCommand} from '../type';
import {CommandContainer} from '../../../framework/type';
import {useEffect} from 'preact/hooks';
import currentSession from './currentSession';
import {useLoadJobCommand} from '../command/loadJob';
import {Valuation} from '../model/Valuation';

export default function useValuation(jobId: string): [Valuation | null, CommandContainer<AnyCommand>] {
  const {teamId} = currentSession();
  const [command, getJob] = useLoadJobCommand();

  const id = teamId.id;

  useEffect(() => {
    if (!id) return;
    getJob({teamId: id, jobId});
  }, [id, jobId]);

  const state = App.useQuery(state => {
    const team = state.team.get(id);

    if (!team) return null;

    const job = team.jobs.get(jobId);
    if (!job) return null;

    return Valuation.fromJob(job);
  }, [id]);

  return [state, command];
}