import {h} from 'preact';
import ValuationSection from '../../model/ValuationSection';
import TableGroup from '../editor/TableGroup';
import {ActionRenderer, EditorColumn, EditorColumnType, EditorPage, SaveHandler} from '../editor/type';
import {default as ValuationSectionItem} from '../../model/ValuationSectionItem';
import {Valuation} from '../../model/Valuation';
import renderCurrency from '../../helper/renderCurrency';
import {CommandContainer} from '../../../../framework/type';
import ActiveIndicator from '../media/ActiveIndicator';
import binaryChoice from '../../helper/binaryChoice';
import PassiveIndicator from '../media/PassiveIndicator';
import PercentageIndicator from '../media/PercentageIndicator';

interface Props {
  valuation: Valuation;
  sections: ValuationSection[];
  onSave?: SaveHandler<ValuationSectionItem>;
  currentCommand?: CommandContainer<any>;
  children?: ActionRenderer<ValuationSectionItem>;
  forceQuantity?: boolean;
  expandToFill?: boolean;
}

export default function SectionsTable({children, expandToFill, forceQuantity, sections, valuation, onSave, currentCommand}: Props) {
  const editable = (item: ValuationSectionItem, key: keyof ValuationSectionItem) => {
    if (!valuation) return false;

    switch (key) {
      case 'omit':
      case 'po':
        return true;

      case 'complete':
        return item && !item.omit;

      case 'charge':
        return !item || (item.variation && !item.omit);

      case 'item':
      case 'description':
        return !item || item.variation;

      default:
        return false;
    }
  };

  const renderHeader = (page: number, _: ValuationSectionItem[], key: keyof ValuationSectionItem) => {
    if (!valuation) return '';
    const section = sections[page];
    if (!section) return '';

    switch (key) {
      case 'charge':
        return renderCurrency(section.charge);
      case 'omit':
        return renderCurrency(section.omitted);
      case 'billable':
        return renderCurrency(section.billable);

      case 'complete':
        return (
          <PercentageIndicator
            alwaysShowLabel={true}
            percent={section.complete}
            pageBackground={true}
          />
        );

      default:
        return '';
    }
  };

  const columns: Array<EditorColumn<ValuationSectionItem>> = [
    {
      key: 'order',
      label: '#',
      width: 32,
      numeric: true,
      editable: () => false,
      renderCell: (item) => {
        return item && item.order;
      }
    },
    {
      key: 'item',
      label: 'Item',
      width: 48,
      editable,
      renderHeader,
    },
    {
      key: 'description',
      label: 'Description',
      width: 320,
      renderCell: (item) => {
        return item && item.description;
      },
      editable,
      renderHeader,
    },
    {
      key: 'company',
      label: 'Allocation',
      width: 120,
      renderCell: (item) => {
        return item && item.company.name;
      },
      stringValue: (item) => {
        return item && item.company.name;
      },
      editable,
      renderHeader,
    },
    {
      key: 'po',
      label: 'PO #',
      width: 60,
      renderCell: (item) => {
        return item && item.po;
      },
      stringValue: (item) => {
        return item && item.po;
      },
      editable,
      renderHeader,
    },
    {
      key: 'charge',
      label: 'Charge',
      width: 80,
      numeric: true,
      type: EditorColumnType.CURRENCY,
      renderCell: (item) => {
        return renderCurrency(item.charge);
      },
      editable,
      renderHeader,
    },
    // {
    //   key: 'invoiced',
    //   label: 'Invoiced',
    //   width: 80,
    //   numeric: true,
    //   type: EditorColumnType.CURRENCY,
    //   renderCell: (item) => {
    //     if (!item.invoiced)
    //       return '';
    //
    //     return renderCurrency(item.invoiced);
    //   },
    //   editable,
    //   renderHeader,
    // },
    {
      key: 'omit',
      label: 'Omit',
      width: 80,
      numeric: true,
      type: EditorColumnType.CURRENCY,
      renderCell: (item) => {
        if (!item.omit)
          return '';

        return renderCurrency(item.omitted);
      },
      stringValue: (item) => {
        return item.omit ? 'Yes' : 'No'
      },
      editable,
      renderHeader,
      choices: binaryChoice
    },
    {
      key: 'complete',
      label: 'Complete',
      width: 80,
      numeric: true,
      type: EditorColumnType.PERCENTAGE,
      renderCell: (item) => {
        if (item.omit)
          return <PassiveIndicator>N/A</PassiveIndicator>;

        return (
          <PercentageIndicator percent={item.complete}/>
        );
      },
      editable,
      renderHeader,
    },
    {
      key: 'billable',
      label: 'Billable',
      width: 80,
      numeric: true,
      type: EditorColumnType.CURRENCY,
      renderCell: (item) => {
        if (item.omit)
          return <ActiveIndicator>OMIT</ActiveIndicator>;

        if (!item.billable)
          return '';

        return renderCurrency(item.billable);
      },
      editable,
      renderHeader,
    },
  ];

  let filteredColumns = columns;

  if (!valuation || !valuation.details.showOrder) {
    filteredColumns = filteredColumns.filter(c => c.key !== 'order')
  }

  const pages: Array<EditorPage<ValuationSectionItem>> = sections.map((section, index) => {
    const isAdditional = !section.name;

    return {
      name: isAdditional ? 'Additionals' : section.name,
      subtitle: isAdditional ? '' : `Section ${index + 1}`,
      items: section.items.all
    }
  });

  return (
    <TableGroup
      id="valuation_sections_table"
      columns={filteredColumns}
      pages={pages}
      onSave={onSave}
      allowCreate={true}
      onlyCreateLast={true}
      children={children}
      currentCommand={currentCommand}
      allowFullScreen={true}
      allowShrinkRows={true}
      expandToFill={expandToFill}
      stickOnScroll={true}
    />
  )
}