import {h, Ref} from 'preact';
import Select from './Select';
import {CommandStatus, HTMLAttributes} from '../../../../framework/type';
import currentTeamTemplates from '../../query/currentTeamTemplates';

interface Props extends HTMLAttributes {
  allowEmpty?: boolean;
  unstyled?: boolean;
  innerRef?: Ref<HTMLSelectElement>;
}

export default function TeamTemplateSelect({children, allowEmpty, ...props}: Props) {
  const [teamTemplates, command] = currentTeamTemplates();

  if (!command || command.status !== CommandStatus.SUCCESS) return (
    <span>Loading templates...</span>
  );

  return (
    <Select {...props}>
      {allowEmpty && (
        <option value="" />
      )}
      {teamTemplates.all.map(template => (
        <option value={template.id}>{template.name}</option>
      ))}
    </Select>
  )
}