import App from '../../app';
import {rem} from 'csx';
import {h} from 'preact';
import {HTMLAttributes} from '../../../../framework/type';

interface Props extends HTMLAttributes {
  error: string;
  status: string;
}

export default function StatusBar({status, error}: Props) {
  const className = App.useStyle(({theme}) => ({
    lineHeight: rem(2),
    whiteSpace: 'pre',
    fontWeight: 900,
    color: error
      ? theme.color.red.toString()
      : theme.color.blue.toString(),
    padding: '0 0.5rem'
  }), []);

  return (
    <div className={className}>
      {error || status}
    </div>
  )
}