import Form from '../form/Form';
import {h} from 'preact';
import FieldGroup from '../form/FieldGroup';
import Field from '../form/Field';
import Input from '../form/Input';
import {useCallback, useEffect, useRef} from 'preact/hooks';
import Button from '../form/Button';
import useCommandErrors from '../../../../framework/hook/useCommandErrors';
import {CommandStatus} from '../../../../framework/type';
import currentUserTeam from '../../query/currentUserTeam';
import autoFocus from '../../effect/autoFocus';
import {Job, JobDetailsJSON, JobFlagTypes} from '../../model/Job';
import TeamUserSelect from '../form/TeamUserSelect';
import setValueOnMount from '../../effect/setValueOnMount';
import {useUpdateJobCommand} from '../../command/updateJob';
import Select from '../form/Select';
import Heading from '../media/Heading';
import DateInput from '../form/DateInput';
import setDateValueOnMount from '../../effect/setDateValueOnMount';

interface CreateJobFormFields {
  'JobDetails.JobName': string;
  'JobDetails.JobRef': string;
  'JobDetails.Assignee': string;
  'JobDetails.ClientName': string;
  'JobDetails.ReceivedAt': string;
  'JobDetails.ReturnBy': string;
  'JobDetails.StartDate': string;
  'JobDetails.Duration': string;
  'JobDetails.ContactName': string;
  'JobDetails.ContactEmail': string;
  'JobDetails.ContactTel': string;
  'JobDetails.ShowQuantity': string;
  'JobDetails.ShowOrder': string;
  'JobDetails.Retention': string;
  'JobDetails.Invoiced': string;
  'JobDetails.RoundCharge': string;
}

interface Props {
  job: Job;
  onCancel(): void;
  onSuccess(): void;
}

export default function CreateJobForm({job, onCancel, onSuccess}: Props) {
  const jobRef = useRef<HTMLInputElement>();
  const jobName = useRef<HTMLInputElement>();
  const assignee = useRef<HTMLSelectElement>();
  const clientName = useRef<HTMLInputElement>();
  const contactName = useRef<HTMLInputElement>();
  const contactEmail = useRef<HTMLInputElement>();
  const contactTel = useRef<HTMLInputElement>();
  const receivedAt = useRef<HTMLInputElement>();
  const returnBy = useRef<HTMLInputElement>();
  const startDate = useRef<HTMLInputElement>();
  const duration = useRef<HTMLInputElement>();
  const enquiriesSent = useRef<HTMLSelectElement>();
  const siteVisitArranged = useRef<HTMLSelectElement>();
  const costing = useRef<HTMLSelectElement>();
  const internal = useRef<HTMLSelectElement>();
  const external = useRef<HTMLSelectElement>();
  const showQuantity = useRef<HTMLSelectElement>();
  const showOrder = useRef<HTMLSelectElement>();
  const retention = useRef<HTMLInputElement>();
  const invoiced = useRef<HTMLInputElement>();
  const roundCharge = useRef<HTMLSelectElement>();

  const team = currentUserTeam();

  if (!team) return null;

  const [command, updateJob] = useUpdateJobCommand();
  const {system, field} = useCommandErrors<CreateJobFormFields>(command);

  const running = command && command.status === CommandStatus.RUNNING;

  const submit = useCallback(() => {
    if (running || !team || !jobName.current) return;
    
    let details: Partial<JobDetailsJSON> = {
      name: jobName.current.value
    };

    if (jobRef.current && jobRef.current.value)
      details.ref = jobRef.current.value;

    if (clientName.current && clientName.current.value)
      details.client = clientName.current.value;

    if (assignee.current && assignee.current.value)
      details.assignee = assignee.current.value;

    if (contactName.current && contactName.current.value)
      details.contactName = contactName.current.value;

    if (contactEmail.current && contactEmail.current.value)
      details.contactEmail = contactEmail.current.value;

    if (contactTel.current && contactTel.current.value)
      details.contactTel = contactTel.current.value;

    if (receivedAt.current && receivedAt.current.value)
      details.receivedAt = receivedAt.current.value;

    if (returnBy.current && returnBy.current.value)
      details.returnBy = returnBy.current.value;

    if (startDate.current && startDate.current.value)
      details.startDate = startDate.current.value;

    if (duration.current && duration.current.value)
      details.duration = parseInt(duration.current.value) || void 0;

    if (showQuantity.current && showQuantity.current.value)
      details.showQuantity = !!showQuantity.current.value;

    if (showOrder.current && showOrder.current.value)
      details.showOrder = !!showOrder.current.value;

    if (retention.current && retention.current.value)
      details.retention = parseInt(retention.current.value);

    if (invoiced.current && invoiced.current.value)
      details.invoiced = parseFloat(invoiced.current.value);

    let flags: string[] = [];

    if (internal.current && internal.current.value)
      flags.push(JobFlagTypes.INTERNAL);

    if (external.current && external.current.value)
      flags.push(JobFlagTypes.EXTERNAL);

    if (enquiriesSent.current && enquiriesSent.current.value)
      flags.push(JobFlagTypes.ENQUIRIES_SENT);

    if (siteVisitArranged.current && siteVisitArranged.current.value)
      flags.push(JobFlagTypes.SITE_VISIT_ARRANGED);

    if (costing.current && costing.current.value)
      flags.push(JobFlagTypes.COSTING);

    if (roundCharge.current && roundCharge.current.value)
      details.roundCharge = parseFloat(roundCharge.current.value);

    details.flags = flags;

    updateJob({
      teamId: team.id,
      jobId: job.id,
      details,
    });
  }, [updateJob, team, jobName]);

  useEffect(() => {
    if (command && command.status === CommandStatus.SUCCESS) {
      onSuccess();
    }
  }, [command]);

  autoFocus(jobName);
  setValueOnMount(jobName, job.name);
  setValueOnMount(jobRef, job.ref);
  setValueOnMount(assignee, job.assignee);
  setValueOnMount(clientName, job.client);
  setValueOnMount(contactName, job.contact);
  setValueOnMount(contactEmail, job.details.contactEmail);
  setValueOnMount(contactTel, job.details.contactTel);
  setDateValueOnMount(receivedAt, job.receivedAt.date);
  setDateValueOnMount(returnBy, job.returnBy.date);
  setDateValueOnMount(startDate, job.startDate.date);

  setValueOnMount(duration, job.duration.toString());
  setValueOnMount(internal, job.internal ? '1' : '');
  setValueOnMount(external, job.external ? '1' : '');
  setValueOnMount(showQuantity, job.details.showQuantity ? '1' : '');
  setValueOnMount(showOrder, job.details.showOrder ? '1' : '');
  setValueOnMount(retention, job.retention.toString());
  setValueOnMount(invoiced, job.invoiced.toString());
  setValueOnMount(roundCharge, job.details.roundCharge.toString());

  return (
    <Form disabled={running} heading="Edit Job" error={system} onSubmit={submit}>
      <FieldGroup horizontal={true}>
        <Field required={true} label="Job name" error={field['JobDetails.JobName']}>
          <Input disabled={running} innerRef={jobName} />
        </Field>
        <Field label="Job ref" error={field['JobDetails.JobRef']} fill={false}>
          <Input disabled={running} innerRef={jobRef} />
        </Field>
        <Field label="Surveyor" error={field['JobDetails.Assignee']}>
          <TeamUserSelect allowEmpty={true} disabled={running} innerRef={assignee} />
        </Field>
      </FieldGroup>
      <FieldGroup horizontal={true}>
        <Field label="Client" error={field['JobDetails.ClientName']}>
          <Input disabled={running} innerRef={clientName} />
        </Field>
        <Field label="Contact name" error={field['JobDetails.ContactName']}>
          <Input disabled={running} innerRef={contactName} />
        </Field>
        <Field label="Contact email" error={field['JobDetails.ContactEmail']}>
          <Input disabled={running} innerRef={contactEmail} />
        </Field>
        <Field label="Contact tel" error={field['JobDetails.ContactTel']}>
          <Input disabled={running} innerRef={contactTel} />
        </Field>
      </FieldGroup>
      <Heading level={5}>Timeline</Heading>
      <FieldGroup horizontal={true}>
        <Field label="Date received" fill={false} error={field['JobDetails.ReceivedAt']}>
          <DateInput disabled={running} innerRef={receivedAt} />
        </Field>
        <Field label="Return by" fill={false} error={field['JobDetails.ReturnBy']}>
          <DateInput disabled={running} innerRef={returnBy} />
        </Field>
        <Field label="Start date" fill={false} error={field['JobDetails.StartDate']}>
          <DateInput disabled={running} innerRef={startDate} />
        </Field>
        <Field label="Duration" fill={false} error={field['JobDetails.Duration']}>
          <Input type="number" disabled={running} innerRef={duration} />
        </Field>
      </FieldGroup>
      <FieldGroup horizontal={true}>
        <Field label="Enquiries sent?" fill={false}>
          <Select disabled={running} innerRef={enquiriesSent}>
            <option value="">No</option>
            <option value="1">Yes</option>
          </Select>
        </Field>
        <Field label="Site visit arranged?" fill={false}>
          <Select disabled={running} innerRef={siteVisitArranged}>
            <option value="">No</option>
            <option value="1">Yes</option>
          </Select>
        </Field>
        <Field label="Costing?" fill={false}>
          <Select disabled={running} innerRef={costing}>
            <option value="">No</option>
            <option value="1">Yes</option>
          </Select>
        </Field>
      </FieldGroup>
      <FieldGroup horizontal={true}>
        <Field label="Retention %" fill={false}>
          <Input disabled={running} innerRef={retention} />
        </Field>
        <Field label="Invoiced to Date" fill={false}>
          <Input disabled={running} innerRef={invoiced} />
        </Field>
      </FieldGroup>
      <Heading level={5}>Settings</Heading>
      <FieldGroup horizontal={true}>
        <Field label="Internal" fill={false}>
          <Select disabled={running} innerRef={internal}>
            <option value="">No</option>
            <option value="1">Yes</option>
          </Select>
        </Field>
        <Field label="External" fill={false}>
          <Select disabled={running} innerRef={external}>
            <option value="">No</option>
            <option value="1">Yes</option>
          </Select>
        </Field>
        <Field label="Show quantity" fill={false} error={field['JobDetails.ShowQuantity']}>
          <Select disabled={running} innerRef={showQuantity}>
            <option value="">No</option>
            <option value="1">Yes</option>
          </Select>
        </Field>
        <Field label="Show order" fill={false} error={field['JobDetails.ShowOrder']}>
          <Select disabled={running} innerRef={showOrder}>
            <option value="">No</option>
            <option value="1">Yes</option>
          </Select>
        </Field>
        <Field label="Round charge" fill={false} error={field['JobDetails.RoundCharge']}>
          <Select disabled={running} innerRef={roundCharge}>
            <option value="0">Off</option>
            <option value="0.01">£0.01</option>
            <option value="0.1">£0.10</option>
            <option value="1">£1.00</option>
            <option value="10">£10.00</option>
            <option value="100">£100.00</option>
          </Select>
        </Field>
      </FieldGroup>
      <FieldGroup horizontal={true}>
        <Button disabled={running} primary={true} type="submit">{running ? 'Saving...' : 'Save'}</Button>
        <Button disabled={running} type="cancel" onClick={onCancel}>Cancel</Button>
      </FieldGroup>
    </Form>
  )
}