import { useEffect, useState } from 'preact/hooks';

let nextPromise = Promise.resolve(null);

export default function useTimedToggle() {
  const [showRelative, setShowRelative] = useState(false);

  useEffect(() => {
    const promise = nextPromise;
    nextPromise.then(() => {
      setShowRelative(!showRelative);
      if (nextPromise !== promise) return;
      nextPromise = new Promise(resolve => {
        setTimeout(() => resolve(), 3000)
      });
    })
  }, [showRelative, setShowRelative, nextPromise]);

  return showRelative;
}