import JobSection from '../../model/JobSection';
import TableGroup from '../editor/TableGroup';
import {h} from 'preact';
import {EditorColumn, EditorColumnType} from '../editor/type';

interface Props {
  id?: string;
  sections: JobSection[]
}

export default function SummaryTable({id, sections}: Props) {
  const columns: Array<EditorColumn<JobSection>> = [
    {
      key: 'id',
      label: '#',
      width: 32,
      renderCell: (item) => {
        return item.order
      }
    },
    {
      key: 'name',
      label: 'Section',
      width: 320,
      renderCell: (item) => {
        return item.name
      }
    },
    {
      key: 'items',
      label: '# Items',
      width: 80,
      numeric: true,
      renderCell: (item) => {
        return item.items.all.length
      }
    },
    {
      key: 'cost',
      label: 'Fixed Total',
      width: 80,
      numeric: true,
      type: EditorColumnType.CURRENCY,
      renderCell: (section) => {
        return section.cost
      }
    }
  ];

  return (
    <TableGroup
      id={id || 'summary_table'}
      columns={columns}
      singlePage={{
        name: 'Section Summary',
        items: sections
      }}
    />
  )
}