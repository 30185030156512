import {h, Ref} from 'preact';
import {HTMLAttributes} from '../../../../framework/type';
import App from '../../app';
import {percent, rem} from 'csx';
import classes from '../../../../framework/classes';

interface Props extends HTMLAttributes {
  innerRef?: Ref<HTMLInputElement>;
}

export default function Input({innerRef, ...props}: Props) {
  const className = App.useStyle(({theme}) => ({
    background: theme.color.white.toString(),
    fontFamily: theme.font.text,
    border: `0.125rem solid ${theme.color.black.toString()}`,
    fontSize: rem(1),
    lineHeight: rem(2.25),
    padding: '0 0.5rem',
    width: percent(100),
    maxWidth: percent(100),
    ...theme.component.Input,
  }));

  return (
    <input {...props} ref={innerRef} className={classes(className, props.className)}/>
  )
}