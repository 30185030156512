import {h} from 'preact';
import {px, rem} from 'csx';
import {PropRef, useCallback} from 'preact/hooks';
import {ApplicationStyle, HTMLAttributes} from '../../../../framework/type';
import {RowSelection, EditorColumn, EditorColumnType} from './type';
import classes from '../../../../framework/classes';
import App from '../../app';

export interface Props<T> extends HTMLAttributes {
  onColumnResize?(): any;
  highlights?: Array<RowSelection>;
  columns: Array<EditorColumn<T>>;
  rowData: Array<T>;
  tableRef?: PropRef<HTMLTableElement>;
  onClickCell?(column: number, row: number, virtual: boolean, event: MouseEvent): any;
  page?: number;
  virtualRows?: number;
  hideHeader?: boolean;
  forceLabelHeader?: boolean;
  preventSelect?: boolean;
}

export default function EditorTable<T>(props: Props<T>) {
  const {
    columns,
    rowData,
    tableRef,
    onClickCell,
    highlights = [],
    page = 0,
    hideHeader,
    virtualRows = 0,
    forceLabelHeader,
    preventSelect,
    ...htmlProps
  } = props;

  const hasTotals = !!columns.find(column => !!column.renderTotal);
  const visibleColumns = columns.filter(column => !column.hide);
  const totalWidth = visibleColumns.reduce((total, {width}) => total + width, 0);

  const heading = (column: EditorColumn<T>, index: number) => {
    const header = forceLabelHeader
      ? column.label
      : column.renderHeader
        ? column.renderHeader(page, rowData, column.key)
        : column.label;

    return (
      <th
        data-numeric={column.numeric}
        key={`${column.key}_${index}`}
        children={header}
      />
    )
  };

  const totals = (column: EditorColumn<T>, index: number) => {
    const total = column.renderTotal
      ? column.renderTotal(page, rowData, column.key)
      : '';

    return (
      <td
        data-numeric={column.numeric}
        children={total}
      />
    )
  };

  const virtualRow = (_: any, index: number) => (
    <tr data-row={index + rowData.length}>
      {visibleColumns.map(column => (
        <td data-numeric={column.numeric}/>
      ))}
    </tr>
  );

  const row = (row: T, index: number) => {
    const highlightCells = highlights.filter(h => h.row === index);
    const shouldHighlight = !!highlightCells.length;

    return (
      <tr
        data-row={index}
        data-highlight={shouldHighlight}
        children={visibleColumns.map(cell.bind(null, row, highlightCells, index))}
      />
    )
  };

  const cell = (row: T, highlights: Array<RowSelection>, rowIndex: number, column: EditorColumn<T>, columnIndex: number) => {
    // todo re-implement?
    // const shouldHighlight = !!highlights.find(h => h.column === columnIndex);
    const shouldHighlight = false;

    return (
      <td
        data-numeric={column.numeric}
        data-highlight={shouldHighlight}
        children={renderCell(row, column, rowIndex)}
        className={classes(column.className, column.classes && column.classes(row, column.key))}
      />
    )
  };

  const renderCell = (row: T, {key, type, renderCell}: EditorColumn<T>, rowIndex: number) => {
    const value = row[key] as any;

    switch (true) {
      case (!!renderCell):
        return renderCell!(row, rowIndex);

      case (type === EditorColumnType.CURRENCY):
        return `${Math.round(parseFloat(value) * 10000) / 100}`;


      case (type === EditorColumnType.PERCENTAGE):
        return `${value} %`;

      default:
        return value;
    }
  };

  const isVirtual = (row: number) =>
    row >= rowData.length;

  const clickBody = useCallback((event: MouseEvent) => {
    if (!onClickCell) return;
    const cell = (event.target as HTMLElement).closest('td');

    if (cell) {
      event.stopPropagation();

      let column = 0;
      let row = 0;
      let current: HTMLElement | Node | null = cell;
      while ((current = current.previousSibling)) column++;

      current = cell.closest('tr')!;
      while ((current = current.previousSibling)) row++;

      onClickCell(column, row, isVirtual(row), event);
    }
  }, [onClickCell]);

  const className = App.useStyle(({theme}) => {
    const cellRule: ApplicationStyle = {
      margin: 0,
      padding: rem(0.25),
      fontSize: rem(0.75),
      height: rem(1.5),
      userSelect: preventSelect
        ? 'none'
        : 'text',
      cursor: 'default',
      lineHeight: rem(1),
      verticalAlign: 'top',
      textAlign: 'left',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      borderBottom: `0.0625rem solid ${theme.color.gray.toString()}`,
      borderRight: `0.0625rem solid ${theme.color.gray.toString()}`,
    };

    const numericRule: ApplicationStyle = {
      textAlign: 'right',
    };

    const collapseRule: ApplicationStyle = {
      whiteSpace: 'pre',
    };

    return {
      borderCollapse: 'collapse',
      margin: 0,
      padding: 0,
      border: 0,
      tableLayout: 'fixed',
      zIndex: 0,
      background: theme.color.white.toString(),
      fontFamily: theme.font.text,
      fontVariantNumeric: 'tabular-nums',
      // borderLeft: `0.0625rem solid ${theme.color.gray.toString()}`,
      marginTop: hideHeader
        ? '-0.0625rem'
        : 0,
      borderTop: `0.0625rem solid ${theme.color.gray.toString()}`,
      $nest: {
        '& th': {...cellRule, ...collapseRule, fontWeight: 700},
        '& td': cellRule,
        '& tbody td:first-child': {
          borderLeft: `0.0625rem solid ${theme.color.gray.toString()}`,
        },
        '& tr[data-collapse="true"] td': collapseRule,
        '& th[data-numeric="true"]': numericRule,
        '& td[data-numeric="true"]': numericRule,
        '& tr[data-highlight="true"]': {
          background: theme.color.blue.fade(0.1).toString(),
        },
        '& td[data-highlight="true"]': {
          background: theme.color.blue.fade(0.25).toString(),
        },
        '& tfoot td': {
          fontWeight: 700,
          background: theme.color.background.toString(),
          borderBottom: `0.0625rem solid ${theme.color.background.toString()}`,
          borderRight: `0.0625rem solid ${theme.color.background.toString()}`,
        },
      }
    }
  }, [hideHeader]);

  return (
    <table {...htmlProps} style={{width: px(totalWidth)}} ref={tableRef} className={classes(className, props.className)}>
      <colgroup>
        {visibleColumns.map(c => (
          <col span={1} style={{width: px(c.width)}} />
        ))}
      </colgroup>
      {!hideHeader && (
        <thead>
        <tr>{visibleColumns.map(heading)}</tr>
        </thead>
      )}
      <tbody data-page={page} onClick={clickBody}>
      {rowData.map(row)}
      {new Array(virtualRows).fill(1).map(virtualRow)}
      </tbody>
      {hasTotals && (
        <tfoot>
        <tr>{visibleColumns.map(totals)}</tr>
        </tfoot>
      )}
    </table>
  )
}