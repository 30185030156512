import {Fragment, h} from 'preact';
import JobList from '../team/JobList';
import currentTeamJobs from '../../query/currentTeamJobs';
import ButtonGroup from '../form/ButtonGroup';
import CreateJobButton from '../team/CreateJobButton';
import DeleteJobsButton from '../team/DeleteJobsButton';
import TeamStatusLine from '../team/TeamStatusLine';
import currentTeam from '../../query/currentTeam';
import Button from '../form/Button';
import {route} from 'preact-router';
import {IconType} from '../media/Icon';
import ArchiveJobsButton from '../team/ArchiveJobsButton';
import UploadHistoricalJobButton from '../job/UploadHistoricalJobButton';

export default function JobsRoute() {
  const team = currentTeam();
  const [jobs] = currentTeamJobs();

  if (!team) return null;

  return (
    <Fragment>
      <TeamStatusLine team={team} rightAlign={true} small={true}/>
      <JobList
        jobs={jobs.all}
        children={(items, selection, setSelection) => (
          <ButtonGroup>
            <CreateJobButton />
            <UploadHistoricalJobButton />
            {selection && (
              <Button
                small={true}
                tooltip="Edit job"
                onlyIcon={IconType.UPDATE_ITEM}
                onClick={() => route(`/job/${items[selection.row].id}`)}
              />
            )}
            <DeleteJobsButton
              jobs={items}
              onSuccess={() => setSelection(null)}
            />
            <ArchiveJobsButton
              jobs={items}
              onSuccess={() => setSelection(null)}
            />
          </ButtonGroup>
        )}
      />
    </Fragment>
  )
}