import {AnyCommand, CREATE_JOB_SECTION_COMMAND, CreateJobSectionCommand} from '../type';
import App from '../app';

export default function createJobSection(payload: CreateJobSectionCommand['payload']): AnyCommand {
  return {
    type: CREATE_JOB_SECTION_COMMAND,
    payload,
  }
}

export const useCreateJobSectionCommand = App.wrapCommand(createJobSection);