import {AnyCommand, CREATE_JOB_SECTION_ITEM_COMMAND, CreateJobSectionItemCommand} from '../type';
import App from '../app';

export default function createJobSectionItem(payload: CreateJobSectionItemCommand['payload']): AnyCommand {
  return {
    type: CREATE_JOB_SECTION_ITEM_COMMAND,
    payload,
  }
}

export const useCreateJobSectionItemCommand = App.wrapCommand(createJobSectionItem);