import {AnyCommand, UNARCHIVE_JOBS_COMMAND, UnarchiveJobsCommand} from '../type';
import App from '../app';

export default function unarchiveJobs(payload: UnarchiveJobsCommand['payload']): AnyCommand {
  return {
    type: UNARCHIVE_JOBS_COMMAND,
    payload,
  }
}

export const useUnarchiveJobsCommand = App.wrapCommand(unarchiveJobs);