import { Fragment, h } from 'preact';
import renderDate from '../../helper/renderDate';
import App from '../../app';
import useTimedToggle from '../../effect/useTimedToggle';

interface Props {
  date: Date;
}

let rtf: any = null;

try {
  rtf = new (Intl as any).RelativeTimeFormat("en", {
    localeMatcher: "best fit", // other values: "lookup"
    numeric: "always", // other values: "auto"
    style: "long", // other values: "short" or "narrow"
  });
} catch (e) {}

// Format relative time using negative value (-1).
rtf.format(-1, "day");
// > "1 day ago"

// Format relative time using positive  value (1).
rtf.format(1, "day");
// > "in 1 day"

export default function DateRelative({date}: Props) {
  if (!rtf || isNaN(date.getTime())) return (
    <Fragment>{renderDate(date)}</Fragment>
  );

  const showRelative = useTimedToggle();

  const className = App.useStyle(({theme}) => ({
    display: 'inline-block',
  }));

  const dayDifference = Math.floor((date.getTime() - Date.now()) / 1000 / 60 / 60 / 24);

  return (
    <span className={className}>
      <span>{showRelative ? rtf.format(dayDifference, 'day') : renderDate(date)}</span>
    </span>
  );
}