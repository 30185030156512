import {Context, FunctionComponent} from 'preact';
import {NestedCSSProperties, KeyFrames} from 'typestyle/lib/types';
import {JSXInternal} from 'preact/src/jsx';
import {ValidationError} from './error';
import * as admin from 'firebase-admin';

export type HTMLAttributes = JSXInternal.HTMLAttributes;

export interface Application<State, CommandTypes, EventTypes, Services extends ServiceMap<State, CommandTypes, EventTypes>> {
  useServices: ServiceHook<State, CommandTypes, EventTypes, Services>;
  useStyle: StyleHook<State>;
  useKeyframes: KeyframeHook<State>;
  useGlobalStyle: GlobalStyleHook<State>;
  useStyleSheet: StyleSheetHook<State>;
  useQuery: QueryHook<State>;
  useCommand: CommandHook<CommandTypes>;
  wrapCommand: CommandWrapper<CommandTypes>;
  nextCommandId: IdGenerator;
  Container: ApplicationComponent<State, CommandTypes, EventTypes, Services>;
}

export type SystemDispatch = (event: AnyFrameworkEvent) => void;
export type IdGenerator = () => string;
export type ServiceHook<State, CommandTypes, EventTypes, Services extends ServiceMap<State, CommandTypes, EventTypes>> = () => Services;
export type ServiceEventHandler<State, EventTypes> = (state: State, event: EventTypes) => State;
export type ServiceCommandHandler<State, CommandTypes> = (state: State, command: CommandTypes) => AsyncCommandResponse;

export type ProxyCommandHandler<CommandTypes> = (command: CommandTypes) => AsyncCommandResponse;
export interface ProxyCommandContainer<CommandTypes> {
  userId: string;
  teamId?: string;
  command: CommandTypes;
}

export interface EventWrapper<EventTypes> {
  user: string;
  team: string;
  timestamp: admin.firestore.FieldValue | string;
  event: EventTypes
}

export interface DomainCommandWrapper<CommandTypes> {
  commandId: string;
  transaction: admin.firestore.Transaction
}

export interface Service<State, CommandTypes, EventTypes> {
  subscribe(listener: EventFunction<EventTypes>): void;
  unsubscribe(listener: EventFunction<EventTypes>): void;
  register(proxy: ProxyCommandHandler<CommandTypes>): void;
  deregister(proxy: ProxyCommandHandler<CommandTypes>): void;
  dispatch: EventFunction<EventTypes>;
  onEvent: ServiceEventHandler<State, EventTypes>;
  onCommand: ServiceCommandHandler<State, CommandTypes>;
}

export interface ServiceMap<State, CommandTypes, EventTypes> {
  [serviceKey: string]: Service<State, CommandTypes, EventTypes>;
}

export type ApplicationStyle = NestedCSSProperties;
export type ApplicationKeyframe = KeyFrames;
export type ApplicationClassName = string;

export type StyleFunction<State> = (state: State) => ApplicationStyle;
export type KeyframeFunction<State> = (state: State) => ApplicationKeyframe;

export type StyleHook<State> = (
  styleFunction: StyleFunction<State> | ApplicationStyle,
  ...dependencies: any[]
) => ApplicationClassName;

export type KeyframeHook<State> = (
  styleFunction: KeyframeFunction<State> | ApplicationKeyframe,
  ...dependencies: any[]
) => string;

export type GlobalStyleHook<State> = (
  selector: string,
  styleFunction: StyleFunction<State> | ApplicationStyle,
  ...dependencies: any[]
) => void;

export type StyleSheetHook<State> = (
  href: string,
  ...dependencies: any[]
) => void;

export type QueryFunction<State, Response> = (state: State) => Response;
export type QueryHook<State> = <Response>(query: QueryFunction<State, Response>, ...dependencies: any[]) => Response

export enum CommandStatus {
  INITIAL = 'initial',
  RUNNING = 'running',
  NOTIFICATION = 'notify',
  SUCCESS = 'success',
  FAIL = 'fail',
}

export type CommandContainer<CommandTypes> = {
  id: string;
  timestamp: number;
  command: CommandTypes
  status: CommandStatus;
  errors: Error[];
}

export type WatchFunctionUnsubscribe = () => void;
export type EventFunction<EventTypes> = (event: EventTypes) => void;

export type CommandFunction<CommandTypes> = (command: CommandTypes) => void;
export type CommandId = string;
export type IdentifiedCommandFunction<CommandTypes> = (command: CommandTypes) => CommandId;
export type CommandHook<CommandTypes> = () => [CommandContainer<CommandTypes>, CommandFunction<CommandTypes>];

export type CommandResponse = Array<Error>;
export type AsyncCommandResponse = Promise<CommandResponse>;

export interface CommandErrors<T extends Record<string, any>> {
  system: string;
  field: T;
}

export interface CommandNotifier {
  initial: () => void,
  running: () => void,
  success: () => void,
  fail: (errors?: Error[]) => void,
}

export type CommandWrapper<CommandTypes> = <Args extends Array<any>>(commandFn: (...args: Args) => CommandTypes)
  => () => [CommandContainer<CommandTypes>, (...args: Args) => void]

export interface SystemState<CommandTypes> {
  command: Record<string, CommandContainer<CommandTypes>>;
}

export interface ApplicationComponentProps<State, CommandTypes, EventTypes, Services extends ServiceMap<State, CommandTypes, EventTypes>> {
  initialState?: State,
  services?: Services
}
export interface ApplicationComponent<State, CommandTypes, EventTypes, Services extends ServiceMap<State, CommandTypes, EventTypes>>
  extends FunctionComponent<ApplicationComponentProps<State, CommandTypes, EventTypes, Services>> {}

export type ValidationResult = ValidationError[];

export type DispatchContext<CommandTypes> = Context<IdentifiedCommandFunction<CommandTypes>>;
export type StateContext<State> = Context<State>;
export type SystemStateContext<CommandTypes> = Context<SystemState<CommandTypes>>;
export type ServicesContext<State, CommandTypes, EventTypes, Services extends ServiceMap<State, CommandTypes, EventTypes>> = Context<Services>;

export type AnyRecord = Record<string, any>;

export type AnyFrameworkEvent
  = CommandUpdated

export const COMMAND_UPDATED = '__COMMAND_UPDATED';
export interface CommandUpdated {
  type: typeof COMMAND_UPDATED,
  payload: CommandContainer<any>
}