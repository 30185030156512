import { Entity } from '../../../framework/value/Entity';
import { InvoiceId, ItemId, PageId, SectionId, ValuationId } from './App';
import EntityMapValue from '../../../framework/value/EntityMapValue';
import ValuationSection, { ValuationSectionJSON } from './ValuationSection';
import { Job, JobDetails, JobDetailsJSON } from './Job';
import ValuationSectionItem, {
  ValuationSectionItemDetailsJSON,
  ValuationSectionItemType
} from './ValuationSectionItem';
import reduceArrayToIdentityMap from '../helper/reduceArrayToIdentityMap';
import {
  ValuationInvoice,
  ValuationInvoiceDetails,
  ValuationInvoiceItems,
  ValuationInvoiceJSON
} from './ValuationInvoice';
import StringValue from '../../../framework/value/StringValue';
import ValuationInvoiceItem from './ValuationInvoiceItem';
import renderPercentage from '../helper/renderPercentage';

export enum ValuationTab {
  SUMMARY = 'summary',
  PROGRESS = 'progress',
  PURCHASE_ORDERS = 'purchase-orders',
  INVOICES = 'invoices',
}

export const valuationPath = (id: string, part: string = '') =>
  `/job/${id}/valuation${part === ValuationTab.SUMMARY ? '' : `/${part}`}`;

export interface ValuationJSON {
  id: string;
  details: Partial<JobDetailsJSON>;
  sections: Partial<ValuationSectionsJSON>;
  variations: ValuationSectionJSON;
  invoices: Partial<ValuationInvoicesJSON>;
}

export interface ValuationSummaryItem {
  label: string;
  value: number;
}

export const INVOICE_AMOUNT_LABEL = 'Interim Payment Excel VAT';

export class Valuation extends Entity<ValuationId, {
  details: JobDetails;
  sections: ValuationSections;
  variations: ValuationSection;
  invoices: ValuationInvoices;
}> {
  get ref() {
    return this.details.value.ref.value;
  }

  get summary(): Array<ValuationSummaryItem> {
    const valuation = this;

    return [
      {
        label: 'Original Contract Price',
        value: valuation.charge,
      },
      {
        label: 'Revised Contract Price',
        value: valuation.newCharge,
      },
      {
        label: 'Work Completed to Date',
        value: valuation.billable,
      },
      {
        label: `Retention ${renderPercentage(valuation.retention)}`,
        value: valuation.retained,
      },
      {
        label: 'Less Amount Previously Invoiced',
        value: valuation.invoiced,
      },
      {
        label: INVOICE_AMOUNT_LABEL,
        value: valuation.newPayment,
      },
      {
        label: '',
        value: 0
      },
      {
        label: 'Original Contract Price',
        value: valuation.charge,
      },
      {
        label: 'Omit',
        value: valuation.omitted,
      },
      {
        label: 'Adds',
        value: valuation.additional,
      },
      {
        label: 'Revised Contract Sum',
        value: valuation.newCharge,
      },
      {
        label: 'Under Contract Sum',
        value: valuation.underSum,
      },
    ]
  }

  get details() {
    return this.value.details;
  }

  get charge() {
    return this.sections.all.reduce((charge, item) => {
      return charge + item.charge;
    }, 0);
  }

  get omitted() {
    return this.variations.omitted + this.sections.all.reduce((omitted, item) => {
      return omitted + item.omitted;
    }, 0);
  }

  get additional() {
    return this.variations.items.included.reduce((charge, item) => {
      return charge + item.charge;
    }, 0)
  }

  get variation() {
    return 0 - this.omitted + this.additional;
  }

  get newCharge() {
    return this.charge - this.omitted + this.additional;
  }

  get underSum() {
    return this.charge - this.newCharge;
  }

  get billable() {
    return this.variations.billable + this.sections.all.reduce((billable, item) => {
      return billable + item.billable;
    }, 0);
  }

  get newPayment() {
    return this.billable - this.retained - this.invoiced;
  }

  get retained() {
    return (this.retention / 100) * this.billable;
  }

  get retention() {
    return this.details.value.retention.value;
  }

  get invoiced() {
    return this.details.value.invoiced.value;
  }

  get canInvoice() {
    return this.newPayment > 0;
  }

  // TODO: Variation Complete
  get complete() {
    return 100 * (this.billable / this.newCharge)
  }

  get name() {
    return this.value.details.value.name.value || this.id;
  }

  get client() {
    return this.value.details.value.client.value;
  }

  get contact() {
    return this.value.details.value.contactName.value;
  }

  get sections() {
    return this.value.sections;
  }

  get variations() {
    return this.value.variations;
  }

  get allSections() {
    return [
      ...this.sections.all,
      this.variations
    ]
  };

  get invoices() {
    return this.value.invoices;
  }

  get maximumSectionOrder() {
    return this.sections.all.reduce((max, section) => {
      return Math.max(max, section.order);
    }, 0)
  }

  get billableItems(): ValuationInvoiceItem[] {
    const sectionItems = this.sections.billable.flatMap(
      (section, sectionIndex) => section.items.billable.map(
        (item, itemIndex) => ValuationInvoiceItem.fromJSON({
          id: item.id,
          sectionId: section.id,
          sectionName: section.name,
          order: (sectionIndex * 1024) + itemIndex
        }).setItem(item)
      )
    );

    const minOrder = sectionItems.length === 0
      ? 0
      : sectionItems[sectionItems.length - 1].order;

    const additionalItems = this.variations.items.billable.map(
      ((item, itemIndex) => ValuationInvoiceItem.fromJSON({
          id: item.id,
          sectionName: 'Variations',
          order: minOrder + itemIndex,
        }).setItem(item)
      )
    );

    return [
      ...sectionItems,
      ...additionalItems,
    ]
  }

  updateSectionItem(sectionId: SectionId, item: ValuationSectionItem): Valuation {
    let response = this;

    const section = this.sections.get(sectionId.id);
    if (!section) return response;

    return response.extend({
      sections: this.sections.extend({
        [section.id]: section.extend({
          items: section.items.extend({
            [item.id]: item
          })
        })
      })
    });
  }

  updateSectionItemDetails(sectionId: SectionId, itemId: ItemId, details: Partial<ValuationSectionItemDetailsJSON>): Valuation {
    let response = this;

    const section = this.sections.get(sectionId.id);
    if (!section) return response;

    const item = section.items.get(itemId.id);
    if (!item) return response;

    return response.extend({
      sections: this.sections.extend({
        [section.id]: section.extend({
          items: section.items.extend({
            [item.id]: item.update(details)
          })
        })
      })
    });
  }

  updateVariationItem(item: ValuationSectionItem): Valuation {
    let response = this;

    return response.extend({
      variations: response.variations.extend({
        items: response.variations.items.extend({
          [item.id]: item
        })
      })
    });
  }

  updateVariationItemDetails(itemId: ItemId, details: Partial<ValuationSectionItemDetailsJSON>): Valuation {
    let response = this;

    const item = response.variations.items.get(itemId.id);
    if (!item) return response;

    return response.extend({
      variations: response.variations.extend({
        items: response.variations.items.extend({
          [item.id]: item.update(details)
        })
      })
    });
  }

  createInvoice(ref: StringValue): Valuation {
    const {billableItems} = this;
    if (billableItems.length === 0) return this;

    const items = reduceArrayToIdentityMap(billableItems);

    const invoice = new ValuationInvoice({
      id: new InvoiceId(InvoiceId.nextId()),
      items: new ValuationInvoiceItems(items),
      details: new ValuationInvoiceDetails({
        ref,
      })
    });

    const response = billableItems.reduce((valuation, item) => {
      return valuation.updateItemInvoiced(item.sectionId, item.id, item.newPayment)
    }, this);

    // TODO report on omit items

    return response.extend({
      invoices: response.invoices.extend({
        [invoice.id]: invoice
      })
    });
  }

  private updateItemInvoiced(sectionId: string, itemId: string, billed: number) {
    if (!sectionId) {
      const item = this.variations.items.get(itemId);
      if (!item) throw new Error('Missing variation item');

      return this.extend({
        variations: this.variations.extend({
          items: this.variations.items.extend({
            [item.id]: item.setInvoiced(billed + item.invoiced)
          })
        })
      })
    }

    const section = this.sections.get(sectionId);
    if (!section) throw new Error('Missing section');

    const item = section.items.get(itemId);
    if (!item) throw new Error('Missing section item');

    return this.extend({
      sections: this.sections.extend({
        [section.id]: section.extend({
          items: section.items.extend({
            [item.id]: item.setInvoiced(billed + item.invoiced)
          })
        })
      })
    });
  }

  toJSON(): ValuationJSON {
    return super.toJSON() as ValuationJSON;
  }

  static fromJSON(data: Partial<ValuationJSON>) {
    return new Valuation({
      id: new ValuationId(data.id || ''),
      details: JobDetails.fromJSON(data.details || {}),
      sections: ValuationSections.fromJSON(data.sections || {}),
      variations: ValuationSection.fromJSON(data.variations),
      invoices: ValuationInvoices.fromJSON(data.invoices || {}),
    });
  }

  static fromJob(job: Job) {
    const allSections = job.sections.all;

    const sections = reduceArrayToIdentityMap(
      allSections.map(section => {
        const json = section.toJSON();

        const items = reduceArrayToIdentityMap(
          section.items.all.map(item => {
            const json = item.toJSON();

            return ValuationSectionItem.fromJSON({
              ...json,
              details: {
                ...json.details,
                type: ValuationSectionItemType.IMPORTED,
                charge: item.charge,
                complete: item.complete,
                invoiced: 0,
                po: '',
                ci: '',
                omit: item.omit,
              },
            }).toJSON()
          })
        );

        return ValuationSection.fromJSON({
          ...json,
          items,
        }).toJSON()
      })
    );

    const variationItems = reduceArrayToIdentityMap(
      job.variations.all.map(variation => {
        const json = variation.toJSON();
        return ValuationSectionItem.fromJSON({
          ...json,
          details: {
            ...json.details,
            item: `${variation.order}${variation.ci ? ` [${variation.ci}]` : ''}`,
            type: ValuationSectionItemType.VARIATION,
            charge: variation.charge,
            complete: variation.complete,
            invoiced: 0,
            po: '',
            omit: variation.omit,
          }
        }).toJSON()
      })
    );

    const variations = ValuationSection.fromJSON({
      details: {
        name: 'Additionals'
      },
      items: variationItems,
      order: allSections.length,
    }).toJSON();

    return Valuation.fromJSON({
      id: job.id,
      details: job.details.toJSON(),
      sections,
      variations,
    });
  }
}

export class Variations extends EntityMapValue<ValuationId, Valuation> {}

export interface ValuationSectionsJSON extends Record<string, ValuationSectionJSON> {};

export class ValuationSections extends EntityMapValue<PageId, ValuationSection> {
  get all(): ValuationSection[] {
    return Object.keys(this.value).reduce((sections, key) => {
      return [...sections, this.value[key]];
    }, [] as ValuationSection[]).sort((a, b) => a.order < b.order ? -1 : a.order > b.order ? 1 : 0);
  }

  get billable(): ValuationSection[] {
    return this.all.filter(section => section.billable > 0);
  }

  toJSON(): ValuationSectionsJSON {
    return super.toJSON() as ValuationSectionsJSON;
  }

  static fromJSON(json: Partial<ValuationSectionsJSON>) {
    return new ValuationSections(Object.keys(json).reduce((items, id) => {
      return {
        ...items,
        [id]: ValuationSection.fromJSON(json[id])
      };
    }, {} as Record<string, ValuationSection>))
  }
}

export interface ValuationInvoicesJSON extends Record<string, ValuationInvoiceJSON> {};

export class ValuationInvoices extends EntityMapValue<InvoiceId, ValuationInvoice> {
  get all(): ValuationInvoice[] {
    return Object.keys(this.value).reduce((invoices, key) => {
      return [...invoices, this.value[key]];
    }, [] as ValuationInvoice[]).sort((a, b) => a.ref < b.ref ? -1 : a.ref > b.ref ? 1 : 0);
  }

  toJSON(): ValuationInvoicesJSON {
    return super.toJSON() as ValuationInvoicesJSON;
  }

  static fromJSON(json: Partial<ValuationInvoicesJSON>) {
    return new ValuationInvoices(Object.keys(json).reduce((items, id) => {
      return {
        ...items,
        [id]: ValuationInvoice.fromJSON(json[id] || {})
      };
    }, {} as Record<string, ValuationInvoice>))
  }
}