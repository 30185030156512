import Form from '../form/Form';
import {h} from 'preact';
import FieldGroup from '../form/FieldGroup';
import Field from '../form/Field';
import {useCallback, useEffect, useRef, useState} from 'preact/hooks';
import Button from '../form/Button';
import autoFocus from '../../effect/autoFocus';
import jobFileStorageUpload from '../../effect/jobFileStorageUpload';
import FileInput from '../form/FileInput';
import {JobAsset, JobAssetType} from '../../model/JobAssets';
import {CommandStatus} from '../../../../framework/type';
import useCommandErrors from '../../../../framework/hook/useCommandErrors';
import commandsAreRunning from '../../../../framework/commandsAreRunning';
import {Job} from '../../model/Job';
import JobSection from '../../model/JobSection';
import Paragraph from '../media/Paragraph';
import {useLoadJobsCommand} from '../../command/loadJobs';
import {useDeleteJobSectionsCommand} from '../../command/deleteJobSections';
import currentUserTeam from '../../query/currentUserTeam';

interface Props {
  job: Job;
  sections: JobSection[];
  onCancel(): void;
  onSuccess(): void;
}

export default function DeleteSectionsForm({job, sections, onCancel, onSuccess}: Props) {
  const team = currentUserTeam();

  const [command, deleteSections] = useDeleteJobSectionsCommand();
  const {system} = useCommandErrors(command);
  const isLoading = commandsAreRunning(command);

  const submit = useCallback(() => {
    if (sections.length === 0 || isLoading || !team) return;

    deleteSections({
      teamId: team.id,
      jobId: job.id,
      sectionIds: sections.map(section => section.id)
    })
  }, [job, team, sections, isLoading, deleteSections]);

  useEffect(() => {
    if (!command || command.status !== CommandStatus.SUCCESS) return;
    onSuccess();
  }, [command]);


  return (
    <Form heading="Delete Sections" onSubmit={submit} error={system}>
      <Paragraph>This will permanently delete the following sections, along with any items within them:</Paragraph>
      <ul>
        {sections.map(section => (
          <li>{section.name}</li>
        ))}
      </ul>
      <Paragraph>Are you sure?</Paragraph>
      <FieldGroup horizontal={true}>
        <Button danger={true} type="submit" disabled={isLoading} onClick={submit}>Delete</Button>
        <Button type="cancel" onClick={onCancel}>Cancel</Button>
      </FieldGroup>
    </Form>
  )
}