import {PropRef} from 'preact/hooks';

export default function synchroniseScrolling(
  ref: PropRef<HTMLElement>,
  next?: (event: Event) => any,
) {
  return (event: Event) => {
    if (!ref || !ref.current || !event.currentTarget) return;
    const target = (event.currentTarget as HTMLElement);
    ref.current.scrollLeft = target.scrollLeft;
    if (next) next(event);
  }
}