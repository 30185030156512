import {
  ALLOCATE_JOB_COMPANY_COMMAND, ALLOCATE_VARIATION_COMPANY_COMMAND,
  AllocateJobCompanyCommand, AllocateVariationCompanyCommand,
  CREATE_JOB_COMPANY_COMMAND,
  CreateJobCompanyCommand,
  DELETE_JOB_COMPANIES_COMMAND,
  DeleteJobCompaniesCommand,
  JOB_COMPANIES_DELETED_EVENT,
  JOB_COMPANY_ALLOCATED_EVENT,
  JOB_COMPANY_CREATED_EVENT,
  JOB_COMPANY_UPDATED_EVENT, JOB_VARIATION_ALLOCATED_EVENT,
  JobCompaniesDeletedEvent,
  JobCompanyAllocatedEvent,
  JobCompanyCreatedEvent,
  JobCompanyService,
  JobCompanyUpdatedEvent, JobVariationCompanyAllocatedEvent,
  UPDATE_JOB_COMPANY_COMMAND,
  UpdateJobCompanyCommand
} from '../../type';
import { AsyncCommandResponse } from '../../../../framework/type';
import { JobCostingsAppService } from '../../services';
import { State } from '../../model/State';
import { CompanyId, ItemId, JobId, TeamId, VariationId } from '../../model/App';
import { JobCompanyDetails } from '../../model/JobCompany';
import { TeamJobs } from '../../model/Team';
import { Job } from '../../model/Job';
import jobCompanyCreated from '../../event/jobCompanyCreated';
import jobCompanyUpdated from '../../event/jobCompanyUpdated';
import jobCompaniesDeleted from '../../event/jobCompaniesDeleted';
import jobCompanyAllocated from '../../event/jobCompanyAllocated';
import jobVariationCompanyAllocated from '../../event/jobVariationCompanyAllocated';

export default class AppJobCompanyService extends JobCostingsAppService implements JobCompanyService {
  async [CREATE_JOB_COMPANY_COMMAND](state: State, command: CreateJobCompanyCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
      new CompanyId(command.payload.companyId),
      JobCompanyDetails.fromJSON(command.payload.details),
    ], async (teamId: TeamId, jobId: JobId, companyId: CompanyId, details: JobCompanyDetails) => {
      this.dispatch(jobCompanyCreated({
        teamId: teamId.id,
        jobId: jobId.id,
        companyId: companyId.id,
        details: command.payload.details
      }));

      return this.proxy(command);
    });
  }

  async [UPDATE_JOB_COMPANY_COMMAND](state: State, command: UpdateJobCompanyCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
      new CompanyId(command.payload.companyId),
      JobCompanyDetails.fromJSON(command.payload.details),
    ], async (teamId: TeamId, jobId: JobId, companyId: CompanyId, details: JobCompanyDetails) => {
      this.dispatch(jobCompanyUpdated({
        teamId: teamId.id,
        jobId: jobId.id,
        companyId: companyId.id,
        details: command.payload.details
      }));

      return this.proxy(command);
    });
  }

  async [DELETE_JOB_COMPANIES_COMMAND](state: State, command: DeleteJobCompaniesCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
      ...command.payload.companyIds.map(c => new CompanyId(c)),
    ], async (teamId: TeamId, jobId: JobId, ...companyIds: CompanyId[]) => {
      this.dispatch(jobCompaniesDeleted({
        teamId: teamId.id,
        jobId: jobId.id,
        companyIds: command.payload.companyIds
      }));

      return this.proxy(command);
    });
  }

  async [ALLOCATE_JOB_COMPANY_COMMAND](state: State, command: AllocateJobCompanyCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
      new CompanyId(command.payload.companyId),
      ...command.payload.itemIds.map(c => new ItemId(c)),
    ], async (teamId: TeamId, jobId: JobId, companyId: CompanyId, ...itemIds: ItemId[]) => {
      this.dispatch(jobCompanyAllocated({
        teamId: teamId.id,
        jobId: jobId.id,
        companyId: companyId.id,
        itemIds: command.payload.itemIds,
        company: command.payload.company,
      }));

      return this.proxy(command);
    });
  }

  async [ALLOCATE_VARIATION_COMPANY_COMMAND](state: State, command: AllocateVariationCompanyCommand): AsyncCommandResponse {
    return this.execute([
      new TeamId(command.payload.teamId),
      new JobId(command.payload.jobId),
      new CompanyId(command.payload.companyId),
      ...command.payload.variationIds.map(c => new VariationId(c)),
    ], async (teamId: TeamId, jobId: JobId, companyId: CompanyId, ...variationIds: ItemId[]) => {
      return this.proxyOptimisticDispatch(command, jobVariationCompanyAllocated({
        teamId: teamId.id,
        jobId: jobId.id,
        companyId: companyId.id,
        variationIds: command.payload.variationIds,
        company: command.payload.company,
      }));
    });
  }

  [JOB_COMPANY_CREATED_EVENT](state: State, event: JobCompanyCreatedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = team.jobs.get(event.payload.jobId);
    if (!job) return state;

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(
          job.createCompany(
            new CompanyId(event.payload.companyId),
            event.payload.details,
          )
        )
      }))
    })
  }

  [JOB_COMPANY_UPDATED_EVENT](state: State, event: JobCompanyUpdatedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = team.jobs.get(event.payload.jobId);
    if (!job) return state;

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(
          job.updateCompany(
            new CompanyId(event.payload.companyId),
            event.payload.details
          )
        )
      }))
    })
  }

  [JOB_COMPANIES_DELETED_EVENT](state: State, event: JobCompaniesDeletedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = team.jobs.get(event.payload.jobId);
    if (!job) return state;

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(
          job.deleteCompanies(
            event.payload.companyIds
          )
        )
      }))
    })
  }

  [JOB_COMPANY_ALLOCATED_EVENT](state: State, event: JobCompanyAllocatedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = team.jobs.get(event.payload.jobId);
    if (!job) return state;

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(
          job.allocateCompanyItems(
            new CompanyId(event.payload.companyId),
            event.payload.itemIds,
            event.payload.company
          )
        )
      }))
    })
  }

  [JOB_VARIATION_ALLOCATED_EVENT](state: State, event: JobVariationCompanyAllocatedEvent) {
    const team = state.team.get(event.payload.teamId);
    if (!team) return state;

    const job = team.jobs.get(event.payload.jobId);
    if (!job) return state;

    return state.extend({
      team: state.team.replace(team.extend({
        jobs: team.jobs.replace<Job, TeamJobs>(
          job.allocateVariationItems(
            new CompanyId(event.payload.companyId),
            event.payload.variationIds,
            event.payload.company
          )
        )
      }))
    })
  }
}