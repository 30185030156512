import pushId from '../../framework/vendor/pushId';
import {Value} from './Value';

export default class Identity<T = string> extends Value<T> {
  static INCORRECT_FORMAT = 'Not a string';

  get id() {
    return this.value;
  }

  validate() {
    return this.collect([
      ...super.validate(),
      // (!this.value) && this.error('Identity is not set')
    ])
  }

  static NULL = new Identity('');
  static nextId = () => pushId();
}