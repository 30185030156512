import {h} from 'preact';
import {HTMLAttributes} from '../../../../framework/type';
import App from '../../app';
import classes from '../../../../framework/classes';
import Heading from '../media/Heading';
import FieldDescription from './FieldDescription';
import {percent} from 'csx';

interface Props extends HTMLAttributes {
  heading?: string;
  error?: string
  description?: string;
  fill?: boolean;
}

export default function Form({error, fill, heading, description, onSubmit, children, ...props}: Props) {
  const submit = (event: Event) => {
    event.preventDefault();
    if (onSubmit) onSubmit(event);
  };

  const className = App.useStyle(({theme}) => ({
    width: fill ? percent(100) : 'unset'
  }), [error, description]);

  return (
    <form {...props} onSubmit={submit} className={classes(className, props.className)}>
      {heading && <Heading level={3}>{heading}</Heading>}
      {children}
      <FieldDescription error={error}>{description}</FieldDescription>
    </form>
  )
}