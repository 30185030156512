import {
  Application,
  DomainCommandWrapper,
  EventFunction,
  EventWrapper,
  Service,
  ServiceCommandHandler,
  ServiceEventHandler,
  ServiceMap,
  WatchFunctionUnsubscribe
} from '../../framework/type';
import {UserTeamJSON} from './model/User';
import {State} from './model/State';
import {JobDetailsJSON, JobJSON} from './model/Job';
import {TeamJSON, TeamUserJSON} from './model/Team';
import {JobFile} from './model/JobFile';
import * as firebase from 'firebase/app';
import {JobImportJSON, JobImportMappingJSON} from './model/JobImport';
import {EditorSettings} from './component/editor/type';
import {Entity} from '../../framework/value/Entity';
import Identity from '../../framework/value/Identity';
import {Value} from '../../framework/value/Value';
import {JobSectionDetailsJSON, JobSectionJSON} from './model/JobSection';
import {
  JobSectionItemCompanyJSON,
  JobSectionItemDetailsJSON,
  JobSectionItemJSON,
  JobSectionItemStatusJSON
} from './model/JobSectionItem';
import {JobAssetsJSON} from './model/JobAssets';
import {JobCompanyDetailsJSON} from './model/JobCompany';
import {
  JobVariationItemCompanyJSON,
  JobVariationItemDetailsJSON,
  JobVariationItemJSON
} from './model/JobVariationItem';

export type JobCostingsApplication = Application<State, AnyCommand, AnyEvent, AppServices>;

export const Collection = {
  COMMANDS: 'commands',
  EVENTS: 'events',
  USERS: 'users',
  TEAMS: 'teams',
  JOBS: 'jobs',
  ARCHIVE: 'archive',
  TEMPLATES: 'templates',
  DELETED_JOBS: 'deleted_jobs',
  SECTIONS: 'sections',
  ITEMS: 'items',
  FILES: 'files',
  IMPORTS: 'imports'
};

export interface Repo<
  ID extends Identity,
  T extends Record<string, Value<any>>,
  E extends Entity<ID, T>
>{
  get(id: ID): AsyncResult<E>;
  batch(ids: ID[]): AsyncResult<NullableArray<E>>;
}

export type NullableArray<T> = Array<T | null>;

export interface AsyncResultSuccess<T> {
  data: T
}

export interface AsyncResultFailure {
  errors: Error[]
}

export type AsyncResult<T> = Promise<AsyncResultSuccess<T>> | Promise<AsyncResultFailure>

/** Commands */

export const RECEIVE_EVENT_COMMAND = 'RECEIVE_EVENT_COMMAND';
export interface ReceiveEventCommand {
  type: typeof RECEIVE_EVENT_COMMAND,
  payload: EventWrapper<AnyEvent>;
}

export const SIGN_OUT_COMMAND = 'SIGN_OUT_COMMAND';
export interface SignOutCommand {
  type: typeof SIGN_OUT_COMMAND
}

export const START_SIGN_IN_WITH_GOOGLE_COMMAND = 'START_SIGN_IN_WITH_GOOGLE_COMMAND';
export interface StartSignInWithGoogleCommand {
  type: typeof START_SIGN_IN_WITH_GOOGLE_COMMAND
}

export const START_SIGN_IN_WITH_EMAIL_COMMAND = 'START_SIGN_IN_WITH_EMAIL_COMMAND';
export interface StartSignInWithEmailCommand {
  type: typeof START_SIGN_IN_WITH_EMAIL_COMMAND,
  payload: {
    redirectUrl: string;
    email: string;
  }
}

export const COMPLETE_SIGN_IN_WITH_EMAIL_COMMAND = 'COMPLETE_SIGN_IN_WITH_EMAIL_COMMAND';
export interface CompleteSignInWithEmailCommand {
  type: typeof COMPLETE_SIGN_IN_WITH_EMAIL_COMMAND,
  payload: {
    linkUrl: string;
    email: string;
  }
}

export const LOAD_USER_TEAMS_COMMAND = 'LOAD_USER_TEAMS_COMMAND';
export interface LoadUserTeamsCommand {
  type: typeof LOAD_USER_TEAMS_COMMAND,
  payload: {
    userId: string
  }
}

export const CREATE_TEAM_COMMAND = 'CREATE_TEAM_COMMAND';
export interface CreateTeamCommand {
  type: typeof CREATE_TEAM_COMMAND,
  payload: {
    userId: string;
    userName: string;
    userColor: number;
    teamName: string;
  }
}

export const SELECT_TEAM_COMMAND = 'SELECT_TEAM_COMMAND';
export interface SelectTeamCommand {
  type: typeof SELECT_TEAM_COMMAND,
  payload: {
    teamId: string;
  }
}

export const CLEAR_TEAM_COMMAND = 'CLEAR_TEAM_COMMAND';
export interface ClearTeamCommand {
  type: typeof CLEAR_TEAM_COMMAND
}

export const LOAD_JOBS_COMMAND = 'LOAD_JOBS_COMMAND';
export interface LoadJobsCommand {
  type: typeof LOAD_JOBS_COMMAND,
  payload: {
    teamId: string;
  }
}

export const LOAD_ARCHIVE_COMMAND = 'LOAD_ARCHIVE_COMMAND';
export interface LoadArchiveCommand {
  type: typeof LOAD_ARCHIVE_COMMAND,
  payload: {
    teamId: string;
  }
}

export const LOAD_TEMPLATES_COMMAND = 'LOAD_TEMPLATES_COMMAND';
export interface LoadTemplatesCommand {
  type: typeof LOAD_TEMPLATES_COMMAND,
  payload: {
    teamId: string;
  }
}

export const IMPORT_JOB_COMMAND = 'IMPORT_JOB_COMMAND';
export interface ImportJobCommand {
  type: typeof IMPORT_JOB_COMMAND,
  payload: {
    teamId: string;
    job: JobJSON;
  }
}

export const CREATE_JOB_COMMAND = 'CREATE_JOB_COMMAND';
export interface CreateJobCommand {
  type: typeof CREATE_JOB_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    templateId?: string;
    details: Partial<JobDetailsJSON>;
  }
}

export const UPDATE_JOB_COMMAND = 'UPDATE_JOB_COMMAND';
export interface UpdateJobCommand {
  type: typeof UPDATE_JOB_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    details: Partial<JobDetailsJSON>;
  }
}

export const DELETE_JOBS_COMMAND = 'DELETE_JOBS_COMMAND';
export interface DeleteJobsCommand {
  type: typeof DELETE_JOBS_COMMAND,
  payload: {
    teamId: string;
    jobIds: string[];
  }
}

export const ARCHIVE_JOBS_COMMAND = 'ARCHIVE_JOBS_COMMAND';
export interface ArchiveJobsCommand {
  type: typeof ARCHIVE_JOBS_COMMAND,
  payload: {
    teamId: string;
    jobIds: string[];
  }
}

export const UNARCHIVE_JOBS_COMMAND = 'UNARCHIVE_JOBS_COMMAND';
export interface UnarchiveJobsCommand {
  type: typeof UNARCHIVE_JOBS_COMMAND,
  payload: {
    teamId: string;
    jobIds: string[];
  }
}

export const LOAD_JOB_COMMAND = 'LOAD_JOB_COMMAND';
export interface LoadJobCommand {
  type: typeof LOAD_JOB_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
  }
}

export const SAVE_JOB_TEMPLATE_COMMAND = 'SAVE_JOB_TEMPLATE_COMMAND';
export interface SaveJobTemplateCommand {
  type: typeof SAVE_JOB_TEMPLATE_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    templateName: string;
  }
}

export const START_JOB_FILE_UPLOAD_COMMAND = 'START_JOB_FILE_UPLOAD_COMMAND';
export interface StartJobFileUploadCommand {
  type: typeof START_JOB_FILE_UPLOAD_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    fileId: string;
    fileName: string;
    fileType: string;
    filePath: string;
  }
}

export const FAIL_JOB_FILE_UPLOAD_COMMAND = 'FAIL_JOB_FILE_UPLOAD_COMMAND';
export interface FailJobFileUploadCommand {
  type: typeof FAIL_JOB_FILE_UPLOAD_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    fileId: string;
    error: string;
  }
}

export const COMPLETE_JOB_FILE_UPLOAD_COMMAND = 'COMPLETE_JOB_FILE_UPLOAD_COMMAND';
export interface CompleteJobFileUploadCommand {
  type: typeof COMPLETE_JOB_FILE_UPLOAD_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    fileId: string;
  }
}


export const LOAD_JOB_IMPORT_COMMAND = 'LOAD_JOB_IMPORT_COMMAND';
export interface LoadJobImportCommand {
  type: typeof LOAD_JOB_IMPORT_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    fileId: string;
    source: string;
  }
}

export const PREPARE_JOB_SECTIONS_IMPORT_COMMAND = 'PREPARE_JOB_SECTIONS_IMPORT_COMMAND';
export interface PrepareJobSectionsImportCommand {
  type: typeof PREPARE_JOB_SECTIONS_IMPORT_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    fileId: string;
  }
}

export const CREATE_JOB_SECTION_COMMAND = 'CREATE_JOB_SECTION_COMMAND';
export interface CreateJobSectionCommand {
  type: typeof CREATE_JOB_SECTION_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    sectionId: string;
    sectionName: string;
  }
}

export const UPDATE_JOB_SECTION_COMMAND = 'UPDATE_JOB_SECTION_COMMAND';
export interface UpdateJobSectionCommand {
  type: typeof UPDATE_JOB_SECTION_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    sectionId: string;
    details: Partial<JobSectionDetailsJSON>;
  }
}

export const DELETE_JOB_SECTIONS_COMMAND = 'DELETE_JOB_SECTIONS_COMMAND';
export interface DeleteJobSectionsCommand {
  type: typeof DELETE_JOB_SECTIONS_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    sectionIds: string[];
  }
}

export const REORDER_JOB_SECTION_COMMAND = 'REORDER_JOB_SECTION_COMMAND';
export interface ReorderJobSectionCommand {
  type: typeof REORDER_JOB_SECTION_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    sectionId: string;
    order: number;
  }
}

export const IMPORT_JOB_SECTIONS_COMMAND = 'IMPORT_JOB_SECTIONS_COMMAND';
export interface ImportJobSectionsCommand {
  type: typeof IMPORT_JOB_SECTIONS_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    fileId: string;
    mapping: JobImportMappingJSON
  }
}

export const CREATE_JOB_SECTION_ITEM_COMMAND = 'CREATE_JOB_SECTION_ITEM_COMMAND';
export interface CreateJobSectionItemCommand {
  type: typeof CREATE_JOB_SECTION_ITEM_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    sectionId: string;
    itemId: string;
    details: Partial<JobSectionItemDetailsJSON>;
  }
}

export const UPDATE_JOB_SECTION_ITEM_COMMAND = 'UPDATE_JOB_SECTION_ITEM_COMMAND';
export interface UpdateJobSectionItemCommand {
  type: typeof UPDATE_JOB_SECTION_ITEM_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    sectionId: string;
    itemId: string;
    details: Partial<JobSectionItemDetailsJSON>;
  }
}

export const REORDER_JOB_SECTION_ITEM_COMMAND = 'REORDER_JOB_SECTION_ITEM_COMMAND';
export interface ReorderJobSectionItemCommand {
  type: typeof REORDER_JOB_SECTION_ITEM_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    sectionId: string;
    itemId: string;
    order: number;
  }
}

export const UPDATE_JOB_SECTION_ITEM_STATUS_COMMAND = 'UPDATE_JOB_SECTION_ITEM_STATUS_COMMAND';
export interface UpdateJobSectionItemStatusCommand {
  type: typeof UPDATE_JOB_SECTION_ITEM_STATUS_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    sectionId: string;
    itemId: string;
    status: Partial<JobSectionItemStatusJSON>;
  }
}

export const DELETE_JOB_SECTION_ITEMS_COMMAND = 'DELETE_JOB_SECTION_ITEMS_COMMAND';
export interface DeleteJobSectionItemsCommand {
  type: typeof DELETE_JOB_SECTION_ITEMS_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    sectionId: string;
    itemIds: string[];
  }
}

export const CREATE_JOB_COMPANY_COMMAND = 'CREATE_JOB_COMPANY_COMMAND';
export interface CreateJobCompanyCommand {
  type: typeof CREATE_JOB_COMPANY_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    companyId: string;
    details: Partial<JobCompanyDetailsJSON>;
  }
}

export const UPDATE_JOB_COMPANY_COMMAND = 'UPDATE_JOB_COMPANY_COMMAND';
export interface UpdateJobCompanyCommand {
  type: typeof UPDATE_JOB_COMPANY_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    companyId: string;
    details: Partial<JobCompanyDetailsJSON>
  }
}

export const DELETE_JOB_COMPANIES_COMMAND = 'DELETE_JOB_COMPANIES_COMMAND';
export interface DeleteJobCompaniesCommand {
  type: typeof DELETE_JOB_COMPANIES_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    companyIds: string[];
  }
}

export const ALLOCATE_JOB_COMPANY_COMMAND = 'ALLOCATE_JOB_COMPANY_COMMAND';
export interface AllocateJobCompanyCommand {
  type: typeof ALLOCATE_JOB_COMPANY_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    companyId: string;
    itemIds: string[];
    company: JobSectionItemCompanyJSON
  }
}

export const CREATE_JOB_VARIATION_ITEM_COMMAND = 'CREATE_JOB_VARIATION_ITEM_COMMAND';
export interface CreateJobVariationItemCommand {
  type: typeof CREATE_JOB_VARIATION_ITEM_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    variationId: string;
    details: Partial<JobVariationItemDetailsJSON>;
  }
}

export const UPDATE_JOB_VARIATION_ITEM_COMMAND = 'UPDATE_JOB_VARIATION_ITEM_COMMAND';
export interface UpdateJobVariationItemCommand {
  type: typeof UPDATE_JOB_VARIATION_ITEM_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    variationId: string;
    details: Partial<JobVariationItemDetailsJSON>;
  }
}

export const REORDER_JOB_VARIATION_ITEM_COMMAND = 'REORDER_JOB_VARIATION_ITEM_COMMAND';
export interface ReorderJobVariationItemCommand {
  type: typeof REORDER_JOB_VARIATION_ITEM_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    variationId: string;
    order: number;
  }
}

export const DELETE_JOB_VARIATION_ITEMS_COMMAND = 'DELETE_JOB_VARIATION_ITEMS_COMMAND';
export interface DeleteJobVariationItemsCommand {
  type: typeof DELETE_JOB_VARIATION_ITEMS_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    variationIds: string[];
  }
}

export const ALLOCATE_VARIATION_COMPANY_COMMAND = 'ALLOCATE_VARIATION_COMPANY_COMMAND';
export interface AllocateVariationCompanyCommand {
  type: typeof ALLOCATE_VARIATION_COMPANY_COMMAND,
  payload: {
    teamId: string;
    jobId: string;
    companyId: string;
    variationIds: string[];
    company: JobVariationItemCompanyJSON
  }
}

export const FINALISE_SCHEDULE = 'FINALISE_SCHEDULE';
export interface FinaliseScheduleCommand {
  type: typeof FINALISE_SCHEDULE,
  payload: {
    teamId: string;
    jobId: string;
  }
}

export const FINALISE_COSTINGS = 'FINALISE_COSTINGS';
export interface FinaliseCostingsCommand {
  type: typeof FINALISE_COSTINGS,
  payload: {
    teamId: string;
    jobId: string;
  }
}

export const FINALISE_VALUATION = 'FINALISE_VALUATION';
export interface FinaliseValuationCommand {
  type: typeof FINALISE_VALUATION,
  payload: {
    teamId: string;
    jobId: string;
  }
}

export const INVITE_TEAM_USER_COMMAND = 'INVITE_TEAM_USER_COMMAND';
export interface InviteTeamUserCommand {
  type: typeof INVITE_TEAM_USER_COMMAND,
  payload: {
    teamId: string;
    userName: string;
    userEmail: string;
  }
}

export const JOIN_TEAM_COMMAND = 'JOIN_TEAM_COMMAND';
export interface JoinTeamCommand {
  type: typeof JOIN_TEAM_COMMAND,
  payload: {
    inviteCode: string;
    userName: string;
  }
}

export type AnyCommand
  = ReceiveEventCommand
  | SignOutCommand
  | StartSignInWithGoogleCommand
  | StartSignInWithEmailCommand
  | CompleteSignInWithEmailCommand
  | LoadUserTeamsCommand
  | CreateTeamCommand
  | SelectTeamCommand
  | ClearTeamCommand
  | LoadJobsCommand
  | LoadArchiveCommand
  | LoadTemplatesCommand
  | CreateJobCommand
  | ImportJobCommand
  | UpdateJobCommand
  | ArchiveJobsCommand
  | UnarchiveJobsCommand
  | DeleteJobsCommand
  | LoadJobCommand
  | SaveJobTemplateCommand
  | StartJobFileUploadCommand
  | FailJobFileUploadCommand
  | CompleteJobFileUploadCommand
  | LoadJobImportCommand
  | PrepareJobSectionsImportCommand
  | CreateJobSectionCommand
  | UpdateJobSectionCommand
  | ReorderJobSectionCommand
  | DeleteJobSectionsCommand
  | ImportJobSectionsCommand
  | CreateJobSectionItemCommand
  | UpdateJobSectionItemCommand
  | ReorderJobSectionItemCommand
  | UpdateJobSectionItemStatusCommand
  | DeleteJobSectionItemsCommand
  | CreateJobVariationItemCommand
  | UpdateJobVariationItemCommand
  | ReorderJobVariationItemCommand
  | DeleteJobVariationItemsCommand
  | AllocateVariationCompanyCommand
  | CreateJobCompanyCommand
  | UpdateJobCompanyCommand
  | DeleteJobCompaniesCommand
  | AllocateJobCompanyCommand
  | FinaliseScheduleCommand
  | FinaliseCostingsCommand
  | FinaliseValuationCommand
  | InviteTeamUserCommand
  | JoinTeamCommand



/** Events */

export const SIGN_IN_EMAIL_SENT_EVENT = 'SIGN_IN_EMAIL_SENT_EVENT';
export interface SignInEmailSentEvent {
  type: typeof SIGN_IN_EMAIL_SENT_EVENT,
  payload: {
    email: string
  }
}

export const SIGN_IN_FAILED_EVENT = 'SIGN_IN_FAILED_EVENT';
export interface SignInFailedEvent {
  type: typeof SIGN_IN_FAILED_EVENT,
  payload: {
    method: string;
    reason: string;
  }
}

export const USER_SIGNED_IN_EVENT = 'USER_SIGNED_IN_EVENT';
export interface UserSignedInEvent {
  type: typeof USER_SIGNED_IN_EVENT,
  payload: {
    userId: string,
    userName: string,
    userEmail: string;
    isNewUser: boolean,
  }
}

export const CURRENT_USER_SIGNED_OUT_EVENT = 'CURRENT_USER_SIGNED_OUT_EVENT';
export interface CurrentUserSignedOutEvent {
  type: typeof CURRENT_USER_SIGNED_OUT_EVENT
}

export const USER_STATUS_UPDATED_EVENT = 'USER_STATUS_UPDATED_EVENT';
export interface UserStatusUpdatedEvent {
  type: typeof USER_STATUS_UPDATED_EVENT,
  payload: {
    userId: string;
    loading: boolean;
  }
}

export const USER_TEAMS_LOADED_EVENT = 'USER_TEAMS_LOADED_EVENT';
export interface UserTeamsLoadedEvent {
  type: typeof USER_TEAMS_LOADED_EVENT,
  payload: {
    userId: string;
    teams: Array<UserTeamJSON>
  }
}

export const USER_TEAM_JOINED_EVENT = 'USER_TEAM_JOINED_EVENT';
export interface UserTeamJoinedEvent {
  type: typeof USER_TEAM_JOINED_EVENT,
  payload: {
    userId: string;
    team: UserTeamJSON
  }
}

export const TEAM_CREATED_EVENT = 'TEAM_CREATED_EVENT';
export interface TeamCreatedEvent {
  type: typeof TEAM_CREATED_EVENT,
  payload: {
    userId: string;
    userName: string;
    userColor: number;
    teamName: string;
    teamId: string;
  }
}

export const TEAM_SELECTED_EVENT = 'TEAM_SELECTED_EVENT';
export interface TeamSelectedEvent {
  type: typeof TEAM_SELECTED_EVENT,
  payload: {
    teamId: string;
    team: TeamJSON;
  }
}

export const TEAM_CLEARED_EVENT = 'TEAM_CLEARED_EVENT';
export interface TeamClearedEvent {
  type: typeof TEAM_CLEARED_EVENT
}

export const JOBS_LOADED_EVENT = 'JOBS_LOADED_EVENT';
export interface JobsLoadedEvent {
  type: typeof JOBS_LOADED_EVENT,
  payload: {
    teamId: string;
    jobs: Array<JobJSON>
  }
}

export const ARCHIVE_LOADED_EVENT = 'ARCHIVE_LOADED_EVENT';
export interface ArchiveLoadedEvent {
  type: typeof ARCHIVE_LOADED_EVENT,
  payload: {
    teamId: string;
    jobs: Array<JobJSON>;
  }
}

export const TEMPLATES_LOADED_EVENT = 'TEMPLATES_LOADED_EVENT';
export interface TemplatesLoadedEvent {
  type: typeof TEMPLATES_LOADED_EVENT,
  payload: {
    teamId: string;
    templates: Array<JobJSON>
  }
}

export const JOB_CREATED_EVENT = 'JOB_CREATED_EVENT';
export interface JobCreatedEvent {
  type: typeof JOB_CREATED_EVENT,
  payload: {
    userId: string;
    teamId: string;
    jobId: string;
    job: JobJSON;
  }
}

export const JOB_UPDATED_EVENT = 'JOB_UPDATED_EVENT';
export interface JobUpdatedEvent {
  type: typeof JOB_UPDATED_EVENT,
  payload: {
    teamId: string;
    jobId: string;
    userId: string;
    details: Partial<JobDetailsJSON>;
  }
}

export const JOBS_DELETED_EVENT = 'JOBS_DELETED_EVENT';
export interface JobsDeletedEvent {
  type: typeof JOBS_DELETED_EVENT,
  payload: {
    teamId: string;
    jobIds: string[];
  }
}

export const JOBS_ARCHIVED_EVENT = 'JOBS_ARCHIVED_EVENT';
export interface JobsArchivedEvent {
  type: typeof JOBS_ARCHIVED_EVENT,
  payload: {
    teamId: string;
    jobIds: string[];
  }
}

export const JOBS_UNARCHIVED_EVENT = 'JOBS_UNARCHIVED_EVENT';
export interface JobsUnarchivedEvent {
  type: typeof JOBS_UNARCHIVED_EVENT,
  payload: {
    teamId: string;
    jobIds: string[];
  }
}

export const JOB_LOADED_EVENT = 'JOB_LOADED_EVENT';
export interface JobLoadedEvent {
  type: typeof JOB_LOADED_EVENT,
  payload: {
    teamId: string;
    job: JobJSON
  }
}

export const JOB_TEMPLATE_SAVED_EVENT = 'JOB_TEMPLATE_SAVED_EVENT';
export interface JobTemplateSavedEvent {
  type: typeof JOB_TEMPLATE_SAVED_EVENT,
  payload: {
    teamId: string;
    templateId: string;
    template: JobJSON
  }
}

export const JOB_FILE_UPLOAD_STARTED = 'JOB_FILE_UPLOAD_STARTED';
export interface JobFileUploadStartedEvent {
  type: typeof JOB_FILE_UPLOAD_STARTED,
  payload: {
    teamId: string;
    jobId: string;
    fileId: string;
    fileName: string;
    fileType: string;
    filePath: string;
  }
}

export const JOB_FILE_UPLOAD_UPDATED = 'JOB_FILE_UPLOAD_UPDATED';
export interface JobFileUploadUpdatedEvent {
  type: typeof JOB_FILE_UPLOAD_UPDATED,
  payload: {
    teamId: string;
    jobId: string;
    fileId: string;
    progress: number;
  }
}

export const JOB_FILE_UPLOAD_FAILED = 'JOB_FILE_UPLOAD_FAILED';
export interface JobFileUploadFailedEvent {
  type: typeof JOB_FILE_UPLOAD_FAILED,
  payload: {
    teamId: string;
    jobId: string;
    fileId: string;
    error: string;
  }
}

export const JOB_FILE_UPLOADED = 'JOB_FILE_UPLOADED';
export interface JobFileUploadedEvent {
  type: typeof JOB_FILE_UPLOADED,
  payload: {
    teamId: string;
    jobId: string;
    fileId: string;
    fileName: string;
    filePath: string;
    fileType: string;
  }
}

export const JOB_IMPORT_LOADED = 'JOB_IMPORT_LOADED';
export interface JobImportLoadedEvent {
  type: typeof JOB_IMPORT_LOADED,
  payload: {
    teamId: string;
    jobId: string;
    jobImport: JobImportJSON
  }
}

export const JOB_IMPORT_PREPARED = 'JOB_IMPORT_PREPARED';
export interface JobImportPreparedEvent {
  type: typeof JOB_IMPORT_PREPARED,
  payload: {
    teamId: string;
    jobId: string;
    fileId: string;
    fileName: string;
    source: string;
  }
}

export const JOB_SECTION_CREATED = 'JOB_SECTION_CREATED';
export interface JobSectionCreatedEvent {
  type: typeof JOB_SECTION_CREATED,
  payload: {
    teamId: string;
    jobId: string;
    sectionId: string;
    sectionName: string;
  }
}

export const JOB_SECTION_UPDATED = 'JOB_SECTION_UPDATED';
export interface JobSectionUpdatedEvent {
  type: typeof JOB_SECTION_UPDATED,
  payload: {
    teamId: string;
    jobId: string;
    sectionId: string;
    details: Partial<JobSectionDetailsJSON>;
  }
}

export const JOB_SECTION_IMPORTED = 'JOB_SECTION_IMPORTED';
export interface JobSectionImportedEvent {
  type: typeof JOB_SECTION_IMPORTED,
  payload: {
    teamId: string;
    jobId: string;
    fileId: string;
    section: JobSectionJSON
  }
}

export const JOB_SECTION_DELETED = 'JOB_SECTION_DELETED';
export interface JobSectionDeletedEvent {
  type: typeof JOB_SECTION_DELETED,
  payload: {
    teamId: string;
    jobId: string;
    sectionId: string;
  }
}

export const JOB_SECTION_REORDERED = 'JOB_SECTION_REORDERED';
export interface JobSectionReorderedEvent {
  type: typeof JOB_SECTION_REORDERED,
  payload: {
    teamId: string;
    jobId: string;
    sectionId: string;
    order: number;
  }
}

export const JOB_SECTION_ITEM_CREATED = 'JOB_SECTION_ITEM_CREATED';
export interface JobSectionItemCreatedEvent {
  type: typeof JOB_SECTION_ITEM_CREATED,
  payload: {
    teamId: string;
    jobId: string;
    sectionId: string;
    itemId: string;
    item: JobSectionItemJSON;
  }
}

export const JOB_SECTION_ITEM_UPDATED = 'JOB_SECTION_ITEM_UPDATED';
export interface JobSectionItemUpdatedEvent {
  type: typeof JOB_SECTION_ITEM_UPDATED,
  payload: {
    teamId: string;
    jobId: string;
    sectionId: string;
    itemId: string;
    details: Partial<JobSectionItemDetailsJSON>;
  }
}

export const JOB_SECTION_ITEM_DELETED = 'JOB_SECTION_ITEM_DELETED';
export interface JobSectionItemDeletedEvent {
  type: typeof JOB_SECTION_ITEM_DELETED,
  payload: {
    teamId: string;
    jobId: string;
    sectionId: string;
    itemId: string;
  }
}

export const JOB_SECTION_ITEM_REORDERED = 'JOB_SECTION_ITEM_REORDERED';
export interface JobSectionItemReorderedEvent {
  type: typeof JOB_SECTION_ITEM_REORDERED,
  payload: {
    teamId: string;
    jobId: string;
    sectionId: string;
    itemId: string;
    order: number;
  }
}

export const JOB_SECTION_ITEM_STATUS_UPDATED = 'JOB_SECTION_ITEM_STATUS_UPDATED';
export interface JobSectionItemStatusUpdatedEvent {
  type: typeof JOB_SECTION_ITEM_STATUS_UPDATED,
  payload: {
    teamId: string;
    jobId: string;
    sectionId: string;
    itemId: string;
    status: Partial<JobSectionItemStatusJSON>;
  }
}

export const JOB_ASSETS_UPDATED = 'JOB_ASSETS_UPDATED';
export interface JobAssetsUpdatedEvent {
  type: typeof JOB_ASSETS_UPDATED,
  payload: {
    teamId: string;
    jobId: string;
    assets: Partial<JobAssetsJSON>;
  }
}

export const JOB_COMPANY_CREATED_EVENT = 'JOB_COMPANY_CREATED_EVENT';
export interface JobCompanyCreatedEvent {
  type: typeof JOB_COMPANY_CREATED_EVENT,
  payload: {
    teamId: string;
    jobId: string;
    companyId: string;
    details: Partial<JobCompanyDetailsJSON>;
  }
}

export const JOB_COMPANY_UPDATED_EVENT = 'JOB_COMPANY_UPDATED_EVENT';
export interface JobCompanyUpdatedEvent {
  type: typeof JOB_COMPANY_UPDATED_EVENT,
  payload: {
    teamId: string;
    jobId: string;
    companyId: string;
    details: Partial<JobCompanyDetailsJSON>
  }
}

export const JOB_COMPANIES_DELETED_EVENT = 'JOB_COMPANIES_DELETED_EVENT';
export interface JobCompaniesDeletedEvent {
  type: typeof JOB_COMPANIES_DELETED_EVENT,
  payload: {
    teamId: string;
    jobId: string;
    companyIds: string[];
  }
}

export const JOB_COMPANY_ALLOCATED_EVENT = 'JOB_COMPANY_ALLOCATED_EVENT';
export interface JobCompanyAllocatedEvent {
  type: typeof JOB_COMPANY_ALLOCATED_EVENT,
  payload: {
    teamId: string;
    jobId: string;
    itemIds: string[];
    companyId: string;
    company: JobSectionItemCompanyJSON;
  }
}

export const JOB_VARIATION_ITEM_CREATED = 'JOB_VARIATION_ITEM_CREATED';
export interface JobVariationItemCreatedEvent {
  type: typeof JOB_VARIATION_ITEM_CREATED,
  payload: {
    teamId: string;
    jobId: string;
    variationId: string;
    variation: JobVariationItemJSON;
  }
}

export const JOB_VARIATION_ITEM_UPDATED = 'JOB_VARIATION_ITEM_UPDATED';
export interface JobVariationItemUpdatedEvent {
  type: typeof JOB_VARIATION_ITEM_UPDATED,
  payload: {
    teamId: string;
    jobId: string;
    variationId: string;
    details: Partial<JobVariationItemDetailsJSON>;
  }
}

export const JOB_VARIATION_ITEM_DELETED = 'JOB_VARIATION_ITEM_DELETED';
export interface JobVariationItemDeletedEvent {
  type: typeof JOB_VARIATION_ITEM_DELETED,
  payload: {
    teamId: string;
    jobId: string;
    variationId: string;
  }
}

export const JOB_VARIATION_ITEM_REORDERED = 'JOB_VARIATION_ITEM_REORDERED';
export interface JobVariationItemReorderedEvent {
  type: typeof JOB_VARIATION_ITEM_REORDERED,
  payload: {
    teamId: string;
    jobId: string;
    variationId: string;
    order: number;
  }
}

export const JOB_VARIATION_ALLOCATED_EVENT = 'JOB_VARIATION_ALLOCATED_EVENT';
export interface JobVariationCompanyAllocatedEvent {
  type: typeof JOB_VARIATION_ALLOCATED_EVENT,
  payload: {
    teamId: string;
    jobId: string;
    variationIds: string[];
    companyId: string;
    company: JobVariationItemCompanyJSON;
  }
}

export const TEAM_USER_INVITED_EVENT = 'TEAM_USER_INVITED_EVENT';
export interface TeamUserInvitedEvent {
  type: typeof TEAM_USER_INVITED_EVENT,
  payload: {
    teamId: string;
    user: TeamUserJSON;
  }
}

export const TEAM_JOINED_EVENT = 'TEAM_JOINED_EVENT';
export interface TeamJoinedEvent {
  type: typeof TEAM_JOINED_EVENT,
  payload: {
    teamId: string;
    originalUserId: string;
    user: TeamUserJSON
  }
}



export type AnyEvent
  = SignInEmailSentEvent
  | SignInFailedEvent
  | UserSignedInEvent
  | CurrentUserSignedOutEvent
  | UserStatusUpdatedEvent
  | UserTeamsLoadedEvent
  | UserTeamJoinedEvent
  | TeamCreatedEvent
  | TeamSelectedEvent
  | TeamClearedEvent
  | JobsLoadedEvent
  | ArchiveLoadedEvent
  | TemplatesLoadedEvent
  | JobCreatedEvent
  | JobUpdatedEvent
  | JobsArchivedEvent
  | JobsUnarchivedEvent
  | JobsDeletedEvent
  | JobLoadedEvent
  | JobFileUploadStartedEvent
  | JobFileUploadUpdatedEvent
  | JobFileUploadFailedEvent
  | JobFileUploadedEvent
  | JobImportLoadedEvent
  | JobImportPreparedEvent
  | JobSectionCreatedEvent
  | JobSectionDeletedEvent
  | JobSectionUpdatedEvent
  | JobSectionImportedEvent
  | JobSectionReorderedEvent
  | JobSectionItemCreatedEvent
  | JobSectionItemUpdatedEvent
  | JobSectionItemReorderedEvent
  | JobSectionItemStatusUpdatedEvent
  | JobVariationItemCreatedEvent
  | JobVariationItemUpdatedEvent
  | JobVariationItemReorderedEvent
  | JobVariationItemDeletedEvent
  | JobVariationCompanyAllocatedEvent
  | JobAssetsUpdatedEvent
  | JobSectionItemDeletedEvent
  | JobCompanyCreatedEvent
  | JobCompanyUpdatedEvent
  | JobCompaniesDeletedEvent
  | JobCompanyAllocatedEvent



/** AppServices */

export type AppService = Service<State, AnyCommand, AnyEvent>;
export type AppServiceCommandHandler<Command = AnyCommand> = ServiceCommandHandler<State, Command>;
export type AppServiceEventHandler<Event = AnyEvent> = ServiceEventHandler<State, Event>;
export type RemoteEventHandler = EventFunction<EventWrapper<AnyEvent>>;

export interface AppServices extends ServiceMap<State, AnyCommand, AnyEvent> {
  auth: AuthAppService;
  event: EventAppService;
  user: UserService;
  team: TeamService;
  job: JobService;
  jobSectionImport: JobSectionImportService;
  jobCompany: JobCompanyService;
  editor: EditorService;
}

export interface EventAppService extends AppService {
  [RECEIVE_EVENT_COMMAND]: AppServiceCommandHandler<ReceiveEventCommand>;
  watchUserEvents(userId: string, handler: RemoteEventHandler): WatchFunctionUnsubscribe
  watchTeamEvents(teamId: string, handler: RemoteEventHandler): WatchFunctionUnsubscribe
}

export interface AuthAppService extends AppService {
  [SIGN_OUT_COMMAND]: AppServiceCommandHandler<SignOutCommand>;
  [START_SIGN_IN_WITH_EMAIL_COMMAND]: AppServiceCommandHandler<StartSignInWithEmailCommand>;
  [COMPLETE_SIGN_IN_WITH_EMAIL_COMMAND]: AppServiceCommandHandler<CompleteSignInWithEmailCommand>;
  [CREATE_TEAM_COMMAND]: AppServiceCommandHandler<CreateTeamCommand>;
  [SELECT_TEAM_COMMAND]: AppServiceCommandHandler<SelectTeamCommand>;
  [CLEAR_TEAM_COMMAND]: AppServiceCommandHandler<ClearTeamCommand>;
  [INVITE_TEAM_USER_COMMAND]: AppServiceCommandHandler<InviteTeamUserCommand>;
  [JOIN_TEAM_COMMAND]: AppServiceCommandHandler<JoinTeamCommand>;

  [USER_SIGNED_IN_EVENT]: AppServiceEventHandler<UserSignedInEvent>;
  [CURRENT_USER_SIGNED_OUT_EVENT]: AppServiceEventHandler<CurrentUserSignedOutEvent>;
  [TEAM_SELECTED_EVENT]: AppServiceEventHandler<TeamSelectedEvent>;
  [TEAM_CLEARED_EVENT]: AppServiceEventHandler<TeamClearedEvent>;
  [TEAM_JOINED_EVENT]: AppServiceEventHandler<TeamJoinedEvent>;
  [TEAM_USER_INVITED_EVENT]: AppServiceEventHandler<TeamUserInvitedEvent>;

  isSignInWithEmailLink(url: string): boolean;
  getStoredEmail(): string | null;
  getStoredTeam(): string | null;
}

export interface UserService extends AppService {
  [LOAD_USER_TEAMS_COMMAND]: AppServiceCommandHandler<LoadUserTeamsCommand>;

  [USER_STATUS_UPDATED_EVENT]: AppServiceEventHandler<UserStatusUpdatedEvent>;
  [USER_TEAMS_LOADED_EVENT]: AppServiceEventHandler<UserTeamsLoadedEvent>;
  [USER_TEAM_JOINED_EVENT]: AppServiceEventHandler<UserTeamJoinedEvent>;
}

export interface TeamService extends AppService {
  [LOAD_JOBS_COMMAND]: AppServiceCommandHandler<LoadJobsCommand>;
  [LOAD_ARCHIVE_COMMAND]: AppServiceCommandHandler<LoadArchiveCommand>;
  [LOAD_TEMPLATES_COMMAND]: AppServiceCommandHandler<LoadTemplatesCommand>;
  [LOAD_JOB_COMMAND]: AppServiceCommandHandler<LoadJobCommand>;
  [IMPORT_JOB_COMMAND]: AppServiceCommandHandler<ImportJobCommand>;
  [CREATE_JOB_COMMAND]: AppServiceCommandHandler<CreateJobCommand>;
  [UPDATE_JOB_COMMAND]: AppServiceCommandHandler<UpdateJobCommand>;
  [ARCHIVE_JOBS_COMMAND]: AppServiceCommandHandler<ArchiveJobsCommand>;
  [UNARCHIVE_JOBS_COMMAND]: AppServiceCommandHandler<UnarchiveJobsCommand>;
  [DELETE_JOBS_COMMAND]: AppServiceCommandHandler<DeleteJobsCommand>;
  [SAVE_JOB_TEMPLATE_COMMAND]: AppServiceCommandHandler<SaveJobTemplateCommand>;

  [ARCHIVE_LOADED_EVENT]: AppServiceEventHandler<ArchiveLoadedEvent>;
  [JOBS_LOADED_EVENT]: AppServiceEventHandler<JobsLoadedEvent>;
  [TEMPLATES_LOADED_EVENT]: AppServiceEventHandler<TemplatesLoadedEvent>;
  [JOB_LOADED_EVENT]: AppServiceEventHandler<JobLoadedEvent>;
  [JOB_CREATED_EVENT]: AppServiceEventHandler<JobCreatedEvent>;
  [JOB_UPDATED_EVENT]: AppServiceEventHandler<JobUpdatedEvent>;
  [JOBS_ARCHIVED_EVENT]: AppServiceEventHandler<JobsArchivedEvent>;
  [JOBS_UNARCHIVED_EVENT]: AppServiceEventHandler<JobsUnarchivedEvent>;
  [JOBS_DELETED_EVENT]: AppServiceEventHandler<JobsDeletedEvent>;

  downloadJSON<T>(path: string): Promise<T>;
}


export interface JobService extends AppService {
  [LOAD_JOB_IMPORT_COMMAND]: AppServiceCommandHandler<LoadJobImportCommand>;
  [START_JOB_FILE_UPLOAD_COMMAND]: AppServiceCommandHandler<StartJobFileUploadCommand>;
  [FAIL_JOB_FILE_UPLOAD_COMMAND]: AppServiceCommandHandler<FailJobFileUploadCommand>;
  [COMPLETE_JOB_FILE_UPLOAD_COMMAND]: AppServiceCommandHandler<CompleteJobFileUploadCommand>;
  [CREATE_JOB_SECTION_COMMAND]: AppServiceCommandHandler<CreateJobSectionCommand>;
  [UPDATE_JOB_SECTION_COMMAND]: AppServiceCommandHandler<UpdateJobSectionCommand>;
  [REORDER_JOB_SECTION_COMMAND]: AppServiceCommandHandler<ReorderJobSectionCommand>;
  [DELETE_JOB_SECTIONS_COMMAND]: AppServiceCommandHandler<DeleteJobSectionsCommand>;
  [CREATE_JOB_SECTION_ITEM_COMMAND]: AppServiceCommandHandler<CreateJobSectionItemCommand>;
  [UPDATE_JOB_SECTION_ITEM_COMMAND]: AppServiceCommandHandler<UpdateJobSectionItemCommand>;
  [REORDER_JOB_SECTION_ITEM_COMMAND]: AppServiceCommandHandler<ReorderJobSectionItemCommand>;
  [UPDATE_JOB_SECTION_ITEM_STATUS_COMMAND]: AppServiceCommandHandler<UpdateJobSectionItemStatusCommand>;
  [DELETE_JOB_SECTION_ITEMS_COMMAND]: AppServiceCommandHandler<DeleteJobSectionItemsCommand>;
  [CREATE_JOB_VARIATION_ITEM_COMMAND]: AppServiceCommandHandler<CreateJobVariationItemCommand>;
  [UPDATE_JOB_VARIATION_ITEM_COMMAND]: AppServiceCommandHandler<UpdateJobVariationItemCommand>;
  [REORDER_JOB_VARIATION_ITEM_COMMAND]: AppServiceCommandHandler<ReorderJobVariationItemCommand>;
  [DELETE_JOB_VARIATION_ITEMS_COMMAND]: AppServiceCommandHandler<DeleteJobVariationItemsCommand>;

  [FINALISE_SCHEDULE]: AppServiceCommandHandler<FinaliseScheduleCommand>;
  [FINALISE_COSTINGS]: AppServiceCommandHandler<FinaliseCostingsCommand>;
  [FINALISE_VALUATION]: AppServiceCommandHandler<FinaliseValuationCommand>;

  [JOB_IMPORT_LOADED]: AppServiceEventHandler<JobImportLoadedEvent>;
  [JOB_FILE_UPLOAD_STARTED]: AppServiceEventHandler<JobFileUploadStartedEvent>;
  [JOB_FILE_UPLOAD_UPDATED]: AppServiceEventHandler<JobFileUploadUpdatedEvent>;
  [JOB_FILE_UPLOADED]: AppServiceEventHandler<JobFileUploadedEvent>;
  [JOB_SECTION_CREATED]: AppServiceEventHandler<JobSectionCreatedEvent>;
  [JOB_SECTION_UPDATED]: AppServiceEventHandler<JobSectionUpdatedEvent>;
  [JOB_SECTION_REORDERED]: AppServiceEventHandler<JobSectionReorderedEvent>;
  [JOB_SECTION_DELETED]: AppServiceEventHandler<JobSectionDeletedEvent>;
  [JOB_SECTION_ITEM_CREATED]: AppServiceEventHandler<JobSectionItemCreatedEvent>;
  [JOB_SECTION_ITEM_UPDATED]: AppServiceEventHandler<JobSectionItemUpdatedEvent>;
  [JOB_SECTION_ITEM_REORDERED]: AppServiceEventHandler<JobSectionItemReorderedEvent>;
  [JOB_SECTION_ITEM_DELETED]: AppServiceEventHandler<JobSectionItemDeletedEvent>;
  [JOB_SECTION_ITEM_STATUS_UPDATED]: AppServiceEventHandler<JobSectionItemStatusUpdatedEvent>;
  [JOB_VARIATION_ITEM_CREATED]: AppServiceEventHandler<JobVariationItemCreatedEvent>;
  [JOB_VARIATION_ITEM_UPDATED]: AppServiceEventHandler<JobVariationItemUpdatedEvent>;
  [JOB_VARIATION_ITEM_REORDERED]: AppServiceEventHandler<JobVariationItemReorderedEvent>;
  [JOB_VARIATION_ITEM_DELETED]: AppServiceEventHandler<JobVariationItemDeletedEvent>;
  [JOB_ASSETS_UPDATED]: AppServiceEventHandler<JobAssetsUpdatedEvent>;

  uploadJobFile(teamId: string, jobId: string, name: string, file: File): [JobFile, firebase.storage.UploadTask]
  getDownloadUrl(filePath: string): Promise<string>;
}

export interface EditorService extends AppService {
  setInitialSettings<T>(id: string, initialSettings: EditorSettings<T>): EditorSettings<T>;
  getColumnSettings<T>(id: string): EditorSettings<T> | null;
  setColumnWidth<T>(id: string, key: string, width: number): void;
}

export interface JobSectionImportService extends AppService {
  [IMPORT_JOB_SECTIONS_COMMAND]: AppServiceCommandHandler<ImportJobSectionsCommand>;

  [JOB_SECTION_IMPORTED]: AppServiceEventHandler<JobSectionImportedEvent>;
}

export interface JobCompanyService extends AppService {
  [CREATE_JOB_COMPANY_COMMAND]: AppServiceCommandHandler<CreateJobCompanyCommand>;
  [UPDATE_JOB_COMPANY_COMMAND]: AppServiceCommandHandler<UpdateJobCompanyCommand>;
  [DELETE_JOB_COMPANIES_COMMAND]: AppServiceCommandHandler<DeleteJobCompaniesCommand>;
  [ALLOCATE_JOB_COMPANY_COMMAND]: AppServiceCommandHandler<AllocateJobCompanyCommand>;
  [ALLOCATE_VARIATION_COMPANY_COMMAND]: AppServiceCommandHandler<AllocateVariationCompanyCommand>;

  [JOB_COMPANY_CREATED_EVENT]: AppServiceEventHandler<JobCompanyCreatedEvent>;
  [JOB_COMPANY_UPDATED_EVENT]: AppServiceEventHandler<JobCompanyUpdatedEvent>;
  [JOB_COMPANIES_DELETED_EVENT]: AppServiceEventHandler<JobCompaniesDeletedEvent>;
  [JOB_COMPANY_ALLOCATED_EVENT]: AppServiceEventHandler<JobCompanyAllocatedEvent>;
  [JOB_VARIATION_ALLOCATED_EVENT]: AppServiceEventHandler<JobVariationCompanyAllocatedEvent>;
}

/** DomainServices */

export type AppDomainCommandWrapper = DomainCommandWrapper<AnyCommand>;
export interface DomainServices extends ServiceMap<State, AnyCommand, AnyEvent> {
  auth: AuthDomainService;
  team: TeamDomainService;
  job: JobDomainService;
  jobSectionImport: JobSectionImportDomainService;
  jobCompany: JobCompanyDomainService;
}

export interface AuthDomainService extends AppService {
  [CREATE_TEAM_COMMAND]: AppServiceCommandHandler<CreateTeamCommand>;
  [INVITE_TEAM_USER_COMMAND]: AppServiceCommandHandler<InviteTeamUserCommand>;
  [JOIN_TEAM_COMMAND]: AppServiceCommandHandler<JoinTeamCommand>;
}

export interface TeamDomainService extends AppService {
  [IMPORT_JOB_COMMAND]: AppServiceCommandHandler<ImportJobCommand>;
  [CREATE_JOB_COMMAND]: AppServiceCommandHandler<CreateJobCommand>;
  [UPDATE_JOB_COMMAND]: AppServiceCommandHandler<UpdateJobCommand>;
  [ARCHIVE_JOBS_COMMAND]: AppServiceCommandHandler<ArchiveJobsCommand>;
  [UNARCHIVE_JOBS_COMMAND]: AppServiceCommandHandler<UnarchiveJobsCommand>;
  [DELETE_JOBS_COMMAND]: AppServiceCommandHandler<DeleteJobsCommand>;
  [SAVE_JOB_TEMPLATE_COMMAND]: AppServiceCommandHandler<SaveJobTemplateCommand>;
}

export interface JobDomainService extends AppService {
  [START_JOB_FILE_UPLOAD_COMMAND]: AppServiceCommandHandler<StartJobFileUploadCommand>;
  [FAIL_JOB_FILE_UPLOAD_COMMAND]: AppServiceCommandHandler<FailJobFileUploadCommand>;
  [COMPLETE_JOB_FILE_UPLOAD_COMMAND]: AppServiceCommandHandler<CompleteJobFileUploadCommand>;
  [CREATE_JOB_SECTION_COMMAND]: AppServiceCommandHandler<CreateJobSectionCommand>;
  [UPDATE_JOB_SECTION_COMMAND]: AppServiceCommandHandler<UpdateJobSectionCommand>;
  [REORDER_JOB_SECTION_COMMAND]: AppServiceCommandHandler<ReorderJobSectionCommand>;
  [DELETE_JOB_SECTIONS_COMMAND]: AppServiceCommandHandler<DeleteJobSectionsCommand>;
  [CREATE_JOB_SECTION_ITEM_COMMAND]: AppServiceCommandHandler<CreateJobSectionItemCommand>;
  [UPDATE_JOB_SECTION_ITEM_COMMAND]: AppServiceCommandHandler<UpdateJobSectionItemCommand>;
  [REORDER_JOB_SECTION_ITEM_COMMAND]: AppServiceCommandHandler<ReorderJobSectionItemCommand>;
  [UPDATE_JOB_SECTION_ITEM_STATUS_COMMAND]: AppServiceCommandHandler<UpdateJobSectionItemStatusCommand>
  [DELETE_JOB_SECTION_ITEMS_COMMAND]: AppServiceCommandHandler<DeleteJobSectionItemsCommand>;
  [CREATE_JOB_VARIATION_ITEM_COMMAND]: AppServiceCommandHandler<CreateJobVariationItemCommand>;
  [UPDATE_JOB_VARIATION_ITEM_COMMAND]: AppServiceCommandHandler<UpdateJobVariationItemCommand>;
  [REORDER_JOB_VARIATION_ITEM_COMMAND]: AppServiceCommandHandler<ReorderJobVariationItemCommand>;
  [DELETE_JOB_VARIATION_ITEMS_COMMAND]: AppServiceCommandHandler<DeleteJobVariationItemsCommand>;
}

export interface JobSectionImportDomainService extends AppService {
  [PREPARE_JOB_SECTIONS_IMPORT_COMMAND]: AppServiceCommandHandler<PrepareJobSectionsImportCommand>;
  [IMPORT_JOB_SECTIONS_COMMAND]: AppServiceCommandHandler<ImportJobSectionsCommand>;
  [FINALISE_COSTINGS]: AppServiceCommandHandler<FinaliseCostingsCommand>;
  [FINALISE_SCHEDULE]: AppServiceCommandHandler<FinaliseScheduleCommand>;
  [FINALISE_VALUATION]: AppServiceCommandHandler<FinaliseValuationCommand>;
}

export interface JobCompanyDomainService extends AppService {
  [CREATE_JOB_COMPANY_COMMAND]: AppServiceCommandHandler<CreateJobCompanyCommand>;
  [UPDATE_JOB_COMPANY_COMMAND]: AppServiceCommandHandler<UpdateJobCompanyCommand>;
  [DELETE_JOB_COMPANIES_COMMAND]: AppServiceCommandHandler<DeleteJobCompaniesCommand>;
  [ALLOCATE_JOB_COMPANY_COMMAND]: AppServiceCommandHandler<AllocateJobCompanyCommand>;
  [ALLOCATE_VARIATION_COMPANY_COMMAND]: AppServiceCommandHandler<AllocateVariationCompanyCommand>;
}