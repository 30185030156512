import Form from '../form/Form';
import {h} from 'preact';
import FieldGroup from '../form/FieldGroup';
import {useCallback, useEffect, useState} from 'preact/hooks';
import Button from '../form/Button';
import {CommandStatus} from '../../../../framework/type';
import useCommandErrors from '../../../../framework/hook/useCommandErrors';
import commandsAreRunning from '../../../../framework/commandsAreRunning';
import {Job} from '../../model/Job';
import JobSection from '../../model/JobSection';
import Paragraph from '../media/Paragraph';
import currentUserTeam from '../../query/currentUserTeam';
import {useDeleteJobSectionItemsCommand} from '../../command/deleteJobSectionItems';
import JobSectionItem from '../../model/JobSectionItem';

interface Props {
  job: Job;
  section: JobSection;
  items: JobSectionItem[];
  onCancel(): void;
  onSuccess(): void;
}

export default function DeleteSectionItemsForm({job, section, items, onCancel, onSuccess}: Props) {
  const team = currentUserTeam();

  const [command, deleteSectionItems] = useDeleteJobSectionItemsCommand();
  const [deletingItems, setDeletingItems] = useState(items);
  const {system} = useCommandErrors(command);
  const isLoading = commandsAreRunning(command);

  const submit = useCallback(() => {
    if (items.length === 0 || isLoading || !team) return;
    setDeletingItems(items);
    deleteSectionItems({
      teamId: team.id,
      jobId: job.id,
      sectionId: section.id,
      itemIds: items.map(item => item.id)
    })
  }, [job, team, section, items, isLoading, deleteSectionItems]);

  useEffect(() => {
    if (!command || command.status !== CommandStatus.SUCCESS) return;
    onSuccess();
  }, [command]);


  return (
    <Form heading="Delete Items" onSubmit={submit} error={system}>
      <Paragraph>This will permanently delete the following item{items.length === 1 ? '' : 's'} from <strong>{section.name}</strong>:</Paragraph>
      <ul>
        {deletingItems.map(item => (
          <li>[{item.item}] {item.description}</li>
        ))}
      </ul>
      <Paragraph>Are you sure?</Paragraph>
      <FieldGroup horizontal={true}>
        <Button danger={true} type="submit" disabled={isLoading} onClick={submit}>Delete</Button>
        <Button type="cancel" onClick={onCancel}>Cancel</Button>
      </FieldGroup>
    </Form>
  )
}