import currentSession from '../query/currentSession';
import App from '../app';
import {useEffect} from 'preact/hooks';
import {useReceiveEventCommand} from '../command/receiveEvent';

export default function watchCurrentTeamEvents() {
  const {signedIn, teamId} = currentSession();
  const {event} = App.useServices();
  const [,receiveEvent] = useReceiveEventCommand();

  useEffect(() => {
    if (!signedIn || !teamId.id) return;
    return event.watchTeamEvents(teamId.id, receiveEvent);
  }, [teamId.id])
}