import App from '../app';
import {AnyCommand, START_SIGN_IN_WITH_EMAIL_COMMAND} from '../type';

export default function startSignInWithEmail(email: string, redirectUrl: string): AnyCommand {
  return {
    type: START_SIGN_IN_WITH_EMAIL_COMMAND,
    payload: {
      email,
      redirectUrl,
    }
  }
}

export const useStartSignInWithEmailCommand =
  App.wrapCommand(startSignInWithEmail);