import Form from '../form/Form';
import {h} from 'preact';
import FieldGroup from '../form/FieldGroup';
import Field from '../form/Field';
import Input from '../form/Input';
import {useCallback, useEffect, useRef} from 'preact/hooks';
import Button from '../form/Button';
import useCommandErrors from '../../../../framework/hook/useCommandErrors';
import {CommandStatus} from '../../../../framework/type';
import currentUserTeam from '../../query/currentUserTeam';
import autoFocus from '../../effect/autoFocus';
import {Job} from '../../model/Job';
import setValueOnMount from '../../effect/setValueOnMount';
import Paragraph from '../media/Paragraph';
import {useSaveJobTemplateCommand} from '../../command/saveJobTemplate';

interface SaveAsTemplateFormFields {
  'JobName': string;
}

interface Props {
  job: Job;
  onCancel(): void;
  onSuccess(): void;
}

export default function SaveAsTemplateForm({job, onCancel, onSuccess}: Props) {
  const jobName = useRef<HTMLInputElement>();

  const team = currentUserTeam();

  if (!team) return null;

  const [command, saveJobTemplate] = useSaveJobTemplateCommand();
  const {system, field} = useCommandErrors<SaveAsTemplateFormFields>(command);

  const running = command && command.status === CommandStatus.RUNNING;

  const submit = useCallback(() => {
    if (running || !team || !jobName.current) return;

    saveJobTemplate({
      teamId: team.id,
      jobId: job.id,
      templateName: jobName.current.value
    });
  }, [saveJobTemplate, team, jobName]);

  useEffect(() => {
    if (command && command.status === CommandStatus.SUCCESS) {
      onSuccess();
    }
  }, [command]);

  autoFocus(jobName);
  setValueOnMount(jobName, job.details.name);

  return (
    <Form disabled={running} heading="Save Job as Template" error={system} onSubmit={submit}>
      <Paragraph>This will save the current companies and sections as a template for new jobs.</Paragraph>
      <FieldGroup horizontal={true}>
        <Field label="Template name" error={field['JobName']}>
          <Input disabled={running} innerRef={jobName} />
        </Field>
      </FieldGroup>
      <FieldGroup horizontal={true}>
        <Button disabled={running} primary={true} type="submit">{running ? 'Saving...' : 'Save'}</Button>
        <Button disabled={running} type="cancel" onClick={onCancel}>Cancel</Button>
      </FieldGroup>
    </Form>
  )
}