import {AnyCommand, FINALISE_VALUATION, FinaliseValuationCommand} from '../type';
import App from '../app';

export default function finaliseValuation(payload: FinaliseValuationCommand['payload']): AnyCommand {
  return {
    type: FINALISE_VALUATION,
    payload,
  }
}

export const useFinaliseValuation = App.wrapCommand(finaliseValuation);