import {Fragment, h} from 'preact';
import Button from '../form/Button';
import Dialog from '../media/Dialog';
import {useCallback, useEffect, useState} from 'preact/hooks';
import {Job} from '../../model/Job';
import JobSection from '../../model/JobSection';
import JobSectionItem from '../../model/JobSectionItem';
import DeleteSectionItemsForm from './DeleteSectionItemsForm';
import {IconType} from '../media/Icon';
import {useUpdateJobSectionCommand} from '../../command/updateJobSection';
import {useReorderJobSectionCommand} from '../../command/reorderJobSection';
import currentSession from '../../query/currentSession';
import currentTeam from '../../query/currentTeam';
import callOnCommandStatus from '../../effect/callOnCommandStatus';
import {CommandStatus} from '../../../../framework/type';

interface Props {
  job: Job;
  section: JobSection;
  onRunning?(): any;
}

export default function MoveSectionUpButton({job, section, onRunning}: Props) {
  const [command, reorderJobSection] = useReorderJobSectionCommand();
  const prevSection = job.prevSection(section.id);
  const team = currentTeam();

  const click = useCallback((event: MouseEvent) => {
    event.stopPropagation();

    if (!!team && !!prevSection) {
      reorderJobSection({
        teamId: team.id,
        jobId: job.id,
        sectionId: section.id,
        order: prevSection.order,
      })
    }
  }, [reorderJobSection, job, team, section, prevSection]);

  if (onRunning) {
    callOnCommandStatus(CommandStatus.RUNNING, command, onRunning);
  }

  return (
    <Fragment>
      <Button
        small={true}
        onClick={click}
        onlyIcon={IconType.MOVE_UP}
        readOnly={job.isFirstSection(section.id)}
        tooltip={`Move Section Up`}
      />
    </Fragment>
  )
}