import {EditorService} from '../../type';
import {JobCostingsBaseService} from '../../services';
import {EditorSettings} from '../../component/editor/type';

export default class MemoryEditorService extends JobCostingsBaseService implements EditorService {
  constructor(
    protected readonly storage: Storage,
  ) {super()}

  getColumnSettings(id: string, initialSettings: EditorSettings<any> = {columns: []}) {
    const existing = this.storage.getItem(id);
    if (existing) return JSON.parse(existing) as EditorSettings<any>;

    this.storage.setItem(id, JSON.stringify(initialSettings));

    return initialSettings;
  }

  setColumnWidth(id: string, index: number, width: number): void {
    const settings = this.getColumnSettings(id);
    if (!settings || !settings.columns[index]) return;

    settings.columns[index].width = width;

    this.storage.setItem(id, JSON.stringify(settings));
  }
}