import {Fragment, h} from 'preact';
import Button from '../form/Button';
import Dialog from '../media/Dialog';
import {useCallback, useEffect, useState} from 'preact/hooks';
import {Job} from '../../model/Job';
import JobSection from '../../model/JobSection';
import JobSectionItem from '../../model/JobSectionItem';
import DeleteSectionItemsForm from './DeleteSectionItemsForm';
import {IconType} from '../media/Icon';
import {useUpdateJobSectionCommand} from '../../command/updateJobSection';
import {useReorderJobSectionCommand} from '../../command/reorderJobSection';
import currentSession from '../../query/currentSession';
import currentTeam from '../../query/currentTeam';
import callOnCommandStatus from '../../effect/callOnCommandStatus';
import {CommandStatus} from '../../../../framework/type';
import {useReorderJobSectionItemCommand} from '../../command/reorderJobSectionItem';

interface Props {
  job: Job;
  section: JobSection;
  item: JobSectionItem;
  onRunning?(): any;
}

export default function MoveSectionItemDownButton({job, section, item, onRunning}: Props) {
  const [command, reorderJobSectionItem] = useReorderJobSectionItemCommand();
  const nextItem = job.nextSectionItem(section.id, item.id);
  const team = currentTeam();

  const click = useCallback((event: MouseEvent) => {
    event.stopPropagation();

    if (!!team && !!nextItem) {
      reorderJobSectionItem({
        teamId: team.id,
        jobId: job.id,
        sectionId: section.id,
        itemId: item.id,
        order: nextItem.order + 0.5,
      })
    }
  }, [reorderJobSectionItem, job, team, section, nextItem]);

  if (onRunning) {
    callOnCommandStatus(CommandStatus.RUNNING, command, onRunning);
  }

  return (
    <Fragment>
      <Button
        small={true}
        onClick={click}
        onlyIcon={IconType.MOVE_DOWN}
        readOnly={!nextItem}
        tooltip={`Move Item Down`}
      />
    </Fragment>
  )
}