import {AnyCommand, LOAD_TEMPLATES_COMMAND, LoadTemplatesCommand} from '../type';
import App from '../app';

export default function loadTemplates(payload: LoadTemplatesCommand['payload']): AnyCommand {
  return {
    type: LOAD_TEMPLATES_COMMAND,
    payload,
  }
}

export const useLoadTemplatesCommand = App.wrapCommand(loadTemplates);