import {FileId} from './App';
import {Entity} from '../../../framework/value/Entity';
import MapValue from '../../../framework/value/MapValue';
import StringValue from '../../../framework/value/StringValue';
import BooleanValue from '../../../framework/value/BooleanValue';
import NumberValue from '../../../framework/value/NumberValue';
import EntityMapValue from '../../../framework/value/EntityMapValue';

export type JobFilesJSON = Record<string, JobFileJSON>;

export interface JobFileJSON {
  id: string;
  path: string;
  type: string;
  details: DetailsJSON
  status: StatusJSON;
}

interface DetailsJSON {
  name: string;
}

interface StatusJSON {
  ready: boolean;
  failed: boolean;
}

export class JobFile extends Entity<FileId, {
  path: Path
  type: Type
  details: Details
  status: Status
}> {
  get id() {
    return this.value.id.id;
  }

  get filePath() {
    return this.value.path.value;
  }

  get fileType() {
    return this.value.type.value;
  }

  get fileName() {
    return this.value.details.value.name.value;
  }

  get status() {
    return this.value.status;
  }

  get ready() {
    return this.status.value.ready.value
  }

  get failed() {
    return this.status.value.failed.value
  }

  static create = (props: {
    id: string,
    filePath: string,
    fileType: string,
    fileName: string,
    ready?: boolean,
    failed?: boolean,
  }) => new JobFile({
    id: new FileId(props.id),
    path: new Path(props.filePath),
    type: new Type(props.fileType),
    details: new Details({
      name: new Name(props.fileName)
    }),
    status: new Status({
      ready: new StatusReady(props.ready || false),
      failed: new StatusFailed(props.failed || false),
      progress: new StatusProgress(0),
    })
  });

  toJSON(): JobFileJSON {
    return super.toJSON() as JobFileJSON;
  }

  static fromJSON(data: JobFileJSON) {
    return JobFile.create({
      id: data.id || '',
      filePath: data.path || '',
      fileType: data.type || '',
      fileName: data && data.details && data.details.name || '',
      ready: data && data.status && data.status.ready || false,
      failed: data && data.status && data.status.failed || false,
    })
  }
}

export class JobFiles extends EntityMapValue<FileId, JobFile> {
  static fromJSON(json: Partial<JobFilesJSON>) {
    return new JobFiles(Object.keys(json).reduce((items, id) => {
      return {
        ...items,
        [id]: JobFile.fromJSON(json[id]!)
      };
    }, {} as Record<string, JobFile>))
  }

  get all(): JobFile[] {
    return Object.keys(this.value).reduce((files, key) => {
      return [...files, this.value[key]];
    }, [] as JobFile[]);
  }
}

export class Imports extends EntityMapValue<FileId, JobFile> {
  static fromJSON(json: JobFilesJSON) {
    return new JobFiles(Object.keys(json).reduce((items, id) => {
      return {
        ...items,
        [id]: JobFile.fromJSON(json[id])
      };
    }, {} as Record<string, JobFile>))
  }

  get all(): JobFile[] {
    return Object.keys(this.value).reduce((files, key) => {
      return [...files, this.value[key]];
    }, [] as JobFile[]);
  }
}

export class Path extends StringValue {}
export class Details extends MapValue<{
  name: Name,
}> {}

export class Status extends MapValue<{
  ready: BooleanValue
  failed: BooleanValue
  progress: NumberValue
}> {}

export class StatusReady extends BooleanValue {}
export class StatusFailed extends BooleanValue {}
export class StatusProgress extends NumberValue {}

export class Name extends StringValue {}
export class Type extends StringValue {}
