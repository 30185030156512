import Form from '../form/Form';
import {h} from 'preact';
import FieldGroup from '../form/FieldGroup';
import {useCallback, useEffect} from 'preact/hooks';
import Button from '../form/Button';
import {CommandStatus} from '../../../../framework/type';
import useCommandErrors from '../../../../framework/hook/useCommandErrors';
import commandsAreRunning from '../../../../framework/commandsAreRunning';
import {Job} from '../../model/Job';
import Paragraph from '../media/Paragraph';
import {useDeleteJobCompaniesCommand} from '../../command/deleteJobCompanies';
import currentUserTeam from '../../query/currentUserTeam';
import {JobCompany} from '../../model/JobCompany';

interface Props {
  job: Job;
  companies: JobCompany[];
  onCancel(): void;
  onSuccess(): void;
}

export default function DeleteCompaniesForm({job, companies, onCancel, onSuccess}: Props) {
  const team = currentUserTeam();

  const [command, deleteCompanies] = useDeleteJobCompaniesCommand();
  const {system} = useCommandErrors(command);
  const isLoading = commandsAreRunning(command);

  const submit = useCallback(() => {
    if (companies.length === 0 || isLoading || !team) return;

    deleteCompanies({
      teamId: team.id,
      jobId: job.id,
      companyIds: companies.map(company => company.id)
    })
  }, [job, team, companies, isLoading, deleteCompanies]);

  useEffect(() => {
    if (!command || command.status !== CommandStatus.SUCCESS) return;
    onSuccess();
  }, [command]);


  return (
    <Form heading="Delete Companies" onSubmit={submit} error={system}>
      <Paragraph>This will permanently delete the following companies and remove their item allocations:</Paragraph>
      <ul>
        {companies.map(section => (
          <li>{section.name}</li>
        ))}
      </ul>
      <Paragraph>Are you sure?</Paragraph>
      <FieldGroup horizontal={true}>
        <Button danger={true} type="submit" disabled={isLoading} onClick={submit}>Delete</Button>
        <Button type="cancel" onClick={onCancel}>Cancel</Button>
      </FieldGroup>
    </Form>
  )
}