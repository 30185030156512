import { h } from 'preact';
import ValuationSection from '../../model/ValuationSection';
import TableGroup from '../editor/TableGroup';
import { ActionRenderer, EditorColumn, EditorColumnType, EditorPage, SaveHandler } from '../editor/type';
import { default as ValuationSectionItem } from '../../model/ValuationSectionItem';
import { Valuation } from '../../model/Valuation';
import renderCurrency from '../../helper/renderCurrency';
import { CommandContainer } from '../../../../framework/type';
import binaryChoice from '../../helper/binaryChoice';
import PassiveIndicator from '../media/PassiveIndicator';
import PercentageIndicator from '../media/PercentageIndicator';

interface Props {
  valuation: Valuation;
  sections: ValuationSection[];
  onSave?: SaveHandler<ValuationSectionItem>;
  currentCommand?: CommandContainer<any>;
  children?: ActionRenderer<ValuationSectionItem>;
  forceQuantity?: boolean;
  expandToFill?: boolean;
}

export default function ValuationTable({children, expandToFill, forceQuantity, sections, valuation, onSave, currentCommand}: Props) {
  const editable = (item: ValuationSectionItem, key: keyof ValuationSectionItem) => {
    if (!valuation) return false;

    switch (key) {
      case 'omit':
        return true;

      case 'complete':
        return item && !item.omit;

      default:
        return false;
    }
  };

  const renderHeader = (page: number, _: ValuationSectionItem[], key: keyof ValuationSectionItem) => {
    if (!valuation) return '';
    const section = sections[page];
    if (!section) return '';

    switch (key) {
      case 'charge':
        return renderCurrency(section.charge);
      case 'omit':
        return renderCurrency(section.omitted);
      case 'billable':
        return renderCurrency(section.billable);

      case 'complete':
        return (
          <PercentageIndicator
            alwaysShowLabel={true}
            percent={section.complete}
            pageBackground={true}
          />
        );

      default:
        return '';
    }
  };

  const columns: Array<EditorColumn<ValuationSectionItem>> = [
    {
      key: 'item',
      label: 'Item',
      width: 48,
      editable,
      renderHeader,
    },
    {
      key: 'description',
      label: 'Description',
      width: 320,
      renderCell: (item) => {
        return item && item.description;
      },
      editable,
      renderHeader,
    },
    {
      key: 'charge',
      label: 'Sum',
      width: 80,
      numeric: true,
      type: EditorColumnType.CURRENCY,
      renderCell: (item) => {
        if (!item.charge) return '';

        return renderCurrency(item.charge);
      },
      editable,
      renderHeader,
    },
    {
      key: 'complete',
      label: 'Complete',
      width: 80,
      numeric: true,
      type: EditorColumnType.PERCENTAGE,
      renderCell: (item) => {
        if (item.omit)
          return <PassiveIndicator>N/A</PassiveIndicator>;

        return (
          <PercentageIndicator percent={item.complete}/>
        );
      },
      editable,
      renderHeader,
    },
    {
      key: 'omit',
      label: 'Omit',
      width: 80,
      numeric: true,
      type: EditorColumnType.CURRENCY,
      renderCell: (item) => {
        if (!item.omit)
          return '';

        return renderCurrency(item.omitted);
      },
      stringValue: (item) => {
        return item.omit ? 'Yes' : 'No'
      },
      editable,
      renderHeader,
      choices: binaryChoice
    },
    {
      key: 'billable',
      label: 'Total',
      width: 80,
      numeric: true,
      type: EditorColumnType.CURRENCY,
      renderCell: (item) => {
        if (item.omit)
          return renderCurrency(0);

        if (!item.billable)
          return '';

        return renderCurrency(item.billable);
      },
      editable,
      renderHeader,
    },
  ];

  let filteredColumns = columns;

  if (!valuation || !valuation.details.showOrder) {
    filteredColumns = filteredColumns.filter(c => c.key !== 'order')
  }

  const pages: Array<EditorPage<ValuationSectionItem>> = sections.map((section, index) => {
    const isAdditional = index === sections.length - 1;

    return {
      name: isAdditional ? 'Additionals' : section.name,
      subtitle: isAdditional ? '' : `Section ${index + 1}`,
      items: section.items.all
    }
  });

  return (
    <TableGroup
      id="valuation_sections_table"
      columns={filteredColumns}
      pages={pages}
      onSave={onSave}
      allowCreate={false}
      children={children}
      currentCommand={currentCommand}
      allowFullScreen={true}
      allowShrinkRows={true}
      expandToFill={expandToFill}
      stickOnScroll={true}
    />
  )
}