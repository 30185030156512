import {AnyCommand, INVITE_TEAM_USER_COMMAND, InviteTeamUserCommand} from '../type';
import App from '../app';

export default function inviteTeamUser(payload: InviteTeamUserCommand['payload']): AnyCommand {
  return {
    type: INVITE_TEAM_USER_COMMAND,
    payload,
  }
}

export const useInviteTeamUserCommand = App.wrapCommand(inviteTeamUser);