import StringValue from '../../../framework/value/StringValue';
import NumberValue from '../../../framework/value/NumberValue';
import MapValue from '../../../framework/value/MapValue';
import {Entity} from '../../../framework/value/Entity';
import {FileId, ItemId, SectionId} from './App';
import EntityMapValue from '../../../framework/value/EntityMapValue';
import {EditorChoice, RowSelection} from '../component/editor/type';
import JobSection, {Items as JobSectionItems} from './JobSection';
import JobSectionItem, {JobSectionItemType, Order} from './JobSectionItem';

export type JobImportsJSON = Record<string, JobImportJSON>;

export interface JobImportJSON {
  id: string;
  pages: JobImportPagesJSON
}

export interface JobImportPagesJSON {
  [sheet: number]: JobImportPageJSON
}

export interface JobImportPageJSON {
  name: string;
  items: JobImportItemsJSON
}

export interface JobImportItemsJSON {
  [item: number]: JobImportItemJSON
}

export interface JobImportItemJSON {
  type?: JobImportItemType
  sourceRow?: number;
  columns: JobImportItemColumnsJSON
}

export interface JobImportItemColumnsJSON {
  [column: number]: JobImportItemColumnJSON
}

export interface JobImportItemColumnJSON {
  value: string;
}

export enum JobImportItemType {
  NOTED = 'noted',
  JOB_TOTAL = 'job_total',
  SECTION_HEADING = 'section_heading',
  ITEM_QUOTE = 'item_quote',
  ITEM_FIXED = 'item_fixed',
  ITEM_INCLUDED = 'item_included',
  ITEM_EXCLUDED = 'item_excluded',
}

export enum JobImportFormat {
  ONE_TAB = 'one_tab',
  MULTIPLE_TABS = 'multiple_tabs'
}

export const JobImportItemTypeLabel: {[key in JobImportItemType]: string} = {
  [JobImportItemType.NOTED]: 'NOTED',
  [JobImportItemType.JOB_TOTAL]: 'JOB TOTAL',
  [JobImportItemType.SECTION_HEADING]: 'SECTION',
  [JobImportItemType.ITEM_QUOTE]: 'COST',
  [JobImportItemType.ITEM_FIXED]: 'FIXED',
  [JobImportItemType.ITEM_INCLUDED]: 'INCLUDED',
  [JobImportItemType.ITEM_EXCLUDED]: 'EXCLUDED',
};

export const EmptyDetections: Record<JobImportItemType, Array<Ordered<JobImportDetection>>> = {
  [JobImportItemType.NOTED]: [],
  [JobImportItemType.JOB_TOTAL]: [],
  [JobImportItemType.SECTION_HEADING]: [],
  [JobImportItemType.ITEM_QUOTE]: [],
  [JobImportItemType.ITEM_FIXED]: [],
  [JobImportItemType.ITEM_INCLUDED]: [],
  [JobImportItemType.ITEM_EXCLUDED]: [],
};

export const JobImportItemTypeChoices: EditorChoice[] = Object.keys(JobImportItemTypeLabel).map(k => ({
  id: k,
  value: (JobImportItemTypeLabel as any)[k],
}));

interface UniqueSection {
  order: number;
  item: JobImportItemColumnsJSON;
  detections: JobImportDetection[];
}

type Ordered<T> = T & {order: number};

export default class JobImport extends Entity<FileId, {
  pages: Pages;
}> {
  get maxColumns() {
    return Math.max(...this.value.pages.all.map(page => page.maxColumns))
  }

  get pages() {
    return this.value.pages;
  }

  static fromJSON(json: JobImportJSON) {
    return new JobImport({
      id: new FileId(json.id),
      pages: Pages.fromJSON(json.pages)
    })
  }

  toJobSectionsArray({detections, columns, format, hasQuantity, willReindex}: JobImportMappingJSON, orderOffset = 0): JobSection[] {
    const pages = this.pages.toJSON();
    const parts = detections.reduce((parts, detection, index) => {
      const part = [...(parts[detection.type] || []), {
        ...detection,
        order: orderOffset + index,
      }];

      return {
        ...parts,
        [detection.type]: part
      };
    }, EmptyDetections);

    const getItemByDetection = (detection: JobImportDetection) => {
      const {page, sourceRow} = detection;
      return pages[page] && pages[page].items[sourceRow]
        ? pages[page].items[sourceRow].columns
        : null;
    };

    const getValuesFromColumns = (json: JobImportItemColumnsJSON) => {
      return {
        // can be missing/virtual
        cost: json[columns.cost]
          ? json[columns.cost].value
          : '',
        item: json[columns.item]
          ? json[columns.item].value
          : '',
        description: json[columns.name]
          ? json[columns.name].value
          : '',
        quantity: hasQuantity && typeof columns.quantity !== 'undefined' && json[columns.quantity]
          ? json[columns.quantity].value
          : '1'
      }
    };

    let currentSectionIndex = 0;
    const getSectionIndex = (item: string) => willReindex
      ? (parseInt(item) + currentSectionIndex++).toString()
      : item;

    const uniqueSections = parts.section_heading.reduce((matches, detection) => {
      const {order} = detection;
      const item = getItemByDetection(detection);

      if (!item) return matches;

      const values = getValuesFromColumns(item);
      const sectionIndex = getSectionIndex(values.item);

      const match = matches[sectionIndex];

      return {
        ...matches,
        [sectionIndex]: {
          item,
          order,
          detections: match && match
            ? [...match.detections, detection]
            : [detection]
        }
      };
    }, {} as Record<string, UniqueSection>);

    const mapToItem = (detection: JobImportDetection): JobSectionItem => {
      const item = getItemByDetection(detection);
      if (!item)
        throw new Error('missing item');

      const values = getValuesFromColumns(item);
      const isItem = detection.type === JobImportItemType.ITEM_FIXED
        || detection.type === JobImportItemType.ITEM_QUOTE;
      const rate = isItem
        ? parseFloat(values.cost) || 0
        : 0;
      const quantity = isItem
        ? hasQuantity
          ? parseFloat(values.quantity)
          : 1
        : 0;

      return JobSectionItem.fromJSON({
        id: ItemId.nextId(),
        order: detection.sourceRow,
        details: {
          markup: 0,
          quantity,
          description: values.description.toString(),
          item: values.item.toString(),
          rate,
          type: mapImportToSectionType(detection.type),
          omit: false,
          complete: 0,
        },
        status: {
          imported: true,
        },
      })
    };

    const firstSection = detections.find(d =>
      d.type === JobImportItemType.SECTION_HEADING
    );

    const firstItem = detections.find(d =>
      d.type === JobImportItemType.ITEM_QUOTE ||
      d.type === JobImportItemType.ITEM_FIXED
    );

    if (!firstItem) {
      console.warn('No items to import, aborting');
      return [];
    }

    if (!firstSection) {
      console.warn('No sections to import, aborting');
      return [];
    }

    const sections = Object.keys(uniqueSections)
      .sort((keyA, keyB) => {
        const a = uniqueSections[keyA];
        const b = uniqueSections[keyB];

        return a.order <= b.order
          ? -1
          : 1;
      })
      .map((key, index) => {
      const section = uniqueSections[key];
      const name = section.item[columns.name].value;

      const sectionItems = section.detections.flatMap(detection => {
        const nextSection = parts.section_heading.find(next => {
          return next.page >= detection.page
            && next.row > detection.row;
        });

        const filterValid = (item: JobImportDetection) => {
          if (item.type !== JobImportItemType.ITEM_FIXED
            && item.type !== JobImportItemType.ITEM_QUOTE
            && item.type !== JobImportItemType.ITEM_INCLUDED
            && item.type !== JobImportItemType.NOTED)
            return false;

          if (item.page !== detection.page)
            return false;

          if (nextSection && item.page === nextSection.page && item.sourceRow >= nextSection.sourceRow)
            return false;

          return item.sourceRow > detection.sourceRow;
        };

        return detections.filter(filterValid);
      }).sort((itemA, itemB) => {
        return itemA.sourceRow <= itemB.sourceRow
          ? -1
          : 1
      });

      return JobSection.fromJSON({
        id: SectionId.nextId(),
        order: index + 1,
        status: {
          imported: true
        },
        details: {
          name
        }
      }).extend({
        items: new JobSectionItems(sectionItems.map(mapToItem).reduce((map, item, index) => {
          return {
            ...map,
            [item.id]: item.extend({
              order: new Order(index + 1)
            })
          }
        }, {} as Record<string, JobSectionItem>))
      });
    });

    // TODO fix prelim finding
    const prelimItems = detections.filter(d => d.page === 0 && d.row < firstSection.row && d.row < firstItem.row);
    if (prelimItems.length === 0) return sections;

    const prelimSection = JobSection.fromJSON({
      id: SectionId.nextId(),
      order: 0,
      status: {
        imported: true,
      },
      details: {
        name: 'PRELIMS'
      }
    }).extend({
      items: new JobSectionItems(prelimItems.map(mapToItem).reduce((map, item) => {
        return {
          ...map,
          [item.id]: item
        }
      }, {} as Record<string, JobSectionItem>))
    });

    return [
      prelimSection,
      ...sections,
    ];
  }
}

function mapImportToSectionType(detectionType: JobImportItemType): JobSectionItemType {
  switch (detectionType) {
    case JobImportItemType.ITEM_FIXED:
      return JobSectionItemType.FIXED;

    case JobImportItemType.ITEM_INCLUDED:
      return JobSectionItemType.INCLUDED;

    case JobImportItemType.ITEM_EXCLUDED:
      return JobSectionItemType.EXCLUDED;

    case JobImportItemType.NOTED:
      return JobSectionItemType.NOTED;

    case JobImportItemType.ITEM_QUOTE:
      return JobSectionItemType.COST;

    default:
      return JobSectionItemType.EXCLUDED;
  }
}

export class Pages extends MapValue<{
  [sheet: number]: Page
}> {
  static fromJSON(json: JobImportPagesJSON) {
    return new Pages(Object.keys(json).reduce((record, page) => {
      return {
        ...record,
        [page]: Page.fromJSON(json[page as any]),
      };
    }, {} as Record<string, Page>))
  }

  get all(): Page[] {
    return Object.keys(this.value).reduce((jobs, key) => {
      return [...jobs, this.value[key as any]];
    }, [] as Page[]);
  }

  toJSON(): JobImportPagesJSON {
    return super.toJSON() as JobImportPagesJSON;
  }
}

export class Page extends MapValue<{
  name: Name,
  items: Items
}> {
  get name() {
    return this.value.name.value;
  }

  get items() {
    return this.value.items;
  }

  get maxColumns() {
    return Math.max(...this.items.all.map(item => item.maxColumns))
  }

  static fromJSON(json: JobImportPageJSON) {
    return new Page({
      name: new Name(json.name),
      items: Items.fromJSON(json.items),
    })
  }
}

export class Name extends StringValue {}
export class Sheet extends NumberValue {}

export class Items extends MapValue<{
  [item: number]: Item
}> {
  static fromJSON(json: JobImportItemsJSON) {
    return new Items(Object.keys(json).reduce((record, item) => {
      return {
        ...record,
        [item]: Item.fromJSON(json[item as any]),
      };
    }, {} as Record<string, Item>))
  }

  get all(): Item[] {
    return Object.keys(this.value).map(k => parseInt(k)).reduce((items, key) => {
      const item = this.value[key as any];

      return [...items, item.extend({
        sourceRow: new SourceRow(key)
      })];
    }, [] as Item[]);
  }
}

export class Item extends MapValue<{
  columns: Columns,
  sourceRow: SourceRow
}> {
  get columns() {
    return this.value.columns;
  }

  get maxColumns() {
    return Math.max(...Object.keys(this.columns.value).map(v => parseInt(v) + 1));
  }

  static fromJSON(json: JobImportItemJSON) {
    return new Item({
      columns: Columns.fromJSON(json.columns),
      sourceRow: new SourceRow(0)
    })
  }
}

export class SourceRow extends NumberValue {}

export class Columns extends MapValue<{
  [column: number]: Column
}> {
  collapse() {
    return Object.keys(this.value).reduce((output, column) => {
      return {
        ...output,
        [column]: this.value[column as any].value.value.value
      }
    }, {} as Record<string, string>)
  }

  static fromJSON(json: JobImportItemColumnsJSON) {
    return new Columns(Object.keys(json).reduce((record, item) => {
      return {
        ...record,
        [item]: Column.fromJSON(json[item as any]),
      };
    }, {} as Record<string, Column>))
  }
}

export class Column extends MapValue<{
  value: StringValue
}> {
  static fromJSON(json: JobImportItemColumnJSON) {
    return new Column({
      value: new StringValue(json.value)
    })
  }
}

export class JobImports extends EntityMapValue<FileId, JobImport> {
  static fromJSON(json: Partial<JobImportsJSON>): JobImports {
    return new JobImports(Object.keys(json).reduce((items, id) => {
      return {
        ...items,
        [id]: JobImport.fromJSON(json[id]!)
      };
    }, {} as Record<string, JobImport>))
  }
}

export type JobImportDetection = RowSelection & {
  page: number;
  sourceRow: number;
  type: JobImportItemType
}

export interface JobImportMappingJSON {
  format: JobImportFormat;
  columns: JobImportMappingColumnsJSON;
  detections: Array<JobImportDetection>;
  hasQuantity?: boolean;
  willReindex?: boolean;
}

export interface JobImportMappingColumnsJSON {
  item: number;
  name: number;
  cost: number;
  quantity?: number;
}