import {AnyEvent, TEAM_SELECTED_EVENT, USER_TEAM_JOINED_EVENT} from '../type';
import {UserTeamJSON} from '../model/User';
import {TeamJSON} from '../model/Team';

export default function teamSelected(teamId: string, team: TeamJSON): AnyEvent {
  return {
    type: TEAM_SELECTED_EVENT,
    payload: {
      teamId,
      team
    },
  }
}