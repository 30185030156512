import Form from '../form/Form';
import {h} from 'preact';
import FieldGroup from '../form/FieldGroup';
import Field from '../form/Field';
import Input from '../form/Input';
import Button from '../form/Button';
import GoogleSignInButton from '../GoogleSignInButton';
import App from '../../app';
import {useStartSignInWithGoogleCommand} from '../../command/startSignInWithGoogle';
import {useStartSignInWithEmailCommand} from '../../command/startSignInWithEmail';
import {useEffect, useRef} from 'preact/hooks';
import useCommandErrors from '../../../../framework/hook/useCommandErrors';
import {useCompleteSignInWithEmailCommand} from '../../command/completeSignInWithEmail';

interface SignInFormFields {
  UserEmail: string;
}

export default function SignInForm() {
  const containerClass = App.useStyle(state => ({
    $nest: {
      '& label': {
        minWidth: 'unset !important',
      }
    }
  }));

  const emailClass = App.useStyle(state => ({
    flex: 1,
    maxWidth: state.theme.component.Input.maxWidth,
  }));

  const emailRef = useRef<HTMLInputElement>();
  const {auth} = App.useServices();
  const currentUrl = window.location.href;
  const isSignInLink = auth.isSignInWithEmailLink(currentUrl);

  const [,signInWithGoogle] = useStartSignInWithGoogleCommand();
  const [state, signInWithEmail] = useStartSignInWithEmailCommand();
  const [,completeSignInWithEmail] = useCompleteSignInWithEmailCommand();
  const {system, field} = useCommandErrors<SignInFormFields>(state);

  useEffect(() => {
    if (!isSignInLink) return;
    const email = auth.getStoredEmail() || prompt('You are using a different browser and/or device to finish signing in. If you wish to continue please enter the email address you signed in with');
    if (!email) return;
    completeSignInWithEmail(email, currentUrl);
  },[isSignInLink]);

  const submit = () => {
    if (!emailRef.current) return;
    signInWithEmail(emailRef.current.value, window.location.href);
  };

  return (
    <Form onSubmit={submit} error={system}>
      <FieldGroup horizontal={true} className={containerClass}>
        <Field label="Email" className={emailClass} error={field.UserEmail}>
          <Input
            innerRef={emailRef}
            placeholder="your@email.com"
          />
        </Field>
        <Field fill={false}>
          <Button primary={true} type="submit">Sign In</Button>
        </Field>
        <Field fill={false}>or</Field>
        <Field fill={false}>
          <GoogleSignInButton
            onClick={(event) => {
              event.preventDefault();
              signInWithGoogle();
            }}
          />
        </Field>
      </FieldGroup>
    </Form>
  )
}