import {h} from 'preact';
import App from '../app';
import {percent, px, rem} from 'csx';
import {HTMLAttributes} from '../../../framework/type';
import classes from '../../../framework/classes';
import NavigationBar from './NavigationBar';

interface Props extends HTMLAttributes {
  showNavigation?: boolean;
}

export default function Layout({showNavigation, children, ...props}: Props) {
  App.useStyleSheet('//rsms.me/inter/inter.css');

  App.useGlobalStyle('html, body, #app', state => ({
    fontFamily: state.theme.font.text,
    fontSize: px(16),
    lineHeight: px(20),
    background: state.theme.color.background.toString(),
    minHeight: percent(100),
    maxHeight: percent(100),
    height: percent(100),
  }));
  
  App.useGlobalStyle('*:focus', state => ({
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    appearance: 'none',
    outline: `0.1875rem solid ${state.theme.color.blue.toString()}`,
    outlineOffset: rem(0.0625)
  }));

  App.useGlobalStyle('*::selection', state => ({
    color: state.theme.color.black.toString(),
    background: state.theme.color.yellow.toString(),
  }));

  App.useGlobalStyle('a', state => ({
    color: state.theme.color.blue.darken(0.2).toString(),
  }));

  const containerClass = App.useStyle({
    width: percent(100),
    minHeight: percent(100),
    maxHeight: percent(100),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  });

  const navigationClass = App.useStyle(({theme}) => ({
    padding: '0 1rem',
    opacity: showNavigation ? 1 : 0,
    zIndex: 41,
    background: theme.color.background.toString(),
    transition: `opacity ${theme.transition.navBar}`,
    ...theme.media.tablet({
      padding: '0 2rem'
    })
  }), [showNavigation]);

  const childrenClass = App.useStyle(state => ({
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    margin: '0 1rem 1.25em 1rem',
    flex: 1,
    width: `calc(100% - 2rem)`,
    overflow: 'auto',
    ...state.theme.media.tablet({
      margin: '0 2rem 2.5rem 2rem',
      width: `calc(100% - 4rem)`
    })
  }));

  return (
    <div {...props} className={classes(containerClass, props.className)}>
      <div className={navigationClass}>
        {showNavigation && (
          <NavigationBar />
        )}
      </div>
      <div className={childrenClass}>{children}</div>
    </div>
  )
}