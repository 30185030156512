import {AnyCommand, DELETE_JOB_VARIATION_ITEMS_COMMAND, DeleteJobVariationItemsCommand} from '../type';
import App from '../app';

export default function deleteJobVariationItems(payload: DeleteJobVariationItemsCommand['payload']): AnyCommand {
  return {
    type: DELETE_JOB_VARIATION_ITEMS_COMMAND,
    payload,
  }
}

export const useDeleteJobVariationItemsCommand = App.wrapCommand(deleteJobVariationItems);