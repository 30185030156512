import { VariationId } from './App';
import { JobSectionJSON } from './JobSection';
import EntityMapValue from '../../../framework/value/EntityMapValue';
import JobVariationItem, { JobVariationItemJSON } from './JobVariationItem';

export default class JobVariations extends EntityMapValue<VariationId, JobVariationItem> {
  get all(): JobVariationItem[] {
    return Object.keys(this.value).reduce((items, key) => {
      return [...items, this.value[key]];
    }, [] as JobVariationItem[]).sort((a, b) => a.order < b.order ? -1 : a.order > b.order ? 1 : 0);
  }

  get maximumVariationOrder() {
    return this.all.reduce((max, item) => {
      return Math.max(max, item.order);
    }, 0)
  }

  toJSON(): JobSectionJSON {
    return super.toJSON() as JobSectionJSON;
  }

  static fromJSON(json: Partial<JobVariationsJSON> = {}) {
    return new JobVariations(Object.keys(json).reduce((items, id) => {
      return {
        ...items,
        [id]: JobVariationItem.fromJSON(json[id] || {})
      };
    }, {} as Record<string, JobVariationItem>))
  }
}

export type JobVariationsJSON = {[id: string]: JobVariationItemJSON}