import App from '../app';
import {AnyCommand, COMPLETE_SIGN_IN_WITH_EMAIL_COMMAND} from '../type';

export default function completeSignInWithEmail(email: string, linkUrl: string): AnyCommand {
  return {
    type: COMPLETE_SIGN_IN_WITH_EMAIL_COMMAND,
    payload: {
      email,
      linkUrl,
    }
  }
}

export const useCompleteSignInWithEmailCommand =
  App.wrapCommand(completeSignInWithEmail);