import {Relation} from '../../../framework/value/Relation';
import {TeamId, UserId} from './App';
import MapValue from '../../../framework/value/MapValue';
import {Email} from '../../../framework/value/Email';
import StringValue from '../../../framework/value/StringValue';
import EntityMapValue from '../../../framework/value/EntityMapValue';
import {Entity} from '../../../framework/value/Entity';
import BooleanValue from '../../../framework/value/BooleanValue';
import EntityStatus from '../../../framework/value/EntityStatus';
import {TeamUser} from './Team';
import NumberValue from '../../../framework/value/NumberValue';

export class User extends Entity<UserId, {
  email: UserEmail;
  profile: UserProfile;
  teams: UserTeams;
  status: UserStatus;
}> {
  static create(props: {id: string, name: string, email: string, isNew: boolean}) {
    return new User({
      id: new UserId(props.id),
      teams: UserTeams.EMPTY,
      email: new UserEmail(props.email),
      status: UserStatus.DEFAULT.extend({
        isNew: new BooleanValue(props.isNew),
      }),
      profile: new UserProfile({
        name: new UserName(props.name),
      })
    })
  }

  get profile() {
    return this.value.profile
  }

  get status() {
    return this.value.status
  }

  get teams() {
    return this.value.teams
  }
}

export class Users extends EntityMapValue<UserId, User> {}

export class UserName extends StringValue {
  static ANONYMOUS = new UserName('Anonymous')
}
export class UserEmail extends Email {}

export class UserProfile extends MapValue<{
  name: UserName
}> {
  get name() {
    return this.value.name.value
  }
}

export class UserStatus extends EntityStatus {
  static create (loading: boolean = false, isNew: boolean = false) {
    return new UserStatus({
      loading: new BooleanValue(loading),
      isNew: new BooleanValue(isNew)
    })
  };

  static DEFAULT = UserStatus.create();
}

export class UserNewUserStatus extends BooleanValue {}

export class UserTeam extends Relation<UserTeamId, {
  name: UserTeamName,
  profile: UserTeamProfile,
}> {
  get id() {
    return this.value.id.id;
  }

  get name() {
    return this.value.name.toString();
  }

  get userName() {
    return this.value.profile.value.name.toString()
  }

  get userLetter() {
    return (this.userName[0] || '-').toUpperCase()
  }

  get userColor() {
    return `hsl(${Math.abs(this.value.profile.value.color.value % 360)}, 100%, 70%)`
  }

  static fromJSON(data: Record<string, any> | UserTeamJSON) {
    return new UserTeam({
      id: new UserTeamId(data.id || ''),
      name: new UserTeamName(data.name || ''),
      profile: new UserTeamProfile({
        name: new UserTeamProfileName(data && data.profile && data.profile.name || ''),
        color: new UserTeamProfileColor(data && data.profile && data.profile.color || 69)
      })
    })
  }
}

export interface UserTeamJSON {
  id: string;
  name: string;
  profile: {
    name: string;
    color: number;
  }
}


export class UserTeamId extends TeamId {}
export class UserTeamName extends StringValue {}
export class UserTeamProfileName extends StringValue {}
export class UserTeamProfileColor extends NumberValue {}

export class UserTeams extends EntityMapValue<UserTeamId, UserTeam> {
  static EMPTY = new UserTeams({});

  get all(): UserTeam[] {
    return Object.keys(this.value).reduce((teams, key) => {
      return [...teams, this.value[key]];
    }, [] as UserTeam[]);
  }

  static fromJSON(json: UserTeamJSON[]) {
    const record = json.map(UserTeam.fromJSON).reduce((record, team) => ({
      ...record,
      [team.id]: team
    }), {} as Record<string, UserTeam>);

    return new UserTeams(record);
  }
}

export class UserTeamProfile extends MapValue<{
  name: UserTeamProfileName,
  color: UserTeamProfileColor,
}> {}