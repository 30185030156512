import MapValue from '../../../framework/value/MapValue';
import {ItemId, SectionId} from './App';
import NumberValue from '../../../framework/value/NumberValue';
import {Entity} from '../../../framework/value/Entity';
import StringValue from '../../../framework/value/StringValue';
import EntityMapValue from '../../../framework/value/EntityMapValue';
import ValuationSectionItem, {ValuationSectionItemJSON} from './ValuationSectionItem';

export interface ValuationSectionJSON {
  id: string;
  details: ValuationSectionDetailsJSON;
  order: number;
  items: ValuationSectionItemsJSON;
}

export default class ValuationSection extends Entity<SectionId, {
  details: ValuationSectionDetails,
  order: Order,
  items: ValuationSectionItems,
}> {
  get id() {
    return this.value.id.id;
  }

  get items() {
    return this.value.items;
  }

  get name() {
    return this.value.details.value.name.value;
  }

  get order() {
    return this.value.order.value;
  }

  get charge() {
    return this.items.all.reduce((charge, item) => {
      return charge + item.charge;
    }, 0);
  }

  get omitted() {
    return this.items.all.reduce((omitted, item) => {
      return omitted + item.omitted;
    }, 0);
  }

  get invoiced() {
    return this.items.all.reduce((invoiced, item) => {
      return invoiced + item.invoiced;
    }, 0);
  }

  get billable() {
    return this.items.all.reduce((charge, item) => {
      return charge + item.billable;
    }, 0);
  }

  get complete() {
    const allItems = this.items.all;
    if (allItems.length === 0) return 0;

    const items = this.items.included;
    if (items.length === 0) return 100;

    const total = items.reduce((total, item) => {
      return total + item.complete
    }, 0);

    if (total === 0) return 0;

    return total / items.length;
  }

  get maximumItemOrder() {
    return this.items.all.reduce((max, item) => {
      return Math.max(max, item.order);
    }, 0)
  }

  toJSON(): ValuationSectionJSON {
    return super.toJSON() as ValuationSectionJSON;
  }

  static fromJSON(json: Partial<ValuationSectionJSON> = {}) {
    return new ValuationSection({
      id: new SectionId(json.id || ''),
      details: ValuationSectionDetails.fromJSON(json.details),
      order: new Order(json.order || 0),
      items: ValuationSectionItems.fromJSON(json.items || {}),
    })
  }
}

export interface ValuationSectionDetailsJSON {
  name: string;
}

export class ValuationSectionDetails extends MapValue<{
  name: Name
}, ValuationSectionDetailsJSON> {
  static fromJSON(json: Partial<ValuationSectionDetailsJSON> = {}) {
    return new ValuationSectionDetails({
      name: new Name(json.name || '')
    })
  }
}

export class Name extends StringValue {}
export class Order extends NumberValue {}

export interface ValuationSectionItemsJSON {
  [id: string]: ValuationSectionItemJSON
}

export class ValuationSectionItems extends EntityMapValue<ItemId, ValuationSectionItem> {
  get all(): ValuationSectionItem[] {
    return Object.keys(this.value).reduce((items, key) => {
      return [...items, this.value[key]];
    }, [] as ValuationSectionItem[]).sort((a, b) => a.order < b.order ? -1 : a.order > b.order ? 1 : 0);
  }

  get included(): ValuationSectionItem[] {
    return this.all.filter(i => !i.omit);
  }

  get billable(): ValuationSectionItem[] {
    return this.all.filter(i => i.billable > 0);
  }

  static fromJSON(json: ValuationSectionItemsJSON) {
    return new ValuationSectionItems(Object.keys(json).reduce((items, id) => {
      return {
        ...items,
        [id]: ValuationSectionItem.fromJSON(json[id])
      };
    }, {} as Record<string, ValuationSectionItem>))
  }
}