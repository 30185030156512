import {HTMLAttributes} from '../../../../framework/type';
import App from '../../app';
import {h} from 'preact';
import classes from '../../../../framework/classes';

interface Props extends HTMLAttributes {

}

export default function PassiveIndicator(props: Props) {
  const className = App.useStyle(({theme}) => ({
    fontStyle: 'italic',
    color: theme.color.gray.toString(),
  }), []);

  return (<i {...props} className={classes(className, props.className)} />)
}