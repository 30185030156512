import {useCallback, useReducer} from 'preact/hooks';
import {
  AnyFrameworkEvent,
  COMMAND_UPDATED,
  CommandResponse,
  CommandStatus,
  IdentifiedCommandFunction,
  ServiceMap,
  SystemState
} from '../type';
import createIdGenerator from '../createIdGenerator';
import {SystemError} from '../error';
import createCommandNotifier from '../createCommandNotifier';

export default function useCommandServiceReducer<CommandTypes>(
  services: ServiceMap<any, CommandTypes, any>,
  initialState: SystemState<CommandTypes> = defaultState
): [SystemState<CommandTypes>, IdentifiedCommandFunction<CommandTypes>] {

  const reducer = useCallback((state: SystemState<CommandTypes>, event: AnyFrameworkEvent) => {
    if (event.type !== COMMAND_UPDATED)
      return state;

    console.log('command', event.payload.status, event.payload.command, event.payload.errors);

    return {
      ...state,
      command: {
        ...state.command,
        [event.payload.id]: event.payload
      }
    };
  }, [services]);

  const [state, dispatch] = useReducer<SystemState<CommandTypes>, AnyFrameworkEvent>(reducer, initialState);

  const wrappedDispatch = (command: CommandTypes) => {
    const id = nextId();
    const {initial, running, success, fail} = createCommandNotifier({
      id,
      status: CommandStatus.INITIAL,
      errors: [],
      command,
      timestamp: Date.now(),
    }, dispatch);

    initial();

    try {
      const promises: Array<Promise<any>> = Object.keys(services).map(key => {
        return services[key].onCommand(state, command);
      });

      Promise.all(promises)
        .then((allErrors: Array<CommandResponse>) => {
          const errors = allErrors.reduce((all, e) => ([...all, ...e]), []);
          return errors.length
            ? fail(errors)
            : success();
        })
        .catch((error) => fail([new SystemError(error.message)]));

      running();
    } catch (error) {
      fail([new SystemError(error.message)]);
    }

    return id;
  };

  return [state, wrappedDispatch];
}

const defaultState: SystemState<any> = {
  command: {}
};

const nextId = createIdGenerator();