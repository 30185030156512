import {
  AsyncCommandResponse,
  CommandFunction,
  CommandResponse,
  EventFunction,
  ProxyCommandHandler,
  Service
} from './type';
import {Value} from './value/Value';
import {SystemError, TodoError, ValidationError} from './error';

export default class ApplicationService<State, CommandTypes, EventTypes> implements Service<State, CommandTypes, EventTypes> {
  protected readonly valid = Value.VALID;
  protected listeners: Array<EventFunction<EventTypes>> = [];
  protected proxies: Array<ProxyCommandHandler<CommandTypes>> = [];

  subscribe(listener: EventFunction<EventTypes>) {
    if (this.listeners.includes(listener)) return;
    this.listeners.push(listener);
  };

  unsubscribe(listener: EventFunction<EventTypes>) {
    this.listeners = this.listeners.filter(l => l !== listener);
  };

  dispatch(event: EventTypes) {
    this.listeners.forEach(listener => listener(event));
  };

  register(proxy: ProxyCommandHandler<CommandTypes>) {
    if (this.proxies.includes(proxy)) return;
    this.proxies.push(proxy);
  };

  deregister(proxy: ProxyCommandHandler<CommandTypes>) {
    this.proxies = this.proxies.filter(p => p !== proxy);
  };

  async proxy(command: CommandTypes): AsyncCommandResponse {
    const allResponses = await Promise.all(this.proxies.map(fn => fn(command)));

    return allResponses.reduce(
      (errors, response) => ([...errors, ...response]),
      [] as CommandResponse
    )
  }

  async proxyOptimisticDispatch(command: CommandTypes, ...events: EventTypes[]) {
    const proxy = this.proxy(command);
    // events.forEach(event => this.dispatch(event));
    proxy.then((errors) => {
      if (errors.length) return;
      events.forEach(event => this.dispatch(event));
    });
    return proxy;
  }

  onEvent(state: State, event: EventTypes) {
    return state;
  };

  async onCommand(state: State, command: CommandTypes): AsyncCommandResponse {
    return this.valid;
  }

  protected todo(message?: string): CommandResponse {
    return this.errors([new TodoError(message)])
  };


  protected systemError(error: Error | string): CommandResponse {
    return this.errors([new SystemError(error instanceof Error ? error.message : error)]);
  }

  protected errors(errors: Error[]): CommandResponse {
    return errors;
  }

  protected validationErrors(values: Array<Value<any>>): CommandResponse {
    return values.reduce((errors, value) => {
      return [
        ...errors,
        ...value.validate()
      ]
    }, [] as ValidationError[])
  }

  protected async execute<Args extends Array<Value<any>>>(values: Args, fn: (...values: Args) => Promise<any>) {
    try {
      if (values.length) {
        const errors = this.validationErrors(values);
        if (errors.length) {
          return errors;
        }
      }
      return (await fn(...values)) || this.valid;
    } catch (error) {
      console.log(error);
      return this.systemError(error.message)
    }
  }
}