import {HTMLAttributes} from '../../../../framework/type';
import {h} from 'preact';
import classes from '../../../../framework/classes';
import App from '../../app';
import {percent} from 'csx';

interface Props extends HTMLAttributes {

}

export default function Truncated(props: Props) {
  const className = App.useStyle(({theme}) => ({
    overflow: 'hidden',
    display: 'inline-block',
    whiteSpace: 'pre',
    maxWidth: percent(100),
    textOverflow: 'ellipsis',
  }));

  return (
    <span {...props} className={classes(className, props.className)}/>
  )
}