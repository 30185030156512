import {AnyCommand, SAVE_JOB_TEMPLATE_COMMAND, SaveJobTemplateCommand} from '../type';
import App from '../app';

export default function saveJobTemplate(payload: SaveJobTemplateCommand['payload']): AnyCommand {
  return {
    type: SAVE_JOB_TEMPLATE_COMMAND,
    payload,
  }
}

export const useSaveJobTemplateCommand = App.wrapCommand(saveJobTemplate);