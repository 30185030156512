import App from '../app';
import {useStartJobFileUpload} from '../command/startJobFileUpload';
import {useCompleteJobFileUpload} from '../command/completeJobFileUpload';
import currentSession from '../query/currentSession';
import {useCallback, useState} from 'preact/hooks';
import * as firebase from 'firebase/app';
import {useReceiveEventCommand} from '../command/receiveEvent';
import jobFileUploadUpdated from '../event/jobFileUploadUpdated';
import {useFailJobFileUpload} from '../command/failJobFileUpload';
import {CommandContainer} from '../../../framework/type';

export default function jobFileStorageUpload(jobId: string): [(fileName: string, upload: File) => void, CommandContainer<any>, CommandContainer<any>, CommandContainer<any>] {
  const {job} = App.useServices();
  const {userId, teamId} = currentSession();

  const [startCommand, startUpload] = useStartJobFileUpload();
  const [completeCommand, completeUpload] = useCompleteJobFileUpload();
  const [failCommand, failUpload] = useFailJobFileUpload();
  const [current, setCurrent] = useState<CommandContainer<any>>(startCommand);
  const [, proxy] = useReceiveEventCommand();

  const dispatch = useCallback((fileName: string, upload: File) => {
    if (!teamId.id) return;

    const [file, task] = job.uploadJobFile(teamId.id, jobId, fileName, upload);
    const commandProps = {
      teamId: teamId.id,
      jobId: jobId,
      fileId: file.id,
      fileType: file.fileType,
      filePath: file.filePath,
      fileName: file.fileName,
    };

    startUpload(commandProps);

    task.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        proxy({
          timestamp: new Date().toISOString(),
          user: userId.id,
          team: teamId.id,
          event: jobFileUploadUpdated({
            jobId,
            progress: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
            teamId: teamId.id,
            fileId: file.id,
          })
        })
      },
      (error) => failUpload({
        jobId,
        fileId: file.id,
        teamId: teamId.id,
        error: error.message,
      }),
      () => completeUpload({
        jobId,
        fileId: file.id,
        teamId: teamId.id,
      })
    )
  }, [jobId]);

  return [dispatch, startCommand, completeCommand, failCommand];
}