import TableGroup from '../editor/TableGroup';
import { h } from 'preact';
import { EditorColumn, EditorColumnType } from '../editor/type';
import { INVOICE_AMOUNT_LABEL, Valuation, ValuationSummaryItem } from '../../model/Valuation';
import ActiveIndicator from '../media/ActiveIndicator';
import renderCurrency from '../../helper/renderCurrency';

interface Props {
  valuation: Valuation;
}

export default function ValuationSummaryTable({valuation}: Props) {
  const columns: Array<EditorColumn<ValuationSummaryItem>> = [
    {
      key: 'label',
      label: 'Summary',
      width: 320,
    },
    {
      key: 'value',
      label: '',
      width: 80,
      type: EditorColumnType.CURRENCY,
      numeric: true,
      renderCell(item: ValuationSummaryItem, index: number): any {
        if (!item.label) return '';

        if (item.label === INVOICE_AMOUNT_LABEL) {
          return (
            <ActiveIndicator>{renderCurrency(item.value)}</ActiveIndicator>
          )
        };

        return renderCurrency(item.value);
      }
    },
  ];

  return (
    <TableGroup
      id={'valuation_summary_table'}
      columns={columns}
      readOnly={true}
      singlePage={{
        name: 'Valuation Summary',
        items: valuation.summary,
      }}
    />
  )
}