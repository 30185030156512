import {h} from 'preact';
import useJob from '../../query/useJob';
import CompanySectionsTable from './CompanySectionsTable';

interface Props {
  jobId: string;
}

export default function CompanyList({jobId}: Props) {
  const [job] = useJob(jobId);

  if (!job) return null;

  return (
    <CompanySectionsTable
      job={job}
    />
  )
}