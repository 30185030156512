import Form from '../form/Form';
import {h} from 'preact';
import FieldGroup from '../form/FieldGroup';
import Field from '../form/Field';
import Input from '../form/Input';
import {useCallback, useEffect, useRef, useState} from 'preact/hooks';
import Button from '../form/Button';
import useCommandErrors from '../../../../framework/hook/useCommandErrors';
import {CommandStatus} from '../../../../framework/type';
import {useCreateJobCommand} from '../../command/createJob';
import autoFocus from '../../effect/autoFocus';
import {JobId} from '../../model/App';
import {route} from 'preact-router';
import currentTeam from '../../query/currentTeam';
import {useInviteTeamUserCommand} from '../../command/inviteTeamUser';

interface CreateJobFormFields {
  UserName: string;
  UserEmail: string;
}

interface Props {
  onCancel(): void;
  onSuccess(): void;
}

export default function InviteUserForm({onCancel, onSuccess}: Props) {
  const userName = useRef<HTMLInputElement>();
  const userEmail = useRef<HTMLInputElement>();

  const team = currentTeam();

  if (!team) return null;

  const [command, inviteUser] = useInviteTeamUserCommand();
  const {system, field} = useCommandErrors<CreateJobFormFields>(command);

  const running = command && command.status === CommandStatus.RUNNING;

  const submit = useCallback(() => {
    if (running || !team || !userName.current || !userEmail.current) return;

    inviteUser({
      teamId: team.id,
      userName: userName.current.value,
      userEmail: userEmail.current.value,
    })
  }, [inviteUser, team, userName]);

  useEffect(() => {
    if (command && command.status === CommandStatus.SUCCESS) {
      onSuccess();
    }
  }, [command]);

  autoFocus(userName);

  if (!team) return null;

  return (
    <Form disabled={running} heading="Invite Teammate" error={system} onSubmit={submit}>
      <FieldGroup>
        <Field label="Their name" error={field.UserName}>
          <Input disabled={running} innerRef={userName} />
        </Field>
        <Field label="Their email address" error={field.UserEmail}>
          <Input disabled={running} innerRef={userEmail} />
        </Field>
      </FieldGroup>
      <FieldGroup horizontal={true}>
        <Button disabled={running} primary={true} type="submit">{running ? 'Inviting...' : 'Invite Teammate'}</Button>
        <Button disabled={running} type="cancel" onClick={onCancel}>Cancel</Button>
      </FieldGroup>
    </Form>
  )
}