import Form from '../form/Form';
import {h} from 'preact';
import FieldGroup from '../form/FieldGroup';
import Field from '../form/Field';
import {useEffect, useRef, useState} from 'preact/hooks';
import Button from '../form/Button';
import autoFocus from '../../effect/autoFocus';
import jobFileStorageUpload from '../../effect/jobFileStorageUpload';
import FileInput from '../form/FileInput';
import {JobAsset, JobAssetType} from '../../model/JobAssets';
import {CommandStatus} from '../../../../framework/type';
import useCommandErrors from '../../../../framework/hook/useCommandErrors';
import commandsAreRunning from '../../../../framework/commandsAreRunning';

interface Props {
  jobId: string;
  onCancel(): void;
  onSuccess(): void;
}

export default function UploadScheduleForm({jobId, onCancel, onSuccess}: Props) {
  const fileData = useRef<HTMLInputElement>();
  const [file, setFile] = useState<File | null>(null);

  const [startUpload, startCommand, completeCommand, failCommand] = jobFileStorageUpload(jobId);
  const start = useCommandErrors(startCommand);
  const complete = useCommandErrors(completeCommand);
  const fail = useCommandErrors(completeCommand);

  const submit = () => {
    if (!file) return;
    startUpload(JobAssetType.SOURCE_SCHEDULE, file);
  };

  useEffect(() => {
    if (!completeCommand || completeCommand.status !== CommandStatus.SUCCESS) return;
    onSuccess();
  }, [completeCommand]);

  autoFocus(fileData);

  const isEmpty = !fileData.current || !fileData.current.files || !fileData.current.files[0];
  const isLoading = commandsAreRunning(startCommand, completeCommand, failCommand);

  return (
    <Form heading="Upload Schedule" onSubmit={submit} error={start.system || complete.system || fail.system}>
      <FieldGroup>
        <Field label="File">
          <FileInput
            innerRef={fileData}
            onChange={event => {
              const target = event.currentTarget as HTMLInputElement;
              const next = target && target.files && target.files.length
                ? target.files[0]
                : null;

              setFile(next);
            }}
          />
        </Field>
      </FieldGroup>
      <FieldGroup horizontal={true}>
        <Button primary={true} type="submit" disabled={isEmpty || isLoading}>Upload</Button>
        <Button type="cancel" onClick={onCancel}>Cancel</Button>
      </FieldGroup>
    </Form>
  )
}