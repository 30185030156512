import {h} from 'preact';
import App from '../../app';
import {rem} from 'csx';
import {TeamUser} from '../../model/Team';

interface Props {
  user: TeamUser
}

export default function TeamUserAvatar({user}: Props) {
  const className = App.useStyle(() => ({
    fontWeight: 900,
    fontSize: rem(0.75),
    textAlign: 'center',
    lineHeight: rem(1.25),
    width: rem(1.25),
    height: rem(1.25),
    borderRadius: rem(0.125)
  }));

  return (
    <div
      className={className}
      style={{backgroundColor: user.userColor}}
      children={user.userLetter}
    />
  )
}