import {Fragment, h} from 'preact';
import SectionsTable from './SectionsTable';
import JobSection from '../../model/JobSection';
import ButtonGroup from '../form/ButtonGroup';
import DeleteSectionItemsButton from './DeleteSectionItemsButton';
import {Job, jobPath, JobTab} from '../../model/Job';
import saveJobSectionItemCallback from '../../effect/saveJobSectionItemCallback';
import Paragraph from '../media/Paragraph';
import {Link} from 'preact-router';
import MoveSectionItemUpButton from './MoveSectionItemUpButton';
import MoveSectionItemDownButton from './MoveSectionItemDownButton';
import AllocateItemsButton from './AllocateItemsButton';

interface Props {
  job: Job;
}

export default function SectionList({job}: Props) {
  if (!job) return null;
  const allSections: JobSection[] = job.sections.all;
  const [currentItemCommand, saveItem] = saveJobSectionItemCallback(job);

  return (
    <Fragment>
      {allSections.length > 0 && (
        <SectionsTable
          sections={allSections}
          job={job}
          onSave={saveItem}
          expandToFill={true}
          currentCommand={currentItemCommand}
          children={(items, selection, setSelection) => {
            const section = selection && job.sections.all[selection.page || 0];
            const item = !!section && !!items.length && items[0];

            return (
              <ButtonGroup>
                {!!item && (
                  <MoveSectionItemUpButton
                    job={job}
                    section={section!}
                    item={item}
                    onRunning={() => setSelection({...selection!, row: selection!.row - 1})}
                  />
                )}
                {!!item && (
                  <MoveSectionItemDownButton
                    job={job}
                    section={section!}
                    item={item}
                    onRunning={() => setSelection({...selection!, row: selection!.row + 1})}
                  />
                )}
                {!!item && (
                  <AllocateItemsButton
                    job={job}
                    section={section!}
                    items={items}
                  />
                )}
                {!!section && (
                  <DeleteSectionItemsButton
                    job={job}
                    section={section}
                    items={items}
                    onSuccess={() => setSelection(null)}
                  />
                )}
              </ButtonGroup>
            )
          }}
        />
      )}
      {allSections.length === 0 && (
        <div>
          <Paragraph>There are no sections in the Job yet.</Paragraph>
          <Paragraph>You can create a section on the <Link href={jobPath(job.id, JobTab.SUMMARY)}>Summary tab</Link>.</Paragraph>
        </div>
      )}
    </Fragment>
  )
}