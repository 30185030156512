import {HTMLAttributes} from '../../../../framework/type';
import App from '../../app';
import {h} from 'preact';
import classes from '../../../../framework/classes';

interface Props extends HTMLAttributes {

}

export default function ActiveIndicator(props: Props) {
  const className = App.useStyle(({theme}) => ({
    fontWeight: 900,
  }), []);

  return (<strong {...props} className={classes(className, props.className)} />)
}