import {h} from 'preact';
import currentSession from '../query/currentSession';
import SignInForm from './onboarding/SignInForm';
import Layout from './Layout';
import Heading from './media/Heading';
import Paragraph from './media/Paragraph';
import currentUser from '../query/currentUser';
import TeamList from './onboarding/TeamList';
import {useCallback, useEffect, useState} from 'preact/hooks';
import FieldGroup from './form/FieldGroup';
import Button from './form/Button';
import {useSignOutCommand} from '../command/signOut';
import watchCurrentUserEvents from '../watch/watchCurrentUserEvents';
import {useSelectTeamCommand} from '../command/selectTeam';
import {useLoadUserTeams} from '../command/loadUserTeams';
import currentUserTeams from '../query/currentUserTeams';
import App from '../app';
import Router, {Route} from 'preact-router';
import JobsRoute from './route/Jobs';
import watchCurrentTeamEvents from '../watch/watchCurrentTeamEvents';
import JobRoute from './route/Job';
import TeamRoute from './route/Team';
import CreateTeamButton from './onboarding/CreateTeamButton';
import JoinTeamButton from './onboarding/JoinTeamButton';
import ButtonGroup from './form/ButtonGroup';
import {useClearTeamCommand} from '../command/clearTeam';
import ValuationRoute from './route/Valuation';
import ArchiveRoute from './route/Archive';
import useOfflineDetector from '../helper/useOfflineDetector';

const INVITE_RE = /invite=([^&]+)/;

export default function JobCostingsApp() {
  const offline = useOfflineDetector();

  watchCurrentUserEvents();
  watchCurrentTeamEvents();

  const {auth} = App.useServices();
  const session = currentSession();
  const user = currentUser();
  const [teams] = currentUserTeams();
  const [,selectTeam] = useSelectTeamCommand();
  const [,loadTeams] = useLoadUserTeams();
  const [,signOut] = useSignOutCommand();
  const [,getTeams] = useLoadUserTeams();
  const [,clearTeam] = useClearTeamCommand();
  const [initialCode, setInitialCode] = useState('');


  useEffect(() => {
  }, [setInitialCode, clearTeam]);

  useEffect(() => {
    if (!user || !user.id) return;
    getTeams(user.id);
  }, [session.signedIn]);

  useEffect(() => {
    if (!session.signedIn) return;
    const team = auth.getStoredTeam();

    let inviteCode = '';
    if (typeof window !== 'undefined' && window.location.search) {
      const match = window.location.search.match(INVITE_RE);
      if (match) {
        inviteCode = match[1];
        setInitialCode(inviteCode);
      }
    }

    if (!team) return;

    if (inviteCode) {
      clearTeam();
    } else {
      selectTeam(team);
    }
  }, [session.signedIn]);

  const clearCode = useCallback(() => {
    if (initialCode) window.location.search
      = window.location.search.replace(INVITE_RE, '');
  }, [initialCode]);

  if (offline) return (
    <Layout>
      <Heading level={1}>You are offline</Heading>
      <Paragraph>Please reconnect to the internet to continue</Paragraph>
    </Layout>
  )

  if (!session.signedIn) return (
    <Layout>
      <Heading level={1}>Job Costings</Heading>
      <Paragraph>Please sign in with your email address or Google account</Paragraph>
      <Paragraph>If you don't yet have an account, one will be created when you sign in for the first time.</Paragraph>
      <SignInForm />
    </Layout>
  );

  if (!session.teamSelected) return (
    <Layout>
      <Heading level={1}>Job Costings</Heading>
      {!user || (user && user.status.loading) && (
        <Paragraph>Loading</Paragraph>
      )}
      {user && !user.status.loading && (
        <Paragraph>Welcome back, {user.profile.name}</Paragraph>
      )}
      <TeamList teams={teams.all}/>
      <ButtonGroup>
        <CreateTeamButton/>
        <JoinTeamButton
          initialCode={initialCode}
          onSuccess={clearCode}
          onCancel={clearCode}
        />
      </ButtonGroup>
      <FieldGroup forceHorizontal={true}>
        <Button onClick={signOut}>Sign out</Button>
      </FieldGroup>
    </Layout>
  );

  return (
    <Layout showNavigation={true}>
      <Router>
        <Route exact={true} path="/" component={TeamRoute} />
        <Route path="/jobs" component={JobsRoute} />
        <Route path="/archive" component={ArchiveRoute} />
        <Route path="/job/:jobId/valuation_preview" component={ValuationRoute} />
        <Route path="/job/:jobId/valuation_preview/:tab" component={ValuationRoute} />
        <Route path="/job/:jobId" component={JobRoute} />
        <Route path="/job/:jobId/:tab" component={JobRoute} />
      </Router>
    </Layout>
  )
}