import {Fragment, h} from 'preact';
import Button from '../form/Button';
import {useCallback, useState} from 'preact/hooks';
import App from '../../app';
import {JobAsset} from '../../model/JobAssets';

interface Props {
  asset: JobAsset;
}

export default function DownloadAssetButton({asset}: Props) {
  const {job} = App.useServices();
  const [loading, setLoading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState('');

  const click = useCallback(async () => {
    if (loading || !asset.filePath) return;
    setDownloadUrl('');
    setLoading(true);
    const url = await job.getDownloadUrl(asset.filePath);
    setDownloadUrl(url);
    setLoading(false);
  }, [asset, setDownloadUrl, setLoading, loading]);

  const iframeClass = App.useStyle({
    position: 'fixed',
    top: -5000,
    left: -5000,
    height: 1,
    width: 1,
  });

  return (
    <Fragment>
      <Button small={true} onClick={click} disabled={loading}>
        {loading ? 'Preparing...' : 'Download'}
      </Button>
      {downloadUrl && (
        <iframe src={downloadUrl} className={iframeClass} onLoad={() => setDownloadUrl('')} />
      )}
    </Fragment>
  )
}