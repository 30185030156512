import {h, Ref} from 'preact';
import {HTMLAttributes} from '../../../../framework/type';
import App from '../../app';
import {percent, rem} from 'csx';
import classes from '../../../../framework/classes';

interface Props extends HTMLAttributes {
  innerRef?: Ref<HTMLTextAreaElement>;
}

export default function TextArea({innerRef, ...props}: Props) {
  const className = App.useStyle(({theme}) => ({
    background: theme.color.white.toString(),
    fontFamily: theme.font.text,
    border: `0.125rem solid ${theme.color.black.toString()}`,
    width: percent(100),
    maxWidth: percent(100),
    padding: rem(0.25),
    fontSize: rem(0.75),
    height: rem(1.5),
    userSelect: 'none',
    cursor: 'default',
    lineHeight: rem(1),
    ...theme.component.TextArea,
  }));

  return (
    <textarea {...props} ref={innerRef} className={classes(className, props.className)}/>
  )
}