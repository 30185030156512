import App from '../app';
import {AnyCommand, SIGN_OUT_COMMAND, START_SIGN_IN_WITH_EMAIL_COMMAND} from '../type';

export default function signOut(): AnyCommand {
  return {
    type: SIGN_OUT_COMMAND,
  }
}

export const useSignOutCommand =
  App.wrapCommand(signOut);