import {ApplicationStyle, GlobalStyleHook, StateContext} from './type';
import {useContext, useMemo} from 'preact/hooks';
import {cssRule} from 'typestyle';

export default function createGlobalStyleHook<State>(
  stateContext: StateContext<State>
): GlobalStyleHook<State> {
  return (selector, styleFn, ...dependencies) => {
    const state = useContext(stateContext);
    const cached = useMemo<ApplicationStyle>(
      () => typeof styleFn === 'function'
        ? styleFn(state)
        : styleFn,
      [state, ...dependencies]
    );

    cssRule(selector, cached);
  };
}