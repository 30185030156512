import {CommandResponse} from '../../../../framework/type';

export interface EditorColumn<T> extends EditorColumnSettings<T> {
  type?: EditorColumnType;
  label: string;
  numeric?: boolean;
  renderCell?(item: T, index: number): any;
  renderHeader?(page: number, items: T[], key: keyof T): any;
  renderTotal?(page: number, items: T[], key: keyof T): any;
  stringValue?(item: T): string;
  editable?(item: T | null, key: keyof T): boolean;
  className?: string;
  classes?(item: T | null, key: keyof T): string;
  choices?(item: T | null): EditorChoice[]
}

export interface EditorChoice {
  id: string;
  value: string;
}

export interface EditorSettings<T> {
  columns: Array<EditorColumnSettings<T>>;
}

export interface EditorColumnSettings<T> {
  key: keyof T;
  width: number;
  hide?: boolean;
}

export interface EditorPage<T> {
  name: string;
  subtitle?: string;
  items: T[];
}

export enum EditorColumnType {
  TEXT,
  CURRENCY,
  PERCENTAGE,
  DATE,
}

export function extractSettings<T>(columns: Array<EditorColumn<T>>): EditorSettings<T> {
  return {
    columns: columns.map(column => ({
      key: column.key,
      width: column.width,
      hide: column.hide,
    }))
  }
}

export function querySelectCell({page, row, column}: CellSelection) {
  return `${typeof page !== 'undefined' ? `[data-page="${page}"] `: ''}[data-row="${row}"] td:nth-child(${column + 1})`
}

export interface RowSelection {
  page?: number;
  row: number;
}

export interface ColumnSelection {
  page?: number;
  column: number;
}

export type CellSelection = RowSelection & ColumnSelection;

export type CellSelector = (page: number, column: number, row: number, virtual: boolean, event?: MouseEvent) => void;

export enum EditorIntention {
  NO_OP,
  COMMIT,
  BLUR,
  CANCEL,
  UP,
  RIGHT,
  LEFT,
  DOWN,
  NEXT_SECTION,
  PREV_SECTION,
}

export type IntentionHandler = (intention: EditorIntention, currentValue: string | null, event: Event | null) => any;
export type SaveHandler<Item> = (page: number, item: Item | null, key: keyof Item, value: string | null) => CommandResponse;
export type ActionRenderer<Item> = (items: Item[], selection: CellSelection | null, setSelection: (state: CellSelection | null) => any, pages: Array<EditorPage<Item>>) => any;