import currentSession from './currentSession';
import currentUser from './currentUser';
import {TeamUser} from '../model/Team';
import App from '../app';

export default function currentTeamUsers(): TeamUser[] {
  const {teamId} = currentSession();
  const user = currentUser();

  return App.useQuery(state => {
    const team = state.team.get(teamId.id);
    if (!team) return [];

    return team.users.all;
  })
}