import {Value} from './Value';

export default class BooleanValue extends Value<boolean> {
  static WRONG_TYPE = 'Not a boolean';

  validate() {
    return this.collect([
      this.isBoolean()
    ])
  }

  protected isBoolean() {
    if (typeof this.value !== 'boolean')
      return this.error(BooleanValue.WRONG_TYPE)
  }
}