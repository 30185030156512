import {Job} from '../../model/Job';
import {ActionRenderer, EditorColumn, EditorColumnType, EditorPage, SaveHandler} from '../editor/type';
import {CommandContainer} from '../../../../framework/type';
import {JobCompany} from '../../model/JobCompany';
import TableGroup from '../editor/TableGroup';
import {h} from 'preact';
import renderPercentage from '../../helper/renderPercentage';
import {useEffect, useState} from 'preact/hooks';
import {CompanyId} from '../../model/App';
import {JobCompanyItem} from '../../model/JobCompanyItem';
import renderCurrency from '../../helper/renderCurrency';
import PassiveIndicator from '../media/PassiveIndicator';

interface Props {
  job: Job;
  onSave?: SaveHandler<JobCompany>;
  children?: ActionRenderer<JobCompany>;
  currentCommand?: CommandContainer<any>;
}

export default function CompanySummaryTable({job, onSave, children, currentCommand}: Props) {
  const [companies, setCompanies] = useState<Array<JobCompany>>([]);
  const [pages, setPages] = useState<Array<EditorPage<JobCompany>>>([]);
  const [items, setItems] = useState<Array<Array<JobCompanyItem>>>([]);

  useEffect(() => {
    const companies = [
      JobCompany.fromJSON({}),
      ...job.companies.all,
    ];

    const companyItems = companies.map(c => job.companyItems(new CompanyId(c.id)));

    const pages = [{
      name: '',
      items: companies,
    }];

    setPages(pages);
    setItems(companyItems);
    setCompanies(companies);
  }, [setPages, setItems, setCompanies, job]);

  const renderCell = (key: keyof JobCompany, company: JobCompany) => {
    if (!company) return '';
    const match = companies.findIndex(c => c.id === company.id);
    if (match === -1) return '';

    switch (key) {
      case 'actualMarkup':
        return renderPercentage(company[key](items[match]));
      case 'cost':
      case 'profit':
      case 'charge':
        return renderCurrency(company[key](items[match]));
      default:
        return '';
    }
  };

  const stringValue = (key: keyof JobCompany, company: JobCompany) => {
    if (!company) return '';
    const match = companies.findIndex(c => c.id === company.id);
    if (match === -1) return '';

    switch (key) {
      case 'actualMarkup':
      case 'cost':
      case 'profit':
      case 'charge':
        return company[key](items[match]).toString();
      default:
        return '';
    }
  };

  const editable = (item: JobCompany | null) => {
    return !job.costed && (!item || (!!item && item.id !== ''));
  };

  const columns: Array<EditorColumn<JobCompany>> = [
    {
      key: 'name',
      label: 'Company',
      width: 160,
      editable,
      renderCell: (company) => {
        if (company.id === '') return (
          <PassiveIndicator>Unallocated</PassiveIndicator>
        );

        return company.name;
      }
    },
    {
      key: 'markup',
      label: 'Default',
      width: 80,
      numeric: true,
      editable,
      renderCell: (company) => {
        return renderPercentage(company.markup);
      }
    },
    {
      key: 'actualMarkup',
      label: 'Markup',
      width: 80,
      numeric: true,
      renderCell: renderCell.bind(null, 'actualMarkup'),
      stringValue: stringValue.bind(null, 'actualMarkup'),
      renderTotal: () => {
        return renderPercentage(job.markup)
      }
    },
    {
      key: 'cost',
      label: 'Cost',
      width: 80,
      numeric: true,
      type: EditorColumnType.CURRENCY,
      renderCell: renderCell.bind(null, 'cost'),
      stringValue: stringValue.bind(null, 'cost'),
      renderTotal: () => {
        return renderCurrency(job.cost)
      }
    },
    {
      key: 'profit',
      label: 'Profit',
      width: 80,
      numeric: true,
      type: EditorColumnType.CURRENCY,
      renderCell: renderCell.bind(null, 'profit'),
      stringValue: stringValue.bind(null, 'profit'),
      renderTotal: () => {
        return renderCurrency(job.profit)
      }
    },
    {
      key: 'charge',
      label: 'Charge',
      width: 80,
      numeric: true,
      type: EditorColumnType.CURRENCY,
      renderCell: renderCell.bind(null, 'charge'),
      stringValue: stringValue.bind(null, 'charge'),
      renderTotal: () => {
        return renderCurrency(job.charge)
      }
    }
  ];

  return (
    <TableGroup
      id="company_summary_table"
      columns={columns}
      pages={pages}
      singlePage={pages[0]}
      onSave={onSave}
      currentCommand={currentCommand}
      allowFullScreen={true}
      allowShrinkRows={true}
      expandToFill={true}
      allowCreate={!!onSave}
      children={children}
    />
  )
}