import {Fragment, h} from 'preact';
import ButtonGroup from '../form/ButtonGroup';
import currentTeam from '../../query/currentTeam';
import currentTeamArchive from '../../query/currentTeamArchive';
import UnarchiveJobsButton from '../team/UnarchiveJobsButton';
import ArchiveList from '../team/ArchiveList';

export default function ArchiveRoute() {
  const team = currentTeam();
  const [jobs] = currentTeamArchive();

  if (!team) return null;

  return (
    <Fragment>
      <ArchiveList
        jobs={jobs.all}
        children={(items, selection, setSelection) => (
          <ButtonGroup>
            {selection && (
              <UnarchiveJobsButton
                jobs={items}
                onSuccess={() => setSelection(null)}
              />
            )}
          </ButtonGroup>
        )}
      />
    </Fragment>
  )
}