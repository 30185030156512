import {AnyCommand, LOAD_JOB_COMMAND, LoadJobCommand} from '../type';
import App from '../app';

export default function loadJob(payload: LoadJobCommand['payload']): AnyCommand {
  return {
    type: LOAD_JOB_COMMAND,
    payload,
  }
}

export const useLoadJobCommand = App.wrapCommand(loadJob);