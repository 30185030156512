import {AnyEvent, TEMPLATES_LOADED_EVENT} from '../type';
import {JobJSON} from '../model/Job';

export default function templatesLoaded(teamId: string, templates: Array<JobJSON>): AnyEvent {
  return {
    type: TEMPLATES_LOADED_EVENT,
    payload: {
      teamId,
      templates
    },
  }
}