import {ProxyCommandHandler, ServiceMap} from './type';

export default function bootstrapServices<CommandTypes, Services extends ServiceMap<any, CommandTypes, any>>(
  services: Services,
  proxies: Array<ProxyCommandHandler<CommandTypes>>
): Services {
  Object.keys(services).forEach(service => {
    proxies.forEach(proxy => {
      services[service].register(proxy)
    })
  });

  return services;
}