import Form from '../form/Form';
import {Fragment, h} from 'preact';
import FieldGroup from '../form/FieldGroup';
import {useEffect, useState} from 'preact/hooks';
import Button from '../form/Button';
import useCommandErrors from '../../../../framework/hook/useCommandErrors';
import {CommandContainer, CommandStatus} from '../../../../framework/type';
import useJobImport from '../../query/useJobImport';
import ImportTable from './ImportTable';
import App from '../../app';
import {percent, rem} from 'csx';
import Paragraph from '../media/Paragraph';
import {JobImportMappingJSON} from '../../model/JobImport';
import {useImportJobPagesCommand} from '../../command/importJobPages';
import currentUserTeam from '../../query/currentUserTeam';
import currentUser from '../../query/currentUser';
import JobSection from '../../model/JobSection';
import SectionsTable from './SectionsTable';
import SummaryTable from './SummaryTable';
import Heading from '../media/Heading';
import {route} from 'preact-router';

interface CreateJobFormFields {
  JobName: string;
}

interface Props {
  jobId: string;
  fileId: string;
  onCancel(): void;
  onSuccess(mapping: JobImportMappingJSON): void;
}

function commandsAreRunning(...commands: Array<CommandContainer<any> | null>) {
  return !!commands.find(c => c && c.status === CommandStatus.RUNNING);
}

export default function ImportSectionsForm(props: Props) {
  const {
    jobId,
    fileId,
    onCancel,
    onSuccess
  } = props;

  const team = currentUserTeam();
  const user = currentUser();
  const [jobImportData, loadCommand] = useJobImport(jobId, fileId);
  const [importCommand, importJobPages] = useImportJobPagesCommand();
  const jobImportCommand = useCommandErrors<CreateJobFormFields>(loadCommand);
  const importJobPagesCommand = useCommandErrors<{}>(importCommand);
  const [mapping, setMapping] = useState<JobImportMappingJSON | null>(null);
  const [sections, setSections] = useState<JobSection[]>([]);
  const loading = commandsAreRunning(loadCommand, importCommand);

  const hasMapping = !!mapping;
  const hasSections = hasMapping && sections.length > 0;

  const submit = () => {
    if (!jobImportData || loading || !mapping || !team || !user) return;

    if (!hasSections) {
      setSections(jobImportData.toJobSectionsArray(mapping));
    } else {
      importJobPages({
        fileId,
        jobId,
        teamId: team.id,
        mapping,
      })
    }
  };

  useEffect(() => {
    if (!!mapping && importCommand && importCommand.status === CommandStatus.SUCCESS) {
      onSuccess(mapping);
    }
  }, [importCommand]);

  const cancel = (event: MouseEvent) => {
    event.preventDefault();

    if (hasSections) {
      setSections([]);
      return;
    }

    onCancel();
  };

  const className = App.useStyle(() => ({
    width: rem(64),
    maxWidth: percent(100)
  }));

  const systemError = jobImportCommand.system || importJobPagesCommand.system;

  return (
    <Form className={className} disabled={loading} heading="Import Sections from File" error={systemError}>
      {loading && (
        <Paragraph>
          Please wait while the raw data is imported...
        </Paragraph>
      )}
      {!hasSections && (
        <ImportTable
          existingMapping={mapping}
          data={jobImportData}
          onMapping={setMapping}
        />
      )}
      {hasSections && (
        <Fragment>
          <SummaryTable id={`summary_table_${fileId}`} sections={sections}/>
          <Heading level={6}>Sections preview</Heading>
          <SectionsTable sections={sections} forceQuantity={!!mapping && mapping.hasQuantity}/>
        </Fragment>
      )}
      <FieldGroup horizontal={true}>
        {hasMapping && !hasSections && (
          <Button disabled={loading} primary={true} type="submit" onClick={submit}>{loading ? 'Loading...' : 'Preview import'}</Button>
        )}
        {hasSections && (
          <Button disabled={loading} primary={true} type="submit" onClick={submit}>{loading ? 'Loading...' : 'Import sections'}</Button>
        )}
        <Button disabled={loading} type="cancel" onClick={cancel}>
          {hasSections ? 'Back' : 'Cancel'}
        </Button>
      </FieldGroup>
    </Form>
  )
}