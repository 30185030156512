import {h} from 'preact';
import currentUserTeam from '../query/currentUserTeam';
import {useClearTeamCommand} from '../command/clearTeam';
import {default as Tabs, TabItem} from './media/Tabs';
import App from '../app';
import TeamUserBadge from './team/TeamUserBadge';
import currentUser from '../query/currentUser';

export default function NavigationBar() {
  const team = currentUserTeam();
  const user = currentUser();
  if (!user || !team) return null;

  const [,clearTeam] = useClearTeamCommand();

  const className = App.useStyle(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    $nest: {
      '& > * + *': {
        marginLeft: '1rem !important'
      }
    }
  }), []);

  const leftTabs: TabItem[] = [
    {
      label: 'Jobs',
      href: '/jobs'
    },
    {
      label: 'Archive',
      href: '/archive'
    }
  ];

  const rightTabs: TabItem[] = [
    {
      label: team.name,
      href: '/'
    },
  ];

  return (
    <div className={className}>
      <Tabs
        left={leftTabs}
        right={rightTabs}
      />
      <TeamUserBadge userId={user.id} noName={true}/>
    </div>
  );

  // return (
  //   <div className={className}>
  //     <div>
  //       <Link href="/">Jobs</Link>
  //     </div>
  //     <div>
  //       {team && (
  //         <Button onClick={clearTeam} unstyled={true}>
  //           <UserTeamBadge team={team}/>
  //         </Button>
  //       )}
  //     </div>
  //   </div>
  // )
}