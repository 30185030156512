import {AnyCommand, FINALISE_COSTINGS, FinaliseCostingsCommand} from '../type';
import App from '../app';

export default function finaliseCostings(payload: FinaliseCostingsCommand['payload']): AnyCommand {
  return {
    type: FINALISE_COSTINGS,
    payload,
  }
}

export const useFinaliseCostings = App.wrapCommand(finaliseCostings);