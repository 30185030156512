import {QueryFunction, QueryHook, StateContext} from './type';
import {useContext} from 'preact/hooks';

export default function createQueryHook<State>(
  stateContext: StateContext<State>,
): QueryHook<State> {
  return function<Response>(selector: QueryFunction<State, Response>, ...dependencies: any[]) {
    const state = useContext(stateContext);
    return selector(state);
  }
}