import { VariationId } from '../model/App';
import { useCallback } from 'preact/hooks';
import { Job } from '../model/Job';
import currentTeam from '../query/currentTeam';
import latestCommand from '../../../framework/latestCommand';
import { SaveHandler } from '../component/editor/type';
import { CommandContainer } from '../../../framework/type';
import JobVariationItem, { JobVariationItemCompany } from '../model/JobVariationItem';
import { useAllocateVariationCompanyCommand } from '../command/allocateVariationCompany';
import { useReorderJobVariationItemCommand } from '../command/reorderJobVariationItem';
import { useUpdateJobVariationItemCommand } from '../command/updateJobVariationItem';
import { useCreateJobVariationItemCommand } from '../command/createJobVariationItem';
import coerceDate from '../helper/coerceDate';

export default function saveJobVariationItemCallback(
  job: Job
): [CommandContainer<any>, SaveHandler<JobVariationItem>] {
  const team = currentTeam();
  const [allocateItemCommand, allocateJobSectionItem] = useAllocateVariationCompanyCommand();
  const [reorderItemCommand, reorderJobSectionItem] = useReorderJobVariationItemCommand();
  const [updateItemCommand, updateJobSectionItem] = useUpdateJobVariationItemCommand();
  const [createItemCommand, createJobSectionItem] = useCreateJobVariationItemCommand();

  const commandContainer = latestCommand([allocateItemCommand, reorderItemCommand, updateItemCommand, createItemCommand]);

  const saveItem = useCallback((page: number, target: null | JobVariationItem, key: keyof JobVariationItem, value: string | null) => {
    if (!job || !team) return [];

    const item = target
      ? job.variations.get(target.id)!
      : JobVariationItem.fromJSON({
        id: VariationId.nextId()
      });

    if (!item) return [new Error('Cannot find the item to save to')];

    let nextValue: any;

    function getValue(): JobVariationItem {
      switch (key) {
        case 'instructedAt':
          nextValue = coerceDate(value || '');
          return item.setInstructedAt(nextValue);

        case 'ci':
          nextValue = value || '';
          return item.setCI(nextValue);

        case 'company':
          nextValue = value || '';
          const company = job!.companies.get(nextValue);

          return item.setCompany(company ? {
            id: company.id,
            name: company.name,
            markup: company.markup,
          } : JobVariationItemCompany.fromJSON({}));

        case 'description':
          nextValue = value || '';
          return item.setDescription(nextValue);

        case 'order':
          nextValue = !value ? 0 : parseFloat(value);
          return item.setOrder(nextValue);

        case 'rate':
          nextValue = !value ? 0 : parseFloat(value);
          return item.setRate(nextValue);

        case 'markup':
          nextValue = !value ? 0 : parseFloat(value);
          return item.setMarkup(nextValue);

        case 'po':
          nextValue = value || '';
          return item.setPO(nextValue);

        case 'sentAt':
          nextValue = coerceDate(value || '');
          return item.setSentAt(nextValue);

        case 'proceedAt':
          nextValue = coerceDate(value || '');
          return item.setProceedAt(nextValue);

        case 'commencedAt':
          nextValue = coerceDate(value || '');
          return item.setCommencedAt(nextValue);

        case 'completedAt':
          nextValue = coerceDate(value || '');
          return item.setCompletedAt(nextValue);

        default:
          return item;
      }
    }

    try {
      const next = getValue();
      const errors = next.validate();

      if (errors.length === 0 && next !== item) {
        if (key === 'order') {
          reorderJobSectionItem({
            teamId: team.id,
            jobId: job.id,
            variationId: next.id,
            order: nextValue,
          })
        } else if (key === 'company') {
          allocateJobSectionItem({
            teamId: team.id,
            jobId: job.id,
            variationIds: [next.id],
            companyId: next.company.id,
            company: next.company.toJSON(),
          })
        } else if (target) {
          updateJobSectionItem({
            teamId: team.id,
            jobId: job.id,
            variationId: next.id,
            details: {
              [key]: nextValue
            }
          })
        } else {
          createJobSectionItem({
            teamId: team.id,
            jobId: job.id,
            variationId: next.id,
            details: {
              [key]: nextValue
            }
          });
        }
      }

      return errors;
    } catch (e) {
      return [e];
    }
  }, [job, allocateJobSectionItem, updateJobSectionItem, createJobSectionItem, reorderJobSectionItem]);

  return [
    commandContainer,
    saveItem,
  ]
}
