import { ItemId } from '../model/App';
import { useCallback } from 'preact/hooks';
import { Job } from '../model/Job';
import currentTeam from '../query/currentTeam';
import latestCommand from '../../../framework/latestCommand';
import { SaveHandler } from '../component/editor/type';
import { CommandContainer } from '../../../framework/type';
import { default as JobSectionItem, JobSectionItemCompany, JobSectionItemType } from '../model/JobSectionItem';
import { useAllocateJobCompanyCommand } from '../command/allocateJobCompany';
import { useUpdateJobSectionItemCommand } from '../command/updateJobSectionItem';
import { useUpdateJobSectionItemStatusCommand } from '../command/updateJobSectionItemStatus';
import { useCreateJobSectionItemCommand } from '../command/createJobSectionItem';
import { useReorderJobSectionItemCommand } from '../command/reorderJobSectionItem';

export default function saveJobSectionItemCallback(
  job: Job
): [CommandContainer<any>, SaveHandler<JobSectionItem>] {
  const team = currentTeam();
  const [updateItemStatusCommand, updateItemStatus] = useUpdateJobSectionItemStatusCommand();
  const [allocateItemCommand, allocateJobSectionItem] = useAllocateJobCompanyCommand();
  const [reorderItemCommand, reorderJobSectionItem] = useReorderJobSectionItemCommand();
  const [updateItemCommand, updateJobSectionItem] = useUpdateJobSectionItemCommand();
  const [createItemCommand, createJobSectionItem] = useCreateJobSectionItemCommand();

  const commandContainer = latestCommand([updateItemStatusCommand, allocateItemCommand, reorderItemCommand, updateItemCommand, createItemCommand]);

  const saveItem = useCallback((page: number, target: null | JobSectionItem, key: keyof JobSectionItem, value: string | null) => {
    if (!job || !team) return [];

    const section = job.sections.all[page];
    if (!section) return [new Error('Cannot find the section to save to')];

    const item = target
      ? section.items.get(target.id)!
      : JobSectionItem.fromJSON({
        id: ItemId.nextId()
      });

    if (!item) return [new Error('Cannot find the item to save to')];

    let nextValue: any;

    function getValue(): JobSectionItem {
      switch (key) {
        case 'company':
          nextValue = value || '';
          const company = job!.companies.get(nextValue);

          return item.setCompany(company ? {
            id: company.id,
            name: company.name,
            markup: company.markup,
          } : JobSectionItemCompany.fromJSON({}));

        case 'type':
          nextValue = value || JobSectionItemType.COST;
          return item.setType(nextValue);

        case 'quantity':
          nextValue = !value ? 0 : parseInt(value);
          return item.setQuantity(nextValue);

        case 'order':
          nextValue = !value ? 0 : parseFloat(value);
          return item.setOrder(nextValue);

        case 'rate':
          nextValue = !value ? 0 : parseFloat(value);
          return item.setRate(nextValue);

        case 'markup':
          nextValue = !value ? 0 : parseFloat(value);
          return item.setMarkup(nextValue);

        case 'item':
          nextValue = value || '';
          return item.setItem(nextValue);

        case 'description':
          nextValue = value || '';
          return item.setDescription(nextValue);

        default:
          return item;
      }
    }

    try {
      const next = getValue();
      const errors = next.validate();

      if (errors.length === 0 && next !== item) {
        if (key === 'order') {
          reorderJobSectionItem({
            teamId: team.id,
            jobId: job.id,
            sectionId: section.id,
            itemId: next.id,
            order: nextValue,
          })
        } else if (key === 'company') {
          allocateJobSectionItem({
            teamId: team.id,
            jobId: job.id,
            itemIds: [next.id],
            companyId: next.company.id,
            company: next.company.toJSON(),
          })
        } else if (target) {
          updateJobSectionItem({
            teamId: team.id,
            jobId: job.id,
            sectionId: section.id,
            itemId: next.id,
            details: {
              [key]: nextValue
            }
          })
        } else {
          createJobSectionItem({
            teamId: team.id,
            jobId: job.id,
            sectionId: section.id,
            itemId: next.id,
            details: {
              [key]: nextValue
            }
          });
        }
      }

      return errors;
    } catch (e) {
      return [e];
    }
  }, [job, allocateJobSectionItem, updateJobSectionItem, createJobSectionItem, reorderJobSectionItem, updateItemStatus]);

  return [
    commandContainer,
    saveItem,
  ]
}
