import Form from '../form/Form';
import {h} from 'preact';
import FieldGroup from '../form/FieldGroup';
import Field from '../form/Field';
import Input from '../form/Input';
import {useEffect, useRef} from 'preact/hooks';
import Button from '../form/Button';
import useCommandErrors from '../../../../framework/hook/useCommandErrors';
import {CommandStatus} from '../../../../framework/type';
import autoFocus from '../../effect/autoFocus';
import {useJoinTeam} from '../../command/joinTeam';

interface JoinTeamFormFields {
  TeamUserName: string;
}

interface Props {
  initialCode: string;
  onCancel(): void;
  onSuccess(): void;
}

export default function JoinTeamForm({initialCode, onCancel, onSuccess}: Props) {
  const inviteCode = useRef<HTMLInputElement>();
  const userName = useRef<HTMLInputElement>();

  const [command, joinTeam] = useJoinTeam();
  const {system, field} = useCommandErrors<JoinTeamFormFields>(command);

  const running = command && command.status === CommandStatus.RUNNING;

  const submit = () => {
    if (running || !inviteCode.current || !userName.current) return;
    joinTeam({
      userName: userName.current.value,
      inviteCode: inviteCode.current.value,
    })
  };

  useEffect(() => {
    if (command && command.status === CommandStatus.SUCCESS) {
      onSuccess();
    }
  }, [command]);

  useEffect(() => {
    if (!initialCode || !inviteCode.current) return;
    inviteCode.current.value = initialCode;
  }, [initialCode]);

  autoFocus(initialCode ? userName : inviteCode);

  return (
    <Form disabled={running} heading="Join Team" error={system} onSubmit={submit}>
      <FieldGroup>
        <Field label="Invite code">
          <Input disabled={running || !!initialCode} innerRef={inviteCode} />
        </Field>
        <Field label="User name" error={field.TeamUserName} description="How you will appear to your teammates">
          <Input disabled={running} innerRef={userName} />
        </Field>
      </FieldGroup>
      <FieldGroup horizontal={true}>
        <Button disabled={running} primary={true} type="submit">{running ? 'Joining...' : 'Join Team'}</Button>
        <Button disabled={running} type="cancel" onClick={onCancel}>Cancel</Button>
      </FieldGroup>
    </Form>
  )
}