import {Value} from './Value';

export default class ArrayValue<T extends Value<any>, JSON = any> extends Value<Array<T>> {
  static WRONG_TYPE = 'Not an array';

  validate() {
    return this.collect([
      this.isArray()
    ])
  }

  protected isArray() {
    if (!Array.isArray(this.value))
      return this.error(ArrayValue.WRONG_TYPE)
  }

  toJSON(): JSON[] {
    return this.value.map(v => v.toJSON() as JSON) as JSON[];
  }
}