import {Value} from './Value';
import renderDate from '../../app/JobCostings/helper/renderDate';

export default class UTCDateValue extends Value<string> {
  static WRONG_TYPE = 'Not a UTCDate';
  static WRONG_FORMAT = 'Wrong UTCDate format';

  constructor(
    public value: string
  ) {super(value)}

  validate() {
    return this.collect([
      ...super.validate(),
      this.isUTCDate()
    ])
  }

  get date() {
    return new Date(this.value);
  }

  get isValid() {
    return this.value !== '' && UTCDateValue.DATE_RE.test(this.value)
  }

  isBefore({date}: UTCDateValue) {
    return this.date.getTime() < date.getTime();
  }

  isOnOrAfter(value: UTCDateValue) {
    return !this.isBefore(value);
  }

  dayDifference(value: UTCDateValue) {
    return Math.abs(this.date.getTime() - value.date.getTime()) / 1000 / 60 / 60 / 24;
  }

  toString() {
    return this.isValid
      ? renderDate(this.date)
      : ''
  }

  protected isUTCDate() {
    if (typeof this.value !== 'string')
      return this.error(UTCDateValue.WRONG_TYPE);

    if (this.value && !this.isValid)
      return this.error(UTCDateValue.WRONG_FORMAT);
  }

  static DATE_RE = /^\d{4}(-\d{2}(-\d{2}(T\d{2}:\d{2}(:\d{2}(\.\d+)?)?Z)?)?)?$/;

  static create(unixEpoch?: number) {
    const date = new Date(typeof unixEpoch === 'number' ? unixEpoch : Date.now());
    return new UTCDateValue(date.toISOString());
  }
}