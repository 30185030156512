import {AnyCommand, UPDATE_JOB_SECTION_ITEM_STATUS_COMMAND, UpdateJobSectionItemStatusCommand} from '../type';
import App from '../app';

export default function updateJobSectionItemStatus(payload: UpdateJobSectionItemStatusCommand['payload']): AnyCommand {
  return {
    type: UPDATE_JOB_SECTION_ITEM_STATUS_COMMAND,
    payload,
  }
}

export const useUpdateJobSectionItemStatusCommand = App.wrapCommand(updateJobSectionItemStatus);