import {h} from 'preact';
import App from '../../app';
import {rem} from 'csx';
import {UserTeam} from '../../model/User';

interface Props {
  team: UserTeam
}

export default function UserTeamName({team}: Props) {
  const className = App.useStyle(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
    textAlign: 'left',
    $nest: {
      '& > div:first-child': {
        fontSize: rem(0.875),
        lineHeight: rem(1.25),
      },
      '& > div:last-child': {
        fontSize: rem(0.875),
        fontStyle: 'italic',
        lineHeight: rem(1.25),
        marginLeft: rem(0.5),
      }
    }
  }));

  return (
    <div className={className}>
      <div>{team.userName}</div>
      <div>{team.name}</div>
    </div>
  )
}