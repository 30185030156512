import 'whatwg-fetch';
import 'core-js';
import 'regenerator-runtime/runtime';
import {Application} from './type';
import {ComponentChild, render} from 'preact';

export default function createBrowserEntrypoint<App extends Application<any, any, any, any>>(
  initialize: () => Promise<ComponentChild>
) {
  const el = document.getElementById('app');
  if (!el) return;

  initialize().then(component => render(component, el));
}