import {
  LOAD_USER_TEAMS_COMMAND,
  LoadUserTeamsCommand,
  USER_STATUS_UPDATED_EVENT, USER_TEAM_JOINED_EVENT,
  USER_TEAMS_LOADED_EVENT,
  UserService,
  UserStatusUpdatedEvent, UserTeamJoinedEvent,
  UserTeamsLoadedEvent
} from '../../type';
import {State} from '../../model/State';
import {JobCostingsBaseService} from '../../services';

export default class MemoryUserService extends JobCostingsBaseService implements UserService {
  constructor(
    protected readonly storage: Storage,
  ) {super()}

  async [LOAD_USER_TEAMS_COMMAND](state: State, command: LoadUserTeamsCommand) {
    return this.todo();
  }

  [USER_STATUS_UPDATED_EVENT](state: State, event: UserStatusUpdatedEvent): State {
    return state;
  }

  [USER_TEAM_JOINED_EVENT](state: State, event: UserTeamJoinedEvent): State {
    return state;
  }

  [USER_TEAMS_LOADED_EVENT](state: State, event: UserTeamsLoadedEvent): State {
    return state;
  }
}