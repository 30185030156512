import {h, Fragment} from 'preact';
import {EditorChoice} from './type';
import App from '../../app';
import {percent, px, rem} from 'csx';
import {useCallback, useEffect, useRef} from 'preact/hooks';

interface Props {
  choices: EditorChoice[];
  current?: string;
  style: any;
  arrowOnRight?: boolean;
  highlightChoice?(choice: EditorChoice): any;
  selectChoice?(choice: EditorChoice): any;
}

export default function UserChoices(props: Props) {
  const {
    choices,
    style,
    arrowOnRight,
    current,
    highlightChoice,
    selectChoice,
  } = props;

  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!ref || !ref.current) return;

    const matchIndex = choices.findIndex(c => c.id === current);
    if (matchIndex !== -1) {
      const match = ref.current.querySelector(`:nth-child(${matchIndex + 1})`);
      if (match) {
        match.scrollIntoView();
      }
    }
  }, [current, choices]);

  const className = App.useStyle(({theme}) => ({
    position: 'absolute',
    fontVariantNumeric: 'tabular-nums',
    zIndex: 40,
    fontSize: rem(0.75),
    lineHeight: rem(1.5),
    minHeight: rem(6),
    overflowX: 'hidden',
    overflowY: 'auto',
    background: theme.color.white.toString(),
    whiteSpace: 'pre',
    border: `0.0625rem solid ${theme.color.blue.toString()} !important`,
    $nest: {
      '&::after': {
        position: 'absolute',
        top: 0,
        left: arrowOnRight
          ? percent(100)
          : rem(-1.5),
        display: 'block',
        content: '""',
        border: `0.75rem solid transparent`,
        borderLeftColor: arrowOnRight
          ? theme.color.blue.toString()
          : 'transparent',
        borderRightColor: arrowOnRight
          ? 'transparent'
          : theme.color.blue.toString()
      },
      '& > div': {
        maxWidth: percent(100),
        padding: '0 0.25rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '& > div[data-highlight="true"]': {
        color: theme.color.white.toString(),
        background: theme.color.blue.darken(0.1).toString(),
      },
      '& > div[data-id=""]': {
        fontStyle: 'italic',
      }
    }
  }), [choices]);

  const arrowClass =  App.useStyle(({theme}) => ({
    position: 'absolute',
    top: 0,
    left: arrowOnRight
      ? percent(100)
      : rem(-1.5),
    display: 'block',
    content: '""',
    border: `0.75rem solid transparent`,
    borderLeftColor: arrowOnRight
      ? theme.color.blue.toString()
      : 'transparent',
    borderRightColor: arrowOnRight
      ? 'transparent'
      : theme.color.blue.toString()
  }), [choices]);

  if (choices.length === 0) return null;

  const mouseOver = useCallback((event: MouseEvent) => {
    if (!highlightChoice) return;

    const el = event.target as HTMLDivElement;
    if (!el) return;
    const id = el.dataset['id'];
    const choice = choices.find(c => c.id === id);
    if (choice) highlightChoice(choice);
  }, [choices, highlightChoice]);

  const click = useCallback((event: MouseEvent) => {
    if (!selectChoice) return;

    const el = event.target as HTMLDivElement;
    if (!el) return;
    const id = el.dataset['id'];
    const choice = choices.find(c => c.id === id);
    if (choice) {
      selectChoice(choice);
      event.stopPropagation();
    }
  }, [selectChoice, choices]);

  const arrowStyle = {
    ...style,
    left: arrowOnRight
      ? style.left + style.width
      : style.left,
    width: 0,
    height: 0,
    marginLeft: rem(arrowOnRight ? 0 : -1.5)
  };

  return (
    <Fragment>
      <div className={className} style={style} onMouseOver={mouseOver} onClick={click}>
        {choices.map(choice => (
          <div
            data-id={choice.id}
            data-highlight={choice.id === current}
            children={choice.value}
          />
        ))}
      </div>
      <div className={arrowClass} style={arrowStyle}/>
    </Fragment>
  )
}