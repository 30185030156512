import {h} from 'preact';
import renderCurrency from '../../helper/renderCurrency';
import FieldGroup from '../form/FieldGroup';
import Field from '../form/Field';
import {Team} from '../../model/Team';
import {em, percent, rem} from 'csx';
import App from '../../app';
import ActiveIndicator from '../media/ActiveIndicator';

interface Props {
  team: Team;
  small?: boolean;
  rightAlign?: boolean;
}

export default function TeamStatusLine({team, small, rightAlign = false}: Props) {
  if (!team) return null;

  const {
    cost,
    markup,
    profit,
    charge
  } = team.jobs;

  const className = App.useStyle(({theme}) => ({
    position: 'relative',
    margin: '0 !important',
    fontSize: small ? em(0.75) : em(1),
    $nest: {
      '& > *': {
        lineHeight: small ? em(1.25) : rem(1.5),
        minWidth: small ? rem(5) : rem(8),
        width: percent(25)
      },
      '& label': {
        minWidth: '0 !important'
      }
    }
  }), []);

  return (
    <FieldGroup forceHorizontal={true} className={className}>
      <Field
        label="Cost"
        title={renderCurrency(cost)}
        numeric={rightAlign}
        hideExtra={true}
        truncate={true}
        children={renderCurrency(cost)}
      />
      <Field
        label="Markup"
        title={renderCurrency(markup)}
        numeric={rightAlign}
        hideExtra={true}
        truncate={true}
        children={renderCurrency(markup)}
      />
      <Field
        label="Profit"
        title={renderCurrency(profit)}
        numeric={rightAlign}
        hideExtra={true}
        truncate={true}
        children={renderCurrency(profit)}
      />
      <Field
        label="Charge"
        title={renderCurrency(charge)}
        numeric={rightAlign}
        hideExtra={true}
        truncate={true}
        children={(
          <ActiveIndicator>
            {renderCurrency(charge)}
          </ActiveIndicator>
        )}
      />
    </FieldGroup>
  )
}