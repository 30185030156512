import {Fragment, h} from 'preact';
import Button from '../form/Button';
import Dialog from '../media/Dialog';
import {useCallback, useState} from 'preact/hooks';
import {Job} from '../../model/Job';
import DeleteCompaniesForm from './DeleteCompaniesForm';
import {JobCompany} from '../../model/JobCompany';
import {IconType} from '../media/Icon';

interface Props {
  job: Job;
  companies: JobCompany[];
  onSuccess?(): any;
}

export default function DeleteCompaniesButton({job, companies, onSuccess}: Props) {
  const {length} = companies
  if (length === 0 || (length === 1 && companies[0].id === ''))
    return null;

  const [showDialog, toggleDialog] = useState(false);

  const click = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    toggleDialog(true);
  }, [toggleDialog, job, companies]);

  const cancel = useCallback(() => {
    toggleDialog(false);
  }, [toggleDialog]);

  const success = useCallback(() => {
    toggleDialog(false);
    if (onSuccess) onSuccess();
  }, [toggleDialog, onSuccess]);

  return (
    <Fragment>
      <Button
        small={true}
        danger={true}
        onClick={click}
        onlyIcon={IconType.DELETE_ITEM}
        tooltip={`Delete${length > 1 ? ` ${length}`:''} Compan${length === 1 ? 'y':'ies'}`}
      />
      <Dialog show={showDialog} onRequestClose={cancel}>
        <DeleteCompaniesForm
          job={job}
          companies={companies}
          onCancel={cancel}
          onSuccess={success}
        />
      </Dialog>
    </Fragment>
  )
}