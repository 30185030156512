import {h} from 'preact';
import App from '../../app';
import {rem} from 'csx';
import {UserTeam} from '../../model/User';

interface Props {
  team: UserTeam
}

export default function UserTeamAvatar({team}: Props) {
  const className = App.useStyle(() => ({
    fontWeight: 900,
    fontSize: rem(0.75),
    textAlign: 'center',
    lineHeight: rem(1.25),
    width: rem(1.25),
    height: rem(1.25),
    borderRadius: rem(0.125)
  }));

  return (
    <div
      className={className}
      style={{backgroundColor: team.userColor}}
      children={team.userLetter}
    />
  )
}