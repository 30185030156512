import Form from '../form/Form';
import {h} from 'preact';
import FieldGroup from '../form/FieldGroup';
import Field from '../form/Field';
import Input from '../form/Input';
import {useEffect, useRef} from 'preact/hooks';
import Button from '../form/Button';
import {useCreateTeam} from '../../command/createTeam';
import useCommandErrors from '../../../../framework/hook/useCommandErrors';
import currentSession from '../../query/currentSession';
import {CommandStatus} from '../../../../framework/type';
import autoFocus from '../../effect/autoFocus';

interface CreateTeamFormFields {
  TeamName: string;
  UserName: string;
}

interface Props {
  onCancel(): void;
  onSuccess(): void;
}

export default function CreateTeamForm({onCancel, onSuccess}: Props) {
  const teamName = useRef<HTMLInputElement>();
  const userName = useRef<HTMLInputElement>();

  const {userId} = currentSession();
  const [command, createTeam] = useCreateTeam();
  const {system, field} = useCommandErrors<CreateTeamFormFields>(command);

  const running = command && command.status === CommandStatus.RUNNING;

  const submit = () => {
    if (running || !teamName.current || !userName.current) return;
    createTeam({
      teamName: teamName.current.value,
      userName: userName.current.value,
      userId: userId.id,
      userColor: 210,
    })
  };

  useEffect(() => {
    if (command && command.status === CommandStatus.SUCCESS) {
      onSuccess();
    }
  }, [command]);

  autoFocus(teamName);

  return (
    <Form disabled={running} heading="Create a Team" error={system} onSubmit={submit}>
      <FieldGroup>
        <Field label="Team name" error={field.TeamName}>
          <Input disabled={running} innerRef={teamName} />
        </Field>
        <Field label="User name" error={field.UserName} description="How you will appear to your teammates">
          <Input disabled={running} innerRef={userName} />
        </Field>
      </FieldGroup>
      <FieldGroup horizontal={true}>
        <Button disabled={running} primary={true} type="submit">{running ? 'Creating...' : 'Create Team'}</Button>
        <Button disabled={running} type="cancel" onClick={onCancel}>Cancel</Button>
      </FieldGroup>
    </Form>
  )
}