import Form from '../form/Form';
import {h} from 'preact';
import FieldGroup from '../form/FieldGroup';
import {useCallback, useEffect, useRef, useState} from 'preact/hooks';
import Button from '../form/Button';
import {CommandStatus} from '../../../../framework/type';
import useCommandErrors from '../../../../framework/hook/useCommandErrors';
import commandsAreRunning from '../../../../framework/commandsAreRunning';
import {Job} from '../../model/Job';
import JobSection from '../../model/JobSection';
import Paragraph from '../media/Paragraph';
import currentUserTeam from '../../query/currentUserTeam';
import {useDeleteJobSectionItemsCommand} from '../../command/deleteJobSectionItems';
import JobSectionItem, {JobSectionItemCompany} from '../../model/JobSectionItem';
import Field from '../form/Field';
import Select from '../form/Select';
import autoFocus from '../../effect/autoFocus';
import {useAllocateJobCompanyCommand} from '../../command/allocateJobCompany';

interface Props {
  job: Job;
  section: JobSection;
  items: JobSectionItem[];
  onCancel(): void;
  onSuccess(): void;
}

export default function AllocateItemsForm({job, section, items, onCancel, onSuccess}: Props) {
  const team = currentUserTeam();
  const companyRef = useRef<HTMLSelectElement>();

  const [command, allocateItems] = useAllocateJobCompanyCommand();
  const [allocatingItems, setDeletingItems] = useState(items);
  const {system} = useCommandErrors(command);
  const isLoading = commandsAreRunning(command);

  const submit = useCallback(() => {
    if (items.length === 0 || isLoading || !team || !companyRef || !companyRef.current) return;
    const companyId = companyRef.current.value || '';
    const company = job.companies.get(companyId);

    const companyJSON = company ? {
      id: company.id,
      name: company.name,
      markup: company.markup,
    } : JobSectionItemCompany.fromJSON({});

    setDeletingItems(items);
    allocateItems({
      teamId: team.id,
      jobId: job.id,
      companyId,
      itemIds: items.map(item => item.id),
      company: companyJSON,
    })
  }, [job, team, section, items, isLoading, allocateItems, companyRef]);

  useEffect(() => {
    if (!command || command.status !== CommandStatus.SUCCESS) return;
    onSuccess();
  }, [command]);

  autoFocus(companyRef);

  return (
    <Form heading="Allocate Items" onSubmit={submit} error={system}>
      <Paragraph>Allocate the following item{items.length === 1 ? '' : 's'}:</Paragraph>
      <ul>
        {allocatingItems.map(item => (
          <li>[{item.item}] {item.description}</li>
        ))}
      </ul>
      <Paragraph>to the company:</Paragraph>
      <FieldGroup>
        <Select innerRef={companyRef}>
          <option value="">Unallocated</option>
          <optgroup label="Company">
            {job.companies.all.map(company => (
              <option value={company.id}>{company.name}</option>
            ))}
          </optgroup>
        </Select>
      </FieldGroup>
      <FieldGroup horizontal={true}>
        <Button type="submit" disabled={isLoading} onClick={submit}>Allocate</Button>
        <Button type="cancel" onClick={onCancel}>Cancel</Button>
      </FieldGroup>
    </Form>
  )
}