import {Value} from '../../../framework/value/Value';
import Identity from '../../../framework/value/Identity';
import MapValue from '../../../framework/value/MapValue';

export class AppUrl extends Value<string> {}

export class NumberIndexId extends Identity {}
export class UserId extends Identity {}
export class TeamId extends Identity {}
export class JobId extends Identity {}
export class ValuationId extends Identity {}
export class PageId extends Identity {}
export class CellId extends Identity {}
export class ItemId extends Identity {}
export class InvoiceId extends Identity {}
export class CompanyId extends Identity {}
export class ReportGroupId extends Identity {}
export class EventId extends Identity {}
export class FileId extends Identity {}
export class SectionId extends Identity {}
export class VariationId extends Identity {}

export class AppSession extends MapValue<{
  userId: UserId;
  teamId: TeamId;
}> {
  static EMPTY = new AppSession({
    userId: UserId.NULL,
    teamId: UserId.NULL,
  });

  get signedIn() {
    return !!this.userId.id;
  }

  get teamSelected() {
    return this.signedIn && !!this.teamId.id;
  }

  get userId() {
    return this.value.userId;
  }

  get teamId() {
    return this.value.teamId;
  }
}