import {AnyCommand, DELETE_JOBS_COMMAND, DeleteJobsCommand} from '../type';
import App from '../app';

export default function deleteJobs(payload: DeleteJobsCommand['payload']): AnyCommand {
  return {
    type: DELETE_JOBS_COMMAND,
    payload,
  }
}

export const useDeleteJobsCommand = App.wrapCommand(deleteJobs);