import {AnyEvent, USER_TEAMS_LOADED_EVENT} from '../type';
import {UserTeamJSON} from '../model/User';

export default function userTeamsLoaded(userId: string, teams: Array<UserTeamJSON>): AnyEvent {
  return {
    type: USER_TEAMS_LOADED_EVENT,
    payload: {
      userId,
      teams
    },
  }
}