export default function createMemoryStorage(): Storage {
  let store: {[key: string]: string} = {};

  return {
    getItem: key => store[key],
    setItem: (key, value) => (store[key] = value),
    removeItem: key => {delete store[key]},
    clear: () => (store = {}),
    key: index => Object.keys(store)[index],
    get length() {
      return Object.keys(store).length;
    }
  }
}