import {useCallback, useEffect, useReducer} from 'preact/hooks';
import {EventFunction, ServiceMap} from '../type';

export default function useEventServiceReducer<State, EventTypes>(
  services: ServiceMap<State, any, EventTypes>,
  initialState: State
): [State, EventFunction<EventTypes>] {
  const reducer = useCallback((state: State, event: EventTypes) => {
    console.log('event', event);
    return Object.keys(services).reduce((lastState, key) => {
      return services[key].onEvent(lastState, event)
    }, state)
  }, [services]);

  const [state, dispatch] = useReducer<State, EventTypes>(reducer, initialState);

  useEffect(() => {
    const serviceKeys = Object.keys(services);
    const listener = (event: EventTypes) => dispatch(event);

    serviceKeys.forEach(key => {
      services[key].subscribe(listener)
    });

    return () => serviceKeys.forEach(key => {
      services[key].unsubscribe(listener)
    });
  }, [services]);

  return [state, dispatch];
}