import renderPercentage from '../../helper/renderPercentage';
import {h} from 'preact';
import App from '../../app';
import {percent as pc, rem} from 'csx';

interface Props {
  percent: number;
  difference?: number;
  alwaysShowLabel?: boolean;
  pageBackground?: boolean;
  inline?: boolean;
}

export default function PercentageIndicator({percent, inline, difference = 0, alwaysShowLabel, pageBackground}: Props) {
  const value = Math.min(100, Math.max(0, percent));

  const className = App.useStyle(({theme}) => {
    const shadowColor = pageBackground
      ? theme.color.background.toString()
      : theme.color.white.toString();

    return {
      width: inline
        ? rem(8)
        : pc(100),
      maxWidth: pc(100),
      position: 'relative',
      $nest: {
        '& > div': {
          borderRadius: rem(0.0625),
          zIndex: 0,
          background: theme.color.blue.toString(),
          position: 'absolute',
          transition: `width ${theme.transition.icon}`,
          opacity: value / 100,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        },
        '& > span': {
          position: 'relative',
          display: 'block',
          zIndex: 2,
          opacity: alwaysShowLabel ? 1 : 0,
          transition: `opacity ${theme.transition.icon}`,
          color: theme.color.black.toString(),
          textShadow: [
            `${rem(-0.0625)} ${rem(-0.0625)} ${shadowColor}`,
            `${rem(-0.0625)} ${rem(0.0625)} ${shadowColor}`,
            `${rem(0.0625)} ${rem(-0.0625)} ${shadowColor}`,
            `${rem(0.0625)} ${rem(0.0625)} ${shadowColor}`,
          ].join(', '),
        },
        '&:hover > span': {
          opacity: 1,
        }
      }
    }
  }, []);

  const label = renderPercentage(value, difference);

  return (
    <div className={className} title={label}>
      <div style={{width: pc(value)}} />
      <span>{label}</span>
    </div>
  )
}