import {h} from 'preact';
import {useState} from 'preact/hooks';
import Button from '../form/Button';
import ImportSectionsForm from './ImportSectionsForm';
import {EditorColumn, EditorPage} from '../editor/type';
import TableGroup from '../editor/TableGroup';
import {JobAsset, JobAssetType} from '../../model/JobAssets';
import {Job} from '../../model/Job';
import ButtonGroup from '../form/ButtonGroup';
import UploadScheduleButton from './UploadScheduleButton';
import DownloadAssetButton from './DownloadAssetButton';
import {route} from 'preact-router';
import Form from '../form/Form';

interface Props {
  job: Job;
}

export default function AssetList({job}: Props) {
  if (!job) return null;

  const [showImport, toggleShowImport] = useState(false);
  const [fileId, setFileId] = useState('');

  const jobAssets: JobAsset[] = job.assets ? job.assets.userFacing : [];

  const fileImport = {
    show: toggleShowImport.bind(null, true),
    hide: toggleShowImport.bind(null, false)
  };

  const columns: Array<EditorColumn<JobAsset>> = [
    {
      key: 'label',
      label: 'Type',
      width: 120,
      renderCell: (item) => {
        return item.label
      }
    },
    {
      key: 'id',
      label: 'Actions',
      width: 240,
      numeric: true,
      renderCell: (item) => {
        if (item.id !== JobAssetType.SOURCE_SCHEDULE) {
          return (
            <DownloadAssetButton asset={item}/>
          );
        }

        return (
          <Button
            small={true}
            children="Import"
            onClick={() => {
              setFileId(item.fileId);
              toggleShowImport(true)
            }}
          />
        );
      }
    }
  ];

  const pages: Array<EditorPage<JobAsset>> = [
    {
      name: '',
      items: jobAssets
    }
  ];

  return (
    <Form fill={true}>
      <TableGroup
        id="job_assets"
        columns={columns}
        pages={pages}
        singlePage={pages[0]}
        preventSelect={true}
        expandToFill={true}
        children={(items) => {
          return (
            <ButtonGroup>
              {!job.assets.sourceSchedule && (
                <UploadScheduleButton job={job}/>
              )}
            </ButtonGroup>
          )
        }}
      />
      {showImport && (
        <ImportSectionsForm
          jobId={job.id}
          fileId={fileId}
          onCancel={fileImport.hide}
          onSuccess={(mapping) => {
            fileImport.hide();
            route(`/job/${job.id}/costings`);
          }}
        />
      )}
    </Form>
  )
}