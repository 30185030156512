import {Fragment, h} from 'preact';
import Button from '../form/Button';
import Dialog from '../media/Dialog';
import {useState} from 'preact/hooks';
import {Job} from '../../model/Job';
import FinaliseCostingsForm from './FinaliseCostingsForm';
import {route} from 'preact-router';
import UploadHistoricalJobForm from './UploadHistoricalJobForm';

interface Props {

}

export default function UploadHistoricalJobButton() {
  const [showDialog, toggleDialog] = useState(false);

  const dialog = {
    show: toggleDialog.bind(null, true),
    hide: toggleDialog.bind(null, false)
  };

  return (
    <Fragment>
      <Button small={true} onClick={dialog.show}>
        Import
      </Button>
      <Dialog show={showDialog} onRequestClose={dialog.hide}>
        <UploadHistoricalJobForm
          onCancel={dialog.hide}
          onSuccess={(jobId) => {
            dialog.hide();
            route(`/job/${jobId}`);
          }}
        />
      </Dialog>
    </Fragment>
  )
}