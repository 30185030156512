import App from '../app';
import {AnyCommand} from '../type';
import {CommandContainer} from '../../../framework/type';
import {useEffect} from 'preact/hooks';
import currentSession from './currentSession';
import {useLoadJobsCommand} from '../command/loadJobs';
import {TeamJobs} from '../model/Team';

export default function currentTeamJobs(): [TeamJobs, CommandContainer<AnyCommand>] {
  const {teamId} = currentSession();
  const [command, getJobs] = useLoadJobsCommand();

  const id = teamId.id;

  useEffect(() => {
    if (!id) return;
    getJobs({teamId: id});
  }, [id]);

  const state = App.useQuery(state => {
    const team = state.team.get(id);

    if (!team) return TeamJobs.EMPTY;

    return team.jobs;
  }, [id]);

  return [state, command];
}