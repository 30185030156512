import {h} from 'preact';
import Heading from '../media/Heading';
import useValuation from '../../query/useValuation';
import Tabs, {TabItem} from '../media/Tabs';
import {valuationPath, ValuationTab} from '../../model/Valuation';
import {percent} from 'csx';
import App from '../../app';
import {useCallback} from 'preact/hooks';
import ValuationSectionList from '../valuation/SectionList';
import ValuationSummaryList from '../valuation/SummaryList';
import ValuationStatusLine from '../valuation/ValuationStatusLine';
import saveValuationSectionItemCallback from '../../effect/saveValuationSectionItemCallback';
import StringValue from '../../../../framework/value/StringValue';
import {ValuationInvoice} from '../../model/ValuationInvoice';
import ValuationInvoicesList from '../valuation/InvoicesList';

interface Props {
  jobId: string
  tab: string;
}

export default function ValuationRoute({jobId, tab = ValuationTab.SUMMARY}: Props) {
  const [initialValuation] = useValuation(jobId);
  if (!initialValuation) return null;

  const [valuation, save, setValuation] = saveValuationSectionItemCallback(initialValuation);

  const path = valuationPath.bind(null, jobId);

  const renderTab = useCallback(() => {
    switch (tab) {
      // case ValuationTab.COMPANIES:
      //   return (
      //     <CompanyList valuationId={valuationId}/>
      //   );
      //
      case ValuationTab.PROGRESS:
        return (
          <ValuationSectionList
            valuation={valuation}
            onSave={save}
            onCreateInvoice={() => {
              const nextIndex = valuation.invoices.all.length + 1;
              setValuation(valuation.createInvoice(
                new StringValue(`Invoice ${nextIndex}`)
              ))
            }}
          />
        );

      case ValuationTab.INVOICES:
        return (
          <ValuationInvoicesList valuation={valuation}/>
        );

      case ValuationTab.SUMMARY:
        return (
          <ValuationSummaryList
            valuation={valuation}
          />
        );

      default:
        return null;
    }
  }, [tab, valuation]);

  const leftTabs: TabItem[] = [
    {
      label: 'Summary',
      href: path(ValuationTab.SUMMARY)
    },
    {
      label: 'Progress',
      href: path(ValuationTab.PROGRESS)
    },
    // {
    //   label: 'Purchase Orders',
    //   href: path(ValuationTab.PURCHASE_ORDERS)
    // },
    {
      label: 'Invoices',
      href: path(ValuationTab.INVOICES)
    },
  ];

  const rightTabs: TabItem[] = [
    // {
    //   label: 'Imports',
    //   href: path(ValuationTab.IMPORTS)
    // },
    // {
    //   label: 'Activity',
    //   href: path(ValuationTab.ACTIVITY)
    // }
  ];

  const containerClass = App.useStyle(({theme}) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    height: percent(100),
    maxHeight: percent(100),
    $nest: {
      '& > *:last-child': {
        flex: 1,
        flexDirection: 'row',
        width: percent(100),
        maxWidth: percent(100),
        display: 'flex',
        position: 'relative',
        overflow: 'auto'
      }
    }
  }), []);

  const headingClass = App.useStyle(({theme}) => ({
    width: percent(100),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    $nest: {
      '& > *': {
        margin: '0 !important'
      }
    }
  }), []);

  const currentTab = renderTab();

  return (
    <div className={containerClass}>
      <div>
        <div className={headingClass}>
          <Heading level={4}>{valuation.name}</Heading>
          <ValuationStatusLine valuation={valuation} small={true} rightAlign={true}/>
        </div>
        <Tabs
          left={leftTabs}
          right={rightTabs}
        />
      </div>
      <div>{currentTab}</div>
    </div>
  )
}