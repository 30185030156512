import StringValue from './StringValue';

export class Email extends StringValue {
  validate() {
    return this.collect([
      ...super.validate(),
      this.isValidFormat(),
    ])
  }

  protected isValidFormat() {
    if (this.value && !Email.VALID_RE.test(this.value))
      return this.error('Invalid email format')
  }

  static VALID_RE = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
}