import {h} from 'preact';
import JobSection from '../../model/JobSection';
import TableGroup from '../editor/TableGroup';
import {ActionRenderer, EditorColumn, EditorColumnType, EditorPage, SaveHandler} from '../editor/type';
import {default as JobSectionItem, JobSectionItemType} from '../../model/JobSectionItem';
import {Job} from '../../model/Job';
import renderCurrency from '../../helper/renderCurrency';
import renderPercentage from '../../helper/renderPercentage';
import {CommandContainer} from '../../../../framework/type';
import PassiveIndicator from '../media/PassiveIndicator';
import JobStatusLine from './JobStatusLine';
import ActiveIndicator from '../media/ActiveIndicator';

interface Props {
  job?: Job;
  sections: JobSection[];
  onSave?: SaveHandler<JobSectionItem>;
  currentCommand?: CommandContainer<any>;
  children?: ActionRenderer<JobSectionItem>;
  forceQuantity?: boolean;
  expandToFill?: boolean;
}

export default function SectionsTable({children, expandToFill, forceQuantity, sections, job, onSave, currentCommand}: Props) {
  const editable = (item: JobSectionItem, key: keyof JobSectionItem) => {
    if (!job || job.costed) return false;

    switch (key) {
      case 'included':
      case 'order':
      case 'company':
        return true;

      case 'type':
        return !item || !item.fixedCost;

      case 'rate':
      case 'markup':
        return !item || (!item.included && !item.fixedCost);

      case 'quantity':
      case 'item':
      case 'description':
        return !item || !item.locked;

      default:
        return false;
    }
  };

  const renderHeader = (page: number, _: JobSectionItem[], key: keyof JobSectionItem) => {
    if (!job) return '';
    const section = sections[page];
    if (!section) return '';

    switch (key) {
      case 'cost':
        return renderCurrency(section.cost);
      case 'profit':
        return renderCurrency(section.profit);
      case 'charge':
        return renderCurrency(section.charge);
      case 'markup':
        return renderPercentage(section.markup);

      default:
        return '';
    }
  };

  const columns: Array<EditorColumn<JobSectionItem>> = [
    {
      key: 'order',
      label: '#',
      width: 32,
      numeric: true,
      editable: () => false,
      renderCell: (item) => {
        return item && item.order;
      }
    },
    {
      key: 'item',
      label: 'Item',
      width: 48,
      editable,
      renderHeader,
    },
    {
      key: 'description',
      label: 'Description',
      width: 320,
      renderCell: (item) => {
        return item && item.description;
      },
      editable,
      renderHeader,
    },
    {
      key: 'company',
      label: 'Allocation',
      width: 120,
      renderCell: (item) => {
        if (item && (item.noted || item.excluded)) return (
          <PassiveIndicator>N/A</PassiveIndicator>
        );

        return item && item.company.name;
      },
      stringValue: (item) => {
        return item && item.company.name;
      },
      editable,
      renderHeader,
      choices: () => job
        ? job.companies.choices
        : []
    },
    {
      key: 'type',
      label: 'Type',
      width: 80,
      renderCell: (item) => {
        return item && item.type || ''
      },
      stringValue: (item) => {
        return item && item.type || ''
      },
      editable,
      renderHeader,
      choices: () => job
        ? job.availableItemTypes
        : JobSectionItem.TypeChoices
    },
    {
      key: 'quantity',
      label: 'Qty',
      width: 48,
      numeric: true,
      type: EditorColumnType.CURRENCY,
      renderCell: (item) => {
        if (item && (item.noted || item.excluded || item.included)) return (
          <PassiveIndicator>N/A</PassiveIndicator>
        );

        return item && item.quantity || '';
      },
      editable,
      renderHeader,
    },
    {
      key: 'rate',
      label: 'Unit Rate',
      width: 80,
      numeric: true,
      type: EditorColumnType.CURRENCY,
      renderCell: (item) => {
        if (item && (item.noted || item.excluded || item.included)) return (
          <PassiveIndicator>N/A</PassiveIndicator>
        );

        return item && item.rate ? renderCurrency(item.rate) : '';
      },
      editable,
      renderHeader,
    },
    {
      key: 'markup',
      label: 'Markup',
      width: 80,
      numeric: true,
      type: EditorColumnType.PERCENTAGE,
      renderCell: (item) => {
        if (item && (item.noted || item.excluded || item.included)) return (
          <PassiveIndicator>N/A</PassiveIndicator>
        );

        if (item.trackCompany) return (
          <PassiveIndicator>
            {renderPercentage(item.actualMarkup)}
          </PassiveIndicator>
        );

        return item && item.actualMarkup ? renderPercentage(item.actualMarkup) : '';
      },
      stringValue: (item: JobSectionItem) => {
        return item.trackCompany ? '' : item.markup.toString();
      },
      editable,
      renderHeader,
    },
    {
      key: 'cost',
      label: 'Cost',
      width: 80,
      numeric: true,
      type: EditorColumnType.CURRENCY,
      renderCell: (item) => {
        if (item && (item.noted || item.excluded || item.included)) return (
          <PassiveIndicator>N/A</PassiveIndicator>
        );

        return item && item.cost ? renderCurrency(item.cost) : '';
      },
      editable,
      renderHeader,
    },
    {
      key: 'profit',
      label: 'Profit',
      width: 80,
      numeric: true,
      type: EditorColumnType.CURRENCY,
      renderCell: (item) => {
        if (item && (item.noted || item.excluded || item.included)) return (
          <PassiveIndicator>N/A</PassiveIndicator>
        );

        return item.profit ? renderCurrency(item.profit) : '';
      },
      editable,
      renderHeader,
    },
    {
      key: 'charge',
      label: 'Charge',
      width: 80,
      numeric: true,
      type: EditorColumnType.CURRENCY,
      renderCell: (item) => {
        return item.type === JobSectionItemType.COST || item.fixedCost
          ? item.charge
            ? renderCurrency(item.charge)
            : ''
          : (<ActiveIndicator>{item.type}</ActiveIndicator>);
      },
      editable,
      renderHeader,
    }
  ];

  let filteredColumns = columns;

  if (!forceQuantity && (!job || !job.details.showQuantity)) {
    filteredColumns = filteredColumns.filter(c => c.key !== 'quantity')
  }

  if (!job || !job.details.showOrder) {
    filteredColumns = filteredColumns.filter(c => c.key !== 'order')
  }

  const pages: Array<EditorPage<JobSectionItem>> = sections.map((section, index) => {
    return {
      name: section.name,
      subtitle: `Section ${index + 1}`,
      items: section.items.all
    }
  });

  return (
    <TableGroup
      id="sections_table"
      columns={filteredColumns}
      pages={pages}
      onSave={onSave}
      allowCreate={true}
      children={children}
      currentCommand={currentCommand}
      allowFullScreen={true}
      allowShrinkRows={true}
      expandToFill={expandToFill}
      stickyHeader={job && (
        <JobStatusLine job={job}/>
      )}
      stickOnScroll={true}
    />
  )
}