import {h} from 'preact';
import renderCurrency from '../../helper/renderCurrency';
import renderPercentage from '../../helper/renderPercentage';
import FieldGroup from '../form/FieldGroup';
import Field from '../form/Field';
import {Job} from '../../model/Job';
import {em, percent, rem} from 'csx';
import App from '../../app';
import ActiveIndicator from '../media/ActiveIndicator';

interface Props {
  job: Job;
  small?: boolean;
  rightAlign?: boolean;
}

export default function JobStatusLine({job, small, rightAlign = false}: Props) {
  const className = App.useStyle(({theme}) => ({
    flex: 1,
    position: 'relative',
    fontSize: small ? em(0.75) : em(1),
    $nest: {
      '& > *': {
        lineHeight: small ? em(1.25) : rem(1.5),
        minWidth: small ? rem(5) : rem(8),
        width: percent(25)
      },
      '& label': {
        minWidth: '0 !important'
      }
    }
  }), []);

  return (
    <FieldGroup forceHorizontal={true} className={className}>
      <Field
        label="Cost"
        title={renderCurrency(job.cost)}
        numeric={rightAlign}
        hideExtra={true}
        truncate={true}
        children={renderCurrency(job.cost)}
      />
      <Field
        label="Markup"
        title={renderCurrency(job.markup)}
        numeric={rightAlign}
        hideExtra={true}
        truncate={true}
        children={renderCurrency(job.markup)}
      />
      <Field
        label="Profit"
        title={renderCurrency(job.profit)}
        numeric={rightAlign}
        hideExtra={true}
        truncate={true}
        children={renderCurrency(job.profit)}
      />
      <Field
        label="Charge"
        title={renderCurrency(job.charge)}
        numeric={rightAlign}
        hideExtra={true}
        truncate={true}
        children={(
          <ActiveIndicator>
            {renderCurrency(job.charge)}
          </ActiveIndicator>
        )}
      />
    </FieldGroup>
  )
}