import {
  ALLOCATE_JOB_COMPANY_COMMAND,
  AllocateJobCompanyCommand,
  CREATE_JOB_COMPANY_COMMAND,
  CreateJobCompanyCommand,
  DELETE_JOB_COMPANIES_COMMAND,
  DeleteJobCompaniesCommand,
  JOB_COMPANY_ALLOCATED_EVENT,
  JOB_COMPANY_CREATED_EVENT,
  JOB_COMPANIES_DELETED_EVENT,
  JOB_COMPANY_UPDATED_EVENT,
  JobCompanyAllocatedEvent,
  JobCompanyCreatedEvent,
  JobCompaniesDeletedEvent,
  JobCompanyService,
  JobCompanyUpdatedEvent,
  UPDATE_JOB_COMPANY_COMMAND,
  UpdateJobCompanyCommand
} from '../../type';
import {AsyncCommandResponse} from '../../../../framework/type';
import {JobCostingsBaseService} from '../../services';
import {State} from '../../model/State';

export default class MemoryJobCompanyService extends JobCostingsBaseService implements JobCompanyService {
  constructor(
    protected readonly storage: Storage,
  ) {super()}

  async [CREATE_JOB_COMPANY_COMMAND](state: State, command: CreateJobCompanyCommand): AsyncCommandResponse {
    return this.todo();
  }

  async [UPDATE_JOB_COMPANY_COMMAND](state: State, command: UpdateJobCompanyCommand): AsyncCommandResponse {
    return this.todo();
  }

  async [DELETE_JOB_COMPANIES_COMMAND](state: State, command: DeleteJobCompaniesCommand): AsyncCommandResponse {
    return this.todo();
  }

  async [ALLOCATE_JOB_COMPANY_COMMAND](state: State, command: AllocateJobCompanyCommand): AsyncCommandResponse {
    return this.todo();
  }

  [JOB_COMPANY_CREATED_EVENT](state: State, event: JobCompanyCreatedEvent) {
    return state;
  }

  [JOB_COMPANY_UPDATED_EVENT](state: State, event: JobCompanyUpdatedEvent) {
    return state;
  }

  [JOB_COMPANIES_DELETED_EVENT](state: State, event: JobCompaniesDeletedEvent) {
    return state;
  }

  [JOB_COMPANY_ALLOCATED_EVENT](state: State, event: JobCompanyAllocatedEvent) {
    return state;
  }
}